import React, { useState, useCallback, useEffect } from 'react';

import axios from 'axios';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { SERVER_URL } from '../../../config/index';
import { PERMISSIONS } from '../../../utils/permissions';

import { useAuth } from '../../../context/useAuth';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

import { Main } from '../../../components/content';
import { Collapse } from 'antd';
import { notification } from 'antd';
import CheckboxCms from '../../../components/checkbox-cms';

import CmsContactHeader from './sections/CmsContactHeader';
import { cmsTranslations } from '../../../components/cmsTranslations';

const CmsContact = () => {
  const { user } = useAuth();
  const [groceryPermissions, setGroceryPermissions] = useState([]);
  const [initialData, setInitialData] = useState(null);
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);

  let countryCodeExtension = null;
  const getCountryExtensionFromCountryCode = (countryCode) => {
    switch (countryCode) {
      case '66a76a9f198a9e71fd572334':
        countryCodeExtension = 'sr';
        break;
      case '6740868b48686db16c24f818':
        countryCodeExtension = 'cg';
        break;
    }
  };
  const countryExtension = getCountryExtensionFromCountryCode(user?.countryCodes[0]) || null;
  const translations = cmsTranslations[countryCodeExtension];

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;

    if (user && token) {
      const decodeToken = jwtDecode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
          setGroceryPermissions(permissions.grocery);
        }
      }
    }
  }, [user, location]);

  // GENERAL SETTINGS HANDLE
  const [formGeneralSettings, setFormGeneralSettings] = React.useState({
    hide: false,
  });

  // IMAGES HANDLE
  const [ctImages, setCtImages] = React.useState({
    sectionHeaderImageSecondaryDesktop: '',
    sectionHeaderImagePrimaryDesktop: '',
    sectionHeaderImageSecondaryTablet: '',
    sectionHeaderImagePrimaryTablet: '',
    sectionHeaderImageSecondaryMobile: '',
    sectionHeaderImagePrimaryMobile: '',
  });
  const [ctImagesPreview, setCtImagesPreview] = React.useState({
    sectionHeaderImageSecondaryDesktop: '',
    sectionHeaderImagePrimaryDesktop: '',
    sectionHeaderImageSecondaryTablet: '',
    sectionHeaderImagePrimaryTablet: '',
    sectionHeaderImageSecondaryMobile: '',
    sectionHeaderImagePrimaryMobile: '',
  });
  const [ctImagesErrors, setCtImagesErrors] = React.useState({
    sectionHeaderImageSecondaryDesktop: false,
    sectionHeaderImagePrimaryDesktop: false,
    sectionHeaderImageSecondaryTablet: false,
    sectionHeaderImagePrimaryTablet: false,
    sectionHeaderImageSecondaryMobile: false,
    sectionHeaderImagePrimaryMobile: false,
  });
  // IMAGES HANDLE

  // HEADER HANDLE
  const [formSectionHeader, setFormSectionHeader] = React.useState({
    secondary: {
      headMainHeadingPart1: '',
      headText: '',
    },
    primary: {
      headMainHeadingPart1: '',
      headText: '',
    },
  });
  const [formSectionHeaderErrors, setFormSectionHeaderErrors] = React.useState({
    secondary: {
      headMainHeadingPart1: false,
      headText: false,
    },
    primary: {
      headMainHeadingPart1: false,
      headText: false,
    },
  });
  const handleInputChangeFormSectionHeader = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    setFormSectionHeader((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value,
      },
    }));
  }, []);
  const onChangeTabSectionHeader = useCallback(() => {}, []);
  // HEADER HANDLE

  // HANDLE FORM SUBMIT
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const { token } = user;

    // Initialize error flag
    let hasError = false;

    // Create a copy of the error state
    let newFormSectionHeaderErrors = { ...formSectionHeaderErrors };

    // Validate Section Header (EN)
    Object.keys(formSectionHeader.secondary).forEach((key) => {
      if (!formSectionHeader.secondary[key]) {
        newFormSectionHeaderErrors.secondary[key] = true;
        hasError = true;
      } else {
        newFormSectionHeaderErrors.secondary[key] = false;
      }
    });

    // Validate Section Header (CG)
    Object.keys(formSectionHeader.primary).forEach((key) => {
      if (!formSectionHeader.primary[key]) {
        newFormSectionHeaderErrors.primary[key] = true;
        hasError = true;
      } else {
        newFormSectionHeaderErrors.primary[key] = false;
      }
    });

    // check if there are any fcImages that have value of '' (empty string)
    Object.keys(ctImages).forEach((key) => {
      if (ctImages[key] === null || ctImages[key] === '') {
        setCtImagesErrors((prevState) => ({
          ...prevState,
          [key]: true,
        }));
        hasError = true;
      } else {
        setCtImagesErrors((prevState) => ({
          ...prevState,
          [key]: false,
        }));
      }
    });

    // Update error state
    setFormSectionHeaderErrors(newFormSectionHeaderErrors);

    // Prevent form submission if there are errors
    if (hasError) {
      notification.error({
        message: 'Please fill in all required fields and upload all images',
        placement: 'topRight',
      });
      return;
    }

    setSaving(true);

    const payload = {
      ctHide: formGeneralSettings.hide || false,

      // header
      sectionHeaderMainHeadingPart1Secondary: formSectionHeader.secondary.headMainHeadingPart1 || '',
      sectionHeaderTextSecondary: formSectionHeader.secondary.headText || '',

      sectionHeaderMainHeadingPart1Primary: formSectionHeader.primary.headMainHeadingPart1 || '',
      sectionHeaderTextPrimary: formSectionHeader.primary.headText || '',

      // images
      sectionHeaderImageSecondaryDesktop: ctImages.sectionHeaderImageSecondaryDesktop || null,
      sectionHeaderImagePrimaryDesktop: ctImages.sectionHeaderImagePrimaryDesktop || null,
      sectionHeaderImageSecondaryTablet: ctImages.sectionHeaderImageSecondaryTablet || null,
      sectionHeaderImagePrimaryTablet: ctImages.sectionHeaderImagePrimaryTablet || null,
      sectionHeaderImageSecondaryMobile: ctImages.sectionHeaderImageSecondaryMobile || null,
      sectionHeaderImagePrimaryMobile: ctImages.sectionHeaderImagePrimaryMobile || null,

      countryCode: user?.countryCodes?.[0] || '',
    };

    try {
      if (!initialData || initialData.length === 0) {
        const response = await axios.post(
          `${SERVER_URL}/${AXIOS_API_CALL.createCtDocument}`,
          {
            ...payload,
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      } else {
        const response = await axios.put(
          `${SERVER_URL}/${AXIOS_API_CALL.updateCtDocument}/${initialData[0]?._id}`,
          {
            ...payload,
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
    notification.success({
      message: 'Data saved successfully',
    });
    setSaving(false);
    setTimeout(() => {
      navigate(0);
    }, 1000);
  };
  // HANDLE FORM SUBMIT

  // HANDLE FETCH AND SET INITIAL DATA
  const handleFetchData = async () => {
    const { token } = user;
    try {
      const response = await axios.get(`${SERVER_URL}/${AXIOS_API_CALL.getCtDocument}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });
      setInitialData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!initialData) return;
    setFormGeneralSettings((prevState) => ({
      ...prevState,
      hide: initialData[0]?.ctHide || false,
    }));
    setFormSectionHeader((prevState) => ({
      ...prevState,
      secondary: {
        headMainHeadingPart1: initialData[0]?.sectionHeaderMainHeadingPart1Secondary || '',
        headText: initialData[0]?.sectionHeaderTextSecondary || '',
      },
      primary: {
        headMainHeadingPart1: initialData[0]?.sectionHeaderMainHeadingPart1Primary || '',
        headText: initialData[0]?.sectionHeaderTextPrimary || '',
      },
    }));

    setCtImages((prevState) => ({
      ...prevState,
      sectionHeaderImageSecondaryDesktop: initialData[0]?.sectionHeaderImageSecondaryDesktop?._id || null,
      sectionHeaderImagePrimaryDesktop: initialData[0]?.sectionHeaderImagePrimaryDesktop?._id || null,
      sectionHeaderImageSecondaryTablet: initialData[0]?.sectionHeaderImageSecondaryTablet?._id || null,
      sectionHeaderImagePrimaryTablet: initialData[0]?.sectionHeaderImagePrimaryTablet?._id || null,
      sectionHeaderImageSecondaryMobile: initialData[0]?.sectionHeaderImageSecondaryMobile?._id || null,
      sectionHeaderImagePrimaryMobile: initialData[0]?.sectionHeaderImagePrimaryMobile?._id || null,
    }));
    setCtImagesPreview((prevState) => ({
      ...prevState,
      sectionHeaderImageSecondaryDesktop: initialData[0]?.sectionHeaderImageSecondaryDesktop?.url || null,
      sectionHeaderImagePrimaryDesktop: initialData[0]?.sectionHeaderImagePrimaryDesktop?.url || null,
      sectionHeaderImageSecondaryTablet: initialData[0]?.sectionHeaderImageSecondaryTablet?.url || null,
      sectionHeaderImagePrimaryTablet: initialData[0]?.sectionHeaderImagePrimaryTablet?.url || null,
      sectionHeaderImageSecondaryMobile: initialData[0]?.sectionHeaderImageSecondaryMobile?.url || null,
      sectionHeaderImagePrimaryMobile: initialData[0]?.sectionHeaderImagePrimaryMobile?.url || null,
    }));
  }, [initialData]);

  useEffect(() => {
    handleFetchData();
  }, []);
  // HANDLE FETCH AND SET INITIAL DATA

  // PANEL AND TABS RELATED FUNCTIONS
  const { Panel } = Collapse;
  const onChange = useCallback(() => {}, []);
  // PANEL AND TABS RELATED FUNCTIONS

  const handleInputChangeGeneralSettings = (e) => {
    const { name, checked } = e.target;
    if (name === 'ct_hide') {
      setFormGeneralSettings((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
    }
  };

  return (
    <div className="cms cms-for-companies">
      <h2 className="page-title">CMS Contact</h2>
      {/* Main Content */}
      <form className="cms-form cms-form-section cms-form-section1">
        <Main className="section__content relative">
          <Collapse onChange={onChange}>
            {/* HEADER */}
            <Panel header="HEADER Contact" key="1">
              <CmsContactHeader formSectionHeader={formSectionHeader} handleInputChangeFormSectionHeader={handleInputChangeFormSectionHeader} ctImages={ctImages} ctImagesErrors={ctImagesErrors} ctImagesPreview={ctImagesPreview} setCtImages={setCtImages} formSectionHeaderErrors={formSectionHeaderErrors} countryCodeExtension={countryCodeExtension} translations={translations} />
            </Panel>
            {/* HEADER */}
          </Collapse>
          <CheckboxCms inputName="ct_hide" isChecked={formGeneralSettings.hide} handleChange={handleInputChangeGeneralSettings} label="Hide entire page on website" tooltipText="Checking this will hide entire page on website" />
          <button className="btn btn-primary pl-3 pr-3 mt-3 mb-5" onClick={(e) => handleFormSubmit(e)}>
            {saving ? 'Saving changes...' : 'Save changes'}
          </button>
        </Main>
      </form>
      {/* Main Content */}
    </div>
  );
};
export default CmsContact;
