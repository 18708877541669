import React from 'react';
import Modal from '../../../../components/modal/Modal';

export const DeleteInUpdateAddress = ({ deleteAddressProps, deleteModalAddresFormRef, handleOnSubmit, deleteAddressBtnLoader, setToggleDeleteAddress }) => {
  return (
    <Modal {...deleteAddressProps} className="sidebar__modal--center">
      <form
        name="deleteAddress"
        ref={deleteModalAddresFormRef}
        onSubmit={(event) =>
          handleOnSubmit({
            event: event,
            name: 'deleteAddress',
            state: deleteAddressProps
          })
        }
        className="ant-form ant-form-horizontal"
      >
        <h2 className="text-center mb-4">Are you sure you want to delete this address ?</h2>

        <div className="form-group form-group-modal mb-4">
          {!deleteAddressBtnLoader ? (
            <button data-cy="modal-delete-address-submit-btn" type="submit" className="btn btn-primary-link">
              <span className="text">Yes</span>
            </button>
          ) : (
            <button type="button" className="btn btn-primary-outline">
              <span className="text">Deleting...</span>
            </button>
          )}

          <button data-cy="modal-delete-address-cancel-btn" type="button" className="btn btn-primary" onClick={() => setToggleDeleteAddress(false)}>
            <span className="text">No</span>
          </button>
        </div>
      </form>
    </Modal>
  );
};
