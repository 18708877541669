import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../../context/useAuth';
import { SERVER_URL } from '../../../config/index';
import { Select, notification } from 'antd';
import Table from './Table';
import SelectCustom from '../../../components/customSelect/CustomSelect';
import Loading from '../../../components/loading/Loading';
import LoadingPlaceholder from '../../../components/loadingPlaceholder/LoadingPlaceholder';
import { Main, Section } from '../../../components/content';
import { jwtDecode } from 'jwt-decode';
import { getGroceryPermissions } from '../../../middleware';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { PERMISSIONS } from '../../../utils/permissions';
import { useTranslation } from 'react-i18next';

const options = [5, 10, 20, 50, 100];
const { Option } = Select;

const NyamiRequest = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const location = useLocation();
  const [groceryPermissions, setGroceryPermissions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limitPage, setLimitPage] = useState(10);
  const [list, setList] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [loading, setLoading] = useState(true);

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;

    if (user && token) {
      const decodeToken = jwtDecode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
          setGroceryPermissions(permissions.grocery);
        }
      }
    }
  }, [user, location]);

  // SEARCH
  const [search, setSearch] = useState(null);
  const [searchForm, setSearchForm] = useState({ query: '' });
  const [filterStatus, setFilterStatus] = useState(null);

  const handleOnSubmitSearch = (e) => {
    e.preventDefault();
    setSearch(searchForm.query);
    setRefetch((prevState) => !prevState);
  };

  const handleOnChangeSearch = (value) => {
    setSearchForm({ query: value });
  };

  const handleOnClearSearch = () => {
    setSearch(null);
    setSearchForm({ query: '' });
    setRefetch((prevState) => !prevState);
  };

  const getTableData = useCallback(async () => {
    const { token } = user;
    setLoading(true);
    try {
      await axios
        .get(`${SERVER_URL}/${AXIOS_API_CALL.niamyRequests}/${currentPage}/${limitPage}/${search || null}/${filterStatus || null}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setList(res?.data);
          setTimeout(() => {
            setLoading(false);
          }, 700);
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  }, [user, currentPage, limitPage, search, filterStatus]);

  // FETCH DATA FOR `TABLE`
  useEffect(() => {
    getTableData();
  }, [getTableData, refetch]);

  useEffect(() => {
    if (!!search) {
      setCurrentPage(1);
    }
  }, [search]);

  useEffect(() => {
    setCurrentPage(1);
  }, [limitPage]);

  // FILTER STATUS
  const handleStatusFilter = (value) => {
    setFilterStatus(value);
    setRefetch((prevState) => !prevState);
  };

  // Get selected rows id one or more
  const [checkedRow, setCheckedRow] = useState([]);
  const handleCheckedRows = (data) => {
    setCheckedRow(data);
  };

  // FETCH ROLES
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    const { token } = user;
    try {
      axios
        .post(
          `${SERVER_URL}/${AXIOS_API_CALL.getRoles}/null/null/null`,
          { department: PERMISSIONS.grocery },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setRoles(res.data?.data.Data);
        })
        .catch((err) => console.error(err))
        .finally(setTimeout(() => {}, 700));
    } catch (err) {
      console.error(err);
    }
  }, [user]);

  // TABLE
  const tableProps = {
    handleCheckedRows: handleCheckedRows,
  };

  const tableActionsProps = {
    location: location,
    groceryPermissions: groceryPermissions,
    getGroceryPermissions: getGroceryPermissions,
  };

  const tableFiltersProps = {
    handleStatusFilter: handleStatusFilter,
  };

  // Table Pagination
  const paginationProps = {
    current: currentPage,
    setCurrentPage: setCurrentPage,
    limitPage: limitPage,
    buttonLimit: 3,
    pageCount: list?.pagesCount,
    totalCount: list?.totalCount,
  };

  return (
    <>
      <h2 className="page-title">{t('nyamiRequests')}</h2>

      <Section className="section section__wrapper section__employees">
        {/* Main Header */}
        <header className="section__header">
          {!loading ? (
            <div className="filter">
              <span style={{ color: '#333', fontWeight: '500' }}>{t('show')}:</span>
              <SelectCustom options={options} label={`${t('items')}`} limit={limitPage} setLimit={setLimitPage} setRefetch={setRefetch} loading={loading} />
            </div>
          ) : (
            <LoadingPlaceholder style={{ width: '155.5px', height: '50px' }} />
          )}

          {!loading ? (
            <div className="search">
              <form className="search-form" onSubmit={(e) => handleOnSubmitSearch(e)} data-cy="search-roles">
                <div className="form-group m-0">
                  <span className="icon icon-search">
                    <img src="/assets/icons/search.svg" alt="Search" title="Search" data-cy="search-input-field" />
                  </span>
                  <input value={searchForm.query || ''} type="text" className="input" placeholder={t('search')} onChange={(e) => handleOnChangeSearch(e.target.value)} />
                  <span className={`icon icon-close ${!!searchForm.query && searchForm.query.length > 0 ? 'isActive' : ''}`} onClick={() => handleOnClearSearch()}>
                    <img src="/assets/icons/times.svg" alt="Clear" title="Clear" />
                  </span>
                </div>
                <button className="form-submit" data-cy="search-submit-btn">
                  {t('search')}
                </button>
              </form>
            </div>
          ) : (
            <LoadingPlaceholder style={{ width: '100%', maxWidth: '850px', height: '50px' }} />
          )}
        </header>
        {/* Main Content */}
        <Main className="section__content relative min-h-table-content">{loading ? <Loading /> : <Table data={list.data} {...tableProps} {...tableActionsProps} {...tableFiltersProps} pagination={paginationProps} />}</Main>
      </Section>
    </>
  );
};

export default NyamiRequest;
