import React, { useEffect, useState } from 'react';
import { SERVER_URL } from '../../../config/index';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { useAuth } from '../../../context/useAuth';
import { Button, Upload, notification } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Wrapper } from '../../../components/content';
import { useProducts } from '../../../context/products';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const { Dragger } = Upload;

const GalleryUpload = ({ data }) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { setRefetchGallery, setTabsGallery } = useProducts();
  const [fileList, setFileList] = useState([]);
  const navigate = useNavigate();
  const uploadProps = {
    name: 'file',
    name: 'image',
    accept: '.jpg,.jpeg,.png',
    multiple: true,
    action: `${SERVER_URL}/${AXIOS_API_CALL.slideshowuploadLocalImage}`,
    headers: {
      uri: 'public/images/common/',
      Authorization: `Bearer ${user.token}`,
      slideshow: true,
      positionindex: (data && Number(data?.length)) || 0,
    },
    onChange(info) {
      const { status } = info.file;
      setFileList(info.fileList);

      if (status !== 'uploading') {
      }

      if (status === 'done') {
      }

      if (status === 'error') {
        // if there is an error show notification
        notification.error({
          message: info.file.response.message,
        });
      }
    },
    onDrop(e) {
      console.warn('Dropped files', e.dataTransfer.files);
    },
  };

  useEffect(() => {
    if (fileList && fileList.length > 0) {
      const isCheckFileUploaded = fileList.every((file) => file.status === 'done');

      if (isCheckFileUploaded) {
        setRefetchGallery((p) => !p);
        setTabsGallery(1);

        notification.success({
          message: t('successImageUpload'),
          placement: 'bottomRight',
        });
        navigate('/admin/grocery');
        setTimeout(() => {
          navigate('/admin/grocery/slideshow');
        }, 1);
      }
    }
  }, [fileList, setRefetchGallery, setTabsGallery]);

  return (
    <Dragger className='upload-area' {...uploadProps}>
      <Wrapper className='relative'>
        <div className='block relative'>
          <span className='image'>
            <img alt='Upload' src='/assets/icons/download-media.svg' />
          </span>
          <h3>{t('dragAndDropImageToUpload')}</h3>
          <p>or</p>

          <Button className='btn btn-primary' icon={<UploadOutlined />}>
            {t('uploadImage')}
          </Button>

          {/* <p>Maksimalna veličina fajla za uvoz: 2 MB.</p> */}
        </div>
      </Wrapper>
    </Dragger>
  );
};

export default GalleryUpload;
