import React, { memo } from 'react';
import { Tabs } from 'antd';
import Section3En from '../tabs/en/Section3En';
import Section3Cg from '../tabs/cg/Section3Cg';
import CheckboxCms from '../../../../components/checkbox-cms';
import { useTranslation } from 'react-i18next';

const CmsHomepageSection3 = ({ onChangeTabSection3, formSection3, handleInputChangeFormSection3, setHpImages, hpImages, hpImagesPreview, formSection3Errors, hpImagesErrors }) => {
  const { t } = useTranslation();
  const { TabPane } = Tabs;

  const sectionProps = {
    formSection3,
    handleInputChangeFormSection3,
    setHpImages,
    hpImages,
    hpImagesPreview,
    formSection3Errors,
    hpImagesErrors,
  };
  return (
    <>
      <Tabs defaultActiveKey='1' onChange={onChangeTabSection3}>
        <TabPane key='1' tab='EN'>
          <Section3En {...sectionProps} />
        </TabPane>
        <TabPane key='2' tab='CG'>
          <Section3Cg {...sectionProps} />
        </TabPane>
      </Tabs>
      <CheckboxCms inputName='section3_hide' isChecked={formSection3.hide} handleChange={handleInputChangeFormSection3} label={t('hideEntireSectionOnWebsite')} tooltipText={t('checkingWillHide')} />
    </>
  );
};
export default memo(CmsHomepageSection3);
