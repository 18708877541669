import React from 'react';
import Message from '../../components/message/Message';
import { contentMessage } from '../../helpers/contentMessage';
import Pagination from '../../components/pagination/Pagination';
import { formatTime, formatDate } from '../../helpers/helpers';
import { useTranslation } from 'react-i18next';

const Table = (props) => {
  const { t } = useTranslation();
  // TABLE
  const { data, handleToggle } = props;
  // PAGINATION
  const { pagination } = props;

  return (
    <>
      {data && data.length > 0 ? (
        <>
          <table className='table'>
            <thead className='thead'>
              <tr>
                <th>{t('date')}</th>
                <th>{t('time')}</th>
                <th>{t('activity')}</th>
                <th>{t('data')}</th>
              </tr>
            </thead>

            <tbody className='tbody'>
              {data && data.length > 0 ? (
                data.map((item) => {
                  return (
                    <tr key={item._id}>
                      <td>{formatDate(item)}</td>
                      <td>{formatTime(item)}</td>
                      <td>{item.description}</td>
                      <td>
                        <button type='button' className='btn btn-download' onClick={() => handleToggle({ name: 'download', data: item._id })}>
                          <span className='icon'>
                            <img alt='Download' src='/assets/icons/download.svg' />
                          </span>
                          <span className='text'>{t('downloadData')}</span>
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={20} style={{ backgroundColor: '#fff', height: '200px', border: '0' }}>
                    <h2 style={{ color: '#666' }}>{t('dataNotFound')}</h2>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination {...pagination} />
        </>
      ) : (
        <Message className='content-message'>
          <h2 style={{ color: '#666' }}>{t('dataNotFound')}</h2>
        </Message>
      )}
    </>
  );
};

export default Table;
