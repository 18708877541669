import { TabsContent } from './TabsContent';
import { TabsHeader } from './TabsHeader';

export const TabsPanel = (props) => {
  const { tabs, setTabs, tabsContent } = props;

  return (
    <div className="tabs__panel">
      <TabsHeader tabs={tabs} setTabs={setTabs} tabsContent={tabsContent} />
      <TabsContent tabs={tabs} setTabs={setTabs} tabsContent={tabsContent} />
    </div>
  );
};
