import React, { useState, useCallback, useEffect } from 'react';
import { Main } from '../../../components/content';
import { Collapse } from 'antd';
import CmsForCompaniesSection1 from './sections/CmsForCompaniesSection1';
import CmsForCompaniesSection2 from './sections/CmsForCompaniesSection2';
import CmsForCompaniesSection3 from './sections/CmsForCompaniesSection3';
import CmsForCompaniesHeader from './sections/CmsForCompaniesHeader';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { useAuth } from '../../../context/useAuth';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { PERMISSIONS } from '../../../utils/permissions';
import { SERVER_URL } from '../../../config/index';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';

import CheckboxCms from '../../../components/checkbox-cms';

const CmsForCompanies = () => {
  const { user } = useAuth();
  const [groceryPermissions, setGroceryPermissions] = useState([]);
  const [initialData, setInitialData] = useState(null);
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;

    if (user && token) {
      const decodeToken = jwtDecode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
          setGroceryPermissions(permissions.grocery);
        }
      }
    }
  }, [user, location]);

  // IMAGES HANDLE
  const [fcImages, setFcImages] = React.useState({
    sectionHeaderImageEn: '',
    sectionHeaderImageCg: '',

    section1Image1En: '',
    section1Image2En: '',
    section1Image3En: '',
    section2ImageEn: '',
    section3Image1En: '',
    section3Image2En: '',
    section3Image3En: '',
    section3Image4En: '',

    section1Image1Cg: '',
    section1Image2Cg: '',
    section1Image3Cg: '',
    section2ImageCg: '',
    section3Image1Cg: '',
    section3Image2Cg: '',
    section3Image3Cg: '',
    section3Image4Cg: '',
  });
  const [fcImagesPreview, setFcImagesPreview] = React.useState({
    sectionHeaderImageEn: '',
    sectionHeaderImageCg: '',

    section1Image1En: '',
    section1Image2En: '',
    section1Image3En: '',
    section2ImageEn: '',
    section3Image1En: '',
    section3Image2En: '',
    section3Image3En: '',
    section3Image4En: '',

    section1Image1Cg: '',
    section1Image2Cg: '',
    section1Image3Cg: '',
    section2ImageCg: '',
    section3Image1Cg: '',
    section3Image2Cg: '',
    section3Image3Cg: '',
    section3Image4Cg: '',
  });
  const [fcImagesErrors, setFcImagesErrors] = React.useState({
    sectionHeaderImageEn: false,
    sectionHeaderImageCg: false,

    section1Image1En: false,
    section1Image2En: false,
    section1Image3En: false,
    section2ImageEn: false,
    section3Image1En: false,
    section3Image2En: false,
    section3Image3En: false,
    section3Image4En: false,

    section1Image1Cg: false,
    section1Image2Cg: false,
    section1Image3Cg: false,
    section2ImageCg: false,
    section3Image1Cg: false,
    section3Image2Cg: false,
    section3Image3Cg: false,
    section3Image4Cg: false,
  });
  // IMAGES HANDLE
  // HEADER HANDLE
  const [formSectionHeader, setFormSectionHeader] = React.useState({
    en: {
      headMainHeadingPart1: '',
      headMainHeadingPart2: '',
    },
    cg: {
      headMainHeadingPart1: '',
      headMainHeadingPart2: '',
    },
  });
  const [formSectionHeaderErrors, setFormSectionHeaderErrors] = React.useState({
    en: {
      headMainHeadingPart1: false,
      headMainHeadingPart2: false,
    },
    cg: {
      headMainHeadingPart1: false,
      headMainHeadingPart2: false,
    },
  });
  const handleInputChangeFormSectionHeader = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    setFormSectionHeader((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value,
      },
    }));
  }, []);
  const onChangeTabSectionHeader = useCallback(() => {}, []);
  // HEADER HANDLE
  const [formGeneralSettings, setFormGeneralSettings] = React.useState({
    hide: false,
  });
  // SECTION 1 HANDLE
  const [formSection1, setFormSection1] = React.useState({
    hide: false,
    en: {
      sec1MainHeadingPart1: '',
      sec1MainHeadingPart2: '',
      sec1Benefit1Text: '',
      sec1Benefit1Resource: '',
      sec1Benefit2Text: '',
      sec1Benefit2Resource: '',
      sec1Benefit3Text: '',
      sec1Benefit3Resource: '',
    },
    cg: {
      sec1MainHeadingPart1: '',
      sec1MainHeadingPart2: '',
      sec1Benefit1Text: '',
      sec1Benefit1Resource: '',
      sec1Benefit2Text: '',
      sec1Benefit2Resource: '',
      sec1Benefit3Text: '',
      sec1Benefit3Resource: '',
    },
  });
  const [formSection1Errors, setFormSection1Errors] = React.useState({
    en: {
      sec1MainHeadingPart1: false,
      sec1MainHeadingPart2: false,
      sec1Benefit1Text: false,
      sec1Benefit1Resource: false,
      sec1Benefit2Text: false,
      sec1Benefit2Resource: false,
      sec1Benefit3Text: false,
      sec1Benefit3Resource: false,
    },
    cg: {
      sec1MainHeadingPart1: false,
      sec1MainHeadingPart2: false,
      sec1Benefit1Text: false,
      sec1Benefit1Resource: false,
      sec1Benefit2Text: false,
      sec1Benefit2Resource: false,
      sec1Benefit3Text: false,
      sec1Benefit3Resource: false,
    },
  });
  const handleInputChangeFormSection1 = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    if (name === 'section1_hide') {
      setFormSection1((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
      return;
    }

    setFormSection1((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value,
      },
    }));
  }, []);
  const onChangeTabSection1 = useCallback(() => {}, []);
  // SECTION 1 HANDLE

  // SECTION 2 HANDLE
  const [formSection2, setFormSection2] = React.useState({
    hide: false,
    en: {
      sec2MainHeadingPart1: '',
      sec2MainHeadingPart2: '',
      sec2Advantage1Title: '',
      sec2Advantage1Text: '',
      sec2Advantage2Title: '',
      sec2Advantage2Text: '',
      sec2Advantage3Title: '',
      sec2Advantage3Text: '',
      sec2Advantage4Title: '',
      sec2Advantage4Text: '',
    },
    cg: {
      sec2MainHeadingPart1: '',
      sec2MainHeadingPart2: '',
      sec2Advantage1Title: '',
      sec2Advantage1Text: '',
      sec2Advantage2Title: '',
      sec2Advantage2Text: '',
      sec2Advantage3Title: '',
      sec2Advantage3Text: '',
      sec2Advantage4Title: '',
      sec2Advantage4Text: '',
    },
  });
  const [formSection2Errors, setFormSection2Errors] = React.useState({
    en: {
      sec2MainHeadingPart1: false,
      sec2MainHeadingPart2: false,
      sec2Advantage1Title: false,
      sec2Advantage1Text: false,
      sec2Advantage2Title: false,
      sec2Advantage2Text: false,
      sec2Advantage3Title: false,
      sec2Advantage3Text: false,
      sec2Advantage4Title: false,
      sec2Advantage4Text: false,
    },
    cg: {
      sec2MainHeadingPart1: false,
      sec2MainHeadingPart2: false,
      sec2Advantage1Title: false,
      sec2Advantage1Text: false,
      sec2Advantage2Title: false,
      sec2Advantage2Text: false,
      sec2Advantage3Title: false,
      sec2Advantage3Text: false,
      sec2Advantage4Title: false,
      sec2Advantage4Text: false,
    },
  });
  const handleInputChangeFormSection2 = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    if (name === 'section2_hide') {
      setFormSection2((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
      return;
    }

    setFormSection2((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value,
      },
    }));
  }, []);
  const onChangeTabSection2 = useCallback(() => {}, []);
  // SECTION 2 HANDLE

  // SECTION 3 HANDLE
  const [formSection3, setFormSection3] = React.useState({
    hide: false,
    en: {
      sec3MainHeading: '',
      sec3Card1Title: '',
      sec3Card1Text: '',
      sec3Card2Title: '',
      sec3Card2Text: '',
      sec3Card3Title: '',
      sec3Card3Text: '',
      sec3Card4Title: '',
      sec3Card4Text: '',
    },
    cg: {
      sec3MainHeading: '',
      sec3Card1Title: '',
      sec3Card1Text: '',
      sec3Card2Title: '',
      sec3Card2Text: '',
      sec3Card3Title: '',
      sec3Card3Text: '',
      sec3Card4Title: '',
      sec3Card4Text: '',
    },
  });
  const [formSection3Errors, setFormSection3Errors] = React.useState({
    en: {
      sec3MainHeading: false,
      sec3Card1Title: false,
      sec3Card1Text: false,
      sec3Card2Title: false,
      sec3Card2Text: false,
      sec3Card3Title: false,
      sec3Card3Text: false,
      sec3Card4Title: false,
      sec3Card4Text: false,
    },
    cg: {
      sec3MainHeading: false,
      sec3Card1Title: false,
      sec3Card1Text: false,
      sec3Card2Title: false,
      sec3Card2Text: false,
      sec3Card3Title: false,
      sec3Card3Text: false,
      sec3Card4Title: false,
      sec3Card4Text: false,
    },
  });
  const handleInputChangeFormSection3 = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    if (name === 'section3_hide') {
      setFormSection3((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
      return;
    }

    setFormSection3((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value,
      },
    }));
  }, []);
  const onChangeTabSection3 = useCallback(() => {}, []);
  // SECTION 3 HANDLE

  // HANDLE FORM SUBMIT
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const { token } = user;

    // Initialize error flag
    let hasError = false;

    // Create a copy of the error state
    let newFormSection1Errors = { ...formSection1Errors };
    let newFormSection2Errors = { ...formSection2Errors };
    let newFormSection3Errors = { ...formSection3Errors };
    let newFormSectionHeaderErrors = { ...formSectionHeaderErrors };

    // Validate Section 1 (EN)
    Object.keys(formSection1.en).forEach((key) => {
      if (!formSection1.en[key]) {
        newFormSection1Errors.en[key] = true;
        hasError = true;
      } else {
        newFormSection1Errors.en[key] = false;
      }
    });

    // Validate Section 1 (CG)
    Object.keys(formSection1.cg).forEach((key) => {
      if (!formSection1.cg[key]) {
        newFormSection1Errors.cg[key] = true;
        hasError = true;
      } else {
        newFormSection1Errors.cg[key] = false;
      }
    });

    // Validate Section 2 (EN)
    Object.keys(formSection2.en).forEach((key) => {
      if (!formSection2.en[key]) {
        newFormSection2Errors.en[key] = true;
        hasError = true;
      } else {
        newFormSection2Errors.en[key] = false;
      }
    });

    // Validate Section 2 (CG)
    Object.keys(formSection2.cg).forEach((key) => {
      if (!formSection2.cg[key]) {
        newFormSection2Errors.cg[key] = true;
        hasError = true;
      } else {
        newFormSection2Errors.cg[key] = false;
      }
    });

    // Validate Section 3 (EN)
    Object.keys(formSection3.en).forEach((key) => {
      if (!formSection3.en[key]) {
        newFormSection3Errors.en[key] = true;
        hasError = true;
      } else {
        newFormSection3Errors.en[key] = false;
      }
    });

    // Validate Section 3 (CG)
    Object.keys(formSection3.cg).forEach((key) => {
      if (!formSection3.cg[key]) {
        newFormSection3Errors.cg[key] = true;
        hasError = true;
      } else {
        newFormSection3Errors.cg[key] = false;
      }
    });

    // Validate Section Header (EN)
    Object.keys(formSectionHeader.en).forEach((key) => {
      if (!formSectionHeader.en[key]) {
        newFormSectionHeaderErrors.en[key] = true;
        hasError = true;
      } else {
        newFormSectionHeaderErrors.en[key] = false;
      }
    });

    // Validate Section Header (CG)
    Object.keys(formSectionHeader.cg).forEach((key) => {
      if (!formSectionHeader.cg[key]) {
        newFormSectionHeaderErrors.cg[key] = true;
        hasError = true;
      } else {
        newFormSectionHeaderErrors.cg[key] = false;
      }
    });

    // check if there are any fcImages that have value of '' (empty string)
    Object.keys(fcImages).forEach((key) => {
      if (fcImages[key] === null || fcImages[key] === '') {
        setFcImagesErrors((prevState) => ({
          ...prevState,
          [key]: true,
        }));
        hasError = true;
      } else {
        setFcImagesErrors((prevState) => ({
          ...prevState,
          [key]: false,
        }));
      }
    });

    // Update error state
    setFormSection1Errors(newFormSection1Errors);
    setFormSection2Errors(newFormSection2Errors);
    setFormSection3Errors(newFormSection3Errors);
    setFormSectionHeaderErrors(newFormSectionHeaderErrors);

    // Prevent form submission if there are errors
    if (hasError) {
      notification.error({
        message: 'Please fill in all required fields and upload all images',
        placement: 'topRight',
      });
      return;
    }

    setSaving(true);

    const payload = {
      fcHide: formGeneralSettings.hide || false,

      section1Hide: formSection1.hide || false,
      section2Hide: formSection2.hide || false,
      section3Hide: formSection3.hide || false,

      // header
      sectionHeaderMainHeadingPart1En: formSectionHeader.en.headMainHeadingPart1 || '',
      sectionHeaderMainHeadingPart2En: formSectionHeader.en.headMainHeadingPart2 || '',

      sectionHeaderMainHeadingPart1Cg: formSectionHeader.cg.headMainHeadingPart1 || '',
      sectionHeaderMainHeadingPart2Cg: formSectionHeader.cg.headMainHeadingPart2 || '',

      section1MainHeadingPart1En: formSection1.en.sec1MainHeadingPart1 || '',
      section1MainHeadingPart2En: formSection1.en.sec1MainHeadingPart2 || '',
      section1Benefit1TextEn: formSection1.en.sec1Benefit1Text || '',
      section1Benefit1ResourceEn: formSection1.en.sec1Benefit1Resource || '',
      section1Benefit2TextEn: formSection1.en.sec1Benefit2Text || '',
      section1Benefit2ResourceEn: formSection1.en.sec1Benefit2Resource || '',
      section1Benefit3TextEn: formSection1.en.sec1Benefit3Text || '',
      section1Benefit3ResourceEn: formSection1.en.sec1Benefit3Resource || '',

      section1MainHeadingPart1Cg: formSection1.cg.sec1MainHeadingPart1 || '',
      section1MainHeadingPart2Cg: formSection1.cg.sec1MainHeadingPart2 || '',
      section1Benefit1TextCg: formSection1.cg.sec1Benefit1Text || '',
      section1Benefit1ResourceCg: formSection1.cg.sec1Benefit1Resource || '',
      section1Benefit2TextCg: formSection1.cg.sec1Benefit2Text || '',
      section1Benefit2ResourceCg: formSection1.cg.sec1Benefit2Resource || '',
      section1Benefit3TextCg: formSection1.cg.sec1Benefit3Text || '',
      section1Benefit3ResourceCg: formSection1.cg.sec1Benefit3Resource || '',

      section2MainHeadingPart1En: formSection2.en.sec2MainHeadingPart1 || '',
      section2MainHeadingPart2En: formSection2.en.sec2MainHeadingPart2 || '',
      section2Advantage1TitleEn: formSection2.en.sec2Advantage1Title || '',
      section2Advantage1TextEn: formSection2.en.sec2Advantage1Text || '',
      section2Advantage2TitleEn: formSection2.en.sec2Advantage2Title || '',
      section2Advantage2TextEn: formSection2.en.sec2Advantage2Text || '',
      section2Advantage3TitleEn: formSection2.en.sec2Advantage3Title || '',
      section2Advantage3TextEn: formSection2.en.sec2Advantage3Text || '',
      section2Advantage4TitleEn: formSection2.en.sec2Advantage4Title || '',
      section2Advantage4TextEn: formSection2.en.sec2Advantage4Text || '',

      section2MainHeadingPart1Cg: formSection2.cg.sec2MainHeadingPart1 || '',
      section2MainHeadingPart2Cg: formSection2.cg.sec2MainHeadingPart2 || '',
      section2Advantage1TitleCg: formSection2.cg.sec2Advantage1Title || '',
      section2Advantage1TextCg: formSection2.cg.sec2Advantage1Text || '',
      section2Advantage2TitleCg: formSection2.cg.sec2Advantage2Title || '',
      section2Advantage2TextCg: formSection2.cg.sec2Advantage2Text || '',
      section2Advantage3TitleCg: formSection2.cg.sec2Advantage3Title || '',
      section2Advantage3TextCg: formSection2.cg.sec2Advantage3Text || '',
      section2Advantage4TitleCg: formSection2.cg.sec2Advantage4Title || '',
      section2Advantage4TextCg: formSection2.cg.sec2Advantage4Text || '',

      section3MainHeadingEn: formSection3.en.sec3MainHeading || '',
      section3Card1TitleEn: formSection3.en.sec3Card1Title || '',
      section3Card1TextEn: formSection3.en.sec3Card1Text || '',
      section3Card2TitleEn: formSection3.en.sec3Card2Title || '',
      section3Card2TextEn: formSection3.en.sec3Card2Text || '',
      section3Card3TitleEn: formSection3.en.sec3Card3Title || '',
      section3Card3TextEn: formSection3.en.sec3Card3Text || '',
      section3Card4TitleEn: formSection3.en.sec3Card4Title || '',
      section3Card4TextEn: formSection3.en.sec3Card4Text || '',

      section3MainHeadingCg: formSection3.cg.sec3MainHeading || '',
      section3Card1TitleCg: formSection3.cg.sec3Card1Title || '',
      section3Card1TextCg: formSection3.cg.sec3Card1Text || '',
      section3Card2TitleCg: formSection3.cg.sec3Card2Title || '',
      section3Card2TextCg: formSection3.cg.sec3Card2Text || '',
      section3Card3TitleCg: formSection3.cg.sec3Card3Title || '',
      section3Card3TextCg: formSection3.cg.sec3Card3Text || '',
      section3Card4TitleCg: formSection3.cg.sec3Card4Title || '',
      section3Card4TextCg: formSection3.cg.sec3Card4Text || '',

      // images
      sectionHeaderImageEn: fcImages.sectionHeaderImageEn || null,
      sectionHeaderImageCg: fcImages.sectionHeaderImageCg || null,

      section1Image1En: fcImages.section1Image1En || null,
      section1Image2En: fcImages.section1Image2En || null,
      section1Image3En: fcImages.section1Image3En || null,
      section2ImageEn: fcImages.section2ImageEn || null,
      section3Image1En: fcImages.section3Image1En || null,
      section3Image2En: fcImages.section3Image2En || null,
      section3Image3En: fcImages.section3Image3En || null,
      section3Image4En: fcImages.section3Image4En || null,

      section1Image1Cg: fcImages.section1Image1Cg || null,
      section1Image2Cg: fcImages.section1Image2Cg || null,
      section1Image3Cg: fcImages.section1Image3Cg || null,
      section2ImageCg: fcImages.section2ImageCg || null,
      section3Image1Cg: fcImages.section3Image1Cg || null,
      section3Image2Cg: fcImages.section3Image2Cg || null,
      section3Image3Cg: fcImages.section3Image3Cg || null,
      section3Image4Cg: fcImages.section3Image4Cg || null,
    };

    try {
      if (!initialData || initialData.length === 0) {
        const response = await axios.post(
          `${SERVER_URL}/${AXIOS_API_CALL.createFcDocument}`,
          {
            ...payload,
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      } else {
        const response = await axios.put(
          `${SERVER_URL}/${AXIOS_API_CALL.updateFcDocument}/${initialData[0]?._id}`,
          {
            ...payload,
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
    notification.success({
      message: 'Data saved successfully',
    });
    setSaving(false);
    setTimeout(() => {
      navigate(0);
    }, 1000);
  };
  // HANDLE FORM SUBMIT

  // HANDLE FETCH AND SET INITIAL DATA
  const handleFetchData = async () => {
    const { token } = user;
    try {
      const response = await axios.get(`${SERVER_URL}/${AXIOS_API_CALL.getFcDocument}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });
      setInitialData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!initialData) return;
    setFormGeneralSettings((prevState) => ({
      ...prevState,
      hide: initialData[0]?.fcHide || false,
    }));
    setFormSectionHeader((prevState) => ({
      ...prevState,
      en: {
        headMainHeadingPart1: initialData[0]?.sectionHeaderMainHeadingPart1En || '',
        headMainHeadingPart2: initialData[0]?.sectionHeaderMainHeadingPart2En || '',
      },
      cg: {
        headMainHeadingPart1: initialData[0]?.sectionHeaderMainHeadingPart1Cg || '',
        headMainHeadingPart2: initialData[0]?.sectionHeaderMainHeadingPart2Cg || '',
      },
    }));
    setFormSection1((prevState) => ({
      ...prevState,
      hide: initialData[0]?.section1Hide || false,
      en: {
        sec1MainHeadingPart1: initialData[0]?.section1MainHeadingPart1En || '',
        sec1MainHeadingPart2: initialData[0]?.section1MainHeadingPart2En || '',
        sec1Benefit1Text: initialData[0]?.section1Benefit1TextEn || '',
        sec1Benefit1Resource: initialData[0]?.section1Benefit1ResourceEn || '',
        sec1Benefit2Text: initialData[0]?.section1Benefit2TextEn || '',
        sec1Benefit2Resource: initialData[0]?.section1Benefit2ResourceEn || '',
        sec1Benefit3Text: initialData[0]?.section1Benefit3TextEn || '',
        sec1Benefit3Resource: initialData[0]?.section1Benefit3ResourceEn || '',
      },
      cg: {
        sec1MainHeadingPart1: initialData[0]?.section1MainHeadingPart1Cg || '',
        sec1MainHeadingPart2: initialData[0]?.section1MainHeadingPart2Cg || '',
        sec1Benefit1Text: initialData[0]?.section1Benefit1TextCg || '',
        sec1Benefit1Resource: initialData[0]?.section1Benefit1ResourceCg || '',
        sec1Benefit2Text: initialData[0]?.section1Benefit2TextCg || '',
        sec1Benefit2Resource: initialData[0]?.section1Benefit2ResourceCg || '',
        sec1Benefit3Text: initialData[0]?.section1Benefit3TextCg || '',
        sec1Benefit3Resource: initialData[0]?.section1Benefit3ResourceCg || '',
      },
    }));
    setFormSection2((prevState) => ({
      ...prevState,
      hide: initialData[0]?.section2Hide || false,
      en: {
        sec2MainHeadingPart1: initialData[0]?.section2MainHeadingPart1En || '',
        sec2MainHeadingPart2: initialData[0]?.section2MainHeadingPart2En || '',
        sec2Advantage1Title: initialData[0]?.section2Advantage1TitleEn || '',
        sec2Advantage1Text: initialData[0]?.section2Advantage1TextEn || '',
        sec2Advantage2Title: initialData[0]?.section2Advantage2TitleEn || '',
        sec2Advantage2Text: initialData[0]?.section2Advantage2TextEn || '',
        sec2Advantage3Title: initialData[0]?.section2Advantage3TitleEn || '',
        sec2Advantage3Text: initialData[0]?.section2Advantage3TextEn || '',
        sec2Advantage4Title: initialData[0]?.section2Advantage4TitleEn || '',
        sec2Advantage4Text: initialData[0]?.section2Advantage4TextEn || '',
      },
      cg: {
        sec2MainHeadingPart1: initialData[0]?.section2MainHeadingPart1Cg || '',
        sec2MainHeadingPart2: initialData[0]?.section2MainHeadingPart2Cg || '',
        sec2Advantage1Title: initialData[0]?.section2Advantage1TitleCg || '',
        sec2Advantage1Text: initialData[0]?.section2Advantage1TextCg || '',
        sec2Advantage2Title: initialData[0]?.section2Advantage2TitleCg || '',
        sec2Advantage2Text: initialData[0]?.section2Advantage2TextCg || '',
        sec2Advantage3Title: initialData[0]?.section2Advantage3TitleCg || '',
        sec2Advantage3Text: initialData[0]?.section2Advantage3TextCg || '',
        sec2Advantage4Title: initialData[0]?.section2Advantage4TitleCg || '',
        sec2Advantage4Text: initialData[0]?.section2Advantage4TextCg || '',
      },
    }));
    setFormSection3((prevState) => ({
      ...prevState,
      hide: initialData[0]?.section3Hide || false,
      en: {
        sec3MainHeading: initialData[0]?.section3MainHeadingEn || '',
        sec3Card1Title: initialData[0]?.section3Card1TitleEn || '',
        sec3Card1Text: initialData[0]?.section3Card1TextEn || '',
        sec3Card2Title: initialData[0]?.section3Card2TitleEn || '',
        sec3Card2Text: initialData[0]?.section3Card2TextEn || '',
        sec3Card3Title: initialData[0]?.section3Card3TitleEn || '',
        sec3Card3Text: initialData[0]?.section3Card3TextEn || '',
        sec3Card4Title: initialData[0]?.section3Card4TitleEn || '',
        sec3Card4Text: initialData[0]?.section3Card4TextEn || '',
      },
      cg: {
        sec3MainHeading: initialData[0]?.section3MainHeadingCg || '',
        sec3Card1Title: initialData[0]?.section3Card1TitleCg || '',
        sec3Card1Text: initialData[0]?.section3Card1TextCg || '',
        sec3Card2Title: initialData[0]?.section3Card2TitleCg || '',
        sec3Card2Text: initialData[0]?.section3Card2TextCg || '',
        sec3Card3Title: initialData[0]?.section3Card3TitleCg || '',
        sec3Card3Text: initialData[0]?.section3Card3TextCg || '',
        sec3Card4Title: initialData[0]?.section3Card4TitleCg || '',
        sec3Card4Text: initialData[0]?.section3Card4TextCg || '',
      },
    }));
    setFcImages((prevState) => ({
      ...prevState,
      sectionHeaderImageEn: initialData[0]?.sectionHeaderImageEn?._id || null,
      section1Image1En: initialData[0]?.section1Image1En?._id || null,
      section1Image2En: initialData[0]?.section1Image2En?._id || null,
      section1Image3En: initialData[0]?.section1Image3En?._id || null,
      section2ImageEn: initialData[0]?.section2ImageEn?._id || null,
      section3Image1En: initialData[0]?.section3Image1En?._id || null,
      section3Image2En: initialData[0]?.section3Image2En?._id || null,
      section3Image3En: initialData[0]?.section3Image3En?._id || null,
      section3Image4En: initialData[0]?.section3Image4En?._id || null,
      sectionHeaderImageCg: initialData[0]?.sectionHeaderImageCg?._id || null,
      section1Image1Cg: initialData[0]?.section1Image1Cg?._id || null,
      section1Image2Cg: initialData[0]?.section1Image2Cg?._id || null,
      section1Image3Cg: initialData[0]?.section1Image3Cg?._id || null,
      section2ImageCg: initialData[0]?.section2ImageCg?._id || null,
      section3Image1Cg: initialData[0]?.section3Image1Cg?._id || null,
      section3Image2Cg: initialData[0]?.section3Image2Cg?._id || null,
      section3Image3Cg: initialData[0]?.section3Image3Cg?._id || null,
      section3Image4Cg: initialData[0]?.section3Image4Cg?._id || null,
    }));
    setFcImagesPreview((prevState) => ({
      ...prevState,
      sectionHeaderImageEn: initialData[0]?.sectionHeaderImageEn?.url || null,
      section1Image1En: initialData[0]?.section1Image1En?.url || null,
      section1Image2En: initialData[0]?.section1Image2En?.url || null,
      section1Image3En: initialData[0]?.section1Image3En?.url || null,
      section2ImageEn: initialData[0]?.section2ImageEn?.url || null,
      section3Image1En: initialData[0]?.section3Image1En?.url || null,
      section3Image2En: initialData[0]?.section3Image2En?.url || null,
      section3Image3En: initialData[0]?.section3Image3En?.url || null,
      section3Image4En: initialData[0]?.section3Image4En?.url || null,
      sectionHeaderImageCg: initialData[0]?.sectionHeaderImageCg?.url || null,
      section1Image1Cg: initialData[0]?.section1Image1Cg?.url || null,
      section1Image2Cg: initialData[0]?.section1Image2Cg?.url || null,
      section1Image3Cg: initialData[0]?.section1Image3Cg?.url || null,
      section2ImageCg: initialData[0]?.section2ImageCg?.url || null,
      section3Image1Cg: initialData[0]?.section3Image1Cg?.url || null,
      section3Image2Cg: initialData[0]?.section3Image2Cg?.url || null,
      section3Image3Cg: initialData[0]?.section3Image3Cg?.url || null,
      section3Image4Cg: initialData[0]?.section3Image4Cg?.url || null,
    }));
  }, [initialData]);

  useEffect(() => {
    handleFetchData();
  }, []);
  // HANDLE FETCH AND SET INITIAL DATA

  const handleInputChangeGeneralSettings = (e) => {
    const { name, checked } = e.target;
    if (name === 'fc_hide') {
      setFormGeneralSettings((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
    }
  };

  // PANEL AND TABS RELATED FUNCTIONS
  const { Panel } = Collapse;
  const onChange = useCallback(() => {}, []);
  // PANEL AND TABS RELATED FUNCTIONS

  return (
    <div className="cms cms-for-companies">
      <h2 className="page-title">CMS For Companies</h2>
      {/* Main Content */}
      <form className="cms-form cms-form-section cms-form-section1">
        <Main className="section__content relative">
          <Collapse onChange={onChange}>
            {/* Header (Join the Fitpass Revolution) */}
            <Panel header="HEADER (Join the Fitpass Revolution)" key="1">
              <CmsForCompaniesHeader onChangeTabSectionHeader={onChangeTabSectionHeader} formSectionHeader={formSectionHeader} handleInputChangeFormSectionHeader={handleInputChangeFormSectionHeader} fcImages={fcImages} fcImagesErrors={fcImagesErrors} fcImagesPreview={fcImagesPreview} setFcImages={setFcImages} formSectionHeaderErrors={formSectionHeaderErrors} />
            </Panel>
            {/* Header (Join the Fitpass Revolution) */}
            {/* SECTION 1 (Did you know? Why do you need Fitpass as a benefit?) */}
            <Panel header="SECTION 1 (Did you know? Why do you need Fitpass as a benefit?)" key="2">
              <CmsForCompaniesSection1 onChangeTabSection1={onChangeTabSection1} formSection1={formSection1} handleInputChangeFormSection1={handleInputChangeFormSection1} fcImages={fcImages} fcImagesErrors={fcImagesErrors} fcImagesPreview={fcImagesPreview} setFcImages={setFcImages} formSection1Errors={formSection1Errors} />
            </Panel>
            {/* SECTION 1 (Did you know? Why do you need Fitpass as a benefit?) */}

            {/* SECTION 2 (Count on us Fitpass dedicated client support team will maximise your company wellbeing results!) */}
            <Panel header="SECTION 2 (Count on us Fitpass dedicated client support team will maximise your company wellbeing results!)" key="3">
              <CmsForCompaniesSection2 onChangeTabSection2={onChangeTabSection2} formSection2={formSection2} handleInputChangeFormSection2={handleInputChangeFormSection2} fcImages={fcImages} fcImagesErrors={fcImagesErrors} fcImagesPreview={fcImagesPreview} setFcImages={setFcImages} formSection2Errors={formSection2Errors} />
            </Panel>
            {/* SECTION 2 (Count on us Fitpass dedicated client support team will maximise your company wellbeing results!) */}

            {/* SECTION 3 (Activate your team - by reducing their spending!) */}
            <Panel header="SECTION 3 (Activate your team - by reducing their spending!)" key="4">
              <CmsForCompaniesSection3 onChangeTabSection2={onChangeTabSection3} formSection3={formSection3} handleInputChangeFormSection3={handleInputChangeFormSection3} fcImages={fcImages} fcImagesErrors={fcImagesErrors} fcImagesPreview={fcImagesPreview} setFcImages={setFcImages} formSection3Errors={formSection3Errors} />
            </Panel>
            {/* SECTION 3 (Activate your team - by reducing their spending!) */}
          </Collapse>
          <CheckboxCms inputName="fc_hide" isChecked={formGeneralSettings.hide} handleChange={handleInputChangeGeneralSettings} label="Hide entire page on website" tooltipText="Checking this will hide entire page on website" />
          <button className="btn btn-primary pl-3 pr-3 mt-3 mb-5" onClick={(e) => handleFormSubmit(e)}>
            {saving ? 'Saving changes...' : 'Save changes'}
          </button>
        </Main>
      </form>
      {/* Main Content */}
    </div>
  );
};
export default CmsForCompanies;
