export const TabsContent = (props) => {
  const { tabs, tabsContent } = props;

  return (
    <div className="tabs__panel-content">
      {tabsContent.map((item, idx) => {
        return (
          <div style={{ display: tabs === idx ? 'block' : 'none' }} key={item.id}>
            {item.element}
          </div>
        );
      })}
    </div>
  );
};
