import { Wrapper } from '../../../../../components/content';
import { useProducts } from '../../../../../context/products';

// Data
const productType = [
  { id: 'fdhhdjfhsdkfjhsds', name: 'Simple Product', value: 'simple' },
  { id: 'fdsfdewoeobarabas', name: 'Variable Product', value: 'variables' }
];

export const ProductType = (props) => {
  const { form, onChange } = useProducts();
  const { className } = props;

  const isChecked = (value) => {
    return form.productType === value;
  };

  return (
    <Wrapper className={`product__card ${className}`}>
      <header className="card-header">
        <h4>Product Type</h4>
      </header>

      <main className="card-content">
        <form name="product-type" className="form">
          <div className="form-group">
            <label className="label" htmlFor="productType">
              <b>Product Type:</b>
            </label>

            <select value={form.productType || 'simple'} onChange={onChange} name="productType" id="productType" className="select">
              {productType.map((item) => {
                return (
                  <option style={{ color: isChecked(item.value) ? 'silver' : 'black' }} key={item.id} value={item.value}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>
        </form>
      </main>
    </Wrapper>
  );
};
