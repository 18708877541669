import React, { memo } from 'react';
import ImageUpload from '../../components/image-upload';
import TooltipDash from '../../../../../helpers/TooltipDash';

const Section1Cg = ({ handleInputChangeFormSection1, formSection1, hpImages, hpImagesPreview, setHpImages, formSection1Errors, hpImagesErrors }) => {
  return (
    <div className="cms-form-flex">
      {/*Card 1:*/}
      <div className="cms-form-flex-col">
        <p className="card-top">Kartica 1:</p>
        <div className="cms-form-head-inner">
          <div className="images-card-header">
            <span>Ikona</span>
            <span className="ml-6">
              <TooltipDash text="Proporcije ikone 1/1 (150x150)px" acceptFormatText={true} />
            </span>
          </div>
          <div className="upload-wrapper">
            <ImageUpload hpImages={hpImages} hpImagesPreview={hpImagesPreview} setHpImages={setHpImages} hpImagesErrors={hpImagesErrors} toStoreImg="section1Icon1Cg" cg={true} />
          </div>
        </div>
        {/*cg_title1*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="cg_title1">
            <b>Naslov:</b>
          </label>
          <input id="cg_title1" type="text" placeholder="Naslov" name="cg_title1" value={formSection1.cg.title1} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.cg.title1 ? 'input-error-important' : ''}`} />
        </div>
        {/*cg_title1*/}
        {/*cg_subTitle1Part1*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="cg_subTitle1Part1">
            <b>Podnaslov linija 1:</b>
          </label>
          <input id="cg_subTitle1Part1" type="text" placeholder="Podnaslov linija 1" name="cg_subTitle1Part1" value={formSection1.cg.subTitle1Part1} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.cg.subTitle1Part1 ? 'input-error-important' : ''}`} />
        </div>
        {/*cg_subTitle1Part1*/}
        {/*cg_subTitle1Part2*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="cg_subTitle1Part2">
            <b>Podnaslov linija 2:</b>
          </label>
          <input id="cg_subTitle1Part2" type="text" placeholder="Podnaslov linija 2" name="cg_subTitle1Part2" value={formSection1.cg.subTitle1Part2} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.cg.subTitle1Part2 ? 'input-error-important' : ''}`} />
        </div>
        {/*cg_subTitle1Part2*/}
        {/*cg_button1Text*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="cg_button1Text">
            <b>Tekst dugmeta:</b>
          </label>
          <input id="cg_button1Text" type="text" placeholder="Tekst dugmeta" name="cg_button1Text" value={formSection1.cg.button1Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.cg.button1Text ? 'input-error-important' : ''}`} />
        </div>
        {/*cg_button1Text*/}
        {/*cg_button1Url*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="cg_buttonUrl1">
            <b>URL dugmeta:</b>
          </label>
          <input id="cg_button1Url" type="text" placeholder="URL dugmeta" name="cg_button1Url" value={formSection1.cg.button1Url} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.cg.button1Url ? 'input-error-important' : ''}`} />
        </div>
        {/*cg_button1Url*/}
      </div>
      {/*Card 1:*/}
      {/*Card 2:*/}
      <div className="cms-form-flex-col">
        <p className="card-top">Kartica 2:</p>
        <div className="cms-form-head-inner">
          <div className="images-card-header">
            <span>Ikona</span>
            <span className="ml-6">
              <TooltipDash text="Proporcije ikone 1/1 (150x150)px" acceptFormatText={true} />
            </span>
          </div>
          <div className="upload-wrapper">
            <ImageUpload hpImages={hpImages} hpImagesPreview={hpImagesPreview} setHpImages={setHpImages} toStoreImg="section1Icon2Cg" cg={true} hpImagesErrors={hpImagesErrors} />
          </div>
        </div>
        {/*cg_title2*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="cg_title2">
            <b>Naslov:</b>
          </label>
          <input id="cg_title2" type="text" placeholder="Naslov" name="cg_title2" value={formSection1.cg.title2} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.cg.title2 ? 'input-error-important' : ''}`} />
        </div>
        {/*cg_title2*/}
        {/*cg_subTitle2Part1*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="cg_subTitle2Part1">
            <b>Podnaslov linija 1:</b>
          </label>
          <input id="cg_subTitle2Part1" type="text" placeholder="Podnaslov linija 1" name="cg_subTitle2Part1" value={formSection1.cg.subTitle2Part1} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.cg.subTitle2Part1 ? 'input-error-important' : ''}`} />
        </div>
        {/*cg_subTitle2Part1*/}
        {/*cg_subTitle2Part2*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="cg_subTitle2Part2">
            <b>Podnaslov linija 2:</b>
          </label>
          <input id="cg_subTitle2Part2" type="text" placeholder="Podnaslov linija 2" name="cg_subTitle2Part2" value={formSection1.cg.subTitle2Part2} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.cg.subTitle2Part2 ? 'input-error-important' : ''}`} />
        </div>
        {/*cg_subTitle2Part2*/}
        {/*cg_button2Text*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="cg_button2Text">
            <b>Tekst dugmeta:</b>
          </label>
          <input id="cg_button2Text" type="text" placeholder="Tekst dugmeta" name="cg_button2Text" value={formSection1.cg.button2Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.cg.button2Text ? 'input-error-important' : ''}`} />
        </div>
        {/*cg_button2Text*/}
        {/*cg_button2Url*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="cg_button2Url">
            <b>URL dugmeta:</b>
          </label>
          <input id="cg_button2Url" type="text" placeholder="URL dugmeta" name="cg_button2Url" value={formSection1.cg.button2Url} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.cg.button2Url ? 'input-error-important' : ''}`} />
        </div>
        {/*cg_button2Url*/}
      </div>
      {/*Card 2:*/}
    </div>
  );
};
export default memo(Section1Cg);
