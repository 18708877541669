import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../../context/useAuth';
import { SERVER_URL } from '../../../config/index';
import { Section, Main } from '../../../components/content';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { PERMISSIONS } from '../../../utils/permissions';
import { slugify, slugifyReplaceAll } from '../../../helpers/helpers';
import { notification } from 'antd';
import SelectCustom from '../../../components/customSelect/CustomSelect';
import Table from './Table';
import Loading from '../../../components/loading/Loading';
import LoadingPlaceholder from '../../../components/loadingPlaceholder/LoadingPlaceholder';
import { getGroceryPermissions } from '../../../middleware';
import { notificationMessages } from '../../../helpers/notificationMessages';
import { CreateAttributesAndUnit } from './create-clobal-attributes/CreateGlobalAttributes';
import { DeleteAttribute } from './delete-attribute/DeleteAttribute';

const options = [5, 10, 20, 50, 100];

const GlobalAttributes = () => {
  const { user } = useAuth();
  const location = useLocation();
  const [groceryPermissions, setGroceryPermissions] = useState([]);
  const [list, setList] = useState([]);

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;
    if (user && token) {
      const decodeToken = jwtDecode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
          setGroceryPermissions(permissions.grocery);
        }
      }
    }
  }, [user, location]);

  // SEARCH
  const [search, setSearch] = useState(null);
  const [searchForm, setSearchForm] = useState({ query: '' });

  const handleOnSubmitSearch = (e) => {
    e.preventDefault();
    setSearch(searchForm.query);
    setReadDataRefetch((prevState) => !prevState);
  };

  const handleOnChangeSearch = (value) => {
    setSearchForm({ query: value });
  };

  const handleOnClearSearch = () => {
    setSearch(null);
    setSearchForm({ query: '' });
    setReadDataRefetch((prevState) => !prevState);
  };

  // PAGE
  const [currentPage, setCurrentPage] = useState(1);
  const [limitPage, setLimitPage] = useState(10);

  // READ
  const [readData, setReadData] = useState([]);
  const [readDataRefetch, setReadDataRefetch] = useState(false);
  const [readDataLoading, setReadDataLoading] = useState(true);

  const getReadData = useCallback(async () => {
    const { token } = user;

    try {
      await axios
        .get(`${SERVER_URL}/${AXIOS_API_CALL.globalAttributes}/${currentPage}/${limitPage}/${search || null}`, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          setList(res?.data);
          setTimeout(() => {}, 700);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  }, [user, currentPage, limitPage, search]);

  useEffect(() => {
    let isMounted = true;

    new Promise((resolve, reject) => {
      setReadDataLoading(true);

      setTimeout(() => {
        resolve(getReadData());
      }, 700);
    }).then((res) => {
      if (isMounted) {
        setReadData(res?.data);
        setReadDataLoading(false);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [getReadData, readDataRefetch]);

  // LOADER PROTECTION
  const [createBtnLoader, setCreateBtnLoader] = useState(false);

  // CREATE - (attribute)
  const createFormRef = useRef(null);
  const [toggleCreate, setToggleCreate] = useState(false);
  const [createForm, setCreateForm] = useState({ name: '', slug: '' }); // FORM data

  // DELETE - (attribute)
  const deleteFormRef = useRef(null);
  const [toggleDelete, setToggleDelete] = useState(false);
  const [deleteFormId, setDeleteFormId] = useState({ id: '' }); // ID for fetch

  /**
   * Toggle modal function
   * @param {*} props
   */
  const handleToggle = (props) => {
    const { name, data } = props;

    switch (name) {
      case 'create':
        setToggleCreate(true);
        break;
      case 'delete':
        setToggleDelete(true);
        setDeleteFormId({ id: data._id });
        break;
      default:
        console.warn('Default of: handleToggle function');
    }
  };

  /**
   * Form submit function
   * @param {*} props
   */
  async function handleOnSubmit({ event, form, type }) {
    const { token } = user;
    setCreateBtnLoader(true);

    switch (type) {
      case 'create-global-attribute':
        setCreateBtnLoader(true);

        let createPayload = {
          department: PERMISSIONS.grocery,
          name: event.name,
          isGlobal: true,
        };

        try {
          axios
            .post(`${SERVER_URL}/${AXIOS_API_CALL.createGlobalAttribute}`, { ...createPayload }, { withCredentials: false, headers: { department: PERMISSIONS.grocery, Authorization: `Bearer ${token}` } })
            .then((res) => {
              if (res.status === 201) {
                setReadDataRefetch((prevState) => !prevState);
                form.onToggle(false);
                createFormRef.current.resetFields();

                // notification
                notification.success({
                  message: notificationMessages.successCreateAttribute,
                  placement: 'bottomLeft',
                });
                setTimeout(() => {
                  setCreateBtnLoader(false);
                }, 400);
              }
            })
            .catch((err) => {
              console.error(err);
              setCreateBtnLoader(false);
            })
            .finally(setTimeout(() => {}, 700));
        } catch (err) {}

        break;

      case 'delete-global-attribute':
        try {
          axios
            .delete(`${SERVER_URL}/${AXIOS_API_CALL.deleteGlobalAttributes}/${deleteFormId.id}`, { withCredentials: false, headers: { department: PERMISSIONS.grocery, Authorization: `Bearer ${token}` } })
            .then((res) => {
              if (res.status === 200) {
                setReadDataRefetch((prevState) => !prevState);
                form.onToggle(false);

                // notification
                notification.success({
                  message: notificationMessages.successDeleteAttribute,
                  placement: 'bottomLeft',
                });
                setTimeout(() => {
                  setCreateBtnLoader(false);
                }, 400);
              }
            })
            .catch((err) => {
              console.error(err);
              setCreateBtnLoader(false);
            })
            .finally(setTimeout(() => {}, 700));
        } catch (err) {}
        break;
      default:
        console.warn('Default of: handleOnSubmit function!');
    }
  }

  // CREATE ATTRIBUTE PROPS
  const createProps = {
    active: toggleCreate,
    onToggle: setToggleCreate,
    formRef: createFormRef,
    data: createForm,
    onData: setCreateForm,
  };

  // DELETE PROPS
  const deleteProps = {
    active: toggleDelete,
    onToggle: setToggleDelete,
    formRef: deleteFormRef,
  };

  // PAGINATION
  const paginationProps = {
    current: currentPage,
    setCurrentPage: setCurrentPage,
    limitPage: limitPage,
    buttonLimit: 3,
    pagesCount: list?.pagesCount,
    totalCount: list?.totalCount,
  };

  // TABLE PROPS
  const tableProps = {};

  // TABLE ACTIONS PROPS
  const tableActionsProps = {
    handleToggle: handleToggle,
    deleteProps: deleteProps,
    location: location,
    groceryPermissions: groceryPermissions,
    getGroceryPermissions: getGroceryPermissions,
  };

  const createAttributesAndUnitProps = {
    createProps,
    createFormRef,
    handleOnSubmit,
    createForm,
    setToggleCreate,
    createBtnLoader,
  };

  const deleteAttributeProps = {
    deleteProps,
    handleOnSubmit,
    deleteFormRef,
    setToggleDelete,
  };

  return (
    <>
      <h2 className="page-title">Global Attributes</h2>
      <Section className="section__wrapper section__attributes">
        {/* Header */}
        <header className="section__header">
          {!readDataLoading ? (
            <div className="filter">
              <span style={{ color: '#333', fontWeight: '500' }}>Show:</span>
              <SelectCustom options={options} label={'Items'} limit={limitPage} setLimit={setLimitPage} setRefetch={setReadDataRefetch} loading={readDataLoading} />
            </div>
          ) : (
            <LoadingPlaceholder style={{ width: '155.5px', height: '50px' }} />
          )}

          {!readDataLoading ? (
            <div className="search">
              <form className="search-form" onSubmit={(e) => handleOnSubmitSearch(e)} data-cy="search-roles">
                <div className="form-group m-0">
                  <span className="icon icon-search">
                    <img src="/assets/icons/search.svg" alt="Search" title="Search" />
                  </span>
                  <input value={searchForm.query || ''} type="text" data-cy="search-input-field" className="input" placeholder="Search" onChange={(e) => handleOnChangeSearch(e.target.value)} />
                  <span className={`icon icon-close ${!!searchForm.query && searchForm.query.length > 0 ? 'isActive' : ''}`} onClick={() => handleOnClearSearch()}>
                    <img src="/assets/icons/times.svg" alt="Clear" title="Clear" />
                  </span>
                </div>
                <button className="form-submit" data-cy="search-submit-btn">
                  Search
                </button>
              </form>
            </div>
          ) : (
            <LoadingPlaceholder style={{ width: '100%', maxWidth: '850px', height: '50px' }} />
          )}

          {getGroceryPermissions(groceryPermissions, PERMISSIONS.createAttributes) && (
            <>
              <div className="actions">
                {!readDataLoading ? (
                  <button type="button" onClick={() => handleToggle({ name: 'create', state: createProps })} data-cy="create-attribute-btn" className="btn btn-primary pl-4 pr-4">
                    <span className="text">Create Global Attribute</span>
                  </button>
                ) : (
                  <LoadingPlaceholder style={{ width: '191px', height: '50px' }} />
                )}
              </div>
            </>
          )}
        </header>

        {/* Main Content */}
        <Main className="section__content relative min-h-table-content">{readDataLoading ? <Loading /> : <Table data={list?.paginatedData} {...tableProps} {...tableActionsProps} pagination={paginationProps} />}</Main>

        {/* Create Attributes and Unit */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.createAttributes) && <CreateAttributesAndUnit {...createAttributesAndUnitProps} />}

        {/* Delete */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.deleteAttributes) && <DeleteAttribute {...deleteAttributeProps} />}
      </Section>
    </>
  );
};

export default GlobalAttributes;
