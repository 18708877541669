import React from 'react';

const Loading = (props) => {
  const { className } = props;

  return (
    <div className={`loading ${className !== undefined ? className : ''}`}>
      <div className="loading-container">
        <span className="loading-animation" />
        <span className="loading-text">Loading...</span>
      </div>
    </div>
  );
};

export default Loading;
