const notificationMessages = {
  // AUTH
  successLogin: 'Successfully logged in!',
  failedLogin: 'Email or Password is incorrect!',
  successLogout: 'Logged out successfully!',
  userAlreadyExists: 'User already exists!',
  userMailVerified: 'Mail verified!',

  // ROLES - (create, update, delete)
  successCreateRole: 'Roles successfully created!',
  successUpdateRole: 'Roles successfully modified!',
  successDeleteRole: 'Role successfully removed!',
  successDeleteMultiRole: 'Roles are successfully removed!',
  successUpdateStatusRole: 'Role status updated successfully!',
  successUpdateStatusRoles: 'Roles status updated successfully!',

  failedCreateRole: 'This role name is already exists!',
  failedUpdateRole: 'Failed to update role!',
  failedDeleteRole: 'Failed to delete role!',
  failedUpdateStatusRole: 'Failed to update status of role!',
  failedUpdateStatusRoles: 'Failed to update status of roles!',

  // EMPLOYEES - (create, update, delete)
  successCreateEmployee: 'Admin successfully created!',
  successUpdateEmployee: 'Admin successfully modified!',
  successUpdateEmployeeEmail: 'Check your mail for verification!',
  successDeleteEmployee: 'Admin successfully removed!',
  successUpdateStatusEmployee: 'Admin status successfully changed!',
  successUpdateStatusMultiEmployee: 'Admins status successfully changed!',

  failedCreateEmployee: 'Failed to create Admin!',
  failedUpdateEmployee: 'Failed to update Admin!',
  failedDeleteEmployee: 'Failed to delete Admin!',

  // CATEGORIES
  successCreateCategory: 'Category created successfully.',
  successCreateSubcategory: 'Subcategory created successfully.',

  // ATTRIBUTES
  successCreateAttribute: 'Attribute created successfully.',
  successDeleteAttribute: 'Attribute deleted successfully.',

  // PROFILE - (update)
  successUpdateProfile: 'Profile information successfully updated!',
};

export { notificationMessages };
