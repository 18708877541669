import React, { memo } from 'react';
import ImageUpload from '../../components/image-upload';
import TooltipDash from '../../../../../helpers/TooltipDash';

const Section2Cg = ({ formSection2, handleInputChangeFormSection2, setEnImages, enImages, enImagesPreview, formSection2Errors, enImagesErrors }) => {
  return (
    <>
      {/*Shared inputs*/}
      <div className="shared">
        {/*cg_mainHeading*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="cg_mainHeading">
            <b>Glavni naslov:</b>
          </label>
          <input id="cg_mainHeading" type="text" placeholder="Glavni naslov" name="cg_mainHeading" value={formSection2.cg.mainHeading} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.cg.mainHeading ? 'input-error-important' : ''}`} />
        </div>
        {/*cg_mainHeading*/}
      </div>
      {/*Shared inputs*/}
      <div className="cms-form-flex">
        {/*Card 1:*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Lista 1:</p>
          <div className="cms-form-head-inner">
            <div className="images-card-header">
              <span>Slika</span>
              <span className="ml-6">
                <TooltipDash text="Proporcije slike 3/2 (1500x1000)px" acceptFormatText={true} />
              </span>
            </div>
            <div className="upload-wrapper">
              <ImageUpload enImages={enImages} enImagesErrors={enImagesErrors} enImagesPreview={enImagesPreview} setEnImages={setEnImages} toStoreImg="section2Image1Cg" />
            </div>
          </div>
          {/*cg_list1item1*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_list1item1">
              <b>Stavka 1:</b>
            </label>
            <input id="cg_list1item1" type="text" placeholder="Stavka 1" name="cg_list1item1" value={formSection2.cg.list1item1} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.cg.list1item1 ? 'input-error-important' : ''}`} />
          </div>
          {/*cg_list1item1*/}
          {/*cg_list1item2*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_list1item2">
              <b>Stavka 2:</b>
            </label>
            <input id="cg_list1item2" type="text" placeholder="Stavka 2" name="cg_list1item2" value={formSection2.cg.list1item2} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.cg.list1item2 ? 'input-error-important' : ''}`} />
          </div>
          {/*cg_list1item2*/}
          {/*cg_list1item3*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_list1item3">
              <b>Stavka 3:</b>
            </label>
            <input id="cg_list1item3" type="text" placeholder="Stavka 3" name="cg_list1item3" value={formSection2.cg.list1item3} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.cg.list1item3 ? 'input-error-important' : ''}`} />
          </div>
          {/*cg_list1item3*/}
          {/*cg_list1item4*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_list1item4">
              <b>Stavka 4:</b>
            </label>
            <input id="cg_list1item4" type="text" placeholder="Stavka 4" name="cg_list1item4" value={formSection2.cg.list1item4} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.cg.list1item4 ? 'input-error-important' : ''}`} />
          </div>
          {/*cg_list1item4*/}
        </div>
        {/*Card 1:*/}
      </div>
    </>
  );
};
export default memo(Section2Cg);
