import React from 'react';
import { stateUS } from '../../../../../helpers/state';
import Loading from '../../../../../components/loading/Loading';
import SidebarModal from '../../../../../components/sidebarModal/SidebarModal';
import { Select, Input, Button, Form, Row, Col, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';

export const UpdateFormAllSettings = ({ updateProps, loadingCountryCode, updateModalFormRef, updateFormFields, onChange, toggleNewPassword, roles, editBtnLoader, handleOnFinish, setToggleUpdate }) => {
  const { t } = useTranslation();
  return (
    <SidebarModal {...updateProps} className='sidebar__modal--right' title={t('editCountryCode')}>
      {!loadingCountryCode ? (
        <Form name='update' ref={updateModalFormRef} wrapperCol={{ span: 24 }} initialValues={{ remember: false }} onFinish={(data) => handleOnFinish({ name: 'update', formData: data })} fields={updateFormFields}>
          <Form.Item
            name='countryCode'
            label={`${t('countryCode')}:`}
            rules={[
              {
                required: true,
                message: 'Please input country code!',
              },
            ]}
          >
            <Input placeholder={`${t('countryCode')}:`} />
          </Form.Item>

          <Form.Item
            name='countryName'
            label={`${t('countryName')}:`}
            rules={[
              {
                required: true,
                message: 'Please input country name!',
              },
            ]}
          >
            <Input placeholder={`${t('countryName')}:`} />
          </Form.Item>

          <Form.Item
            name='countryTimezone'
            label={`${t('timezone')}:`}
            rules={[
              {
                required: true,
                message: 'Please input country timezone!',
              },
            ]}
          >
            <Input placeholder={`${t('timezone')}:`} />
          </Form.Item>

          <Form.Item name='updateCountryCodeId' style={{ display: 'none' }}>
            <Input type='hidden' />
          </Form.Item>

          <Form.Item className='ant-col-btn'>
            <Row gutter={16} className='row-direction'>
              <Col span={12}>
                <Button type='primary' htmlType='button' className='btn btn-primary-link btn-block' onClick={() => setToggleUpdate(false)}>
                  <span>{t('cancel')}</span>
                </Button>
              </Col>
              <Col span={12}>
                {!editBtnLoader ? (
                  <Button type='submit' htmlType='submit' className='btn btn-primary btn-block'>
                    <span>{t('editCountryCode')}</span>
                  </Button>
                ) : (
                  <Button type='button' className='btn btn-primary-outline btn-block'>
                    <span>{t('editing')}...</span>
                  </Button>
                )}
              </Col>
            </Row>
          </Form.Item>
        </Form>
      ) : (
        <Loading />
      )}
    </SidebarModal>
  );
};
