import ImageUpload from '../../components/image-upload';
import TooltipDash from '../../../../../helpers/TooltipDash';

const SectionHeaderEn = ({ handleInputChangeFormSectionHeader, formSectionHeader, clImages, clImagesErrors, clImagesPreview, setClImages, formSectionHeaderErrors }) => {
  return (
    <>
      <div className="cms-form-flex media" style={{ paddingBottom: '20px', marginBottom: '40px' }}>
        <div className="cms-form-flex-col">
          <div className="images-card-header">
            <span>Background image</span>
            <span className="ml-6">
              <TooltipDash acceptFormatText={true} />
            </span>
          </div>
          <div className="upload-wrapper">
            <ImageUpload clImages={clImages} clImagesErrors={clImagesErrors} clImagesPreview={clImagesPreview} setClImages={setClImages} toStoreImg="sectionHeaderImageEn" />
          </div>
        </div>
      </div>
      {/*Shared inputs*/}
      <div className="shared">
        {/*en_headMainHeadingPart1*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="en_headMainHeadingPart1">
            <b>Main heading part 1:</b>
          </label>
          <input id="en_headMainHeadingPart1" type="text" placeholder="Main heading part 1" name="en_headMainHeadingPart1" value={formSectionHeader.en.headMainHeadingPart1} onChange={handleInputChangeFormSectionHeader} className={`${formSectionHeaderErrors.en.headMainHeadingPart1 ? 'input-error-important' : ''}`} />
        </div>
        {/*en_headMainHeadingPart1*/}
        {/*en_headMainHeadingPart2*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="en_headMainHeadingPart2">
            <b>Main heading part 2:</b>
          </label>
          <input id="en_headMainHeadingPart2" type="text" placeholder="Main heading part 2" name="en_headMainHeadingPart2" value={formSectionHeader.en.headMainHeadingPart2} onChange={handleInputChangeFormSectionHeader} className={`${formSectionHeaderErrors.en.headMainHeadingPart2 ? 'input-error-important' : ''}`} />
        </div>
        {/*en_headMainHeadingPart2*/}
        {/*en_headText*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="en_headText">
            <b>Main text:</b>
          </label>
          <input id="en_headText" type="text" placeholder="Main head text" name="en_headText" value={formSectionHeader.en.headText} onChange={handleInputChangeFormSectionHeader} className={`${formSectionHeaderErrors.en.headText ? 'input-error-important' : ''}`} />
        </div>
        {/*en_headText*/}
      </div>
    </>
  );
};
export default SectionHeaderEn;
