import React, { memo } from 'react';
import { Tabs } from 'antd';
import Section1En from '../tabs/en/Section1En';
import Section1Cg from '../tabs/cg/Section1Cg';
import CheckboxCms from '../../../../components/checkbox-cms';
import { useTranslation } from 'react-i18next';

const CmsHomepageSection1 = ({ onChangeTabSection1, handleInputChangeFormSection1, formSection1, hpImages, hpImagesPreview, setHpImages, formSection1Errors, hpImagesErrors = { hpImagesErrors } }) => {
  const { t } = useTranslation();
  const { TabPane } = Tabs;

  const sectionProps = {
    handleInputChangeFormSection1,
    formSection1,
    hpImages,
    hpImagesPreview,
    setHpImages,
    formSection1Errors,
    hpImagesErrors,
  };
  return (
    <>
      <Tabs defaultActiveKey='1' onChange={onChangeTabSection1}>
        <TabPane key='1' tab='EN'>
          <Section1En {...sectionProps} />
        </TabPane>
        <TabPane key='2' tab='CG'>
          <Section1Cg {...sectionProps} />
        </TabPane>
      </Tabs>
      <CheckboxCms inputName='section1_hide' isChecked={formSection1.hide} handleChange={handleInputChangeFormSection1} label={t('hideEntireSectionOnWebsite')} tooltipText={t('checkingWillHide')} />
    </>
  );
};
export default memo(CmsHomepageSection1);
