import React from 'react';
import SidebarModal from '../../../../components/sidebarModal/SidebarModal';
import { useTranslation } from 'react-i18next';

import { Form, Input } from 'antd';

export const CreateAttributesAndUnit = ({ createProps, createFormRef, handleOnSubmit, setToggleCreate, createBtnLoader }) => {
  const { t } = useTranslation();
  return (
    <SidebarModal {...createProps} className="sidebar__modal--right" title={t('createGlobalAttribute')}>
      {/* Create Attribute */}
      <Form ref={createFormRef} className="ant-form ant-form-horizontal mb-2" onFinish={(event) => handleOnSubmit({ event: event, form: createProps, type: 'create-global-attribute' })}>
        <div className="form-group form-group-units relative">
          <Form.Item
            name="name"
            label={`${t('attributeName')}:`}
            rules={[
              {
                required: true,
                message: `${t('pleaseInputAttributeName')}!`,
              },
            ]}
          >
            <Input placeholder={`${t('attributeName')}:`} />
          </Form.Item>
        </div>

        <div className="form-group form-group-sidebar">
          <button data-cy="modal-create-cancel-btn" type="button" className="btn btn-primary-link" onClick={() => setToggleCreate(false)}>
            <span className="text">{t('cancel')}</span>
          </button>

          {!createBtnLoader ? (
            <button data-cy="modal-create-submit-btn" type="submit" className="btn btn-primary">
              <span className="text">{t('createAttribute')}</span>
            </button>
          ) : (
            <button type="button" className="btn btn-primary-outline">
              <span className="text">{t('creating')}...</span>
            </button>
          )}
        </div>
      </Form>
    </SidebarModal>
  );
};
