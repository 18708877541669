import React from 'react';

import { Input, Button, Form, Row } from 'antd';
import Modal from '../../../../../components/modal/Modal';

export const UpdateEmployeeStatusMultiChanges = ({ statusMultiProps, statusModalMultiFormRef, handleOnFinish, statusMultiFormFields, deactivateBlkBtnLoader, setToggleStatusMulti }) => {
  return (
    <Modal {...statusMultiProps} className="sidebar__modal--center" title="">
      <Form name="updateStatusMulti" ref={statusModalMultiFormRef} wrapperCol={{ span: 24 }} initialValues={{ remember: false }} onFinish={(data) => handleOnFinish({ name: 'updateStatusMulti', data: data })} fields={statusMultiFormFields}>
        <h2 className="text-center mb-4">
          <span className="d-block">Are you sure you want to change </span>
          <span className="d-block">status of selected admins?</span>
        </h2>

        <Form.Item name="statusMultiUser" style={{ display: 'none' }}>
          <Input type="hidden" />
        </Form.Item>

        <Form.Item>
          <Row gutter={16} style={{ justifyContent: 'center', columnGap: '20px' }}>
            {!deactivateBlkBtnLoader ? (
              <Button type="submit" htmlType="submit" className="btn btn-primary-link btn-block">
                <span>Yes</span>
              </Button>
            ) : (
              <Button type="button" className="btn btn-primary-link btn-block">
                <span>Deactivating...</span>
              </Button>
            )}

            <Button type="primary" htmlType="button" className="btn btn-primary btn-block" onClick={() => setToggleStatusMulti(false)}>
              <span>No</span>
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};
