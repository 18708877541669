import React, { useState, useCallback, useEffect } from 'react';
import { FormDynamic } from './FormDynamic/FormDynamic';
import { useProducts } from '../../../../../../../context/products';
import GalleryModal from '../../../../../gallery-page/GalleryModal';

export function CustomVariations({ formRef }) {
  const { setToggleGallery, setGallerySelect, selectImage, setToggleGalleryId, selectedAttributeUnit, formFields, setFormFields, saveCustomVariations: onSubmit } = useProducts();

  const [formIndex, setFormIndex] = useState('');
  const handleToggleGallery = useCallback(
    (index, image) => {
      setToggleGallery((prevState) => !prevState);
      setGallerySelect('singleselect');
      setFormIndex(index);
      setToggleGalleryId({
        index: index,
        image: image
      });
    },
    [setToggleGallery, setGallerySelect, setFormIndex, setToggleGalleryId]
  );

  useEffect(() => {
    if (formIndex !== '') {
      const updatedFields = [...formFields];
      if (selectImage) {
        updatedFields[formIndex].variantImage = selectImage;
      }
      setFormFields(updatedFields);
    }
  }, [selectImage]);

  // FORM VARIATION ON CHANGE HANDLER
  const handleFormChange = (props) => {
    const { event, index, attributes } = props;

    let copyFormFields = [...formFields];

    if (!attributes) {
      copyFormFields[index][event.target.name] = event.target.value;
      setFormFields(copyFormFields);
    } else {
      const updateCopyFormFields = Object.assign({}, copyFormFields[index]);
      const updateUnitFormFields = updateCopyFormFields.units.find((unit) => unit.hasOwnProperty(event.target.name));

      if (updateUnitFormFields) {
        updateUnitFormFields[event.target.name] = event.target.value;
      } else {
        updateCopyFormFields.units.push({
          [event.target.name]: event.target.value
        });
      }

      const newTodos = copyFormFields.slice();
      newTodos[index] = updateCopyFormFields;
    }

    if (event.target.name === 'variantRegularPrice') {
      const priceValue = event.target.value
        .replace(/[^\d\.]/g, '')
        .replace(/\./, 'x')
        .replace(/\./g, '')
        .replace(/x/, '.');

      setFormFields((prevState) => {
        const updatedFormFields = [...prevState];
        updatedFormFields[index].variantRegularPrice = priceValue;
        return updatedFormFields;
      });

      if (event.target.name === 'variantSalePrice') {
        const priceValue = event.target.value
          .replace(/[^\d\.]/g, '')
          .replace(/\./, 'x')
          .replace(/\./g, '')
          .replace(/x/, '.');

        setFormFields((prevState) => {
          const updatedFormFields = [...prevState];
          updatedFormFields[index].variantSalePrice = priceValue;
          return updatedFormFields;
        });
      }
    }
    if (event.target.name === 'variantQuantity') {
      const value = event.target.value.replace(/[^0-9]/g, ''); // Remove any non-numeric characters
      setFormFields((prevState) => {
        const updatedFormFields = [...prevState];
        updatedFormFields[index].variantQuantity = value;
        return updatedFormFields;
      });
    }
  };

  // RESET VARIATION FORM
  const onCancel = () => {
    // TODO: Fix to reset the form fields values
    setFormFields([]);
    formRef.current.reset();
  };

  // REMOVE FIELD FROM VARIATION FORM
  const removeFields = (index) => {
    const removeItem = formFields.filter((item) => item._id !== index);
    setFormFields(removeItem);
  };
  const [toggleGalleryModalIndex, setToggleGalleryModalIndex] = useState('');

  const formProps = {
    attributes: selectedAttributeUnit,
    removeFields: removeFields,
    handleFormChange: handleFormChange,
    handleToggleGallery: handleToggleGallery,
    setToggleGalleryModalIndex: setToggleGalleryModalIndex,
    setGallerySelect
  };

  const galleryModalProps = {
    formFields,
    formIndex
  };

  return (
    <div className="variations">
      <div className="extend">
        <div className="actions">{/* <button className="btn btn-primary pl-4 pr-4" onClick={addFields}>
            <span className="text">Add</span>
          </button> */}</div>
        <form
          ref={formRef}
          className="form"
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          {formFields.map((item, idx) => {
            return <FormDynamic key={idx} index={idx} form={item} {...formProps} />;
          })}

          <div className="actions">
            <button type="button" className="btn btn-primary-outline pl-4 pr-4" onClick={onCancel}>
              <span className="text">Cancel</span>
            </button>
            <button type="submit" className="btn btn-primary pl-4 pr-4">
              <span className="text">Save changes</span>
            </button>
          </div>
        </form>

        <GalleryModal {...galleryModalProps} />
      </div>
    </div>
  );
}
