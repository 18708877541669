import React from 'react';
import { Form, Row, Button } from 'antd';
import Modal from '../../../../components/modal/Modal';
import { useTranslation } from 'react-i18next';

export const DeleteSingleRole = ({ deleteSingleProps, deleteSingleFormRef, handleSubmit, deleteBtnLoader, setToggleSingleDelete }) => {
  const { t } = useTranslation();
  return (
    <Modal {...deleteSingleProps} className='sidebar__modal--center' title=''>
      <form data-cy='modal-delete' name='delete_single' ref={deleteSingleFormRef} onSubmit={(e) => handleSubmit(e)}>
        <h2 className='text-center mb-4'>{t('areYouSureToDeleteSelectedItem')}</h2>

        <Form.Item>
          <Row gutter={16} style={{ justifyContent: 'center', columnGap: '20px' }}>
            {!deleteBtnLoader ? (
              <Button data-cy='modal-delete-yes-btn' type='submit' htmlType='submit' className='btn btn-primary-link btn-block'>
                <span>{t('yes')}</span>
              </Button>
            ) : (
              <Button type='button' className='btn btn-primary-link btn-block'>
                <span>{t('deleting')}...</span>
              </Button>
            )}

            <Button data-cy='modal-delete-no-btn' type='primary' htmlType='button' className='btn btn-primary btn-block' onClick={() => setToggleSingleDelete(false)}>
              <span>{t('no')}</span>
            </Button>
          </Row>
        </Form.Item>
      </form>
    </Modal>
  );
};
