import { useProducts } from '../../../../../../context/products';

export const Inventory = (props) => {
  const { form, onChange } = useProducts();

  return (
    <form name="product-inventory" className="form">
      <div className="form-group">
        <label className="label" htmlFor="productSku">
          <b>SKU:</b>
        </label>

        <input onChange={onChange} name="productSku" type="text" placeholder="SKU" id="productSku" className="input" value={form.productSku} />
      </div>

      <hr className="hr hr-x" />

      <div className="form-group">
        <span className="label">
          <b>Product Manage:</b>
        </span>
        <div className="checkbox-group">
          <div className="item">
            <input checked={form.productManage || false} onChange={onChange} name="productManage" type="checkbox" id="productManage" className="checkbox" />

            <label htmlFor="productManage" className="label">
              <b>Manage Stock?</b>
            </label>
          </div>
        </div>
      </div>

      {form.productManage ? (
        <div className="form-group">
          <label className="label" htmlFor="productStockQuantity">
            <b>Stock Quantity:</b>
          </label>

          <input value={form.productStockQuantity || ''} onChange={onChange} name="productStockQuantity" type="text" pattern="[0-9]*" placeholder="Stock Quantity" id="productStockQuantity" className="input" />
        </div>
      ) : null}
    </form>
  );
};
