import React from 'react';
import { Input, Button, Form, Row } from 'antd';
import Modal from '../../../../../components/modal/Modal';
import { useTranslation } from 'react-i18next';

export const UpdateEmployeeStatusSingleChanges = ({ statusSingleProps, statusModalSingleFormRef, handleOnFinish, statusSingleFormFields, setToggleStatusSingle, deactivateBtnLoader }) => {
  const { t } = useTranslation();
  return (
    <Modal {...statusSingleProps} className='sidebar__modal--center' title=''>
      <Form name='updateStatusSingle' ref={statusModalSingleFormRef} wrapperCol={{ span: 24 }} initialValues={{ remember: false }} onFinish={(data) => handleOnFinish({ name: 'status', data: data })} fields={statusSingleFormFields}>
        <h2 className='text-center mb-4'>
          <span className='d-block'>{t('areYouSureYouWantToChange')}</span>
          <span className='d-block'>{t('statusOfSelectedAdmin')}?</span>
        </h2>

        <Form.Item name='statusSingleUserId' style={{ display: 'none' }}>
          <Input type='hidden' />
        </Form.Item>

        <Form.Item name='statusSingleUser' style={{ display: 'none' }}>
          <Input type='hidden' />
        </Form.Item>

        <Form.Item>
          <Row gutter={16} style={{ justifyContent: 'center', columnGap: '20px' }} className='row-direction'>
            {!deactivateBtnLoader ? (
              <Button type='submit' htmlType='submit' className='btn btn-primary-link btn-block'>
                <span>{t('yes')}</span>
              </Button>
            ) : (
              <Button type='button' className='btn btn-primary-link btn-block'>
                <span>{t('updating')}...</span>
              </Button>
            )}

            <Button type='primary' htmlType='button' className='btn btn-primary btn-block' onClick={() => setToggleStatusSingle(false)}>
              <span>{t('no')}</span>
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};
