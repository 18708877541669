export const TabsHeader = (props) => {
  const { tabsGallery, setTabsGallery, tabsContent } = props;

  return (
    <div className="tabs__panel-header">
      <ul>
        {tabsContent.map((item, idx) => {
          return (
            <li onClick={() => setTabsGallery(idx)} className={`${tabsGallery === idx ? 'isActive' : ''}`} key={item.id}>
              <span className="text">{item.name}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
