import React, { useEffect, useLayoutEffect, useState, useCallback } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { SERVER_URL } from '../config/index';
import { useAuth } from '../context/useAuth';
import ProtectedRoute from './ProtectedRouter';
import { jwtDecode } from 'jwt-decode';

// Auth
import Login from '../pages/auth/Login';
import ForgotPassword from '../pages/auth/ForgotPassword';
import ResetPassword from '../pages/auth/ResetPassword';

// Catch page
import Home from '../pages/home-page/Home';
import Profile from '../pages/profile-page/Profile';
import NotFoundError from '../pages/error-page/NotFoundError';

// Component pages - GROCERY
import RolesGrocery from '../pages/grocery/roles-page/Roles';
import EmployeesGrocery from '../pages/grocery/employees-page/Employees';
import Venues from '../pages/grocery/venue-page/Venues';
import EditRequest from '../pages/grocery/edit-request-page/EditRequest';
import EditHistory from '../pages/grocery/edit-history-page/EditHistory';
import NyamiRequest from '../pages/grocery/nyami-request-page/NyamiRequest';
import CustomersGrocery from '../pages/grocery/customers-page/Customers';
import ActivityGrocery from '../pages/grocery/activity-page/Activity';
import CategoriesGrocery from '../pages/grocery/categories-page/Categories';
import AttributesGrocery from '../pages/grocery/attributes-page/Attributes';
import GlobalAttributes from '../pages/grocery/global-attributes-page/GlobalAttributes';
import OrdersGrocery from '../pages/grocery/orders-page/Orders';
import OrderGroceryDetails from '../pages/grocery/order-details-page/OrderDetails';
import GalleryGrocery from '../pages/grocery/gallery-page/Gallery';
import ProductsGrocery from '../pages/grocery/products-page/Products';
import ProductGroceryCreate from '../pages/grocery/products-page/create-product/CreateProduct';
import ProductGroceryUpdate from '../pages/grocery/products-page/update-product/UpdateProduct';
import CmsHomepage from '../pages/grocery/cms-homepage/CmsHomepage';
import CmsForCompanies from '../pages/grocery/cms-for-companies/CmsForCompanies';
import CmsForPartners from '../pages/grocery/cms-for-partners/CmsForPartners';
import CmsAboutUs from '../pages/grocery/cms-about-us/CmsAboutUs';
import Slideshow from '../pages/grocery/slideshow-page/Slideshow';
import CmsExploreNetwork from '../pages/grocery/cms-explore-network/CmsExploreNetwork';
import CountryCodes from '../pages/grocery/country-codes/CountryCodes';
import CmsClub from '../pages/grocery/cms-club/CmsClub';
import CmsContact from '../pages/grocery/cms-contact/CmsContact';

// AUTH VERIFICATION APP
import { getGroceryPermissions } from '../middleware';
import { AXIOS_API_CALL } from '../utils/endpoint';
import { PERMISSIONS, USER } from '../utils/permissions';
import EmployeeEmailVerification from '../pages/auth/EmployeeEmailVerification';

const Router = (props) => {
  const { pathname } = props;
  const { user, refetchToken } = useAuth();
  const location = useLocation();

  const getUserTokenStatus = async () => {
    const checkUser = window.sessionStorage.getItem(USER);

    if (!checkUser || checkUser.includes('null') || checkUser.includes('undefined')) {
      window.sessionStorage.setItem(USER, JSON.stringify(null));
      window.location.href = `/${PERMISSIONS.login}`;
      return false;
    }

    const jsonToken = JSON.parse(checkUser);

    if (!jsonToken) {
      window.sessionStorage.setItem(USER, JSON.stringify(null));
      window.location.href = `/${PERMISSIONS.login}`;

      return false;
    }

    try {
      const fetchToken = await axios.post(`${SERVER_URL}/${AXIOS_API_CALL.userCheckToken}`, null, { withCredentials: false, headers: { Authorization: `Bearer ${jsonToken.token}` } });

      if (fetchToken.status !== 200) {
        window.sessionStorage.setItem(USER, JSON.stringify(null));
        window.location.href = `/${PERMISSIONS.login}`;
      }
    } catch (err) {
      window.sessionStorage.setItem(USER, JSON.stringify(null));
      window.location.href = `/${PERMISSIONS.login}`;
    }
  };

  // TOKEN VALIDATION
  useEffect(() => {
    if (user && user.token) {
      getUserTokenStatus();
    }
  }, [user, location, refetchToken]);

  // CHECK USER SESSION STORAGE
  useEffect(() => {
    const checkUser = window.sessionStorage.getItem(USER);
    if (!checkUser) {
      window.sessionStorage.setItem(USER, JSON.stringify(null));
      window.location.href = `/${PERMISSIONS.login}`;
      window.location.reload();
    }
  }, [location]);

  const [groceryPermissions, setGroceryPermissions] = useState([]);

  // GET USER TOKEN
  useEffect(() => {
    if (user && user.token) {
      const token = user.token;
      const decodeToken = jwtDecode(token);

      const isCheckRoleData = decodeToken && decodeToken.hasOwnProperty('roleData');
      if (isCheckRoleData) {
        const permissions = decodeToken.roleData?.permissions;

        if (location.pathname.includes(`/${PERMISSIONS.dashboard}`)) {
          if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
            setGroceryPermissions(permissions.grocery);
          }
        }
      }
    }
  }, [user, location]);

  const getLocationPathname = useCallback(
    (path) => {
      if (location.pathname.includes(`/${path}`)) return true;
      return false;
    },
    [location]
  );

  console.log('groceryPermissions', groceryPermissions);

  return (
    <Routes>
      {/* public routes */}
      <Route index element={<Navigate to={`/${PERMISSIONS.login}`} replace />} />
      <Route path={`/${PERMISSIONS.login}`} element={<Login />} />
      <Route path={`/${PERMISSIONS.forgotPassword}`} element={<ForgotPassword />} />
      <Route path={`/${PERMISSIONS.passwordReset}/:id`} element={<ResetPassword />} />
      <Route path={`/${PERMISSIONS.employeeEmailVerification}/:token`} element={<EmployeeEmailVerification />} />

      {/* protected routes */}
      <Route element={<ProtectedRoute pathname={pathname} redirectPath={`/${PERMISSIONS.login}`} />}>
        <Route index path={`/${PERMISSIONS.dashboard}`} element={<Navigate to={`/${PERMISSIONS.dashboard}`} replace />} />

        {/* GROCERY */}
        {getLocationPathname(PERMISSIONS.dashboard) ? (
          <>
            <Route index path={`/${PERMISSIONS.dashboard}`} element={<Home />} />
            <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.profile}`} element={<Profile />} />
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.readRoles) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.roles}`} element={<RolesGrocery />} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.readMembers) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.members}`} element={<CustomersGrocery />} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.readAdmins) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.admins}`} element={<EmployeesGrocery />} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.readActivity) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.activity}`} element={<ActivityGrocery />} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.readCategories) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.categories}`} element={<CategoriesGrocery />} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.readAttributes) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.attributes}`} element={<AttributesGrocery />} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.readAttributes) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.globalAttributes}`} element={<GlobalAttributes />} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.readVenues) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.venues}`} element={<Venues />} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.readRequests) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.requests}`} element={<EditRequest />} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.readRequests) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.nyamiRequests}`} element={<NyamiRequest />} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.readRequests) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.editHistory}`} element={<EditHistory />} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.readOrders) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.orders}`} element={<OrdersGrocery />} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.readOrders) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.orders}/:id`} element={<OrderGroceryDetails />} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.readGallery) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.gallery}`} element={<GalleryGrocery />} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.readProducts) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.products}`} element={<ProductsGrocery />} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.createProducts) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.createProduct}`} element={<ProductGroceryCreate />} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.updateProducts) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.updateProduct}/:id`} element={<ProductGroceryUpdate />} />}
            {/*CMS ROUTES - need to add permission and edit them to have read cms*/}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.readCMS) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.CMSHomepage}`} element={<CmsHomepage />} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.readCMS) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.CMSForCompanies}`} element={<CmsForCompanies />} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.readCMS) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.CMSForPartners}`} element={<CmsForPartners />} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.readCMS) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.CMSAboutUs}`} element={<CmsAboutUs />} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.readCMS) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.CMSExploreNetwork}`} element={<CmsExploreNetwork />} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.readCMS) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.slideshow}`} element={<Slideshow />} />}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.readCountryCodes) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.countryCodes}`} element={<CountryCodes />} />}
            <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.CMSClub}`} element={<CmsClub />} />
            <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.CMSContact}`} element={<CmsContact />} />
            {/*CMS ROUTES - need to add permission and edit them to have read cms*/}
          </>
        ) : null}

        {/* catch all */}
        <Route path="*" element={<NotFoundError />} />
      </Route>
    </Routes>
  );
};

export default Router;
