import { readBlobText } from '@testing-library/user-event/dist/cjs/utils/index.js';

const PERMISSIONS = {
  // STORE
  store: 'store',

  // PRODUCTS
  products: 'products',
  createProducts: 'create_products',
  readProducts: 'read_products',
  updateProducts: 'update_products',
  deleteProducts: 'delete_products',
  createProduct: 'create-product',
  updateProduct: 'update-product',
  productDetails: 'product-details',

  // ORDERS
  orders: 'orders',
  createOrders: 'create_orders',
  readOrders: 'read_orders',
  updateOrders: 'update_orders',
  deleteOrders: 'delete_orders',

  // GALLERY
  gallery: 'gallery',
  readGallery: 'read_images',
  createGallery: 'create_images',
  updateGallery: 'update_images',
  deleteGallery: 'delete_images',

  // ATTRIBUTES
  attributes: 'attributes',
  createAttributes: 'create_attributes',
  readAttributes: 'read_attributes',
  updateAttributes: 'update_attributes',
  deleteAttributes: 'delete_attributes',

  // GLOBAL ATTRIBUTES
  globalAttributes: 'globalattributes',
  createglobalAttributes: 'create_globalattributes',
  readglobalAttributes: 'read_globalattributes',
  updateglobalAttributes: 'update_globalattributes',
  deleteglobalAttributes: 'delete_globalattributes',

  // ROLES
  roles: 'roles',
  createRoles: 'create_roles',
  readRoles: 'read_roles',
  updateRoles: 'update_roles',
  deleteRoles: 'delete_roles',

  // CUSTOMERS
  customers: 'customers',
  createCustomers: 'create_customers',
  readCustomers: 'read_customers',
  updateCustomers: 'update_customers',
  deleteCustomers: 'delete_customers',

  // EMPLOYEES
  employees: 'employees',
  createEmployees: 'create_employees',
  readEmployees: 'read_employees',
  updateEmployees: 'update_employees',
  deleteEmployees: 'delete_employees',

  // CATEGORIES
  categories: 'categories',
  createCategories: 'create_categories',
  readCategories: 'read_categories',
  updateCategories: 'update_categories',
  deleteCategories: 'delete_categories',

  // VENUES
  venues: 'venues',
  readVenues: 'read_venues',
  updateVenues: 'update_venues',
  deleteVenues: 'delete_venues',
  createVenues: 'create_venues',

  // REQUESTS
  requests: 'requests',
  readRequests: 'read_editvenuerequests',
  updateRequests: 'update_editvenuerequests',
  editHistory: 'editHistory',

  // NYAMI REQUESTS
  nyamiRequests: 'nyamirequests',
  readNyamirequests: 'read_nyamirequests',
  updateNyamiRequests: 'update_enyamirequests',
  deleteNyamiRequests: 'delete_nyamirequests',
  createNyamiRequests: 'create_nyamirequests',

  // ACTIVITY
  activity: 'activity',
  readActivity: 'read_logs',

  // PROFILE
  profile: 'profile',
  readProfile: 'read_profile',
  updateProfile: 'update_profile',
  deleteProfile: 'delete_profile',

  // EMPLOYEE E-MAIL VERIFICATION
  employeeEmailVerification: 'employee-email-verification',

  // AUTH TYPE
  authType: 'basic',

  // SIGN IN PATH
  signInPath: 'dashboard',

  // LOGIN
  login: 'login',

  // FORGOT PASSWORD
  forgotPassword: 'forgot-password',

  // PASSWORD RESET
  passwordReset: 'password-reset',

  // DASHBOARD
  dashboard: 'admin',

  // GROCERY
  grocery: 'grocery',
  departmentGrocery: 'grocery',

  // CMS
  cms: 'cms',
  readCMS: 'read_cms',
  updateCMS: 'update_cms',
  deleteCMS: 'delete_cms',
  CMSHomepage: 'cms-homepage',
  CMSForCompanies: 'cms-for-companies',
  CMSForPartners: 'cms-for-partners',
  CMSAboutUs: 'cms-about-us',

  // SLIDESHOW
  slideshow: 'slideshow',
};

const USER = 'user';

const SUPER_ADMIN = 'Super Admin';

export { PERMISSIONS, USER, SUPER_ADMIN };
