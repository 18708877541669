import React from 'react';
import Modal from '../../../../components/modal/Modal';

export const DeleteReschedule = ({ deleteRescheduledProps, deleteRescheduledModalFormRef, handleOnSubmitRescheduled, updateStatusBtnLoader, setToggleDeleteRescheduled }) => {
  return (
    <Modal {...deleteRescheduledProps} className="sidebar__modal--center order-rescheduled-modal">
      <form name="delete-reschedule" ref={deleteRescheduledModalFormRef} onSubmit={(event) => handleOnSubmitRescheduled({ event: event })} className="ant-form ant-form-horizontal">
        <h2 className="text-center mb-4">Remove rescheduling for this order ?</h2>
        <div className="form-group form-group-modal mb-4">
          {!updateStatusBtnLoader ? (
            <button data-cy="modal-delete-submit-btn" type="submit" className="btn btn-primary-link">
              <span className="text">Remove</span>
            </button>
          ) : (
            <button type="button" className="btn btn-primary-outline">
              <span className="text">Removing...</span>
            </button>
          )}

          <button
            data-cy="modal-delete-cancel-btn"
            type="button"
            className="btn btn-primary"
            onClick={() => {
              setToggleDeleteRescheduled(false);
            }}
          >
            <span className="text">Cancel</span>
          </button>
        </div>
      </form>
    </Modal>
  );
};
