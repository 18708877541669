import React from 'react';
import { Form, Row, Button } from 'antd';
import Modal from '../../../../components/modal/Modal';
import { useTranslation } from 'react-i18next';

export const DeleteMultiRole = ({ deleteMultiProps, deleteMultiFormRef, handleSubmit, setToggleMultiDelete, deleteBlkBtnLoader }) => {
  const { t } = useTranslation();
  return (
    <Modal {...deleteMultiProps} className='sidebar__modal--center' title=''>
      <form data-cy='modal-delete-bulk' name='delete_multi' ref={deleteMultiFormRef} onSubmit={(e) => handleSubmit(e)}>
        <h2 className='text-center mb-4'>{t('areYouSureToDeleteSelectedItems')}</h2>

        <Form.Item>
          <Row gutter={16} style={{ justifyContent: 'center', columnGap: '20px' }}>
            {!deleteBlkBtnLoader ? (
              <Button data-cy='modal-delete-bulk-yes-btn' type='submit' htmlType='submit' className='btn btn-primary-link btn-block'>
                <span>{t('yes')}</span>
              </Button>
            ) : (
              <Button type='button' className='btn btn-primary-link btn-block'>
                <span>{t('deleting')}...</span>
              </Button>
            )}

            <Button data-cy='modal-delete-bulk-no-btn' type='primary' htmlType='button' className='btn btn-primary btn-block' onClick={() => setToggleMultiDelete(false)}>
              <span>{t('no')}</span>
            </Button>
          </Row>
        </Form.Item>
      </form>
    </Modal>
  );
};
