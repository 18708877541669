import React, { memo } from 'react';
import ImageUpload from '../../components/image-upload';
import TooltipDash from '../../../../../helpers/TooltipDash';

const Section2En = ({ formSection2, handleInputChangeFormSection2, setHpImages, hpImages, hpImagesPreview, formSection2Errors, hpImagesErrors }) => {
  return (
    <>
      {/*Shared inputs*/}
      <div className="shared">
        {/*en_mainHeading*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="en_mainHeading">
            <b>Main heading:</b>
          </label>
          <input id="en_mainHeading" type="text" placeholder="Main heading" name="en_mainHeading" value={formSection2.en.mainHeading} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.en.mainHeading ? 'input-error-important' : ''}`} />
        </div>
        {/*en_mainHeading*/}
        {/*en_sec2button1Text*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="en_sec2button1Text">
            <b>Button 1 text:</b>
          </label>
          <input id="en_sec2button1Text" type="text" placeholder="Button 1 text" name="en_sec2button1Text" value={formSection2.en.sec2button1Text} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.en.sec2button1Text ? 'input-error-important' : ''}`} />
        </div>
        {/*en_sec2button1Text*/}
        {/*en_sec2button2Text*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="en_sec2button2Text">
            <b>Button 2 text:</b>
          </label>
          <input id="en_sec2button2Text" type="text" placeholder="Button 2 text" name="en_sec2button2Text" value={formSection2.en.sec2button2Text} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.en.sec2button2Text ? 'input-error-important' : ''}`} />
        </div>
        {/*en_button2Text*/}
      </div>
      {/*Shared inputs*/}
      <div className="cms-form-flex">
        {/*Card 1:*/}
        <div className="cms-form-flex-col">
          <p className="card-top">List 1:</p>
          <div className="cms-form-head-inner">
            <div className="images-card-header">
              <span>Image</span>
              <span className="ml-6">
                <TooltipDash text="Image aspect ratio 3/2 (1500x1000)px" acceptFormatText={true} />
              </span>
            </div>
            <div className="upload-wrapper">
              <ImageUpload hpImages={hpImages} hpImagesErrors={hpImagesErrors} hpImagesPreview={hpImagesPreview} setHpImages={setHpImages} toStoreImg="section2Image1En" />
            </div>
          </div>
          {/*en_list1item1*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="en_list1item1">
              <b>Item 1:</b>
            </label>
            <input id="en_list1item1" type="text" placeholder="Item 1" name="en_list1item1" value={formSection2.en.list1item1} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.en.list1item1 ? 'input-error-important' : ''}`} />
          </div>
          {/*en_list1item1*/}
          {/*en_list1item2*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="en_list1item2">
              <b>Item 2:</b>
            </label>
            <input id="en_list1item2" type="text" placeholder="Item 2:" name="en_list1item2" value={formSection2.en.list1item2} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.en.list1item2 ? 'input-error-important' : ''}`} />
          </div>
          {/*en_list1item2*/}
          {/*en_list1item3*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="en_list1item3">
              <b>Item 3:</b>
            </label>
            <input id="en_list1item3" type="text" placeholder="Item 3" name="en_list1item3" value={formSection2.en.list1item3} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.en.list1item3 ? 'input-error-important' : ''}`} />
          </div>
          {/*en_list1item3*/}
          {/*en_list1item4*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="en_list1item4">
              <b>Item 4:</b>
            </label>
            <input id="en_list1item4" type="text" placeholder="Item 4" name="en_list1item4" value={formSection2.en.list1item4} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.en.list1item4 ? 'input-error-important' : ''}`} />
          </div>
          {/*en_list1item4*/}
          {/*en_list1item5*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="en_list1item5">
              <b>Item 5:</b>
            </label>
            <input id="en_list1item5" type="text" placeholder="Item 5" name="en_list1item5" value={formSection2.en.list1item5} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.en.list1item5 ? 'input-error-important' : ''}`} />
          </div>
          {/*en_list1item5*/}
        </div>
        {/*Card 1:*/}
        {/*Card 2:*/}
        <div className="cms-form-flex-col">
          <p className="card-top">List 2:</p>
          <div className="cms-form-head-inner">
            <div className="images-card-header">
              <span>Image</span>
              <span className="ml-6">
                <TooltipDash text="Image aspect ratio 3/2 (1500x1000)px" acceptFormatText={true} />
              </span>
            </div>
            <div className="upload-wrapper">
              <ImageUpload hpImages={hpImages} hpImagesErrors={hpImagesErrors} hpImagesPreview={hpImagesPreview} setHpImages={setHpImages} toStoreImg="section2Image2En" />
            </div>
          </div>
          {/*en_list2item1*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative', flex: 'unset' }}>
            <label className="label d-block" htmlFor="en_list2item1">
              <b>Item 1:</b>
            </label>
            <input id="en_list2item1" type="text" placeholder="Item 1" name="en_list2item1" value={formSection2.en.list2item1} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.en.list2item1 ? 'input-error-important' : ''}`} />
          </div>
          {/*en_list2item1*/}
          {/*en_list2item2*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative', flex: 'unset' }}>
            <label className="label d-block" htmlFor="en_list2item2">
              <b>Item 2:</b>
            </label>
            <input id="en_list2item2" type="text" placeholder="Item 2:" name="en_list2item2" value={formSection2.en.list2item2} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.en.list2item2 ? 'input-error-important' : ''}`} />
          </div>
          {/*en_list2item2*/}
          {/*en_list2item3*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative', flex: 'unset' }}>
            <label className="label d-block" htmlFor="en_list2item3">
              <b>Item 3:</b>
            </label>
            <input id="en_list2item3" type="text" placeholder="Item 3" name="en_list2item3" value={formSection2.en.list2item3} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.en.list2item3 ? 'input-error-important' : ''}`} />
          </div>
          {/*en_list2item3*/}
          {/*en_list2item4*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative', flex: 'unset' }}>
            <label className="label d-block" htmlFor="en_list2item4">
              <b>Item 4:</b>
            </label>
            <input id="en_list2item4" type="text" placeholder="Item 4" name="en_list2item4" value={formSection2.en.list2item4} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.en.list2item4 ? 'input-error-important' : ''}`} />
          </div>
          {/*en_list2item4*/}
          {/*en_list2item5*/}
          {/* <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="en_list2item5">
              <b>Item 5:</b>
            </label>
            <input id="en_list2item5" type="text" placeholder="Item 5" name="en_list2item5" value={formSection2.en.list2item5} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.en.list2item5 ? 'input-error-important' : ''}`} />
          </div> */}
          {/*en_list1item5*/}
        </div>
        {/*Card 2:*/}
      </div>
    </>
  );
};
export default memo(Section2En);
