import React from 'react';
import Modal from '../../../../components/modal/Modal';
import { DatePicker } from 'antd';

export const RescheduleOrder = ({ rescheduledProps, rescheduledModalFormRef, handleOnSubmitRescheduled, rescheduledOrder, updateStatusBtnLoader, setToggleRescheduled, disabledDate }) => {
  return (
    <Modal {...rescheduledProps} className="sidebar__modal--center order-rescheduled-modal">
      <form name="reschedule" ref={rescheduledModalFormRef} onSubmit={(event) => handleOnSubmitRescheduled({ event: event })} className="ant-form ant-form-horizontal">
        <h2 className="text-center mb-4">Reschedule delivery for:</h2>
        <div className="text-center">
          <DatePicker value={rescheduledOrder.moment} allowClear={false} showToday={false} onChange={(m, s) => onSelectDate(m, s, rescheduledOrder.id, rescheduledOrder.status)} disabledDate={disabledDate} />
        </div>
        <div className="form-group form-group-modal mb-4">
          {!updateStatusBtnLoader ? (
            <button data-cy="modal-delete-submit-btn" type="submit" className="btn btn-primary-link" disabled={rescheduledOrder.moment === undefined && true}>
              <span className="text">Update</span>
            </button>
          ) : (
            <button type="button" className="btn btn-primary-outline">
              <span className="text">Updating...</span>
            </button>
          )}

          <button
            data-cy="modal-delete-cancel-btn"
            type="button"
            className="btn btn-primary"
            onClick={() => {
              setToggleRescheduled(false);
            }}
          >
            <span className="text">Cancel</span>
          </button>
        </div>
      </form>
    </Modal>
  );
};
