export const constructInitialFormSection1State = (data, prevState) => {
  return {
    ...prevState,
    hide: data?.section1Hide || false,
    en: {
      ...prevState.en,
      title1: data?.section1Card1TitleEn || '',
      title2: data?.section1Card2TitleEn || '',
      subTitle1Part1: data?.section1Card1SubtitlePart1En || '',
      subTitle1Part2: data?.section1Card1SubtitlePart2En || '',
      subTitle2Part1: data?.section1Card2SubtitlePart1En || '',
      subTitle2Part2: data?.section1Card2SubtitlePart2En || '',
      button1Text: data?.section1Card1ButtonTextEn || '',
      button2Text: data?.section1Card2ButtonTextEn || '',
      button1Url: data?.section1Card1ButtonUrlEn || '',
      button2Url: data?.section1Card2ButtonUrlEn || '',
    },
    cg: {
      ...prevState.cg,
      title1: data?.section1Card1TitleCg || '',
      title2: data?.section1Card2TitleCg || '',
      subTitle1Part1: data?.section1Card1SubtitlePart1Cg || '',
      subTitle1Part2: data?.section1Card1SubtitlePart2Cg || '',
      subTitle2Part1: data?.section1Card2SubtitlePart1Cg || '',
      subTitle2Part2: data?.section1Card2SubtitlePart2Cg || '',
      button1Text: data?.section1Card1ButtonTextCg || '',
      button2Text: data?.section1Card2ButtonTextCg || '',
      button1Url: data?.section1Card1ButtonUrlCg || '',
      button2Url: data?.section1Card2ButtonUrlCg || '',
    },
  };
};

export const constructInitialFormSection2State = (data, prevState) => {
  return {
    ...prevState,
    hide: data?.section2Hide || false,
    en: {
      ...prevState.en,
      mainHeading: data?.section2MainHeadingEn || '',
      list1item1: data?.section2List1Item1En || '',
      list1item2: data?.section2List1Item2En || '',
      list1item3: data?.section2List1Item3En || '',
      list1item4: data?.section2List1Item4En || '',
    },
    cg: {
      ...prevState.cg,
      mainHeading: data?.section2MainHeadingCg || '',
      list1item1: data?.section2List1Item1Cg || '',
      list1item2: data?.section2List1Item2Cg || '',
      list1item3: data?.section2List1Item3Cg || '',
      list1item4: data?.section2List1Item4Cg || '',
    },
  };
};

export const constructInitialEnImagesState = (data, prevState) => {
  return {
    ...prevState,
    section1Icon1En: data?.section1Card1IconEn?._id || null,
    section1Icon2En: data?.section1Card2IconEn?._id || null,
    section2Image1En: data?.section2Image1En?._id || null,

    section1Icon1Cg: data?.section1Card1IconCg?._id || null,
    section1Icon2Cg: data?.section1Card2IconCg?._id || null,
    section2Image1Cg: data?.section2Image1Cg?._id || null,
  };
};

export const constructInitialEnImagesPreviewState = (data, prevState) => {
  return {
    ...prevState,
    section1Icon1En: data?.section1Card1IconEn?.url || null,
    section1Icon2En: data?.section1Card2IconEn?.url || null,
    section2Image1En: data?.section2Image1En?.url || null,

    section1Icon1Cg: data?.section1Card1IconCg?.url || null,
    section1Icon2Cg: data?.section1Card2IconCg?.url || null,
    section2Image1Cg: data?.section2Image1Cg?.url || null,
  };
};
