import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../context/useAuth';
import Router from '../router/Router';
import Header from '../components/header/Header';
import Sidebar from '../components/sidebar/Sidebar';
import { App, Main, Article } from '../components/content/index';
import { USER, PERMISSIONS } from '../utils/permissions';

const Layout = (props) => {
  const { pathname } = props;
  const { user } = useAuth();
  const location = useLocation();

  const [toggleSidebar, setToggleSidebar] = useState(false);
  const handleToggleSidebar = () => {
    setToggleSidebar((prevState) => !prevState);
  };

  const claseToggleSidebarEscape = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        setToggleSidebar(false);
      }
    },
    [setToggleSidebar]
  );

  useEffect(() => {
    document.addEventListener('keydown', claseToggleSidebarEscape, false);
    return () => {
      document.removeEventListener('keydown', claseToggleSidebarEscape, false);
    };
  }, [claseToggleSidebarEscape]);

  const headerProps = {
    active: toggleSidebar,
    onToggle: handleToggleSidebar,
    onHide: setToggleSidebar
  };

  const sidebarProps = {
    active: toggleSidebar,
    onToggle: handleToggleSidebar,
    onHide: setToggleSidebar
  };

  // CHECK USER SESSION STORAGE
  useEffect(() => {
    const checkUser = window.sessionStorage.getItem(USER);
    if (!checkUser) {
      window.sessionStorage.setItem(USER, JSON.stringify(null));
      window.location.href = `/${PERMISSIONS.login}`;
      window.location.reload();
    }
  }, [location]);

  return (
    <App className={`${user ? 'app__layout' : ''}`}>
      <Main className={`${user ? 'main__layout' : ''}`}>
        {user && <Header {...headerProps} />}
        {user && <Sidebar {...sidebarProps} />}

        <Article className={`${user ? 'gl__article' : ''}`} children={<Router pathname={pathname} />} />
      </Main>
    </App>
  );
};

export default Layout;
