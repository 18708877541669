import React from 'react';
import { stateUS } from '../../../../helpers/state';
import SidebarModal from '../../../../components/sidebarModal/SidebarModal';

export const CreateForm = ({ createProps, createModalFormRef, handleOnSubmit, createForm, createFormError, handleOnChange, handleOnBlur, createFormEmail, addresses, handleOnRemove, setToggleCreateAddress, toggleCreateAddress, TogglePasswordChecker, createPasswordProps, createLoading }) => {
  return (
    <SidebarModal {...createProps} className="sidebar__modal--right" title="Create Member">
      <form
        name="create"
        ref={createModalFormRef}
        onSubmit={(event) =>
          handleOnSubmit({
            event: event,
            name: 'create',
            state: createProps,
          })
        }
        className="ant-form ant-form-horizontal"
      >
        <div className="form-group relative">
          <label className="label mb-1 d-block" htmlFor="createFirstName">
            <b className="required">First Name:</b>
          </label>

          <input name="createFirstName" id="createFirstName" value={createForm.createFirstName || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder="First Name" data-cy="" className={`input ${createFormError.createFirstNameError ? 'input-error' : ''}`} />
          <p data-cy="" className={`error__onblur ${createFormError.createFirstNameError ? 'error' : ''}`}>
            This field is required!
          </p>
        </div>
        <div className="form-group relative">
          <label className="label mb-1 d-block" htmlFor="createLastName">
            <b className="required">Last Name:</b>
          </label>

          <input name="createLastName" id="createLastName" value={createForm.createLastName || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder="Last Name" data-cy="" className={`input ${createFormError.createLastNameError ? 'input-error' : ''}`} />
          <p data-cy="" className={`error__onblur ${createFormError.createLastNameError ? 'error' : ''}`}>
            This field is required!
          </p>
        </div>
        <div className="form-group relative">
          <label className="label mb-1 d-block" htmlFor="createEmail">
            <b className="required">Email:</b>
          </label>

          <input name="createEmail" id="createEmail" value={createForm.createEmail || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="email" placeholder="Email" data-cy="" className={`input ${createFormError.createEmailError || createFormEmail ? 'input-error' : ''}`} />
          <p data-cy="" className={`error__onblur ${createFormError.createEmailError ? 'error' : ''}`}>
            This field is required!
          </p>

          <p data-cy="" className={`error__onblur ${createFormEmail ? 'error' : ''}`}>
            User already exists.
          </p>
        </div>

        <div className="form-group relative">
          <label className="label mb-1 d-block" htmlFor="createStatus">
            <b className="required">Venue:</b>
          </label>

          <select name="createStatus" id="createStatus" defaultValue={createForm.createStatus || 'none'} onChange={handleOnChange} onBlur={handleOnBlur} data-cy="" className={`select select-full ${createFormError.createStatusError ? 'input-error' : ''}`}>
            <option value="none">Select</option>
            <option value="srb">Srbija</option>
            <option value="cg">Crna Gora</option>
            <option value="lt">Litvanija</option>
          </select>

          <p data-cy="" className={`error__onblur ${createFormError.createStatusError ? 'error' : ''}`}>
            This field is required!
          </p>
        </div>

        <span className="fake-label">
          <b className="required">Address:</b>
        </span>
        <div className="form-group form-group-address relative">
          <div className="addresses" data-cy="">
            {addresses &&
              addresses.map((item) => {
                const { address, zip_code, city, state } = item;
                return (
                  <div key={item.address_id} className="item" data-cy="">
                    <p>
                      {address}, {zip_code}, {city}, {state}
                    </p>
                    <button className="btn" type="button" onClick={() => handleOnRemove(item.address_id)}>
                      <span className="icon">
                        <img alt="Delete" src="/assets/icons/delete.svg" />
                      </span>
                    </button>
                  </div>
                );
              })}
          </div>

          <button className="btn btn-default" type="button" onClick={() => setToggleCreateAddress((prevState) => !prevState)}>
            <span className="icon">
              <img alt="Create new Address" src="/assets/icons/plus.svg" />
            </span>
            <span className="text"></span>
          </button>
        </div>
        {toggleCreateAddress && (
          <div className="wrapper-address">
            <div className="form-group relative">
              <label className="label mb-1 d-block" htmlFor="createAddress">
                <b className="required">Address:</b>
              </label>

              <input name="createAddress" id="createAddress" value={createForm.createAddress || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder="Address" data-cy="" className={`input ${createFormError.createAddressError ? 'input-error' : ''}`} />
              <p data-cy="" className={`error__onblur ${createFormError.createAddressError ? 'error' : ''}`}>
                This field is required!
              </p>
            </div>

            <div className="form-group relative">
              <label className="label mb-1 d-block" htmlFor="createZipCode">
                <b className="required">Zip Code:</b>
              </label>

              <input name="createZipCode" id="createZipCode" value={createForm.createZipCode || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="number" placeholder="Zip Code" data-cy="" className={`input ${createFormError.createZipCodeError ? 'input-error' : ''}`} />
              <p data-cy="" className={`error__onblur ${createFormError.createZipCodeError ? 'error' : ''}`}>
                This field is required!
              </p>
            </div>

            <div className="form-group relative">
              <label className="label mb-1 d-block" htmlFor="createCity">
                <b className="required">City:</b>
              </label>

              <input name="createCity" id="createCity" value={createForm.createCity || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder="City" data-cy="" className={`input ${createFormError.createCityError ? 'input-error' : ''}`} />
              <p data-cy="" className={`error__onblur ${createFormError.createCityError ? 'error' : ''}`}>
                This field is required!
              </p>
            </div>

            <div className="form-group relative">
              <label className="label mb-1 d-block" htmlFor="createState">
                <b className="required">State:</b>
              </label>

              <select name="createState" id="createState" value={createForm.createState || 'none'} onChange={handleOnChange} onBlur={handleOnBlur} data-cy="" className={`select select-full ${createFormError.createStateError ? 'input-error' : ''}`}>
                <option value="none">Select State</option>
                {stateUS &&
                  stateUS.length > 0 &&
                  stateUS.map((item) => {
                    return (
                      <option key={`create_${item.name.toLowerCase()}`} value={item.abbreviation}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>

              <p data-cy="" className={`error__onblur ${createFormError.createStateError ? 'error' : ''}`}>
                This field is required!
              </p>
            </div>

            <div className="form-group form-group-sidebar mt-6">
              <button data-cy="" type="button" className="btn btn-primary-link" onClick={() => setToggleCreateAddress(false)}>
                <span className="text">Cancel</span>
              </button>

              <button data-cy="" type="button" className="btn btn-primary" onClick={handleOnSave}>
                <span className="text">Create Address</span>
              </button>
            </div>
          </div>
        )}
        <div className="form-group relative">
          <label className="label mb-1 d-block" htmlFor="createPhone">
            <b className="required">Phone:</b>
          </label>

          <input name="createPhone" id="createPhone" value={createForm.createPhone || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="number" placeholder="Phone" data-cy="" className={`input ${createFormError.createPhoneError ? 'input-error' : ''}`} />

          <p data-cy="" className={`error__onblur ${createFormError.createPhoneError ? 'error' : ''}`}>
            This field is required!
          </p>
        </div>

        <TogglePasswordChecker {...createPasswordProps} />

        <div className="form-group relative">
          <label className="label mb-1 d-block" htmlFor="createStatus">
            <b className="required">Status:</b>
          </label>

          <select name="createStatus" id="createStatus" defaultValue={createForm.createStatus || 'none'} onChange={handleOnChange} onBlur={handleOnBlur} data-cy="" className={`select select-full ${createFormError.createStatusError ? 'input-error' : ''}`}>
            <option value="none">Select</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>

          <p data-cy="" className={`error__onblur ${createFormError.createStatusError ? 'error' : ''}`}>
            This field is required!
          </p>
        </div>
        <div className="form-group form-group-sidebar">
          <button data-cy="modal-create-cancel-btn" type="button" className="btn btn-primary-link" onClick={() => setToggleCreate(false)}>
            <span className="text">Cancel</span>
          </button>

          {!createLoading ? (
            <button data-cy="modal-create-submit-btn" type="submit" className="btn btn-primary">
              <span className="text">Create Member</span>
            </button>
          ) : (
            <button data-cy="modal-create-submit-btn" type="button" className="btn btn-primary-outline">
              <span className="text">Loading...</span>
            </button>
          )}
        </div>
      </form>
    </SidebarModal>
  );
};
