import React, { useState, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Image } from 'antd';
import { SERVER_URL } from '../../../../config/index';
import { AXIOS_API_CALL } from '../../../../utils/endpoint';
import { useAuth } from '../../../../context/useAuth';
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload, Modal, notification } from 'antd';
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
const ImageUpload = ({ enImages, enImagesPreview, setEnImages, toStoreImg, enImagesErrors, cg }) => {
  const MAX_FILE_SIZE = 2 * 1024 * 1024;
  const { user } = useAuth();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.response.image.url || file.preview);
    setPreviewOpen(true);
  };
  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    // setPreviewOpen(true);
  };
  const handleRemove = () => {
    setPreviewOpen(false);
  };
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      {/* <PlusOutlined /> */}
      <div
        style={{
          marginTop: 8,
        }}
      >
        {!enImagesPreview[toStoreImg] && fileList.length === 0 ? 'Upload image' : 'Replace image'}
      </div>
    </button>
  );
  const uploadProps = {
    name: 'file',
    name: 'image',
    accept: '.jpg,.jpeg,.png',
    action: `${SERVER_URL}/${AXIOS_API_CALL.uploadLocalImage}`,
    headers: {
      uri: 'public/images/common/',
      Authorization: `Bearer ${user.token}`,
      // caption: props.caption,
    },
    beforeUpload: (file) => {
      const isAcceptedType = ['image/jpeg', 'image/png', 'image/webp'].includes(file.type);
      if (!isAcceptedType) {
        notification.error({
          message: 'You can only upload JPG/PNG/WEBP images.',
        });
        return Upload.LIST_IGNORE; // Prevent upload
      }
      if (file.size > MAX_FILE_SIZE) {
        notification.error({
          message: 'Image size exceeds the 2MB limit.',
        });
        return Upload.LIST_IGNORE; // Prevent upload
      }
      return true; // Allow upload
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
        setFileList(info.fileList);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  useEffect(() => {
    if (fileList.length === 1) {
      setEnImages((prev) => ({
        ...prev,
        [toStoreImg]: fileList[0]?.response?.image?._id,
      }));
    }
  }, [fileList]);

  return (
    <>
      <div
        className={`img-upload-wrap Hpsection1Icon
        ${toStoreImg === 'section2Image1En' ? 'Hpsection2Image' : ''}
        ${toStoreImg === 'section2Image2En' ? 'Hpsection2Image' : ''}`}
      >
        {fileList.length !== 1 && enImagesPreview[toStoreImg] && (
          <div className="img-upload-wrap__placeholder">
            <Image src={`${enImagesPreview[toStoreImg]}`} />
          </div>
        )}
        {fileList.length === 0 && !enImagesPreview[toStoreImg] && <div className={`img-upload-wrap__placeholder ${enImagesErrors[toStoreImg] ? 'input-error-important dashed' : ''}`}>No Image Picked</div>}
        <Upload {...uploadProps} listType="picture-card" fileList={fileList} onPreview={handlePreview} onRemove={handleRemove} onChange={handleChange} maxCount={1}>
          {fileList.length >= 8 ? null : uploadButton}
        </Upload>
        {previewImage && (
          <div className="" style={{ display: 'none' }}>
            <Image
              preview={{
                visible: previewOpen,
                onVisibleChange: (visible) => setPreviewOpen(visible),
                afterOpenChange: (visible) => !visible && setPreviewImage(''),
              }}
              src={previewImage}
              style={{ display: 'none' }}
            />
          </div>
        )}
        {/* {fileList.length !== 1 && hpImagesPreview[toStoreImg] && <Image src={`${hpImagesPreview[toStoreImg]}`} width={200} />} */}
      </div>
    </>
  );
};
export default ImageUpload;
