import React from 'react';
import Modal from '../../../../components/modal/Modal';
import { useTranslation } from 'react-i18next';

export const BulkStatusDeactivate = ({ bulkDeactivateProps, deactiveModalFormRef, handleOnSubmit, deactivateBlkBtnLoader, setToggleBulkDeactive }) => {
  const { t } = useTranslation();
  return (
    <Modal {...bulkDeactivateProps} className='sidebar__modal--center' title=''>
      <form
        name='bulkDeactive'
        ref={deactiveModalFormRef}
        onSubmit={(event) =>
          handleOnSubmit({
            event: event,
            name: 'bulk-deactivate',
            state: bulkDeactivateProps,
          })
        }
        className='ant-form ant-form-horizontal'
      >
        <h2 className='text-center mb-4'>{t('areYouSureToDeactivateMembers')}</h2>

        <div className='form-group form-group-modal mb-4'>
          {!deactivateBlkBtnLoader ? (
            <button data-cy='modal-bulk-deactive-submit-btn' type='submit' className='btn btn-primary-link'>
              <span className='text'>{t('yes')}</span>
            </button>
          ) : (
            <button type='submit' className='btn btn-primary-link-outline'>
              <span className='text'>{t('deactivating')}...</span>
            </button>
          )}

          <button data-cy='modal-bulk-deactive-cancel-btn' type='button' className='btn btn-primary' onClick={() => setToggleBulkDeactive(false)}>
            <span className='text'>{t('no')}</span>
          </button>
        </div>
      </form>
    </Modal>
  );
};
