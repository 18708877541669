const Section3En = ({ handleInputChangeFormSection3, formSection3, formSection3Errors }) => {
  return (
    <>
      {/*Shared inputs*/}
      <div className="shared">
        {/*en_sec3MainHeading*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="en_sec3MainHeading">
            <b>Main heading:</b>
          </label>
          <input id="en_sec3MainHeading" type="text" placeholder="Main heading" name="en_sec3MainHeading" value={formSection3.en.sec3MainHeading} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.en.sec3MainHeading ? 'input-error-important' : ''}`} />
        </div>
        {/*en_sec3MainHeading*/}
      </div>
      {/*Shared inputs*/}
      <div className="cms-form-flex">
        {/*Info 1*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Info 1:</p>
          {/*en_sec3Info1Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="en_sec3Info1Title">
              <b>Info 1 Title:</b>
            </label>
            <textarea id="en_sec3Info1Title" type="text" placeholder="Info 1 Title" name="en_sec3Info1Title" value={formSection3.en.sec3Info1Title} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.en.sec3Info1Title ? 'input-error-important' : ''}`} />
          </div>
          {/*en_sec3Info1Title*/}
          {/*en_sec3Info1Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="en_sec3Info1Text">
              <b>Info 1 Text:</b>
            </label>
            <textarea id="en_sec3Info1Text" type="text" placeholder="Info 1 Text" name="en_sec3Info1Text" value={formSection3.en.sec3Info1Text} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.en.sec3Info1Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*en_sec3Info1Text*/}
        </div>
        {/*Info 1*/}

        {/*Info 2*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Info 2:</p>
          {/*en_sec3Info2Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="en_sec3Info2Title">
              <b>Info 2 Title:</b>
            </label>
            <textarea id="en_sec3Info2Title" type="text" placeholder="Info 2 Title" name="en_sec3Info2Title" value={formSection3.en.sec3Info2Title} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.en.sec3Info2Title ? 'input-error-important' : ''}`} />
          </div>
          {/*en_sec3Info2Title*/}
          {/*en_sec3Info2Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="en_sec3Info2Text">
              <b>Info 2 Text:</b>
            </label>
            <textarea id="en_sec3Info2Text" type="text" placeholder="Info 2 Text" name="en_sec3Info2Text" value={formSection3.en.sec3Info2Text} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.en.sec3Info2Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*en_sec3Info2Text*/}
        </div>
        {/*Info 2*/}

        {/*Info 3*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Info 3:</p>
          {/*en_sec3Info3Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="en_sec3Info3Title">
              <b>Info 3 Title:</b>
            </label>
            <textarea id="en_sec3Info3Title" type="text" placeholder="Info 3 Title" name="en_sec3Info3Title" value={formSection3.en.sec3Info3Title} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.en.sec3Info3Title ? 'input-error-important' : ''}`} />
          </div>
          {/*en_sec3Info3Title*/}
          {/*en_sec3Info3Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="en_sec3Info3Text">
              <b>Info 3 Text:</b>
            </label>
            <textarea id="en_sec3Info3Text" type="text" placeholder="Info 3 Text" name="en_sec3Info3Text" value={formSection3.en.sec3Info3Text} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.en.sec3Info3Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*en_sec3Info3Text*/}
        </div>
        {/*Info 3*/}

        {/*Info 4*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Info 4:</p>
          {/*en_sec3Info4Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="en_sec3Info4Title">
              <b>Info 4 Title:</b>
            </label>
            <textarea id="en_sec3Info4Title" type="text" placeholder="Info 4 Title" name="en_sec3Info4Title" value={formSection3.en.sec3Info4Title} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.en.sec3Info4Title ? 'input-error-important' : ''}`} />
          </div>
          {/*en_sec3Info4Title*/}
          {/*en_sec3Info4Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="en_sec3Info4Text">
              <b>Info 4 Text:</b>
            </label>
            <textarea id="en_sec3Info4Text" type="text" placeholder="Info 4 Text" name="en_sec3Info4Text" value={formSection3.en.sec3Info4Text} onChange={handleInputChangeFormSection3} className={`${formSection3Errors.en.sec3Info4Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*en_sec3Info4Text*/}
        </div>
        {/*Info 4*/}
      </div>
    </>
  );
};
export default Section3En;
