const App = (props) => {
  const { className, children } = props;
  return <div className={`app ${className !== undefined ? className : ''}`}>{children}</div>;
};

const Main = (props) => {
  const { className, children } = props;
  return <main className={`main ${className !== undefined ? className : ''}`}>{children}</main>;
};

const Article = (props) => {
  const { className, children } = props;
  return <article className={`article ${className !== undefined ? className : ''}`}>{children}</article>;
};

const Section = (props) => {
  const { className, children } = props;
  return <section className={`section ${className !== undefined ? className : ''}`}>{children}</section>;
};

const Aside = (props) => {
  const { className, children } = props;
  return <aside className={`sidebar ${className !== undefined ? className : ''}`}>{children}</aside>;
};

const Nav = (props) => {
  const { className, children } = props;
  return <nav className={`nav ${className !== undefined ? className : ''}`}>{children}</nav>;
};

const Figure = (props) => {
  const { className, children } = props;
  return <figure className={`figure ${className !== undefined ? className : ''}`}>{children}</figure>;
};

const Wrapper = (props) => {
  const { className, children } = props;
  return <div className={`wrapper ${className !== undefined ? className : ''}`}>{children}</div>;
};

const Container = (props) => {
  const { className, children } = props;
  return <div className={`container ${className !== undefined ? className : ''}`}>{children}</div>;
};

const ContainerFluid = (props) => {
  const { className, children } = props;
  return <div className={`container-fluid ${className !== undefined ? className : ''}`}>{children}</div>;
};

const Row = (props) => {
  const { className, children } = props;
  return <div className={`row ${className !== undefined ? className : ''}`}>{children}</div>;
};

const Col = (props) => {
  const { className, children } = props;
  return <div className={`col ${className !== undefined ? className : ''}`}>{children}</div>;
};

const Breadcrumbs = (props) => {
  const { className, children } = props;
  return <nav className={`breadcrumbs ${className !== undefined ? className : ''}`}>{children}</nav>;
};

export { App, Main, Article, Section, Aside, Nav, Figure, Wrapper, Container, ContainerFluid, Row, Col, Breadcrumbs };
