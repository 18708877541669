import React, { memo } from 'react';
import ImageUpload from '../../components/image-upload';
import TooltipDash from '../../../../../helpers/TooltipDash';

const Section1En = ({ handleInputChangeFormSection1, formSection1, hpImages, hpImagesPreview, setHpImages, formSection1Errors, hpImagesErrors }) => {
  return (
    <div className="cms-form-flex">
      {/*Card 1:*/}
      <div className="cms-form-flex-col">
        <p className="card-top">Card 1:</p>
        <div className="cms-form-head-inner">
          <div className="images-card-header">
            <span>Icon</span>
            <span className="ml-6">
              <TooltipDash text="Icon aspect ratio 1/1 (150x150)px" acceptFormatText={true} />
            </span>
          </div>
          <div className="upload-wrapper">
            <ImageUpload hpImages={hpImages} hpImagesPreview={hpImagesPreview} setHpImages={setHpImages} hpImagesErrors={hpImagesErrors} toStoreImg="section1Icon1En" />
          </div>
        </div>
        {/*en_title1*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="en_title1">
            <b>Title:</b>
          </label>
          <input id="en_title1" type="text" placeholder="Title" name="en_title1" value={formSection1.en.title1} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.en.title1 ? 'input-error-important' : ''}`} />
        </div>
        {/*en_title1*/}
        {/*en_subTitle1Part1*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="en_subTitle1Part1">
            <b>Sub Title Line 1:</b>
          </label>
          <input id="en_subTitle1Part1" type="text" placeholder="Sub Title Line 1" name="en_subTitle1Part1" value={formSection1.en.subTitle1Part1} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.en.subTitle1Part1 ? 'input-error-important' : ''}`} />
        </div>
        {/*en_subTitle1Part1*/}
        {/*en_subTitle1Part2*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="en_subTitle1Part2">
            <b>Sub Title Line 2:</b>
          </label>
          <input id="en_subTitle1Part2" type="text" placeholder="Sub Title Line 2" name="en_subTitle1Part2" value={formSection1.en.subTitle1Part2} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.en.subTitle1Part2 ? 'input-error-important' : ''}`} />
        </div>
        {/*en_subTitle1Part2*/}
        {/*en_button1Text*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="en_button1Text">
            <b>Button text:</b>
          </label>
          <input id="en_button1Text" type="text" placeholder="Button text" name="en_button1Text" value={formSection1.en.button1Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.en.button1Text ? 'input-error-important' : ''}`} />
        </div>
        {/*en_button1Text*/}
        {/*en_button1Url*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="en_buttonUrl1">
            <b>Button URL:</b>
          </label>
          <input id="en_button1Url" type="text" placeholder="Button URL" name="en_button1Url" value={formSection1.en.button1Url} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.en.button1Url ? 'input-error-important' : ''}`} />
        </div>
        {/*en_button1Url*/}
      </div>
      {/*Card 1:*/}
      {/*Card 2:*/}
      <div className="cms-form-flex-col">
        <p className="card-top">Card 2:</p>
        <div className="cms-form-head-inner">
          <div className="images-card-header">
            <span>Icon</span>
            <span className="ml-6">
              <TooltipDash text="Icon aspect ratio 1/1 (150x150)px" acceptFormatText={true} />
            </span>
          </div>
          <div className="upload-wrapper">
            <ImageUpload hpImages={hpImages} hpImagesPreview={hpImagesPreview} setHpImages={setHpImages} hpImagesErrors={hpImagesErrors} toStoreImg="section1Icon2En" />
          </div>
        </div>
        {/*en_title2*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="en_title2">
            <b>Title:</b>
          </label>
          <input id="en_title2" type="text" placeholder="Title" name="en_title2" value={formSection1.en.title2} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.en.title2 ? 'input-error-important' : ''}`} />
        </div>
        {/*en_title2*/}
        {/*en_subTitle2Part1*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="en_subTitle2Part1">
            <b>Sub Title Line 1:</b>
          </label>
          <input id="en_subTitle2Part1" type="text" placeholder="Sub Title Line 1" name="en_subTitle2Part1" value={formSection1.en.subTitle2Part1} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.en.subTitle2Part1 ? 'input-error-important' : ''}`} />
        </div>
        {/*en_subTitle2Part1*/}
        {/*en_subTitle2Part2*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="en_subTitle2Part2">
            <b>Sub Title Line 2:</b>
          </label>
          <input id="en_subTitle2Part2" type="text" placeholder="Sub Title Line 2" name="en_subTitle2Part2" value={formSection1.en.subTitle2Part2} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.en.subTitle2Part2 ? 'input-error-important' : ''}`} />
        </div>
        {/*en_subTitle2Part2*/}
        {/*en_button2Text*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="en_button2Text">
            <b>Button text:</b>
          </label>
          <input id="en_button2Text" type="text" placeholder="Button text" name="en_button2Text" value={formSection1.en.button2Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.en.button2Text ? 'input-error-important' : ''}`} />
        </div>
        {/*en_button2Text*/}
        {/*en_button2Url*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="en_button2Url">
            <b>Button URL:</b>
          </label>
          <input id="en_button2Url" type="text" placeholder="Button URL" name="en_button2Url" value={formSection1.en.button2Url} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.en.button2Url ? 'input-error-important' : ''}`} />
        </div>
        {/*en_button2Url*/}
      </div>
      {/*Card 2:*/}
    </div>
  );
};
export default memo(Section1En);
