import { createContext, useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const AppContext = createContext();

export const useAppProvider = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(null);
  const [secondaryLanguage, setSecondaryLanguage] = useState(null);
  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    sessionStorage.setItem('primaryLang', lang);
    sessionStorage.setItem('secondaryLang', lang === 'cg' ? 'en' : 'cg');
    setCurrentLanguage(lang);
    setSecondaryLanguage(lang === 'cg' ? 'en' : 'cg');
    // setLangDropdownOpen(false);
  };
  useEffect(() => {
    // get item from session storage and setCurrentLanguage
    setCurrentLanguage(sessionStorage.getItem('primaryLang') || 'en');
    setSecondaryLanguage(sessionStorage.getItem('secondaryLang') || 'cg');
    i18n.changeLanguage(sessionStorage.getItem('primaryLang') || 'en');
  }, []);
  return (
    <AppContext.Provider
      value={{
        handleChangeLanguage,
        currentLanguage,
        secondaryLanguage,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export function useAppContext() {
  return useContext(AppContext);
}
