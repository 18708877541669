import React, { useState, useCallback, useEffect } from 'react';
import { Main } from '../../../components/content';
import { Collapse } from 'antd';
import CmsAboutUsHeader from './sections/CmsAboutUsHeader';
import CmsAboutUsSection1 from './sections/CmsAboutUsSection1';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { useAuth } from '../../../context/useAuth';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { PERMISSIONS } from '../../../utils/permissions';
import { SERVER_URL } from '../../../config/index';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';

import CheckboxCms from '../../../components/checkbox-cms';

const CmsAboutUs = () => {
  const { user } = useAuth();
  const [groceryPermissions, setGroceryPermissions] = useState([]);
  const [initialData, setInitialData] = useState(null);
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;

    if (user && token) {
      const decodeToken = jwtDecode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
          setGroceryPermissions(permissions.grocery);
        }
      }
    }
  }, [user, location]);

  // IMAGES HANDLE
  const [auImages, setAuImages] = React.useState({
    sectionHeaderImageEn: '',
    sectionHeaderImageCg: '',

    section1Image1En: '',
    section1Image2En: '',

    section1Image1Cg: '',
    section1Image2Cg: '',
  });
  const [auImagesPreview, setAuImagesPreview] = React.useState({
    sectionHeaderImageEn: '',
    sectionHeaderImageCg: '',

    section1Image1En: '',
    section1Image2En: '',

    section1Image1Cg: '',
    section1Image2Cg: '',
  });
  const [auImagesErrors, setAuImagesErrors] = React.useState({
    sectionHeaderImageEn: false,
    sectionHeaderImageCg: false,

    section1Image1En: false,
    section1Image2En: false,

    section1Image1Cg: false,
    section1Image2Cg: false,
  });
  // IMAGES HANDLE

  // HEADER HANDLE
  const [formSectionHeader, setFormSectionHeader] = React.useState({
    en: {
      headMainHeadingPart1: '',
      headMainHeadingPart2: '',
    },
    cg: {
      headMainHeadingPart1: '',
      headMainHeadingPart2: '',
    },
  });
  const [formSectionHeaderErrors, setFormSectionHeaderErrors] = React.useState({
    en: {
      headMainHeadingPart1: false,
      headMainHeadingPart2: false,
    },
    cg: {
      headMainHeadingPart1: false,
      headMainHeadingPart2: false,
    },
  });
  const handleInputChangeFormSectionHeader = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    setFormSectionHeader((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value,
      },
    }));
  }, []);
  const onChangeTabSectionHeader = useCallback(() => {}, []);
  // HEADER HANDLE

  const [formGeneralSettings, setFormGeneralSettings] = React.useState({
    hide: false,
  });
  // SECTION 1 HANDLE
  const [formSection1, setFormSection1] = React.useState({
    hide: false,
    en: {
      sec1MainHeading: '',
      sec1Row1Text: '',
      sec1Row1VideoUrl: '',
      sec1Row2Text: '',
      sec1Row3Text: '',
    },
    cg: {
      sec1MainHeading: '',
      sec1Row1Text: '',
      sec1Row1VideoUrl: '',
      sec1Row2Text: '',
      sec1Row3Text: '',
    },
  });
  const [formSection1Errors, setFormSection1Errors] = React.useState({
    en: {
      sec1MainHeading: false,
      sec1Row1Text: false,
      sec1RowVideoUrl: false,
      sec1Row2Text: false,
      sec1Row3Text: false,
    },
    cg: {
      sec1MainHeading: false,
      sec1Row1Text: false,
      sec1RowVideoUrl: false,
      sec1Row2Text: false,
      sec1Row3Text: false,
    },
  });
  const handleInputChangeFormSection1 = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    if (name === 'section1_hide') {
      setFormSection1((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
      return;
    }

    setFormSection1((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value,
      },
    }));
  }, []);
  const onChangeTabSection1 = useCallback(() => {}, []);
  // SECTION 1 HANDLE

  // HANDLE FORM SUBMIT
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const { token } = user;

    // Initialize error flag
    let hasError = false;

    // Create a copy of the error state
    let newFormSection1Errors = { ...formSection1Errors };
    let newFormSectionHeaderErrors = { ...formSectionHeaderErrors };

    // Validate Section 1 (EN)
    Object.keys(formSection1.en).forEach((key) => {
      if (!formSection1.en[key]) {
        newFormSection1Errors.en[key] = true;
        hasError = true;
      } else {
        newFormSection1Errors.en[key] = false;
      }
    });

    // Validate Section 1 (CG)
    Object.keys(formSection1.cg).forEach((key) => {
      if (!formSection1.cg[key]) {
        newFormSection1Errors.cg[key] = true;
        hasError = true;
      } else {
        newFormSection1Errors.cg[key] = false;
      }
    });

    // Validate Section Header (EN)
    Object.keys(formSectionHeader.en).forEach((key) => {
      if (!formSectionHeader.en[key]) {
        newFormSectionHeaderErrors.en[key] = true;
        hasError = true;
      } else {
        newFormSectionHeaderErrors.en[key] = false;
      }
    });

    // Validate Section Header (CG)
    Object.keys(formSectionHeader.cg).forEach((key) => {
      if (!formSectionHeader.cg[key]) {
        newFormSectionHeaderErrors.cg[key] = true;
        hasError = true;
      } else {
        newFormSectionHeaderErrors.cg[key] = false;
      }
    });

    // check if there are any fcImages that have value of '' (empty string)
    Object.keys(auImages).forEach((key) => {
      if (auImages[key] === null || auImages[key] === '') {
        setAuImagesErrors((prevState) => ({
          ...prevState,
          [key]: true,
        }));
        hasError = true;
      } else {
        setAuImagesErrors((prevState) => ({
          ...prevState,
          [key]: false,
        }));
      }
    });

    // Update error state
    setFormSection1Errors(newFormSection1Errors);
    setFormSectionHeaderErrors(newFormSectionHeaderErrors);

    // Prevent form submission if there are errors
    if (hasError) {
      notification.error({
        message: 'Please fill in all required fields and upload all images',
        placement: 'topRight',
      });
      return;
    }

    setSaving(true);

    const payload = {
      auHide: formGeneralSettings.hide || false,

      section1Hide: formSection1.hide || false,

      // header
      sectionHeaderMainHeadingPart1En: formSectionHeader.en.headMainHeadingPart1 || '',
      sectionHeaderMainHeadingPart2En: formSectionHeader.en.headMainHeadingPart2 || '',

      sectionHeaderMainHeadingPart1Cg: formSectionHeader.cg.headMainHeadingPart1 || '',
      sectionHeaderMainHeadingPart2Cg: formSectionHeader.cg.headMainHeadingPart2 || '',

      section1MainHeadingEn: formSection1.en.sec1MainHeading || '',
      section1Row1TextEn: formSection1.en.sec1Row1Text || '',
      section1Row1VideoUrlEn: formSection1.en.sec1Row1VideoUrl || '',
      section1Row2TextEn: formSection1.en.sec1Row2Text || '',
      section1Row3TextEn: formSection1.en.sec1Row3Text || '',

      section1MainHeadingCg: formSection1.cg.sec1MainHeading || '',
      section1Row1TextCg: formSection1.cg.sec1Row1Text || '',
      section1Row1VideoUrlCg: formSection1.cg.sec1Row1VideoUrl || '',
      section1Row2TextCg: formSection1.cg.sec1Row2Text || '',
      section1Row3TextCg: formSection1.cg.sec1Row3Text || '',

      // images
      sectionHeaderImageEn: auImages.sectionHeaderImageEn || null,
      sectionHeaderImageCg: auImages.sectionHeaderImageCg || null,

      section1Image1En: auImages.section1Image1En || null,
      section1Image2En: auImages.section1Image2En || null,

      section1Image1Cg: auImages.section1Image1Cg || null,
      section1Image2Cg: auImages.section1Image2Cg || null,
    };

    try {
      if (!initialData || initialData.length === 0) {
        const response = await axios.post(
          `${SERVER_URL}/${AXIOS_API_CALL.createAuDocument}`,
          {
            ...payload,
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      } else {
        const response = await axios.put(
          `${SERVER_URL}/${AXIOS_API_CALL.updateAuDocument}/${initialData[0]?._id}`,
          {
            ...payload,
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
    notification.success({
      message: 'Data saved successfully',
    });
    setSaving(false);
    setTimeout(() => {
      navigate(0);
    }, 1000);
  };
  // HANDLE FORM SUBMIT

  // HANDLE FETCH AND SET INITIAL DATA
  const handleFetchData = async () => {
    const { token } = user;
    try {
      const response = await axios.get(`${SERVER_URL}/${AXIOS_API_CALL.getAuDocument}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });
      setInitialData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!initialData) return;
    setFormGeneralSettings((prevState) => ({
      ...prevState,
      hide: initialData[0]?.auHide || false,
    }));
    setFormSectionHeader((prevState) => ({
      ...prevState,
      en: {
        headMainHeadingPart1: initialData[0]?.sectionHeaderMainHeadingPart1En || '',
        headMainHeadingPart2: initialData[0]?.sectionHeaderMainHeadingPart2En || '',
      },
      cg: {
        headMainHeadingPart1: initialData[0]?.sectionHeaderMainHeadingPart1Cg || '',
        headMainHeadingPart2: initialData[0]?.sectionHeaderMainHeadingPart2Cg || '',
      },
    }));
    setFormSection1((prevState) => ({
      ...prevState,
      hide: initialData[0]?.section1Hide || false,
      en: {
        sec1MainHeading: initialData[0]?.section1MainHeadingEn || '',
        sec1Row1Text: initialData[0]?.section1Row1TextEn || '',
        sec1Row1VideoUrl: initialData[0]?.section1Row1VideoUrlEn || '',
        sec1Row2Text: initialData[0]?.section1Row2TextEn || '',
        sec1Row3Text: initialData[0]?.section1Row3TextEn || '',
      },
      cg: {
        sec1MainHeading: initialData[0]?.section1MainHeadingCg || '',
        sec1Row1Text: initialData[0]?.section1Row1TextCg || '',
        sec1Row1VideoUrl: initialData[0]?.section1Row1VideoUrlCg || '',
        sec1Row2Text: initialData[0]?.section1Row2TextCg || '',
        sec1Row3Text: initialData[0]?.section1Row3TextCg || '',
      },
    }));

    setAuImages((prevState) => ({
      ...prevState,
      sectionHeaderImageEn: initialData[0]?.sectionHeaderImageEn?._id || null,
      sectionHeaderImageCg: initialData[0]?.sectionHeaderImageCg?._id || null,
      section1Image1En: initialData[0]?.section1Image1En?._id || null,
      section1Image2En: initialData[0]?.section1Image2En?._id || null,
      section1Image1Cg: initialData[0]?.section1Image1Cg?._id || null,
      section1Image2Cg: initialData[0]?.section1Image2Cg?._id || null,
    }));
    setAuImagesPreview((prevState) => ({
      ...prevState,
      sectionHeaderImageEn: initialData[0]?.sectionHeaderImageEn?.url || null,
      sectionHeaderImageCg: initialData[0]?.sectionHeaderImageCg?.url || null,
      section1Image1En: initialData[0]?.section1Image1En?.url || null,
      section1Image2En: initialData[0]?.section1Image2En?.url || null,
      section1Image1Cg: initialData[0]?.section1Image1Cg?.url || null,
      section1Image2Cg: initialData[0]?.section1Image2Cg?.url || null,
    }));
  }, [initialData]);

  useEffect(() => {
    handleFetchData();
  }, []);
  // HANDLE FETCH AND SET INITIAL DATA

  const handleInputChangeGeneralSettings = (e) => {
    const { name, checked } = e.target;
    if (name === 'au_hide') {
      setFormGeneralSettings((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
    }
  };

  // PANEL AND TABS RELATED FUNCTIONS
  const { Panel } = Collapse;
  const onChange = useCallback(() => {}, []);
  // PANEL AND TABS RELATED FUNCTIONS

  return (
    <div className="cms cms-for-companies">
      <h2 className="page-title">CMS About us</h2>
      {/* Main Content */}
      <form className="cms-form cms-form-section cms-form-section1">
        <Main className="section__content relative">
          <Collapse onChange={onChange}>
            {/* Header (Join the Fitpass Revolution) */}
            <Panel header="HEADER (Join the Fitpass Revolution)" key="1">
              <CmsAboutUsHeader onChangeTabSectionHeader={onChangeTabSectionHeader} formSectionHeader={formSectionHeader} handleInputChangeFormSectionHeader={handleInputChangeFormSectionHeader} auImages={auImages} auImagesErrors={auImagesErrors} auImagesPreview={auImagesPreview} setAuImages={setAuImages} formSectionHeaderErrors={formSectionHeaderErrors} />
            </Panel>
            {/* Header (Join the Fitpass Revolution) */}

            {/* SECTION 1 (About us) */}
            <Panel header="SECTION 1 (About us)" key="2">
              <CmsAboutUsSection1 onChangeTabSection1={onChangeTabSection1} formSection1={formSection1} handleInputChangeFormSection1={handleInputChangeFormSection1} auImages={auImages} auImagesErrors={auImagesErrors} auImagesPreview={auImagesPreview} setAuImages={setAuImages} formSection1Errors={formSection1Errors} />
            </Panel>
            {/* SECTION 1 (About us) */}
          </Collapse>
          <CheckboxCms inputName="au_hide" isChecked={formGeneralSettings.hide} handleChange={handleInputChangeGeneralSettings} label="Hide entire page on website" tooltipText="Checking this will hide entire page on website" />
          <button className="btn btn-primary pl-3 pr-3 mt-3 mb-5" onClick={(e) => handleFormSubmit(e)}>
            {saving ? 'Saving changes...' : 'Save changes'}
          </button>
        </Main>
      </form>
      {/* Main Content */}
    </div>
  );
};
export default CmsAboutUs;
