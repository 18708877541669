import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { SERVER_URL } from '../../../config/index';
import { useAuth } from '../../../context/useAuth';
import { Main, Section } from '../../../components/content';
import { notification } from 'antd';
import Sorting from './Sorting';
import Loading from '../../../components/loading/Loading';
import LoadingPlaceholder from '../../../components/loadingPlaceholder/LoadingPlaceholder';
import { jwtDecode } from 'jwt-decode';
import { getGroceryPermissions } from '../../../middleware';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { PERMISSIONS } from '../../../utils/permissions';
import { slugify, slugifyReplaceAll } from '../../../helpers/helpers';
import { notificationMessages } from '../../../helpers/notificationMessages';
import { CreateParentCategory } from './create-parent-category/CreateParentCategory';
import { CreateSubcategory } from './create-subcategory/CreateSubcategory';
import { UpdateCategory } from './update-category/UpdateCategory';
import { DeleteCategory } from './delete-category/DeleteCategory';
import { ImportNetSuiteModal } from './import-netsuite-modal/ImportNetSuiteModal';

const Categories = () => {
  const { user } = useAuth();
  const location = useLocation();
  const [groceryPermissions, setGroceryPermissions] = useState([]);

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;
    if (user && token) {
      const decodeToken = jwtDecode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
          setGroceryPermissions(permissions.grocery);
        }
      }
    }
  }, [user, location]);

  // READ
  const [readData, setReadData] = useState([]);
  const [readDataRefetch, setReadDataRefetch] = useState(false);
  const [readDataLoading, setReadDataLoading] = useState(true);

  const getReadData = useCallback(async () => {
    const { token } = user;

    try {
      setReadDataLoading(true);
      await axios
        .post(
          `${SERVER_URL}/${AXIOS_API_CALL.categoriesGetAll}`,
          { department: PERMISSIONS.grocery },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` }
          }
        )
        .then((res) => {
          setReadData(res?.data);
        })
        .catch((err) => console.error(err))
        .finally(
          setTimeout(() => {
            setReadDataLoading(false);
          }, 700)
        );
    } catch (err) {
      console.error(err);
      setReadDataLoading(false);
    }
  }, [user, setReadDataLoading, setReadData]);

  useEffect(() => {
    getReadData();
  }, [getReadData, readDataRefetch]);

  const [attributes, setAttributes] = useState([]);
  const getData = useCallback(
    async (props) => {
      const { token } = user;

      try {
        let response = await axios
          .post(
            `${SERVER_URL}/${AXIOS_API_CALL.attributes}`,
            { department: PERMISSIONS.grocery },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${token}` }
            }
          )
          .then((res) => {
            setAttributes(res.data.items);
          })
          .catch((err) => console.error(err))
          .finally(setTimeout(() => {}, 200));

        return response;
      } catch (err) {}
    },
    [user, setAttributes]
  );

  useEffect(() => {
    getData();
  }, [getData]);

  // Selected Attributes
  const [selected, setSelected] = useState([]);
  const handleOnSelect = (data) => {
    setSelected(data);
  };

  // LOADER PROTECTION
  const [createBtnLoader, setCreateBtnLoader] = useState(false);
  const [createSubBtnLoader, setCreateSubBtnLoader] = useState(false);
  const [editBtnLoader, setEditBtnLoader] = useState(false);
  const [deleteBtnLoader, setDeleteBtnLoader] = useState(false);

  // CREATE - parent
  const createFormRef = useRef(null);
  const [toggleCreate, setToggleCreate] = useState(false);
  const [createFormParent, setCreateFormParent] = useState({ parent: false });
  const [createForm, setCreateForm] = useState({
    createName: '',
    createSlug: ''
  });
  const [isFeatured, setIsFeatured] = useState(false);
  // ----------------------- IMAGE ----------------------
  const [imageData, setImageData] = useState({
    upload: [],
    image: [],
    blob: ''
  });
  // ------------------ ERROR HANDLING ------------------
  const [createFormError, setCreateFormError] = useState({
    createName: false,
    createSlug: false,
    uniqueName: false,
    uniqueSlug: false,
    imageSize: false,
    imageType: false
  }); // check

  // CREATE - subcategory
  const createSubcategoryFormRef = useRef(null);
  const [toggleSubcategoryCreate, setToggleSubcategoryCreate] = useState(false);
  const [createSubcategoryFormId, setCreateSubcategoryFormId] = useState({
    id: ''
  });
  const [createSubcategoryForm, setCreateSubcategoryForm] = useState({
    createSubcategoryName: '',
    createSubcategorySlug: ''
  });

  // ----------------------- IMAGE ----------------------
  const [imageSubcategoryData, setImageSubcategoryData] = useState({
    upload: [],
    image: [],
    blob: ''
  });
  // ------------------ ERROR HANDLING ------------------
  const [createSubcategoryFormError, setCreateSubcategoryFormError] = useState({
    createSubcategoryName: false,
    createSubcategorySlug: false,
    uniqueSubcategoryName: false,
    uniqueSubcategorySlug: false,
    imageSize: false,
    imageType: false
  }); // check

  // UPDATE
  const updateFormRef = useRef(null);
  const [toggleUpdate, setToggleUpdate] = useState(false);
  const [updateFormId, setUpdateFormId] = useState({ id: '' });
  const [updateParentFormId, setUpdateParentFormId] = useState({ id: '' });
  const [updateData, setUpdateData] = useState([]);
  const [updateForm, setUpdateForm] = useState({
    updateName: '',
    updateSlug: '',
    image: [],
    parentCategory: null
  });
  const [updateFormDropdown, setUpdateFormDropdown] = useState([]);

  // ----------------------- IMAGE ----------------------
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [imageUpdateData, setImageUpdateData] = useState({
    upload: [],
    image: [],
    blob: ''
  });
  // ------------------ ERROR HANDLING ------------------
  const [updateFormError, setUpdateFormError] = useState({
    updateName: false,
    updateSlug: false,
    uniqueName: false,
    uniqueSlug: false,
    imageSize: false,
    imageType: false
  }); // check

  // DELETE
  const deleteFormRef = useRef(null);
  const [toggleDelete, setToggleDelete] = useState(false);
  const [deleteFormId, setDeleteFormId] = useState({ id: '' });

  // IMPORT FROM NETSUITE
  const importNetSuiteModalRef = useRef(null);
  const [toggleImportNetSuite, setToggleImportNetSuite] = useState(false);
  const [showImportNetSuiteLoader, setShowImportNetSuiteLoader] = useState(false);

  // FUNCTION
  function handleToggle(props) {
    const { name, data } = props;

    switch (name) {
      case 'create':
        setToggleCreate(true);
        setCreateFormParent({ parent: true });

        break;
      case 'createSubcategory':
        setToggleSubcategoryCreate(true);
        setCreateSubcategoryFormId({ id: data.node._id });
        setCreateFormParent({ parent: false });

        break;
      case 'update':
        setToggleUpdate(true);
        setUpdateFormId({ id: data.node._id });
        setUpdateParentFormId({ id: data.node.parent });

        if (data.node.parent) {
          setCreateFormParent({ parent: false });
        } else {
          setCreateFormParent({ parent: true });
        }

        break;
      case 'delete':
        setToggleDelete(true);
        setDeleteFormId({ id: data.node._id });
        setCreateFormParent({ parent: false });
        break;
      case 'importNetSuite':
        setToggleImportNetSuite(true);
        break;
      default:
        console.warn('Default of: handleToggle function');
    }
  }

  // SETUP UPDATE STATE AFTER FETCH DATA
  const getUpdateData = useCallback(async () => {
    const { token } = user;
    let updateId = updateFormId.id;

    if (!!updateId) {
      try {
        setLoadingUpdate(true);
        await axios
          .get(`${SERVER_URL}/${AXIOS_API_CALL.categories}/${updateId}`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` }
          })
          .then((res) => {
            if (res.status === 200) {
              setUpdateData(res?.data);
              setIsFeatured(res?.data.categoryData.isFeatured);
            }
          })
          .catch((err) => console.error(err))
          .finally(
            setTimeout(() => {
              setLoadingUpdate(false);
            }, 700)
          );
      } catch (err) {
        console.error(err);
        setLoadingUpdate(false);
      }
    }
  }, [user, updateFormId, setUpdateData, setLoadingUpdate]);

  useEffect(() => {
    getUpdateData();
  }, [getUpdateData]);

  useEffect(() => {
    if (updateData && updateData.length !== 0) {
      let newUpdateFormData = {
        updateName: updateData.categoryData.name,
        updateSlug: updateData.categoryData.slug,
        parentCategory: updateData.categoryData.parent,
        image: [],
        isFeatured: updateData.categoryData.isFeatured
      };

      if (updateData.categoryData.hasOwnProperty('image')) {
        const image = updateData?.categoryData?.image;

        newUpdateFormData = {
          ...newUpdateFormData,
          image: image
        };

        setUpdateForm(newUpdateFormData);
      } else {
        setUpdateForm(newUpdateFormData);
      }
    }
  }, [updateData]);

  useEffect(() => {
    if (updateData && updateData.hasOwnProperty('DropDownData')) {
      setUpdateFormDropdown(updateData.DropDownData);
    }
  }, [updateData]);

  function handleOnUpload(props) {
    const { name, img } = props;

    const isJpgOrPng = img.type === 'image/jpeg' || img.type === 'image/jpg' || img.type === 'image/png';
    const isLt2M = img.size / 1024 / 1024 < 2;

    if (name === 'createImage') {
      if (isJpgOrPng && isLt2M) {
        setImageData({
          ...imageData,
          upload: img,
          blob: URL.createObjectURL(img)
        });
        setCreateFormError({
          ...createFormError,
          imageType: false,
          imageSize: false
        });
      }

      if (!isJpgOrPng) {
        setCreateFormError({ ...createFormError, imageType: true });
      }

      if (!isLt2M) {
        setCreateFormError({ ...createFormError, imageSize: true });
      }
    }

    if (name === 'createSubcategoryImage') {
      if (isJpgOrPng && isLt2M) {
        setImageSubcategoryData({
          ...imageSubcategoryData,
          upload: img,
          blob: URL.createObjectURL(img)
        });
        setCreateSubcategoryFormError({
          ...createSubcategoryFormError,
          imageType: false,
          imageSize: false
        });
      }

      if (!isJpgOrPng) {
        setCreateSubcategoryFormError({
          ...createSubcategoryFormError,
          imageType: true
        });
      }

      if (!isLt2M) {
        setCreateSubcategoryFormError({
          ...createSubcategoryFormError,
          imageSize: true
        });
      }
    }

    if (name === 'updateImage') {
      if (isJpgOrPng && isLt2M) {
        setImageUpdateData({
          ...imageUpdateData,
          upload: img,
          blob: URL.createObjectURL(img)
        });
        setUpdateFormError({
          ...updateFormError,
          imageType: false,
          imageSize: false
        });
      }

      if (!isJpgOrPng) {
        setUpdateFormError({ ...updateFormError, imageType: true });
      }

      if (!isLt2M) {
        setUpdateFormError({ ...updateFormError, imageSize: true });
      }
    }
  }

  function handleOnRemoveUpload(props) {
    const { name } = props;

    if (name === 'create') {
      setImageData({ upload: [], image: [], blob: '' });
      setCreateFormError({
        ...createFormError,
        imageSize: false,
        imageType: false
      });
    }

    if (name === 'createSubcategory') {
      setImageSubcategoryData({ upload: [], image: [], blob: '' });
    }

    if (name === 'update') {
      setImageUpdateData({ upload: [], image: [], blob: '' });
    }

    if (name === 'updateRemove') {
      setUpdateForm({ ...updateForm, image: [] });
    }
  }

  async function getUploadImage(props) {
    const { name } = props;
    let data = [];

    const { token } = user;

    if (name === 'create') {
      if (imageData.upload.length !== 0) {
        let uploadImage = new FormData();
        uploadImage.append('image', imageData?.upload);

        if (typeof imageData?.upload === 'object') {
          try {
            await axios
              .post(`${SERVER_URL}/${AXIOS_API_CALL.uploadLocalImage}`, uploadImage, {
                withCredentials: false,
                headers: {
                  'form-data': 'application/json',
                  uri: 'public/images/dashboard/categories/',
                  Authorization: `Bearer ${token}`
                }
              })
              .then((res) => {
                data = res.data.image;
                setImageData({ ...imageData, image: res.data.image });
              })
              .catch((err) => console.error(err))
              .finally(setTimeout(() => {}, 700));
          } catch (err) {
            console.error(err);
          }
        }
      }
    }

    if (name === 'createSubcategory') {
      if (imageSubcategoryData.upload.length !== 0) {
        let uploadImage = new FormData();
        uploadImage.append('image', imageSubcategoryData?.upload);

        if (typeof imageSubcategoryData?.upload === 'object') {
          try {
            await axios
              .post(`${SERVER_URL}/${AXIOS_API_CALL.uploadLocalImage}`, uploadImage, {
                withCredentials: false,
                headers: {
                  'form-data': 'application/json',
                  uri: 'public/images/dashboard/categories/',
                  Authorization: `Bearer ${token}`
                }
              })
              .then((res) => {
                data = res.data.image;
                setImageSubcategoryData({
                  ...imageSubcategoryData,
                  image: res.data.image
                });
              })
              .catch((err) => console.error(err))
              .finally(setTimeout(() => {}, 700));
          } catch (err) {
            console.error(err);
          }
        }
      }
    }

    if (name === 'update') {
      if (imageUpdateData.upload.length !== 0) {
        let uploadImage = new FormData();
        uploadImage.append('image', imageUpdateData?.upload);

        if (typeof imageSubcategoryData?.upload === 'object') {
          try {
            await axios
              .post(`${SERVER_URL}/${AXIOS_API_CALL.uploadLocalImage}`, uploadImage, {
                withCredentials: false,
                headers: {
                  'form-data': 'application/json',
                  uri: 'public/images/dashboard/categories/',
                  Authorization: `Bearer ${token}`
                }
              })
              .then((res) => {
                data = res.data.image;
                setImageUpdateData({
                  ...imageUpdateData,
                  image: res.data.image
                });
              })
              .catch((err) => console.error(err))
              .finally(setTimeout(() => {}, 700));
          } catch (err) {
            console.error(err);
          }
        }
      }
    }

    return data;
  }

  function handleOnBlur(event) {
    const { name, value } = event.target;

    switch (name) {
      case 'createName':
        if (value.length >= 2) {
          setCreateFormError({ ...createFormError, createName: false });
        } else {
          toggleCreate && setCreateFormError({ ...createFormError, createName: true });
        }
        break;
      case 'createSlug':
        if (value.length >= 2) {
          setCreateFormError({ ...createFormError, createSlug: false });
        } else {
          toggleCreate && setCreateFormError({ ...createFormError, createSlug: true });
        }
        break;
      case 'createSubcategoryName':
        if (value.length >= 2) {
          setCreateSubcategoryFormError({
            ...createSubcategoryFormError,
            createSubcategoryName: false
          });
        } else {
          toggleSubcategoryCreate &&
            setCreateSubcategoryFormError({
              ...createSubcategoryFormError,
              createSubcategoryName: true
            });
        }
        break;
      case 'createSubcategorySlug':
        if (value.length >= 2) {
          setCreateSubcategoryFormError({
            ...createSubcategoryFormError,
            createSubcategorySlug: false
          });
        } else {
          toggleSubcategoryCreate &&
            setCreateSubcategoryFormError({
              ...createSubcategoryFormError,
              createSubcategorySlug: true
            });
        }
        break;
      case 'updateName':
        if (value.length >= 2) {
          setUpdateFormError({ ...updateFormError, updateName: false });
        } else {
          toggleUpdate && setUpdateFormError({ ...updateFormError, updateName: true });
        }
        break;
      case 'updateSlug':
        if (value.length >= 2) {
          setUpdateFormError({ ...updateFormError, updateSlug: false });
        } else {
          toggleUpdate && setUpdateFormError({ ...updateFormError, updateSlug: true });
        }
        break;
      default:
        console.warn('Default of: handleOnBlur function');
    }
  }

  async function checkSlug(slug) {
    try {
      const res = await axios.post(`${SERVER_URL}/${AXIOS_API_CALL.checkCategorySlug}`, {
        slug
      });
      return res.data.message;
    } catch (e) {
      console.error(e.message);
    }
  }

  async function checkName(name) {
    try {
      const res = await axios.post(`${SERVER_URL}/${AXIOS_API_CALL.checkCategoryName}`, {
        name
      });
      return res.data.message;
    } catch (e) {
      console.error(e.message);
    }
  }

  const [disabledCreate, setDisabledCreate] = useState(false);
  const [disabledCreateSub, setDisabledCreateSub] = useState(false);
  const [disabledUpdate, setDisabledUpdate] = useState(false);
  const [createUniqueSlug, setCreateUniqueSlug] = useState(false);
  const [createUniqueName, setCreateUniqueName] = useState(false);
  const [createSubUniqueSlug, setCreateSubUniqueSlug] = useState(false);
  const [createSubUniqueName, setCreateSubUniqueName] = useState(false);
  const [updateUniqueSlug, setUpdateUniqueSlug] = useState(false);
  const [updateUniqueName, setUpdateUniqueName] = useState(false);

  useEffect(() => {
    setDisabledCreate(createFormError.uniqueSlug || createFormError.uniqueName);
  }, [createFormError.uniqueSlug, createFormError.uniqueName]);

  useEffect(() => {
    if (createSubcategoryFormError.uniqueSubcategorySlug || createSubcategoryFormError.uniqueSubcategoryName) {
      setDisabledCreateSub(true);
    } else {
      setDisabledCreateSub(false);
    }
  }, [createSubcategoryFormError.uniqueSubcategorySlug, createSubcategoryFormError.uniqueSubcategoryName]);

  useEffect(() => {
    if (updateData.categoryData?.name === updateForm.updateName && updateData.categoryData?.slug === updateForm.updateSlug) {
      setDisabledUpdate(false);
    } else {
      setDisabledUpdate(updateFormError.uniqueSlug || updateFormError.uniqueName);
    }
  }, [updateData.categoryData?.name, updateData.updateSlug, updateForm.updateName, updateForm.updateSlug, updateFormError.uniqueSlug, updateFormError.uniqueName]);

  useEffect(() => {
    setCreateFormError((prevState) => {
      return {
        ...prevState,
        uniqueSlug: createUniqueSlug,
        uniqueName: createUniqueName
      };
    });
  }, [createUniqueSlug, createUniqueName, setCreateFormError]);

  useEffect(() => {
    setCreateSubcategoryFormError((prevState) => {
      return {
        ...prevState,
        uniqueSubcategorySlug: createSubUniqueSlug,
        uniqueSubcategoryName: createSubUniqueName
      };
    });
  }, [createSubUniqueSlug, createSubUniqueName, setCreateSubcategoryFormError]);

  useEffect(() => {
    setUpdateFormError((prevState) => {
      return {
        ...prevState,
        uniqueName: updateUniqueName,
        uniqueSlug: updateUniqueSlug
      };
    });
  }, [updateUniqueSlug, updateUniqueName, setUpdateFormError]);

  const handleOnChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case 'createName':
        let slug = slugify(value);

        checkSlug(slug).then((res) => {
          setCreateUniqueSlug(res);
        });

        checkName(value).then((res) => {
          setCreateUniqueName(res);
        });

        setCreateForm({ ...createForm, [name]: value, createSlug: slug });
        break;
      case 'createSlug':
        let createSlug = slugifyReplaceAll(value);

        checkSlug(createSlug).then((res) => {
          setCreateUniqueSlug(res);
        });

        setCreateForm({ ...createForm, [name]: createSlug });
        break;
      case 'createSubcategoryName':
        let slugSubcategory = slugify(value);

        checkSlug(slugSubcategory).then((res) => {
          setCreateSubUniqueSlug(res);
        });

        checkName(value).then((res) => {
          setCreateSubUniqueName(res);
        });

        setCreateSubcategoryForm({
          ...createSubcategoryForm,
          [name]: value,
          createSubcategorySlug: slugSubcategory
        });
        break;
      case 'createSubcategorySlug':
        let createSlugSubcategory = slugifyReplaceAll(value);

        checkSlug(createSlugSubcategory).then((res) => {
          setCreateSubUniqueSlug(res);
        });

        setCreateSubcategoryForm({
          ...createSubcategoryForm,
          [name]: createSlugSubcategory
        });
        break;
      case 'updateName':
        setUpdateForm({ ...updateForm, [name]: value });

        if (value !== updateData.categoryData?.name) {
          checkName(value).then((res) => {
            setUpdateUniqueName(res);
          });
        } else {
          setUpdateUniqueName(false);
        }
        break;
      case 'updateSlug':
        let updateSlug = slugifyReplaceAll(value);

        setUpdateForm({ ...updateForm, [name]: updateSlug });

        if (updateSlug !== updateData.categoryData?.slug) {
          checkSlug(updateSlug).then((res) => {
            setUpdateUniqueSlug(res);
          });
        } else {
          setUpdateUniqueSlug(false);
        }
        break;
      case 'parentCategory':
        if (value !== 'none') {
          setUpdateForm({ ...updateForm, [name]: value });
        } else {
          setUpdateForm({ ...updateForm, [name]: null });
        }
        break;
      default:
        console.warn('Default of: handleOnChange function');
    }
  };

  function handleOnValidation(props) {
    const { name, form } = props;

    let checkCase = false;

    let checkCreateName = false;
    let checkCreateSlug = false;

    let checkCreateSubcategoryName = false;
    let checkCreateSubcategorySlug = false;

    let checkUpdateName = false;
    let checkUpdateSlug = false;

    switch (name) {
      case 'create':
        if (form.createName.length >= 2) {
          checkCreateName = true;
          setCreateFormError({ ...createFormError, createName: false });
        } else {
          checkCreateName = false;
          setCreateFormError({ ...createFormError, createName: true });
        }

        if (form.createSlug.length >= 2) {
          checkCreateSlug = true;
          setCreateFormError({ ...createFormError, createSlug: false });
        } else {
          checkCreateSlug = false;
          setCreateFormError({ ...createFormError, createSlug: true });
        }

        if (!checkCreateName && !checkCreateSlug) {
          setCreateFormError({
            ...createFormError,
            createName: true,
            createSlug: true
          });
        }

        if (checkCreateName && checkCreateSlug) {
          checkCase = true;
        }

        break;
      case 'createSubcategory':
        if (form.createSubcategoryName.length >= 2) {
          checkCreateSubcategoryName = true;
          setCreateSubcategoryFormError({
            ...createSubcategoryFormError,
            createSubcategoryName: false
          });
        } else {
          checkCreateSubcategoryName = false;
          setCreateSubcategoryFormError({
            ...createSubcategoryFormError,
            createSubcategoryName: true
          });
        }

        if (form.createSubcategorySlug.length >= 2) {
          checkCreateSubcategorySlug = true;
          setCreateSubcategoryFormError({
            ...createSubcategoryFormError,
            createSubcategorySlug: false
          });
        } else {
          checkCreateSubcategorySlug = false;
          setCreateSubcategoryFormError({
            ...createSubcategoryFormError,
            createSubcategorySlug: true
          });
        }

        if (!checkCreateSubcategoryName && !checkCreateSubcategorySlug) {
          setCreateSubcategoryFormError({
            ...createSubcategoryFormError,
            createSubcategoryName: true,
            createSubcategorySlug: true
          });
        }

        if (checkCreateSubcategoryName && checkCreateSubcategorySlug) {
          checkCase = true;
        }

        break;
      case 'update':
        if (form.updateName.length >= 2) {
          checkUpdateName = true;
          setUpdateFormError({ ...updateFormError, updateName: false });
        } else {
          checkUpdateName = false;
          setUpdateFormError({ ...updateFormError, updateName: true });
        }

        if (form.updateSlug.length >= 2) {
          checkUpdateSlug = true;
          setUpdateFormError({ ...updateFormError, updateSlug: false });
        } else {
          checkUpdateSlug = false;
          setUpdateFormError({ ...updateFormError, updateSlug: true });
        }

        if (!checkUpdateName && !checkUpdateSlug) {
          setUpdateFormError({
            ...updateFormError,
            updateName: true,
            updateSlug: true
          });
        }

        if (checkUpdateName && checkUpdateSlug) {
          checkCase = true;
        }
        break;
      default:
        console.warn('Default of: handleOnValidation function');
    }

    return checkCase;
  }

  async function handleOnSubmit(props) {
    const { event, formRef } = props;
    const { name } = event.target;

    event.preventDefault();

    const { token } = user;

    switch (name) {
      case 'create':
        setCreateBtnLoader(true);
        let validateCreate = handleOnValidation({
          name: 'create',
          form: createForm
        });

        if (validateCreate) {
          const getImageUpload = await getUploadImage({ name: 'create' });

          setTimeout(() => {
            const selectedAttributes = selected && selected.map((item) => item._id);

            let createPayload = {
              name: createForm.createName,
              slug: createForm.createSlug,
              department: PERMISSIONS.grocery,
              attributes: selectedAttributes,
              isFeatured: isFeatured
            };

            if (getImageUpload && getImageUpload.length !== 0) {
              createPayload = {
                ...createPayload,
                image: getImageUpload
              };
            }

            try {
              axios
                .post(
                  `${SERVER_URL}/${AXIOS_API_CALL.categories}`,
                  { ...createPayload },
                  {
                    withCredentials: false,
                    headers: { Authorization: `Bearer ${token}` }
                  }
                )
                .then((res) => {
                  if (res.status === 201) {
                    setToggleCreate(false);
                    setReadDataRefetch((prevState) => !prevState);
                    setCreateForm({ createName: '', createSlug: '' });
                    setIsFeatured(false);
                    setImageData({ upload: [], image: [], blob: '' });
                    formRef.current.reset();

                    // notification
                    notification.success({
                      message: notificationMessages.successCreateCategory,
                      placement: 'bottomLeft'
                    });
                    setTimeout(() => {
                      setCreateBtnLoader(false);
                    }, 700);
                  }
                })
                .catch((err) => {
                  console.error(err);
                  setCreateBtnLoader(false);
                });
            } catch (err) {
              console.error(err);
            }
          }, 150);
        } else {
          setCreateBtnLoader(false);
        }
        break;

      case 'createSubcategory':
        setCreateSubBtnLoader(true);
        let validateSubcategory = handleOnValidation({
          name: 'createSubcategory',
          form: createSubcategoryForm
        });

        if (validateSubcategory) {
          let subcategoryID = createSubcategoryFormId.id;
          const getImageUpload = await getUploadImage({
            name: 'createSubcategory'
          });

          setTimeout(() => {
            let subcategoryCreatePayload = [];

            const selectedAttributes = selected && selected.map((item) => item._id);

            if (getImageUpload && getImageUpload.length === 0) {
              subcategoryCreatePayload = {
                name: createSubcategoryForm.createSubcategoryName,
                slug: createSubcategoryForm.createSubcategorySlug,
                department: PERMISSIONS.grocery,
                parent: subcategoryID,
                attributes: selectedAttributes
              };
            } else {
              subcategoryCreatePayload = {
                name: createSubcategoryForm.createSubcategoryName,
                slug: createSubcategoryForm.createSubcategorySlug,
                department: PERMISSIONS.grocery,
                parent: subcategoryID,
                image: getImageUpload,
                attributes: selectedAttributes
              };
            }

            try {
              axios
                .post(
                  `${SERVER_URL}/${AXIOS_API_CALL.categories}`,
                  { ...subcategoryCreatePayload },
                  {
                    withCredentials: false,
                    headers: { Authorization: `Bearer ${token}` }
                  }
                )
                .then((res) => {
                  if (res.status === 201) {
                    setToggleSubcategoryCreate(false);
                    setReadDataRefetch((prevState) => !prevState);
                    setCreateSubcategoryFormId({ id: '' });
                    setCreateSubcategoryForm({ createSubcategoryName: '' });
                    setImageSubcategoryData({
                      upload: [],
                      image: [],
                      blob: ''
                    });
                    formRef.current.reset();

                    // notification
                    notification.success({
                      message: notificationMessages.successCreateSubcategory,
                      placement: 'bottomLeft'
                    });
                  }
                  setTimeout(() => {
                    setCreateSubBtnLoader(false);
                  }, 700);
                })
                .catch((err) => console.error(err));
            } catch (err) {
              console.error(err);
            }
          }, 150);
        } else {
          setCreateSubBtnLoader(false);
        }
        break;

      case 'update':
        setEditBtnLoader(true);
        let validateUpdate = handleOnValidation({
          name: 'update',
          form: updateForm
        });

        if (validateUpdate) {
          let updateID = updateFormId.id;
          const getImageUpload = await getUploadImage({ name: 'update' });

          setTimeout(() => {
            let updatePayload = [];

            const selectedAttributes = selected && selected.map((item) => item._id);

            if (getImageUpload && getImageUpload.length === 0) {
              if (!!updateForm.image && updateForm.image._id) {
                updatePayload = {
                  name: updateForm.updateName,
                  slug: updateForm.updateSlug,
                  parent: updateForm.parentCategory,
                  department: PERMISSIONS.grocery,
                  attributes: selectedAttributes,
                  isFeatured: isFeatured
                };
              } else {
                updatePayload = {
                  name: updateForm.updateName,
                  slug: updateForm.updateSlug,
                  image: null,
                  parent: updateForm.parentCategory,
                  department: PERMISSIONS.grocery,
                  attributes: selectedAttributes,
                  isFeatured: isFeatured
                };
              }
            } else {
              updatePayload = {
                name: updateForm.updateName,
                slug: updateForm.updateSlug,
                image: getImageUpload,
                parent: updateForm.parentCategory,
                department: PERMISSIONS.grocery,
                attributes: selectedAttributes,
                isFeatured: isFeatured
              };
            }

            axios
              .put(
                `${SERVER_URL}/${AXIOS_API_CALL.categories}/${updateID}`,
                { ...updatePayload },
                {
                  withCredentials: false,
                  headers: { Authorization: `Bearer ${token}` }
                }
              )
              .then((res) => {
                if (res.status === 200) {
                  notification.success({
                    message: res.data.message,
                    placement: 'bottomLeft'
                  });

                  setToggleUpdate(false);
                  setReadDataRefetch((prevState) => !prevState);
                  setUpdateFormId({ id: '' });
                  setIsFeatured(false);
                  setUpdateForm({ updateName: '' });
                  setImageUpdateData({ upload: [], image: [], blob: '' });
                  formRef.current.reset();
                }
              })
              .catch((err) => console.error(err))
              .finally(
                setTimeout(() => {
                  setEditBtnLoader(false);
                }, 700)
              );
          }, 150);
        } else {
          setEditBtnLoader(false);
        }
        break;

      case 'delete':
        setDeleteBtnLoader(true);
        let deleteID = deleteFormId.id;

        try {
          await axios
            .delete(`${SERVER_URL}/${AXIOS_API_CALL.categories}/${deleteID}`, {
              withCredentials: false,
              headers: {
                department: PERMISSIONS.grocery,
                Authorization: `Bearer ${token}`
              }
            })
            .then((res) => {
              if (res.status === 200) {
                setToggleDelete(false);
                setReadDataRefetch((prevState) => !prevState);
                setDeleteFormId({ id: '' });
                formRef.current.reset();
              }
              setTimeout(() => {
                setDeleteBtnLoader(false);
              }, 700);
            })
            .catch((err) => console.error(err));
        } catch (err) {
          console.error(err);
          setDeleteBtnLoader(false);
        }
        break;

      case 'importNetSuite':
        setShowImportNetSuiteLoader(true);
        try {
          await axios
            .post(
              `${SERVER_URL}/${AXIOS_API_CALL.importCategoriesFromNetSuite}`,
              { department: PERMISSIONS.grocery },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Access-Control-Allow-Origin': '*'
                }
              }
            )
            .then((res) => {
              if (res.status === 200) {
                setReadDataRefetch((prevState) => !prevState);
                setToggleImportNetSuite(false);
                // notification
                notification.success({
                  message: res.data.message,
                  placement: 'bottomLeft'
                });
              }
              setTimeout(() => {
                setShowImportNetSuiteLoader(false);
              }, 700);
            });
        } catch (err) {
          setShowImportNetSuiteLoader(false);
          setToggleImportNetSuite(false);
          console.error(err.message);
          // notification
          notification.warn({
            message: err.message,
            placement: 'bottomLeft'
          });
          console.error('Import categories from NetSuite error: ', err);
        }
        break;
      default:
        console.warn('Default of: handleOnSubmit function');
    }
  }

  // PROPS
  const createProps = {
    active: toggleCreate,
    onToggle: setToggleCreate,
    formRef: createFormRef
  };

  const createSubcategoryProps = {
    active: toggleSubcategoryCreate,
    onToggle: setToggleSubcategoryCreate,
    formRef: createSubcategoryFormRef
  };

  const updateProps = {
    active: toggleUpdate,
    onToggle: setToggleUpdate,
    formRef: updateFormRef,
    loading: loadingUpdate
  };

  const deleteProps = {
    active: toggleDelete,
    onToggle: setToggleDelete,
    formRef: deleteFormRef
  };

  const sortingActionsProps = {
    user: user,
    handleToggle: handleToggle,

    location: location,
    groceryPermissions: groceryPermissions,
    getGroceryPermissions: getGroceryPermissions
  };

  // import NetSuite PROPS
  const importNetSuiteProps = {
    active: toggleImportNetSuite,
    onToggle: setToggleImportNetSuite,
    formRef: importNetSuiteModalRef
  };

  const [updateDataAttribute, setUpdateDataAttribute] = useState([]);
  useEffect(() => {
    if (updateData && updateData.categoryData && updateData.categoryData.length !== 0) {
      setUpdateDataAttribute(updateData.categoryData.attributes);
    }
  }, [updateData]);

  const createParentCategoryProps = {
    createProps,
    createFormRef,
    handleOnSubmit,
    imageData,
    handleOnRemoveUpload,
    handleOnUpload,
    createFormError,
    createForm,
    handleOnChange,
    handleOnBlur,
    attributes,
    toggleCreate,
    handleOnSelect,
    setToggleCreate,
    createBtnLoader,
    disabledCreate,
    setSelected,
    isFeatured,
    setIsFeatured
  };

  const createSubcatProps = {
    createSubcategoryProps,
    createSubcategoryFormRef,
    handleOnSubmit,
    createSubcategoryForm,
    handleOnChange,
    handleOnBlur,
    createSubcategoryFormError,
    attributes,
    toggleSubcategoryCreate,
    handleOnSelect,
    setSelected,
    setToggleSubcategoryCreate,
    createSubBtnLoader,
    disabledCreateSub
  };

  const updateCategoryProps = {
    updateProps,
    loadingUpdate,
    updateFormRef,
    handleOnSubmit,
    createFormParent,
    updateForm,
    handleOnRemoveUpload,
    imageUpdateData,
    handleOnUpload,
    updateFormError,
    updateParentFormId,
    updateFormDropdown,
    attributes,
    updateDataAttribute,
    toggleUpdate,
    handleOnSelect,
    setSelected,
    setToggleUpdate,
    editBtnLoader,
    disabledUpdate,
    handleOnChange,
    handleOnBlur,
    isFeatured,
    setIsFeatured
  };

  const deleteCategoryProps = {
    deleteProps,
    deleteFormRef,
    handleOnSubmit,
    deleteBtnLoader,
    setToggleDelete
  };

  const importNetSuiteModalProps = {
    importNetSuiteProps,
    handleOnSubmit,
    showImportNetSuiteLoader,
    setToggleImportNetSuite
  };

  return (
    <>
      <h2 className="page-title">Categories</h2>
      <Section className="section section__wrapper section__categories">
        {/* Header */}
        <header className="section__header">
          <div className="left">{!readDataLoading ? <h3 className="mb-0">Categories</h3> : <LoadingPlaceholder style={{ width: '94.5px', height: '50px' }} />}</div>

          <div className="right actions">
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.createCategories) && (
              <>
                {!readDataLoading ? (
                  <button
                    style={{ opacity: '.2' }}
                    disabled
                    type="button"
                    onClick={() =>
                      handleToggle({
                        name: 'importNetSuite',
                        state: importNetSuiteProps
                      })
                    }
                    data-cy="import-netsuite-btn"
                    className="btn btn-primary-outline pl-4 pr-4"
                  >
                    <span className="text">Import From NetSuite</span>
                  </button>
                ) : (
                  <LoadingPlaceholder style={{ width: '238px', height: '50px' }} />
                )}
              </>
            )}
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.createCategories) && (
              <>
                {!readDataLoading ? (
                  <button className="btn btn-primary pl-2 pr-2" data-cy="create-category-btn" onClick={() => handleToggle({ name: 'create', type: 'basic' })}>
                    <span className="text">Create Category</span>
                  </button>
                ) : (
                  <LoadingPlaceholder style={{ width: '158.5px', height: '50px' }} />
                )}
              </>
            )}
          </div>
        </header>

        {/* Main Content */}
        <Main className="section__content relative min-h-table-content">{!readDataLoading ? <Sorting readData={readData} {...sortingActionsProps} /> : <Loading />}</Main>

        {/* Create - Parent category */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.createCategories) && <CreateParentCategory {...createParentCategoryProps} />}

        {/* Create - Subcategory */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.createCategories) && <CreateSubcategory {...createSubcatProps} />}

        {/* Update */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.updateCategories) && <UpdateCategory {...updateCategoryProps} />}

        {/* Delete */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.deleteCategories) && <DeleteCategory {...deleteCategoryProps} />}
        {/* Import NetSuite Modal */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.createCategories) && <ImportNetSuiteModal {...importNetSuiteModalProps} />}
      </Section>
    </>
  );
};

export default Categories;
