import React, { memo } from 'react';
import { Tabs } from 'antd';
import Section2En from '../tabs/en/Section2En';
import Section2Cg from '../tabs/cg/Section2Cg';
import CheckboxCms from '../../../../components/checkbox-cms';

const CmsForPartnersSection2 = ({ onChangeTabSection2, handleInputChangeFormSection2, formSection2, fpImages, fpImagesPreview, setFpImages, formSection2Errors, fpImagesErrors }) => {
  const { TabPane } = Tabs;

  const sectionProps = {
    handleInputChangeFormSection2,
    formSection2,
    fpImages,
    fpImagesPreview,
    setFpImages,
    onChangeTabSection2,
    formSection2Errors,
    fpImagesErrors,
  };
  return (
    <>
      <Tabs defaultActiveKey="1" onChange={onChangeTabSection2}>
        <TabPane key="1" tab="EN">
          <Section2En {...sectionProps} />
        </TabPane>
        <TabPane key="2" tab="CG">
          <Section2Cg {...sectionProps} />
        </TabPane>
      </Tabs>
      <CheckboxCms inputName="section2_hide" isChecked={formSection2.hide} handleChange={handleInputChangeFormSection2} label="Hide entire section on website" tooltipText="Checking this will hide entire section on website" />
    </>
  );
};
export default memo(CmsForPartnersSection2);
