import { TabsContent } from './TabsContent';
import { TabsHeader } from './TabsHeader';

export const TabsPanel = (props) => {
  const { tabsGallery, setTabsGallery, tabsContent } = props;

  return (
    <div className="tabs__panel">
      <TabsHeader tabsGallery={tabsGallery} setTabsGallery={setTabsGallery} tabsContent={tabsContent} />
      <TabsContent tabsGallery={tabsGallery} setTabsGallery={setTabsGallery} tabsContent={tabsContent} />
    </div>
  );
};
