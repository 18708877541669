import React from 'react';
import { Form, Row, Button } from 'antd';
import Modal from '../../../../components/modal/Modal';

export const DeleteSingleRole = ({ deleteSingleProps, deleteSingleFormRef, handleSubmit, deleteBtnLoader, setToggleSingleDelete }) => {
  return (
    <Modal {...deleteSingleProps} className="sidebar__modal--center" title="">
      <form data-cy="modal-delete" name="delete_single" ref={deleteSingleFormRef} onSubmit={(e) => handleSubmit(e)}>
        <h2 className="text-center mb-4">Are you sure you want to delete the selected item ?</h2>

        <Form.Item>
          <Row gutter={16} style={{ justifyContent: 'center', columnGap: '20px' }}>
            {!deleteBtnLoader ? (
              <Button data-cy="modal-delete-yes-btn" type="submit" htmlType="submit" className="btn btn-primary-link btn-block">
                <span>Yes</span>
              </Button>
            ) : (
              <Button type="button" className="btn btn-primary-link btn-block">
                <span>Deleting...</span>
              </Button>
            )}

            <Button data-cy="modal-delete-no-btn" type="primary" htmlType="button" className="btn btn-primary btn-block" onClick={() => setToggleSingleDelete(false)}>
              <span>No</span>
            </Button>
          </Row>
        </Form.Item>
      </form>
    </Modal>
  );
};
