import ImageUpload from '../../components/image-upload';
import TooltipDash from '../../../../../helpers/TooltipDash';

const Section1Cg = ({ handleInputChangeFormSection1, formSection1, fpImages, fpImagesErrors, fpImagesPreview, setFpImages, formSection1Errors }) => {
  return (
    <>
      <div className="cms-form-flex media" style={{ paddingBottom: '20px', marginBottom: '40px' }}>
        <div className="cms-form-flex-col">
          <div className="images-card-header">
            <span>Slika</span>
            <span className="ml-6">
              <TooltipDash text="Proporcije slike 1/1.82 (638x1165)px" acceptFormatText={true} />
            </span>
          </div>
          <div className="upload-wrapper">
            <ImageUpload fpImages={fpImages} fpImagesErrors={fpImagesErrors} fpImagesPreview={fpImagesPreview} setFpImages={setFpImages} toStoreImg="section1ImageCg" />
          </div>
        </div>
      </div>
      {/*Shared inputs*/}
      <div className="shared">
        {/*cg_sec1MainHeadingPart1*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="cg_sec1MainHeadingPart1">
            <b>Glavni naslov linija 1:</b>
          </label>
          <input id="cg_sec1MainHeadingPart1" type="text" placeholder="Glavni naslov linija 1" name="cg_sec1MainHeadingPart1" value={formSection1.cg.sec1MainHeadingPart1} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.cg.sec1MainHeadingPart1 ? 'input-error-important' : ''}`} />
        </div>
        {/*cg_sec1MainHeadingPart1*/}
        {/*cg_sec1MainHeadingPart2*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="cg_sec1MainHeadingPart2">
            <b>Glavni naslov linija 2:</b>
          </label>
          <input id="cg_sec1MainHeadingPart2" type="text" placeholder="Glavni naslov linija 2" name="cg_sec1MainHeadingPart2" value={formSection1.cg.sec1MainHeadingPart2} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.cg.sec1MainHeadingPart2 ? 'input-error-important' : ''}`} />
        </div>
        {/*cg_sec1MainHeadingPart2*/}
        {/*cg_sec1MainHeadingPart3*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="cg_sec1MainHeadingPart3">
            <b>Glavni naslov linija 3:</b>
          </label>
          <input id="cg_sec1MainHeadingPart3" type="text" placeholder="Glavni naslov linija 3" name="cg_sec1MainHeadingPart3" value={formSection1.cg.sec1MainHeadingPart3} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.cg.sec1MainHeadingPart3 ? 'input-error-important' : ''}`} />
        </div>
        {/*cg_sec1MainHeadingPart3*/}
      </div>
      {/*Shared inputs*/}
      <div className="cms-form-flex">
        {/*Advantage 1*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Prednost 1:</p>
          {/*cg_sec1Advantage1Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec1Advantage1Title">
              <b>Prednost 1 Naslov:</b>
            </label>
            <textarea id="cg_sec1Advantage1Title" type="text" placeholder="Prednost 1 Naslov" name="cg_sec1Advantage1Title" value={formSection1.cg.sec1Advantage1Title} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.cg.sec1Advantage1Title ? 'input-error-important' : ''}`} />
          </div>
          {/*cg_sec1Advantage1Title*/}
          {/*cg_sec1Advantage1Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec1Advantage1Text">
              <b>Prednost 1 Tekst:</b>
            </label>
            <textarea id="cg_sec1Advantage1Text" type="text" placeholder="Prednost 1 Tekst" name="cg_sec1Advantage1Text" value={formSection1.cg.sec1Advantage1Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.cg.sec1Advantage1Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*cg_sec1Advantage1Text*/}
        </div>
        {/*Advantage 1*/}

        {/*Advantage 2*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Prednost 2:</p>
          {/*cg_sec1Advantage2Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec1Advantage2Title">
              <b>Prednost 2 Naslov:</b>
            </label>
            <textarea id="cg_sec1Advantage2Title" type="text" placeholder="Prednost 2 Naslov" name="cg_sec1Advantage2Title" value={formSection1.cg.sec1Advantage2Title} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.cg.sec1Advantage2Title ? 'input-error-important' : ''}`} />
          </div>
          {/*cg_sec1Advantage2Title*/}
          {/*cg_sec1Advantage2Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec1Advantage2Text">
              <b>Prednost 2 Tekst:</b>
            </label>
            <textarea id="cg_sec1Advantage2Text" type="text" placeholder="Prednost 2 Tekst" name="cg_sec1Advantage2Text" value={formSection1.cg.sec1Advantage2Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.cg.sec1Advantage2Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*cg_sec1Advantage2Text*/}
        </div>
        {/*Advantage 2*/}

        {/*Advantage 3*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Prednost 3:</p>
          {/*cg_sec1Advantage3Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec1Advantage3Title">
              <b>Prednost 3 Naslov:</b>
            </label>
            <textarea id="cg_sec1Advantage3Title" type="text" placeholder="Prednost 3 Naslov" name="cg_sec1Advantage3Title" value={formSection1.cg.sec1Advantage3Title} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.cg.sec1Advantage3Title ? 'input-error-important' : ''}`} />
          </div>
          {/*cg_sec1Advantage3Title*/}
          {/*cg_sec1Advantage3Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec1Advantage3Text">
              <b>Prednost 3 Tekst:</b>
            </label>
            <textarea id="cg_sec1Advantage3Text" type="text" placeholder="Prednost 3 Tekst" name="cg_sec1Advantage3Text" value={formSection1.cg.sec1Advantage3Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.cg.sec1Advantage3Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*cg_sec1Advantage3Text*/}
        </div>
        {/*Advantage 3*/}
      </div>
    </>
  );
};
export default Section1Cg;
