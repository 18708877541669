import React from 'react';
import { Form, Row, Button } from 'antd';
import Modal from '../../../../components/modal/Modal';

export const DeleteMultiRole = ({ deleteMultiProps, deleteMultiFormRef, handleSubmit, setToggleMultiDelete, deleteBlkBtnLoader }) => {
  return (
    <Modal {...deleteMultiProps} className="sidebar__modal--center" title="">
      <form data-cy="modal-delete-bulk" name="delete_multi" ref={deleteMultiFormRef} onSubmit={(e) => handleSubmit(e)}>
        <h2 className="text-center mb-4">Are you sure you want to delete the selected items ?</h2>

        <Form.Item>
          <Row gutter={16} style={{ justifyContent: 'center', columnGap: '20px' }}>
            {!deleteBlkBtnLoader ? (
              <Button data-cy="modal-delete-bulk-yes-btn" type="submit" htmlType="submit" className="btn btn-primary-link btn-block">
                <span>Yes</span>
              </Button>
            ) : (
              <Button type="button" className="btn btn-primary-link btn-block">
                <span>Deleting...</span>
              </Button>
            )}

            <Button data-cy="modal-delete-bulk-no-btn" type="primary" htmlType="button" className="btn btn-primary btn-block" onClick={() => setToggleMultiDelete(false)}>
              <span>No</span>
            </Button>
          </Row>
        </Form.Item>
      </form>
    </Modal>
  );
};
