import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { SERVER_URL } from '../../config';
import { notification } from 'antd';
import { Section } from '../../components/content';
import { Link, useNavigate } from 'react-router-dom';
import { AXIOS_API_CALL } from '../../utils/endpoint';
import { PERMISSIONS } from '../../utils/permissions';

const EmployeeEmailVerification = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [tokenIsValid, setTokenIsValid] = useState(false);

  const emailVerify = useCallback(
    async (token) => {
      if (token && token.length > 0) {
        setTimeout(() => {
          let statusIsOk = false;

          axios
            .post(`${SERVER_URL}/${AXIOS_API_CALL.employeeEmailVerification}/${token}`)
            .then((res) => {
              if (res.status === 200) {
                statusIsOk = true;
                setTokenIsValid(true);

                // notification
                notification.success({
                  message: res.data.message,
                  placement: 'bottomLeft'
                });
              }
            })
            .catch((err) => {
              if (!statusIsOk) {
                if (err.response.status === 404) {
                  navigate(`/${PERMISSIONS.login}`, { replace: true });

                  // notification
                  notification.warn({
                    message: err.response.data.message,
                    placement: 'bottomLeft'
                  });
                }
              }
            })
            .finally(setTimeout(() => {}, 700));
        }, 700);
      }
    },
    [navigate, setTokenIsValid]
  );

  useEffect(() => {
    emailVerify(token);
  }, [emailVerify, token]);

  return (
    <Section className="section section__email-verification">
      {tokenIsValid && (
        <div className="card-wrapper form-wrapper">
          <div className="title">
            <img className="login-circle" src="/assets/icons/message.svg" alt="Message" />
            <h1 className="mb-0">Email address is successfully activated.</h1>
          </div>
          <Link to="/login" className="link link-primary text-center d-flex items-center gap-x-1">
            <img src="/assets/icons/arrow.svg" alt="Button logo" />
            <span>Back to Login</span>
          </Link>
        </div>
      )}
    </Section>
  );
};

export default EmployeeEmailVerification;
