import React from 'react';
import Modal from '../../../../components/modal/Modal';
import { Input, Button, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';

export const DeleteForm = ({ deleteProps, deleteModalFormRef, handleOnFinish, deleteFormFields, deleteBtnLoader, setToggleDelete }) => {
  const { t } = useTranslation();
  return (
    <Modal {...deleteProps} className='sidebar__modal--center' title=''>
      <Form name='delete' ref={deleteModalFormRef} wrapperCol={{ span: 24 }} initialValues={{ remember: false }} onFinish={(data) => handleOnFinish({ name: 'delete', data: data })} fields={deleteFormFields}>
        <h2 className='text-center mb-4'>{t('areYouSureToDeleteSelectedAdmin')}</h2>

        <Form.Item name='deleteUserId' style={{ display: 'none' }}>
          <Input type='hidden' />
        </Form.Item>

        <Form.Item>
          <Row gutter={16} style={{ justifyContent: 'center', columnGap: '20px' }} className='row-direction'>
            {!deleteBtnLoader ? (
              <Button type='submit' htmlType='submit' className='btn btn-primary-link btn-block'>
                <span>{t('yes')}</span>
              </Button>
            ) : (
              <Button type='button' className='btn btn-primary-link btn-block'>
                <span>{t('deleting')}...</span>
              </Button>
            )}

            <Button type='primary' htmlType='button' className='btn btn-primary btn-block' onClick={() => setToggleDelete(false)}>
              <span>{t('no')}</span>
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};
