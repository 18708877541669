import React, { memo } from 'react';
import ImageUpload from '../../components/image-upload';
import TooltipDash from '../../../../../helpers/TooltipDash';

const Section4En = ({ handleInputChangeFormSection4, formSection4, setHpImages, hpImages, hpImagesPreview, formSection4Errors, hpImagesErrors }) => {
  return (
    <>
      <div className="cms-form-flex media" style={{ paddingBottom: '20px', marginBottom: '40px' }}>
        <div className="cms-form-flex-col">
          <div className="images-card-header">
            <span>Intro image</span>
            <span className="ml-6">
              <TooltipDash text="Image aspect ratio 7.7/1 (570x74)px" acceptFormatText={true} />
            </span>
          </div>
          <div className="upload-wrapper">
            <ImageUpload hpImages={hpImages} hpImagesErrors={hpImagesErrors} hpImagesPreview={hpImagesPreview} setHpImages={setHpImages} toStoreImg="section4Image1En" />
          </div>
        </div>
      </div>
      {/*Shared inputs*/}
      <div className="shared">
        {/*en_sec4VideoUrl*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="en_sec4VideoUrl">
            <b>3 Steps video URL:</b>
          </label>
          <input id="en_sec4VideoUrl" type="text" placeholder="3 Steps video URL" name="en_sec4VideoUrl" value={formSection4.en.sec4VideoUrl} onChange={handleInputChangeFormSection4} className={`${formSection4Errors.en.sec4VideoUrl ? 'input-error-important' : ''}`} />
        </div>
        {/*en_sec4VideoUrl*/}
        {/*en_sec4StepsTitlePart1*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="en_sec4StepsTitlePart1">
            <b>3 Steps title part 1:</b>
          </label>
          <input id="en_sec4StepsTitlePart1" type="text" placeholder="3 Steps title part 1" name="en_sec4StepsTitlePart1" value={formSection4.en.sec4StepsTitlePart1} onChange={handleInputChangeFormSection4} className={`${formSection4Errors.en.sec4StepsTitlePart1 ? 'input-error-important' : ''}`} />
        </div>
        {/*en_sec4StepsTitlePart1*/}
        {/*en_sec4StepsTitlePart2*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="en_sec4StepsTitlePart2">
            <b>3 Steps title part 2:</b>
          </label>
          <input id="en_sec4StepsTitlePart2" type="text" placeholder="3 Steps title part 2" name="en_sec4StepsTitlePart2" value={formSection4.en.sec4StepsTitlePart2} onChange={handleInputChangeFormSection4} className={`${formSection4Errors.en.sec4StepsTitlePart2 ? 'input-error-important' : ''}`} />
        </div>
        {/*en_sec4StepsTitlePart2*/}
        {/*en_sec4StepsButtonText*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="en_sec4StepsButtonText">
            <b>3 Steps button text:</b>
          </label>
          <input id="en_sec4StepsButtonText" type="text" placeholder="3 Steps button text" name="en_sec4StepsButtonText" value={formSection4.en.sec4StepsButtonText} onChange={handleInputChangeFormSection4} className={`${formSection4Errors.en.sec4StepsButtonText ? 'input-error-important' : ''}`} />
        </div>
        {/*en_sec4StepsButtonText*/}
        {/*en_sec4StepsButtonUrl*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="en_sec4StepsButtonUrl">
            <b>3 Steps button URL:</b>
          </label>
          <input id="en_sec4StepsButtonUrl" type="text" placeholder="3 Steps button URL" name="en_sec4StepsButtonUrl" value={formSection4.en.sec4StepsButtonUrl} onChange={handleInputChangeFormSection4} className={`${formSection4Errors.en.sec4StepsButtonUrl ? 'input-error-important' : ''}`} />
        </div>
        {/*en_sec4StepsButtonUrl*/}
      </div>
      {/*Shared inputs*/}
      <section>
        <article>
          <div className="cms-form-flex">
            {/*Card 1:*/}
            <div className="cms-form-flex-col">
              <p className="card-top">Step 1:</p>
              {/*en_sec4Step1Title*/}
              <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
                <label className="label d-block" htmlFor="en_sec4Step1Title">
                  <b>Title:</b>
                </label>
                <textarea id="en_sec4Step1Title" type="text" placeholder="Title" name="en_sec4Step1Title" value={formSection4.en.sec4Step1Title} onChange={handleInputChangeFormSection4} className={`${formSection4Errors.en.sec4Step1Title ? 'input-error-important' : ''}`} />
              </div>
              {/*en_sec4Step1Title*/}
              {/*en_sec4Step1Text*/}
              <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
                <label className="label d-block" htmlFor="en_sec4Step1Text">
                  <b>Text:</b>
                </label>
                <textarea id="en_sec4Step1Text" type="text" placeholder="Text" name="en_sec4Step1Text" value={formSection4.en.sec4Step1Text} onChange={handleInputChangeFormSection4} className={`${formSection4Errors.en.sec4Step1Text ? 'input-error-important' : ''}`} />
              </div>
              {/*en_sec4Step1Text*/}
            </div>
            {/*Card 1:*/}
            {/*Card 2:*/}
            <div className="cms-form-flex-col">
              <p className="card-top">Step 2:</p>
              {/*en_sec4Step2Title*/}
              <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
                <label className="label d-block" htmlFor="en_sec4Step2Title">
                  <b>Title:</b>
                </label>
                <textarea id="en_sec4Step2Title" type="text" placeholder="Title" name="en_sec4Step2Title" value={formSection4.en.sec4Step2Title} onChange={handleInputChangeFormSection4} className={`${formSection4Errors.en.sec4Step2Title ? 'input-error-important' : ''}`} />
              </div>
              {/*en_sec4Step2Title*/}
              {/*en_sec4Step2Text*/}
              <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
                <label className="label d-block" htmlFor="en_sec4Step2Text">
                  <b>Text:</b>
                </label>
                <textarea id="en_sec4Step2Text" type="text" placeholder="Text" name="en_sec4Step2Text" value={formSection4.en.sec4Step2Text} onChange={handleInputChangeFormSection4} className={`${formSection4Errors.en.sec4Step2Text ? 'input-error-important' : ''}`} />
              </div>
              {/*en_sec4Step2Text*/}
            </div>
            {/*Card 2:*/}
            {/*Card 3:*/}
            <div className="cms-form-flex-col">
              <p className="card-top">Step 3:</p>
              {/*en_sec4Step2Title*/}
              <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
                <label className="label d-block" htmlFor="en_sec4Step3Title">
                  <b>Title:</b>
                </label>
                <textarea id="en_sec4Step3Title" type="text" placeholder="Title" name="en_sec4Step3Title" value={formSection4.en.sec4Step3Title} onChange={handleInputChangeFormSection4} className={`${formSection4Errors.en.sec4Step3Title ? 'input-error-important' : ''}`} />
              </div>
              {/*en_sec4Step3Title*/}
              {/*en_sec4Step3Text*/}
              <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
                <label className="label d-block" htmlFor="en_sec4Step3Text">
                  <b>Text:</b>
                </label>
                <textarea id="en_sec4Step3Text" type="text" placeholder="Text" name="en_sec4Step3Text" value={formSection4.en.sec4Step3Text} onChange={handleInputChangeFormSection4} className={`${formSection4Errors.en.sec4Step3Text ? 'input-error-important' : ''}`} />
              </div>
              {/*en_sec4Step3Text*/}
            </div>
            {/*Card 3:*/}
          </div>
        </article>
      </section>
    </>
  );
};
export default memo(Section4En);
