import React, { useEffect, useState } from 'react';
import { Popover } from 'antd';
import Checkbox from '../../../components/customCheckbox/CustomCheckbox';
import { PERMISSIONS } from '../../../utils/permissions';
import Message from '../../../components/message/Message';
import { contentMessage } from '../../../helpers/contentMessage';
import Pagination from '../../../components/pagination/Pagination';
import axios from 'axios';
import { useAuth } from '../../../context/useAuth';
import { SERVER_URL } from '../../../config/index';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { useTranslation } from 'react-i18next';

const Table = (props) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  // TABLE
  const { data, handleCheckedRows, handleToggle } = props;
  // PAGINATION
  const { pagination } = props;

  // CUSTOM FOR ACTIONS
  const { updateActions, location, groceryPermissions, getGroceryPermissions } = props;
  // CUSTOM FOR FILTERS

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  // UNTOGGLE CHECKED TOP
  useEffect(() => {
    if (isCheck.length < 1) {
      setIsCheckAll(false);
    }
  }, [isCheck, setIsCheckAll]);

  useEffect(() => {
    handleCheckedRows(isCheck);
  }, [isCheck, handleCheckedRows]);

  const [items, setItems] = useState(
    data.map((item) => ({
      ...item,
      isFamilyFriendly: item.isFamilyFriendly || false,
      isTeamBuilding: item.isTeamBuilding || false,
      isSpecialOffer: item.isSpecialOffer || false,
    }))
  );
  const handleFavoritesChange = async (e, id) => {
    const { token } = user;
    const { name, checked } = e.target;
    setItems(items.map((item) => (item._id === id ? { ...item, [name]: checked } : item)));
    try {
      await axios.put(
        `${SERVER_URL}/${AXIOS_API_CALL.updateFavoritesVenues}/${id}`,
        { [name]: checked },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        }
      );
    } catch (error) {}
  };

  return (
    <>
      <table className="table">
        <thead className="thead">
          <tr>
            <th>{t('venueName')}</th>
            <th>{t('address')}</th>
            <th className="center">
              {t('familyFriendly1')}
              <br />
              {t('familyFriendly2')}
            </th>
            <th className="center">
              {t('teamBuildingActivities1')}
              <br />
              {t('teamBuildingActivities2')}
              <br />
              {t('teamBuildingActivities3')}
            </th>
            <th className="center">
              {t('specialOffers1')}
              <br />
              {t('specialOffers2')}
            </th>
            <th>{t('action')}</th>
          </tr>
        </thead>
        <tbody className="tbody">
          {items && items.length > 0 ? (
            items?.map((item, idx) => {
              return (
                <tr key={item._id}>
                  <td>{item.name}</td>
                  <td>
                    {item?.address?.street} {item?.address?.number}, {item?.address?.city}
                  </td>
                  <td className="center">
                    <input style={{ display: 'inline' }} type="checkbox" name="isFamilyFriendly" onChange={(e) => handleFavoritesChange(e, item._id)} checked={item.isFamilyFriendly} />
                  </td>
                  <td className="center">
                    <input style={{ display: 'inline' }} type="checkbox" name="isTeamBuilding" onChange={(e) => handleFavoritesChange(e, item._id)} checked={item.isTeamBuilding} />
                  </td>
                  <td className="center">
                    <input style={{ display: 'inline' }} type="checkbox" name="isSpecialOffer" onChange={(e) => handleFavoritesChange(e, item._id)} checked={item.isSpecialOffer} />
                  </td>
                  <td className="y-top">
                    {Object.values(groceryPermissions).some((key) => [PERMISSIONS.updateVenues, PERMISSIONS.deleteEmployees].includes(key.name)) && (
                      <div className="actions" data-cy="actions-popover-employees">
                        {location && location.pathname.includes(`/${PERMISSIONS.dashboard}`) && (
                          <>
                            {Object.values(groceryPermissions).some((key) => [PERMISSIONS.updateVenues].includes(key.name)) && (
                              <div className="actions-group" style={{ border: 'none' }}>
                                {getGroceryPermissions(groceryPermissions, PERMISSIONS.updateVenues) && (
                                  <button className="btn btn-actions" type="button" data-cy="actions-edit-employee" onClick={() => handleToggle({ name: 'update', state: updateActions, data: item._id })} style={{ margin: '0' }}>
                                    <span className="icon">
                                      <img alt="Delete" src="/assets/icons/edit-user.svg" />
                                    </span>
                                    <span className="text">
                                      <strong>{t('edit')}</strong>
                                    </span>
                                  </button>
                                )}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={20}>
                <Message className="content-message">
                  <h2 style={{ color: '#666' }}>{contentMessage.dataNotFound}</h2>
                </Message>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {data && data.length > 0 && <Pagination {...pagination} />}
    </>
  );
};

export default Table;
