import ImageUpload from '../../components/image-upload';
import TooltipDash from '../../../../../helpers/TooltipDash';

const Section1En = ({ handleInputChangeFormSection1, formSection1, fcImages, fcImagesErrors, fcImagesPreview, setFcImages, formSection1Errors }) => {
  return (
    <>
      {/*Shared inputs*/}
      <div className="shared">
        {/*en_sec1MainHeadingPart1*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="en_sec1MainHeadingPart1">
            <b>Main heading part 1:</b>
          </label>
          <input id="en_sec1MainHeadingPart1" type="text" placeholder="Main heading part 1" name="en_sec1MainHeadingPart1" value={formSection1.en.sec1MainHeadingPart1} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.en.sec1MainHeadingPart1 ? 'input-error-important' : ''}`} />
        </div>
        {/*en_sec1MainHeadingPart1*/}
        {/*en_sec1MainHeadingPart2*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="en_sec1MainHeadingPart2">
            <b>Main heading part 2:</b>
          </label>
          <input id="en_sec1MainHeadingPart2" type="text" placeholder="Main heading part 2" name="en_sec1MainHeadingPart2" value={formSection1.en.sec1MainHeadingPart2} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.en.sec1MainHeadingPart2 ? 'input-error-important' : ''}`} />
        </div>
        {/*en_sec1MainHeadingPart2*/}
      </div>
      {/*Shared inputs*/}
      <div className="cms-form-flex">
        {/*Benefit 1:*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Benefit 1:</p>
          <div className="cms-form-head-inner">
            <div className="images-card-header">
              <span>Image</span>
              <span className="ml-6">
                <TooltipDash text="Image aspect ratio 2.14/1 (383x169)px" acceptFormatText={true} />
              </span>
            </div>
            <div className="upload-wrapper">
              <ImageUpload fcImages={fcImages} fcImagesErrors={fcImagesErrors} fcImagesPreview={fcImagesPreview} setFcImages={setFcImages} toStoreImg="section1Image1En" />
            </div>
          </div>
          {/*en_sec1Benefit1Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="en_sec1Benefit1Text">
              <b>Benefit 1 Text:</b>
            </label>
            <textarea id="en_sec1Benefit1Text" type="text" placeholder="Benefit 1 Text" name="en_sec1Benefit1Text" value={formSection1.en.sec1Benefit1Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.en.sec1Benefit1Text ? 'input-error-important' : ''}`} />
          </div>
          {/*en_sec1Benefit1Text*/}
          {/*en_sec1Benefit1Resource*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="en_sec1Benefit1Resource">
              <b>Benefit 1 Resource:</b>
            </label>
            <textarea id="en_sec1Benefit1Resource" type="text" placeholder="Benefit 1 Resource:" name="en_sec1Benefit1Resource" value={formSection1.en.sec1Benefit1Resource} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.en.sec1Benefit1Resource ? 'input-error-important' : ''}`} />
          </div>
          {/*en_sec1Benefit1Resource*/}
        </div>
        {/*Benefit 1:*/}
        {/*Benefit 2:*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Benefit 2:</p>
          <div className="cms-form-head-inner">
            <div className="images-card-header">
              <span>Image</span>
              <span className="ml-6">
                <TooltipDash text="Image aspect ratio 2.45/1 (425x167)px" acceptFormatText={true} />
              </span>
            </div>
            <div className="upload-wrapper">
              <ImageUpload fcImages={fcImages} fcImagesErrors={fcImagesErrors} fcImagesPreview={fcImagesPreview} setFcImages={setFcImages} toStoreImg="section1Image2En" />
            </div>
          </div>
          {/*en_sec1Benefit2Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="en_sec1Benefit2Text">
              <b>Benefit 2 Text:</b>
            </label>
            <textarea id="en_sec1Benefit2Text" type="text" placeholder="Benefit 2 Text" name="en_sec1Benefit2Text" value={formSection1.en.sec1Benefit2Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.en.sec1Benefit2Text ? 'input-error-important' : ''}`} />
          </div>
          {/*en_sec1Benefit2Text*/}
          {/*en_sec1Benefit2Resource*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="en_sec1Benefit2Resource">
              <b>Benefit 2 Resource:</b>
            </label>
            <textarea id="en_sec1Benefit1Resource" type="text" placeholder="Benefit 2 Resource:" name="en_sec1Benefit2Resource" value={formSection1.en.sec1Benefit2Resource} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.en.sec1Benefit2Resource ? 'input-error-important' : ''}`} />
          </div>
          {/*en_sec1Benefit2Resource*/}
        </div>
        {/*Benefit 2:*/}
        {/*Benefit 3:*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Benefit 3:</p>
          <div className="cms-form-head-inner">
            <div className="images-card-header">
              <span>Image</span>
              <span className="ml-6">
                <TooltipDash text="Image aspect ratio 2.7/1 (425x157)px" acceptFormatText={true} />
              </span>
            </div>
            <div className="upload-wrapper">
              <ImageUpload fcImages={fcImages} fcImagesErrors={fcImagesErrors} fcImagesPreview={fcImagesPreview} setFcImages={setFcImages} toStoreImg="section1Image3En" />
            </div>
          </div>
          {/*en_sec1Benefit3Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="en_sec1Benefit3Text">
              <b>Benefit 3 Text:</b>
            </label>
            <textarea id="en_sec1Benefit3Text" type="text" placeholder="Benefit 3 Text" name="en_sec1Benefit3Text" value={formSection1.en.sec1Benefit3Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.en.sec1Benefit3Text ? 'input-error-important' : ''}`} />
          </div>
          {/*en_sec1Benefit3Text*/}
          {/*en_sec1Benefit3Resource*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="en_sec1Benefit3Resource">
              <b>Benefit 3 Resource:</b>
            </label>
            <textarea id="en_sec1Benefit3Resource" type="text" placeholder="Benefit 3 Resource:" name="en_sec1Benefit3Resource" value={formSection1.en.sec1Benefit3Resource} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.en.sec1Benefit3Resource ? 'input-error-important' : ''}`} />
          </div>
          {/*en_sec1Benefit3Resource*/}
        </div>
        {/*Benefit 3:*/}
      </div>
    </>
  );
};
export default Section1En;
