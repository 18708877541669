import React, { memo } from 'react';
import ImageUpload from '../../components/image-upload';
import TooltipDash from '../../../../../helpers/TooltipDash';

const Section4Cg = ({ handleInputChangeFormSection4, formSection4, setHpImages, hpImages, hpImagesPreview, formSection4Errors, hpImagesErrors }) => {
  return (
    <>
      {/*Shared inputs*/}
      <div className="shared">
        <div className="cms-form-flex media" style={{ paddingBottom: '20px', marginBottom: '40px' }}>
          <div className="cms-form-flex-col">
            <div className="images-card-header">
              <span>Uvodna slika</span>
              <span className="ml-6">
                <TooltipDash text="Proporcije slike 7.7/1 (570x74)px" acceptFormatText={true} />
              </span>
            </div>
            <div className="upload-wrapper">
              <ImageUpload hpImages={hpImages} hpImagesErrors={hpImagesErrors} hpImagesPreview={hpImagesPreview} setHpImages={setHpImages} toStoreImg="section4Image1Cg" />
            </div>
          </div>
        </div>
        {/*Shared inputs*/}
        <div className="shared">
          {/*cg_sec4VideoUrl*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec4VideoUrl">
              <b>3 Koraka video URL:</b>
            </label>
            <input id="cg_sec4VideoUrl" type="text" placeholder="3 Koraka video URL" name="cg_sec4VideoUrl" value={formSection4.cg.sec4VideoUrl} onChange={handleInputChangeFormSection4} className={`${formSection4Errors.cg.sec4VideoUrl ? 'input-error-important' : ''}`} />
          </div>
          {/*cg_sec4VideoUrl*/}
          {/*cg_sec4StepsTitlePart1*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec4StepsTitlePart1">
              <b>3 Koraka naslov deo 1:</b>
            </label>
            <input id="cg_sec4StepsTitlePart1" type="text" placeholder="3 Koraka naslov deo 1" name="cg_sec4StepsTitlePart1" value={formSection4.cg.sec4StepsTitlePart1} onChange={handleInputChangeFormSection4} className={`${formSection4Errors.cg.sec4StepsTitlePart1 ? 'input-error-important' : ''}`} />
          </div>
          {/*cg_sec4StepsTitlePart1*/}
          {/*cg_sec4StepsTitlePart2*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec4StepsTitlePart2">
              <b>3 Koraka naslov deo 2:</b>
            </label>
            <input id="cg_sec4StepsTitlePart2" type="text" placeholder="3 Koraka naslov deo 2" name="cg_sec4StepsTitlePart2" value={formSection4.cg.sec4StepsTitlePart2} onChange={handleInputChangeFormSection4} className={`${formSection4Errors.cg.sec4StepsTitlePart2 ? 'input-error-important' : ''}`} />
          </div>
          {/*cg_sec4StepsTitlePart2*/}
          {/*cg_sec4StepsButtonText*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec4StepsButtonText">
              <b>3 Koraka tekst dugmeta:</b>
            </label>
            <input id="cg_sec4StepsButtonText" type="text" placeholder="3 Koraka tekst dugmeta" name="cg_sec4StepsButtonText" value={formSection4.cg.sec4StepsButtonText} onChange={handleInputChangeFormSection4} className={`${formSection4Errors.cg.sec4StepsButtonText ? 'input-error-important' : ''}`} />
          </div>
          {/*cg_sec4StepsButtonText*/}
          {/*cg_sec4StepsButtonUrl*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec4StepsButtonUrl">
              <b>3 Koraka URL dugmeta:</b>
            </label>
            <input id="cg_sec4StepsButtonUrl" type="text" placeholder="3 Koraka URL dugmeta" name="cg_sec4StepsButtonUrl" value={formSection4.cg.sec4StepsButtonUrl} onChange={handleInputChangeFormSection4} className={`${formSection4Errors.cg.sec4StepsButtonUrl ? 'input-error-important' : ''}`} />
          </div>
          {/*cg_sec4StepsButtonUrl*/}
        </div>
      </div>
      {/*Shared inputs*/}
      <section>
        <article>
          <div className="cms-form-flex">
            {/*Card 1:*/}
            <div className="cms-form-flex-col">
              <p className="card-top">Korak 1:</p>
              {/*cg_sec4Step1Title*/}
              <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
                <label className="label d-block" htmlFor="cg_sec4Step1Title">
                  <b>Naslov:</b>
                </label>
                <textarea id="cg_sec4Step1Title" type="text" placeholder="Naslov" name="cg_sec4Step1Title" value={formSection4.cg.sec4Step1Title} onChange={handleInputChangeFormSection4} className={`${formSection4Errors.cg.sec4Step1Title ? 'input-error-important' : ''}`} />
              </div>
              {/*cg_sec4Step1Title*/}
              {/*cg_sec4Step1Text*/}
              <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
                <label className="label d-block" htmlFor="cg_sec4Step1Text">
                  <b>Tekst:</b>
                </label>
                <textarea id="cg_sec4Step1Text" type="text" placeholder="Tekst" name="cg_sec4Step1Text" value={formSection4.cg.sec4Step1Text} onChange={handleInputChangeFormSection4} className={`${formSection4Errors.cg.sec4Step1Text ? 'input-error-important' : ''}`} />
              </div>
              {/*cg_sec4Step1Text*/}
            </div>
            {/*Card 1:*/}
            {/*Card 2:*/}
            <div className="cms-form-flex-col">
              <p className="card-top">Korak 2:</p>
              {/*cg_sec4Step2Title*/}
              <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
                <label className="label d-block" htmlFor="cg_sec4Step2Title">
                  <b>Naslov:</b>
                </label>
                <textarea id="cg_sec4Step2Title" type="text" placeholder="Naslov" name="cg_sec4Step2Title" value={formSection4.cg.sec4Step2Title} onChange={handleInputChangeFormSection4} className={`${formSection4Errors.cg.sec4Step2Title ? 'input-error-important' : ''}`} />
              </div>
              {/*cg_sec4Step2Title*/}
              {/*cg_sec4Step2Text*/}
              <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
                <label className="label d-block" htmlFor="cg_sec4Step2Text">
                  <b>Tekst:</b>
                </label>
                <textarea id="cg_sec4Step2Text" type="text" placeholder="Tekst" name="cg_sec4Step2Text" value={formSection4.cg.sec4Step2Text} onChange={handleInputChangeFormSection4} className={`${formSection4Errors.cg.sec4Step2Text ? 'input-error-important' : ''}`} />
              </div>
              {/*cg_sec4Step2Text*/}
            </div>
            {/*Card 2:*/}
            {/*Card 3:*/}
            <div className="cms-form-flex-col">
              <p className="card-top">Korak 3:</p>
              {/*cg_sec4Step2Title*/}
              <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
                <label className="label d-block" htmlFor="cg_sec4Step3Title">
                  <b>Naslov:</b>
                </label>
                <textarea id="cg_sec4Step3Title" type="text" placeholder="Naslov" name="cg_sec4Step3Title" value={formSection4.cg.sec4Step3Title} onChange={handleInputChangeFormSection4} className={`${formSection4Errors.cg.sec4Step3Title ? 'input-error-important' : ''}`} />
              </div>
              {/*cg_sec4Step3Title*/}
              {/*cg_sec4Step3Text*/}
              <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
                <label className="label d-block" htmlFor="cg_sec4Step3Text">
                  <b>Tekst:</b>
                </label>
                <textarea id="cg_sec4Step3Text" type="text" placeholder="Tekst" name="cg_sec4Step3Text" value={formSection4.cg.sec4Step3Text} onChange={handleInputChangeFormSection4} className={`${formSection4Errors.cg.sec4Step3Text ? 'input-error-important' : ''}`} />
              </div>
              {/*cg_sec4Step3Text*/}
            </div>
            {/*Card 3:*/}
          </div>
        </article>
      </section>
    </>
  );
};
export default memo(Section4Cg);
