import React, { memo } from 'react';
import { Tabs } from 'antd';
import Section2En from '../tabs/en/Section2En';
import Section2Cg from '../tabs/cg/Section2Cg';
import CheckboxCms from '../../../../components/checkbox-cms';
import { useTranslation } from 'react-i18next';

const CmsHomepageSection2 = ({ onChangeTabSection2, formSection2, handleInputChangeFormSection2, setHpImages, hpImages, hpImagesPreview, formSection2Errors, hpImagesErrors }) => {
  const { t } = useTranslation();
  const { TabPane } = Tabs;
  const sectionProps = {
    formSection2,
    handleInputChangeFormSection2,
    setHpImages,
    hpImages,
    hpImagesPreview,
    formSection2Errors,
    hpImagesErrors,
  };
  return (
    <>
      <Tabs defaultActiveKey='1' onChange={onChangeTabSection2}>
        <TabPane key='1' tab='EN'>
          <Section2En {...sectionProps} />
        </TabPane>
        <TabPane key='2' tab='CG'>
          <Section2Cg {...sectionProps} />
        </TabPane>
      </Tabs>
      <CheckboxCms inputName='section2_hide' isChecked={formSection2.hide} handleChange={handleInputChangeFormSection2} label={t('hideEntireSectionOnWebsite')} tooltipText={t('checkingWillHide')} />
    </>
  );
};
export default memo(CmsHomepageSection2);
