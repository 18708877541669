import ImageUpload from '../../components/image-upload';
import TooltipDash from '../../../../../helpers/TooltipDash';

const SectionHeaderCg = ({ handleInputChangeFormSectionHeader, formSectionHeader, clImages, clImagesErrors, clImagesPreview, setClImages, formSectionHeaderErrors }) => {
  return (
    <>
      <div className="cms-form-flex media" style={{ paddingBottom: '20px', marginBottom: '40px' }}>
        <div className="cms-form-flex-col">
          <div className="images-card-header">
            <span>Pozadinska slika</span>
            <span className="ml-6">
              <TooltipDash acceptFormatText={true} />
            </span>
          </div>
          <div className="upload-wrapper">
            <ImageUpload clImages={clImages} clImagesErrors={clImagesErrors} clImagesPreview={clImagesPreview} setClImages={setClImages} toStoreImg="sectionHeaderImageCg" />
          </div>
        </div>
      </div>
      {/*Shared inputs*/}
      <div className="shared">
        {/*cg_headMainHeadingPart1*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="cg_headMainHeadingPart1">
            <b>Glavni naslov linija 1:</b>
          </label>
          <input id="cg_headMainHeadingPart1" type="text" placeholder="Glavni naslov linija 1" name="cg_headMainHeadingPart1" value={formSectionHeader.cg.headMainHeadingPart1} onChange={handleInputChangeFormSectionHeader} className={`${formSectionHeaderErrors.cg.headMainHeadingPart1 ? 'input-error-important' : ''}`} />
        </div>
        {/*cg_headMainHeadingPart1*/}
        {/*cg_headMainHeadingPart2*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="cg_headMainHeadingPart2">
            <b>Glavni naslov linija 2:</b>
          </label>
          <input id="cg_headMainHeadingPart2" type="text" placeholder="Glavni naslov linija 2" name="cg_headMainHeadingPart2" value={formSectionHeader.cg.headMainHeadingPart2} onChange={handleInputChangeFormSectionHeader} className={`${formSectionHeaderErrors.cg.headMainHeadingPart2 ? 'input-error-important' : ''}`} />
        </div>
        {/*cg_headMainHeadingPart2*/}
        {/*cg_headText*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="cg_headText">
            <b>Tekst:</b>
          </label>
          <input id="cg_headText" type="text" placeholder="Tekst" name="cg_headText" value={formSectionHeader.cg.headText} onChange={handleInputChangeFormSectionHeader} className={`${formSectionHeaderErrors.cg.headText ? 'input-error-important' : ''}`} />
        </div>
        {/*cg_headText*/}
      </div>
    </>
  );
};
export default SectionHeaderCg;
