import ImageUpload from '../../components/image-upload';
import TooltipDash from '../../../../../helpers/TooltipDash';

const Section1Cg = ({ handleInputChangeFormSection1, formSection1, formSection1Errors, fcImages, fcImagesErrors, fcImagesPreview, setFcImages }) => {
  return (
    <>
      {/*Shared inputs*/}
      <div className="shared">
        {/*cg_sec1MainHeadingPart1*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="cg_sec1MainHeadingPart1">
            <b>Glavni naslov deo 1:</b>
          </label>
          <input id="cg_sec1MainHeadingPart1" type="text" placeholder="Glavni naslov deo 1" name="cg_sec1MainHeadingPart1" value={formSection1.cg.sec1MainHeadingPart1} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.cg.sec1MainHeadingPart1 ? 'input-error-important' : ''}`} />
        </div>
        {/*cg_sec1MainHeadingPart1*/}
        {/*cg_sec1MainHeadingPart2*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="cg_sec1MainHeadingPart2">
            <b>Glavni naslov deo 2:</b>
          </label>
          <input id="cg_sec1MainHeadingPart2" type="text" placeholder="Glavni naslov deo 2" name="cg_sec1MainHeadingPart2" value={formSection1.cg.sec1MainHeadingPart2} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.cg.sec1MainHeadingPart2 ? 'input-error-important' : ''}`} />
        </div>
        {/*cg_sec1MainHeadingPart2*/}
      </div>
      {/*Shared inputs*/}
      <div className="cms-form-flex">
        {/*Benefit 1:*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Benefit 1:</p>
          <div className="cms-form-head-inner">
            <div className="images-card-header">
              <span>Slika</span>
              <span className="ml-6">
                <TooltipDash text="Proporcije slike 1.82/1 (284x156)px" acceptFormatText={true} />
              </span>
            </div>
            <div className="upload-wrapper">
              <ImageUpload fcImages={fcImages} fcImagesErrors={fcImagesErrors} fcImagesPreview={fcImagesPreview} setFcImages={setFcImages} toStoreImg="section1Image1Cg" />
            </div>
          </div>
          {/*cg_sec1Benefit1Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec1Benefit1Text">
              <b>Benefit 1 Tekst:</b>
            </label>
            <textarea id="cg_sec1Benefit1Text" type="text" placeholder="Benefit 1 Tekst" name="cg_sec1Benefit1Text" value={formSection1.cg.sec1Benefit1Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.cg.sec1Benefit1Text ? 'input-error-important' : ''}`} />
          </div>
          {/*cg_sec1Benefit1Text*/}
          {/*cg_sec1Benefit1Resource*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec1Benefit1Resource">
              <b>Benefit 1 Resurs:</b>
            </label>
            <textarea id="cg_sec1Benefit1Resource" type="text" placeholder="Benefit 1 Resurs:" name="cg_sec1Benefit1Resource" value={formSection1.cg.sec1Benefit1Resource} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.cg.sec1Benefit1Resource ? 'input-error-important' : ''}`} />
          </div>
          {/*cg_sec1Benefit1Resource*/}
        </div>
        {/*Benefit 1:*/}
        {/*Benefit 2:*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Benefit 2:</p>
          <div className="cms-form-head-inner">
            <div className="images-card-header">
              <span>Slika</span>
              <span className="ml-6">
                <TooltipDash text="Proporcije slike 1.64/1 (350x213)px" acceptFormatText={true} />
              </span>
            </div>
            <div className="upload-wrapper">
              <ImageUpload fcImages={fcImages} fcImagesErrors={fcImagesErrors} fcImagesPreview={fcImagesPreview} setFcImages={setFcImages} toStoreImg="section1Image2Cg" />
            </div>
          </div>
          {/*cg_sec1Benefit2Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec1Benefit2Text">
              <b>Benefit 2 Tekst:</b>
            </label>
            <textarea id="cg_sec1Benefit2Text" type="text" placeholder="Benefit 2 Tekst" name="cg_sec1Benefit2Text" value={formSection1.cg.sec1Benefit2Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.cg.sec1Benefit2Text ? 'input-error-important' : ''}`} />
          </div>
          {/*cg_sec1Benefit2Text*/}
          {/*cg_sec1Benefit2Resource*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec1Benefit2Resource">
              <b>Benefit 2 Resurs:</b>
            </label>
            <textarea id="cg_sec1Benefit1Resource" type="text" placeholder="Benefit 2 Resurs" name="cg_sec1Benefit2Resource" value={formSection1.cg.sec1Benefit2Resource} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.cg.sec1Benefit1Resource ? 'input-error-important' : ''}`} />
          </div>
          {/*cg_sec1Benefit2Resource*/}
        </div>
        {/*Benefit 2:*/}
        {/*Benefit 3:*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Benefit 3:</p>
          <div className="cms-form-head-inner">
            <div className="images-card-header">
              <span>Slika</span>
              <span className="ml-6">
                <TooltipDash text="Proporcije slike 2.94/1 (460x156)px" acceptFormatText={true} />
              </span>
            </div>
            <div className="upload-wrapper">
              <ImageUpload fcImages={fcImages} fcImagesErrors={fcImagesErrors} fcImagesPreview={fcImagesPreview} setFcImages={setFcImages} toStoreImg="section1Image3Cg" />
            </div>
          </div>
          {/*cg_sec1Benefit3Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec1Benefit3Text">
              <b>Benefit 3 Tekst:</b>
            </label>
            <textarea id="cg_sec1Benefit3Text" type="text" placeholder="Benefit 3 Tekst" name="cg_sec1Benefit3Text" value={formSection1.cg.sec1Benefit3Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.cg.sec1Benefit3Text ? 'input-error-important' : ''}`} />
          </div>
          {/*cg_sec1Benefit3Text*/}
          {/*cg_sec1Benefit3Resource*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec1Benefit3Resource">
              <b>Benefit 3 Resurs:</b>
            </label>
            <textarea id="cg_sec1Benefit3Resource" type="text" placeholder="Benefit 3 Resurs" name="cg_sec1Benefit3Resource" value={formSection1.cg.sec1Benefit3Resource} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.cg.sec1Benefit3Resource ? 'input-error-important' : ''}`} />
          </div>
          {/*cg_sec1Benefit3Resource*/}
        </div>
        {/*Benefit 3:*/}
      </div>
    </>
  );
};
export default Section1Cg;
