import React, { useState, useCallback, useEffect } from 'react';
import { Main } from '../../../components/content';
import { Collapse } from 'antd';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { useAuth } from '../../../context/useAuth';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { PERMISSIONS } from '../../../utils/permissions';
import { SERVER_URL } from '../../../config/index';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import CheckboxCms from '../../../components/checkbox-cms';

import CmsForPartnersSection1 from './sections/CmsForPartnersSection1';
import CmsForPartnersSection2 from './sections/CmsForPartnersSection2';
import CmsForPartnersSection3 from './sections/CmsForPartnersSection3';
import CmsForPartnersSectionHeader from './sections/CmsForPartnersSectionHeader';

const CmsForPartners = () => {
  const { user } = useAuth();
  const [groceryPermissions, setGroceryPermissions] = useState([]);
  const [initialData, setInitialData] = useState(null);
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;

    if (user && token) {
      const decodeToken = jwtDecode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
          setGroceryPermissions(permissions.grocery);
        }
      }
    }
  }, [user, location]);

  // IMAGES HANDLE
  const [fpImages, setFpImages] = React.useState({
    sectionHeaderImageEn: '',
    sectionHeaderImageCg: '',

    section1ImageEn: '',
    section2ImageEn: '',

    section1ImageCg: '',
    section2ImageCg: '',
  });
  const [fpImagesPreview, setFpImagesPreview] = React.useState({
    sectionHeaderImageEn: '',
    sectionHeaderImageCg: '',

    section1ImageEn: '',
    section2ImageEn: '',

    section1ImageCg: '',
    section2ImageCg: '',
  });
  const [fpImagesErrors, setFpImagesErrors] = React.useState({
    sectionHeaderImageEn: false,
    sectionHeaderImageCg: false,

    section1ImageEn: false,
    section2ImageEn: false,

    section1ImageCg: false,
    section2ImageCg: false,
  });
  // IMAGES HANDLE

  // HEADER HANDLE
  const [formSectionHeader, setFormSectionHeader] = React.useState({
    en: {
      headMainHeadingPart1: '',
      headMainHeadingPart2: '',
    },
    cg: {
      headMainHeadingPart1: '',
      headMainHeadingPart2: '',
    },
  });
  const [formSectionHeaderErrors, setFormSectionHeaderErrors] = React.useState({
    en: {
      headMainHeadingPart1: false,
      headMainHeadingPart2: false,
    },
    cg: {
      headMainHeadingPart1: false,
      headMainHeadingPart2: false,
    },
  });
  const handleInputChangeFormSectionHeader = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    setFormSectionHeader((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value,
      },
    }));
  }, []);
  const onChangeTabSectionHeader = useCallback(() => {}, []);
  // HEADER HANDLE

  // GENERAL SETTINGS HANDLE
  const [formGeneralSettings, setFormGeneralSettings] = React.useState({
    hide: false,
  });

  // SECTION 1 HANDLE
  const [formSection1, setFormSection1] = React.useState({
    hide: false,
    en: {
      sec1MainHeadingPart1: '',
      sec1MainHeadingPart2: '',
      sec1MainHeadingPart3: '',
      sec1Advantage1Title: '',
      sec1Advantage1Text: '',
      sec1Advantage2Title: '',
      sec1Advantage2Text: '',
      sec1Advantage3Title: '',
      sec1Advantage3Text: '',
    },
    cg: {
      sec1MainHeadingPart1: '',
      sec1MainHeadingPart2: '',
      sec1MainHeadingPart3: '',
      sec1Advantage1Title: '',
      sec1Advantage1Text: '',
      sec1Advantage2Title: '',
      sec1Advantage2Text: '',
      sec1Advantage3Title: '',
      sec1Advantage3Text: '',
    },
  });
  const [formSection1Errors, setFormSection1Errors] = React.useState({
    en: {
      sec1MainHeadingPart1: false,
      sec1MainHeadingPart2: false,
      sec1MainHeadingPart3: false,
      sec1Advantage1Title: false,
      sec1Advantage1Text: false,
      sec1Advantage2Title: false,
      sec1Advantage2Text: false,
      sec1Advantage3Title: false,
      sec1Advantage3Text: false,
    },
    cg: {
      sec1MainHeadingPart1: false,
      sec1MainHeadingPart2: false,
      sec1MainHeadingPart3: false,
      sec1Advantage1Title: false,
      sec1Advantage1Text: false,
      sec1Advantage2Title: false,
      sec1Advantage2Text: false,
      sec1Advantage3Title: false,
      sec1Advantage3Text: false,
    },
  });
  const handleInputChangeFormSection1 = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    if (name === 'section1_hide') {
      setFormSection1((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
      return;
    }

    setFormSection1((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value,
      },
    }));
  }, []);
  const onChangeTabSection1 = useCallback(() => {}, []);
  // SECTION 1 HANDLE

  // SECTION 2 HANDLE
  const [formSection2, setFormSection2] = React.useState({
    hide: false,
    en: {
      sec2MainHeadingPart1: '',
      sec2MainHeadingPart2: '',
      sec2MainHeadingPart3: '',
      sec2Step1Title: '',
      sec2Step1Text: '',
      sec2Step2Title: '',
      sec2Step2Text: '',
      sec2Step3Title: '',
      sec2Step3Text: '',
      sec2ButtonText: '',
      sec2LogoText: '',
    },
    cg: {
      sec2MainHeadingPart1: '',
      sec2MainHeadingPart2: '',
      sec2MainHeadingPart3: '',
      sec2Step1Title: '',
      sec2Step1Text: '',
      sec2Step2Title: '',
      sec2Step2Text: '',
      sec2Step3Title: '',
      sec2Step3Text: '',
      sec2ButtonText: '',
      sec2LogoText: '',
    },
  });
  const [formSection2Errors, setFormSection2Errors] = React.useState({
    en: {
      sec2MainHeadingPart1: false,
      sec2MainHeadingPart2: false,
      sec2MainHeadingPart3: false,
      sec2Step1Title: false,
      sec2Step1Text: false,
      sec2Step2Title: false,
      sec2Step2Text: false,
      sec2Step3Title: false,
      sec2Step3Text: false,
      sec2ButtonText: false,
      sec2LogoText: false,
    },
    cg: {
      sec2MainHeadingPart1: false,
      sec2MainHeadingPart2: false,
      sec2MainHeadingPart3: false,
      sec2Step1Title: false,
      sec2Step1Text: false,
      sec2Step2Title: false,
      sec2Step2Text: false,
      sec2Step3Title: false,
      sec2Step3Text: false,
      sec2ButtonText: false,
      sec2LogoText: false,
    },
  });
  const handleInputChangeFormSection2 = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    if (name === 'section2_hide') {
      setFormSection2((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
      return;
    }

    setFormSection2((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value,
      },
    }));
  }, []);
  const onChangeTabSection2 = useCallback(() => {}, []);
  // SECTION 2 HANDLE

  // SECTION WANT MORE INFO HANDLE
  const [formSection3, setFormSection3] = React.useState({
    hide: false,
    en: {
      sec3MainHeading: '',
      sec3Info1Title: '',
      sec3Info1Text: '',
      sec3Info2Title: '',
      sec3Info2Text: '',
      sec3Info3Title: '',
      sec3Info3Text: '',
      sec3Info4Title: '',
      sec3Info4Text: '',
    },
    cg: {
      sec3MainHeading: '',
      sec3Info1Title: '',
      sec3Info1Text: '',
      sec3Info2Title: '',
      sec3Info2Text: '',
      sec3Info3Title: '',
      sec3Info3Text: '',
      sec3Info4Title: '',
      sec3Info4Text: '',
    },
  });
  const [formSection3Errors, setFormSection3Errors] = React.useState({
    en: {
      sec3MainHeading: false,
      sec3Info1Title: false,
      sec3Info1Text: false,
      sec3Info2Title: false,
      sec3Info2Text: false,
      sec3Info3Title: false,
      sec3Info3Text: false,
      sec3Info4Title: false,
      sec3Info4Text: false,
    },
    cg: {
      sec3MainHeading: false,
      sec3Info1Title: false,
      sec3Info1Text: false,
      sec3Info2Title: false,
      sec3Info2Text: false,
      sec3Info3Title: false,
      sec3Info3Text: false,
      sec3Info4Title: false,
      sec3Info4Text: false,
    },
  });
  const handleInputChangeFormSection3 = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    if (name === 'section3_hide') {
      setFormSection3((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
      return;
    }

    setFormSection3((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value,
      },
    }));
  }, []);
  const onChangeTabSection3 = useCallback(() => {}, []);
  // SECTION WANT MORE INFO HANDLE

  // HANDLE FORM SUBMIT
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const { token } = user;

    // Initialize error flag
    let hasError = false;

    // Create a copy of the error state
    let newFormSection1Errors = { ...formSection1Errors };
    let newFormSection2Errors = { ...formSection2Errors };
    let newFormSection3Errors = { ...formSection3Errors };
    let newFormSectionHeaderErrors = { ...formSectionHeaderErrors };

    // Validate Section 1 (EN)
    Object.keys(formSection1.en).forEach((key) => {
      if (!formSection1.en[key]) {
        newFormSection1Errors.en[key] = true;
        hasError = true;
      } else {
        newFormSection1Errors.en[key] = false;
      }
    });

    // Validate Section 1 (CG)
    Object.keys(formSection1.cg).forEach((key) => {
      if (!formSection1.cg[key]) {
        newFormSection1Errors.cg[key] = true;
        hasError = true;
      } else {
        newFormSection1Errors.cg[key] = false;
      }
    });

    // Validate Section 2 (EN)
    Object.keys(formSection2.en).forEach((key) => {
      if (!formSection2.en[key]) {
        newFormSection2Errors.en[key] = true;
        hasError = true;
      } else {
        newFormSection2Errors.en[key] = false;
      }
    });

    // Validate Section 2 (CG)
    Object.keys(formSection2.cg).forEach((key) => {
      if (!formSection2.cg[key]) {
        newFormSection2Errors.cg[key] = true;
        hasError = true;
      } else {
        newFormSection2Errors.cg[key] = false;
      }
    });

    // Validate Section 3 (EN)
    Object.keys(formSection3.en).forEach((key) => {
      if (!formSection3.en[key]) {
        newFormSection3Errors.en[key] = true;
        hasError = true;
      } else {
        newFormSection3Errors.en[key] = false;
      }
    });

    // Validate Section 3 (CG)
    Object.keys(formSection3.cg).forEach((key) => {
      if (!formSection3.cg[key]) {
        newFormSection3Errors.cg[key] = true;
        hasError = true;
      } else {
        newFormSection3Errors.cg[key] = false;
      }
    });

    // Validate Section Header (EN)
    Object.keys(formSectionHeader.en).forEach((key) => {
      if (!formSectionHeader.en[key]) {
        newFormSectionHeaderErrors.en[key] = true;
        hasError = true;
      } else {
        newFormSectionHeaderErrors.en[key] = false;
      }
    });

    // Validate Section Header (CG)
    Object.keys(formSectionHeader.cg).forEach((key) => {
      if (!formSectionHeader.cg[key]) {
        newFormSectionHeaderErrors.cg[key] = true;
        hasError = true;
      } else {
        newFormSectionHeaderErrors.cg[key] = false;
      }
    });

    // check if there are any fcImages that have value of '' (empty string)
    Object.keys(fpImages).forEach((key) => {
      if (fpImages[key] === null || fpImages[key] === '') {
        setFpImagesErrors((prevState) => ({
          ...prevState,
          [key]: true,
        }));
        hasError = true;
      } else {
        setFpImagesErrors((prevState) => ({
          ...prevState,
          [key]: false,
        }));
      }
    });

    // Update error state
    setFormSection1Errors(newFormSection1Errors);
    setFormSection2Errors(newFormSection2Errors);
    setFormSection3Errors(newFormSection3Errors);
    setFormSectionHeaderErrors(newFormSectionHeaderErrors);

    // Prevent form submission if there are errors
    if (hasError) {
      notification.error({
        message: 'Please fill in all required fields and upload all images',
        placement: 'topRight',
      });
      return;
    }

    setSaving(true);

    const payload = {
      fpHide: formGeneralSettings.hide || false,

      section1Hide: formSection1.hide || false,
      section2Hide: formSection2.hide || false,
      section3Hide: formSection3.hide || false,

      // header
      sectionHeaderMainHeadingPart1En: formSectionHeader.en.headMainHeadingPart1 || '',
      sectionHeaderMainHeadingPart2En: formSectionHeader.en.headMainHeadingPart2 || '',

      sectionHeaderMainHeadingPart1Cg: formSectionHeader.cg.headMainHeadingPart1 || '',
      sectionHeaderMainHeadingPart2Cg: formSectionHeader.cg.headMainHeadingPart2 || '',

      // section 1
      section1MainHeadingPart1En: formSection1.en.sec1MainHeadingPart1 || '',
      section1MainHeadingPart2En: formSection1.en.sec1MainHeadingPart2 || '',
      section1MainHeadingPart3En: formSection1.en.sec1MainHeadingPart3 || '',
      section1Advantage1TitleEn: formSection1.en.sec1Advantage1Title || '',
      section1Advantage1TextEn: formSection1.en.sec1Advantage1Text || '',
      section1Advantage2TitleEn: formSection1.en.sec1Advantage2Title || '',
      section1Advantage2TextEn: formSection1.en.sec1Advantage2Text || '',
      section1Advantage3TitleEn: formSection1.en.sec1Advantage3Title || '',
      section1Advantage3TextEn: formSection1.en.sec1Advantage3Text || '',

      section1MainHeadingPart1Cg: formSection1.cg.sec1MainHeadingPart1 || '',
      section1MainHeadingPart2Cg: formSection1.cg.sec1MainHeadingPart2 || '',
      section1MainHeadingPart3Cg: formSection1.cg.sec1MainHeadingPart3 || '',
      section1Advantage1TitleCg: formSection1.cg.sec1Advantage1Title || '',
      section1Advantage1TextCg: formSection1.cg.sec1Advantage1Text || '',
      section1Advantage2TitleCg: formSection1.cg.sec1Advantage2Title || '',
      section1Advantage2TextCg: formSection1.cg.sec1Advantage2Text || '',
      section1Advantage3TitleCg: formSection1.cg.sec1Advantage3Title || '',
      section1Advantage3TextCg: formSection1.cg.sec1Advantage3Text || '',

      // section 2
      section2MainHeadingPart1En: formSection2.en.sec2MainHeadingPart1 || '',
      section2MainHeadingPart2En: formSection2.en.sec2MainHeadingPart2 || '',
      section2MainHeadingPart3En: formSection2.en.sec2MainHeadingPart3 || '',
      section2Step1TitleEn: formSection2.en.sec2Step1Title || '',
      section2Step1TextEn: formSection2.en.sec2Step1Text || '',
      section2Step2TitleEn: formSection2.en.sec2Step2Title || '',
      section2Step2TextEn: formSection2.en.sec2Step2Text || '',
      section2Step3TitleEn: formSection2.en.sec2Step3Title || '',
      section2Step3TextEn: formSection2.en.sec2Step3Text || '',
      section2ButtonTextEn: formSection2.en.sec2ButtonText || '',
      section2LogoTextEn: formSection2.en.sec2LogoText || '',

      section2MainHeadingPart1Cg: formSection2.cg.sec2MainHeadingPart1 || '',
      section2MainHeadingPart2Cg: formSection2.cg.sec2MainHeadingPart2 || '',
      section2MainHeadingPart3Cg: formSection2.cg.sec2MainHeadingPart3 || '',
      section2Step1TitleCg: formSection2.cg.sec2Step1Title || '',
      section2Step1TextCg: formSection2.cg.sec2Step1Text || '',
      section2Step2TitleCg: formSection2.cg.sec2Step2Title || '',
      section2Step2TextCg: formSection2.cg.sec2Step2Text || '',
      section2Step3TitleCg: formSection2.cg.sec2Step3Title || '',
      section2Step3TextCg: formSection2.cg.sec2Step3Text || '',
      section2ButtonTextCg: formSection2.cg.sec2ButtonText || '',
      section2LogoTextCg: formSection2.cg.sec2LogoText || '',

      // section 3
      section3MainHeadingEn: formSection3.en.sec3MainHeading || '',
      section3Info1TitleEn: formSection3.en.sec3Info1Title || '',
      section3Info1TextEn: formSection3.en.sec3Info1Text || '',
      section3Info2TitleEn: formSection3.en.sec3Info2Title || '',
      section3Info2TextEn: formSection3.en.sec3Info2Text || '',
      section3Info3TitleEn: formSection3.en.sec3Info3Title || '',
      section3Info3TextEn: formSection3.en.sec3Info3Text || '',
      section3Info4TitleEn: formSection3.en.sec3Info4Title || '',
      section3Info4TextEn: formSection3.en.sec3Info4Text || '',

      section3MainHeadingCg: formSection3.cg.sec3MainHeading || '',
      section3Info1TitleCg: formSection3.cg.sec3Info1Title || '',
      section3Info1TextCg: formSection3.cg.sec3Info1Text || '',
      section3Info2TitleCg: formSection3.cg.sec3Info2Title || '',
      section3Info2TextCg: formSection3.cg.sec3Info2Text || '',
      section3Info3TitleCg: formSection3.cg.sec3Info3Title || '',
      section3Info3TextCg: formSection3.cg.sec3Info3Text || '',
      section3Info4TitleCg: formSection3.cg.sec3Info4Title || '',
      section3Info4TextCg: formSection3.cg.sec3Info4Text || '',

      // images
      sectionHeaderImageEn: fpImages.sectionHeaderImageEn || null,
      sectionHeaderImageCg: fpImages.sectionHeaderImageCg || null,

      section1ImageEn: fpImages.section1ImageEn || null,
      section2ImageEn: fpImages.section2ImageEn || null,

      section1ImageCg: fpImages.section1ImageCg || null,
      section2ImageCg: fpImages.section2ImageCg || null,
    };

    try {
      if (!initialData || initialData.length === 0) {
        const response = await axios.post(
          `${SERVER_URL}/${AXIOS_API_CALL.createFpDocument}`,
          {
            ...payload,
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      } else {
        const response = await axios.put(
          `${SERVER_URL}/${AXIOS_API_CALL.updateFpDocument}/${initialData[0]?._id}`,
          {
            ...payload,
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
    notification.success({
      message: 'Data saved successfully',
    });
    setSaving(false);
    setTimeout(() => {
      navigate(0);
    }, 1000);
  };
  // HANDLE FORM SUBMIT

  // HANDLE FETCH AND SET INITIAL DATA
  const handleFetchData = async () => {
    const { token } = user;
    try {
      const response = await axios.get(`${SERVER_URL}/${AXIOS_API_CALL.getFpDocument}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });
      setInitialData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!initialData) return;
    setFormGeneralSettings((prevState) => ({
      ...prevState,
      hide: initialData[0]?.fpHide || false,
    }));
    setFormSectionHeader((prevState) => ({
      ...prevState,
      en: {
        headMainHeadingPart1: initialData[0]?.sectionHeaderMainHeadingPart1En || '',
        headMainHeadingPart2: initialData[0]?.sectionHeaderMainHeadingPart2En || '',
      },
      cg: {
        headMainHeadingPart1: initialData[0]?.sectionHeaderMainHeadingPart1Cg || '',
        headMainHeadingPart2: initialData[0]?.sectionHeaderMainHeadingPart2Cg || '',
      },
    }));
    setFormSection1((prevState) => ({
      ...prevState,
      hide: initialData[0]?.section1Hide || false,
      en: {
        sec1MainHeadingPart1: initialData[0]?.section1MainHeadingPart1En || '',
        sec1MainHeadingPart2: initialData[0]?.section1MainHeadingPart2En || '',
        sec1MainHeadingPart3: initialData[0]?.section1MainHeadingPart3En || '',
        sec1Advantage1Title: initialData[0]?.section1Advantage1TitleEn || '',
        sec1Advantage1Text: initialData[0]?.section1Advantage1TextEn || '',
        sec1Advantage2Title: initialData[0]?.section1Advantage2TitleEn || '',
        sec1Advantage2Text: initialData[0]?.section1Advantage2TextEn || '',
        sec1Advantage3Title: initialData[0]?.section1Advantage3TitleEn || '',
        sec1Advantage3Text: initialData[0]?.section1Advantage3TextEn || '',
      },
      cg: {
        sec1MainHeadingPart1: initialData[0]?.section1MainHeadingPart1Cg || '',
        sec1MainHeadingPart2: initialData[0]?.section1MainHeadingPart2Cg || '',
        sec1MainHeadingPart3: initialData[0]?.section1MainHeadingPart3Cg || '',
        sec1Advantage1Title: initialData[0]?.section1Advantage1TitleCg || '',
        sec1Advantage1Text: initialData[0]?.section1Advantage1TextCg || '',
        sec1Advantage2Title: initialData[0]?.section1Advantage2TitleCg || '',
        sec1Advantage2Text: initialData[0]?.section1Advantage2TextCg || '',
        sec1Advantage3Title: initialData[0]?.section1Advantage3TitleCg || '',
        sec1Advantage3Text: initialData[0]?.section1Advantage3TextCg || '',
      },
    }));
    setFormSection2((prevState) => ({
      ...prevState,
      hide: initialData[0]?.section2Hide || false,
      en: {
        sec2MainHeadingPart1: initialData[0]?.section2MainHeadingPart1En || '',
        sec2MainHeadingPart2: initialData[0]?.section2MainHeadingPart2En || '',
        sec2MainHeadingPart3: initialData[0]?.section2MainHeadingPart3En || '',
        sec2Step1Title: initialData[0]?.section2Step1TitleEn || '',
        sec2Step1Text: initialData[0]?.section2Step1TextEn || '',
        sec2Step2Title: initialData[0]?.section2Step2TitleEn || '',
        sec2Step2Text: initialData[0]?.section2Step2TextEn || '',
        sec2Step3Title: initialData[0]?.section2Step3TitleEn || '',
        sec2Step3Text: initialData[0]?.section2Step3TextEn || '',
        sec2ButtonText: initialData[0]?.section2ButtonTextEn || '',
        sec2LogoText: initialData[0]?.section2LogoTextEn || '',
      },
      cg: {
        sec2MainHeadingPart1: initialData[0]?.section2MainHeadingPart1Cg || '',
        sec2MainHeadingPart2: initialData[0]?.section2MainHeadingPart2Cg || '',
        sec2MainHeadingPart3: initialData[0]?.section2MainHeadingPart3Cg || '',
        sec2Step1Title: initialData[0]?.section2Step1TitleCg || '',
        sec2Step1Text: initialData[0]?.section2Step1TextCg || '',
        sec2Step2Title: initialData[0]?.section2Step2TitleCg || '',
        sec2Step2Text: initialData[0]?.section2Step2TextCg || '',
        sec2Step3Title: initialData[0]?.section2Step3TitleCg || '',
        sec2Step3Text: initialData[0]?.section2Step3TextCg || '',
        sec2ButtonText: initialData[0]?.section2ButtonTextCg || '',
        sec2LogoText: initialData[0]?.section2LogoTextCg || '',
      },
    }));
    setFormSection3((prevState) => ({
      ...prevState,
      hide: initialData[0]?.section3Hide || false,
      en: {
        sec3MainHeading: initialData[0]?.section3MainHeadingEn || '',
        sec3Info1Title: initialData[0]?.section3Info1TitleEn || '',
        sec3Info1Text: initialData[0]?.section3Info1TextEn || '',
        sec3Info2Title: initialData[0]?.section3Info2TitleEn || '',
        sec3Info2Text: initialData[0]?.section3Info2TextEn || '',
        sec3Info3Title: initialData[0]?.section3Info3TitleEn || '',
        sec3Info3Text: initialData[0]?.section3Info3TextEn || '',
        sec3Info4Title: initialData[0]?.section3Info4TitleEn || '',
        sec3Info4Text: initialData[0]?.section3Info4TextEn || '',
      },
      cg: {
        sec3MainHeading: initialData[0]?.section3MainHeadingCg || '',
        sec3Info1Title: initialData[0]?.section3Info1TitleCg || '',
        sec3Info1Text: initialData[0]?.section3Info1TextCg || '',
        sec3Info2Title: initialData[0]?.section3Info2TitleCg || '',
        sec3Info2Text: initialData[0]?.section3Info2TextCg || '',
        sec3Info3Title: initialData[0]?.section3Info3TitleCg || '',
        sec3Info3Text: initialData[0]?.section3Info3TextCg || '',
        sec3Info4Title: initialData[0]?.section3Info4TitleCg || '',
        sec3Info4Text: initialData[0]?.section3Info4TextCg || '',
      },
    }));

    setFpImages((prevState) => ({
      ...prevState,
      sectionHeaderImageEn: initialData[0]?.sectionHeaderImageEn?._id || null,
      section1ImageEn: initialData[0]?.section1ImageEn?._id || null,
      section2ImageEn: initialData[0]?.section2ImageEn?._id || null,
      sectionHeaderImageCg: initialData[0]?.sectionHeaderImageCg?._id || null,
      section1ImageCg: initialData[0]?.section1ImageCg?._id || null,
      section2ImageCg: initialData[0]?.section2ImageCg?._id || null,
    }));
    setFpImagesPreview((prevState) => ({
      ...prevState,
      sectionHeaderImageEn: initialData[0]?.sectionHeaderImageEn?.url || null,
      section1ImageEn: initialData[0]?.section1ImageEn?.url || null,
      section2ImageEn: initialData[0]?.section2ImageEn?.url || null,
      sectionHeaderImageCg: initialData[0]?.sectionHeaderImageCg?.url || null,
      section1ImageCg: initialData[0]?.section1ImageCg?.url || null,
      section2ImageCg: initialData[0]?.section2ImageCg?.url || null,
    }));
  }, [initialData]);

  useEffect(() => {
    handleFetchData();
  }, []);
  // HANDLE FETCH AND SET INITIAL DATA

  // PANEL AND TABS RELATED FUNCTIONS
  const { Panel } = Collapse;
  const onChange = useCallback(() => {}, []);
  // PANEL AND TABS RELATED FUNCTIONS

  const handleInputChangeGeneralSettings = (e) => {
    const { name, checked } = e.target;
    if (name === 'fp_hide') {
      setFormGeneralSettings((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
    }
  };

  return (
    <div className="cms cms-for-companies">
      <h2 className="page-title">CMS For Partners</h2>
      {/* Main Content */}
      <form className="cms-form cms-form-section cms-form-section1">
        <Main className="section__content relative">
          <Collapse onChange={onChange}>
            {/* HEADER (Boost Your Client Base: partner with Fitpass) */}
            <Panel header="HEADER (Boost Your Client Base: partner with Fitpass)" key="1">
              <CmsForPartnersSectionHeader onChangeTabSectionHeader={onChangeTabSectionHeader} formSectionHeader={formSectionHeader} handleInputChangeFormSectionHeader={handleInputChangeFormSectionHeader} fpImages={fpImages} fpImagesErrors={fpImagesErrors} fpImagesPreview={fpImagesPreview} setFpImages={setFpImages} formSectionHeaderErrors={formSectionHeaderErrors} />
            </Panel>
            {/* HEADER (Boost Your Client Base: partner with Fitpass) */}

            {/* SECTION 1 (Boost Revenue and Membership with Fitpass with 0 investment!) */}
            <Panel header="SECTION 1 (Boost Revenue and Membership with Fitpass with 0 investment!)" key="2">
              <CmsForPartnersSection1 onChangeTabSection1={onChangeTabSection1} formSection1={formSection1} handleInputChangeFormSection1={handleInputChangeFormSection1} fpImages={fpImages} fpImagesErrors={fpImagesErrors} fpImagesPreview={fpImagesPreview} setFpImages={setFpImages} formSection1Errors={formSection1Errors} />
            </Panel>
            {/* SECTION 1 (Boost Revenue and Membership with Fitpass with 0 investment!) */}

            {/* SECTION 2 (Not a local Fitpass partner yet? Here is how to join our network for free) */}
            <Panel header="SECTION 2 (Not a local Fitpass partner yet? Here is how to join our network for free)" key="3">
              <CmsForPartnersSection2 onChangeTabSection2={onChangeTabSection2} formSection2={formSection2} handleInputChangeFormSection2={handleInputChangeFormSection2} fpImages={fpImages} fpImagesErrors={fpImagesErrors} fpImagesPreview={fpImagesPreview} setFpImages={setFpImages} formSection2Errors={formSection2Errors} />
            </Panel>
            {/* SECTION 2 (Not a local Fitpass partner yet? Here is how to join our network for free) */}

            {/* SECTION 3 (Want more info) */}
            <Panel header="SECTION 3 (Want more info)" key="4">
              <CmsForPartnersSection3 onChangeTabSection3={onChangeTabSection3} formSection3={formSection3} handleInputChangeFormSection3={handleInputChangeFormSection3} formSection3Errors={formSection3Errors} />
            </Panel>
            {/* SECTION 3 (Want more info) */}
          </Collapse>
          <CheckboxCms inputName="fp_hide" isChecked={formGeneralSettings.hide} handleChange={handleInputChangeGeneralSettings} label="Hide entire page on website" tooltipText="Checking this will hide entire page on website" />
          <button className="btn btn-primary pl-3 pr-3 mt-3 mb-5" onClick={(e) => handleFormSubmit(e)}>
            {saving ? 'Saving changes...' : 'Save changes'}
          </button>
        </Main>
      </form>
      {/* Main Content */}
    </div>
  );
};
export default CmsForPartners;
