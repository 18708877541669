import ImageUpload from '../../components/image-upload';
import TooltipDash from '../../../../../helpers/TooltipDash';

const Section2Cg = ({ handleInputChangeFormSection2, formSection2, fpImages, fpImagesErrors, fpImagesPreview, setFpImages, formSection2Errors }) => {
  return (
    <>
      <div className="cms-form-flex media" style={{ paddingBottom: '20px', marginBottom: '40px' }}>
        <div className="cms-form-flex-col">
          <div className="images-card-header">
            <span>Logo Slika</span>
            <span className="ml-6">
              <TooltipDash text="Proporcije slike 5/1 (300x60)px" acceptFormatText={true} />
            </span>
          </div>
          <div className="upload-wrapper">
            <ImageUpload fpImages={fpImages} fpImagesErrors={fpImagesErrors} fpImagesPreview={fpImagesPreview} setFpImages={setFpImages} toStoreImg="section2ImageCg" />
          </div>
        </div>
      </div>
      {/*Shared inputs*/}
      <div className="shared">
        {/*cg_sec2MainHeadingPart1*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="cg_sec2MainHeadingPart1">
            <b>Glavni naslov linija 1:</b>
          </label>
          <input id="cg_sec2MainHeadingPart1" type="text" placeholder="Glavni naslov linija 1" name="cg_sec2MainHeadingPart1" value={formSection2.cg.sec2MainHeadingPart1} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.cg.sec2MainHeadingPart1 ? 'input-error-important' : ''}`} />
        </div>
        {/*cg_sec2MainHeadingPart1*/}
        {/*cg_sec2MainHeadingPart2*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="cg_sec2MainHeadingPart2">
            <b>Glavni naslov linija 2:</b>
          </label>
          <input id="cg_sec2MainHeadingPart2" type="text" placeholder="Glavni naslov linija 2" name="cg_sec2MainHeadingPart2" value={formSection2.cg.sec2MainHeadingPart2} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.cg.sec2MainHeadingPart2 ? 'input-error-important' : ''}`} />
        </div>
        {/*cg_sec2MainHeadingPart2*/}
        {/*cg_sec2MainHeadingPart3*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="cg_sec2MainHeadingPart3">
            <b>Glavni naslov linija 3:</b>
          </label>
          <input id="cg_sec2MainHeadingPart3" type="text" placeholder="Glavni naslov linija 3" name="cg_sec2MainHeadingPart3" value={formSection2.cg.sec2MainHeadingPart3} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.cg.sec2MainHeadingPart3 ? 'input-error-important' : ''}`} />
        </div>
        {/*cg_sec2MainHeadingPart3*/}
        {/*cg_sec2ButtonText*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="cg_sec2ButtonText">
            <b>Tekst dugmeta:</b>
          </label>
          <input id="cg_sec2ButtonText" type="text" placeholder="Tekst dugmeta" name="cg_sec2ButtonText" value={formSection2.cg.sec2ButtonText} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.cg.sec2ButtonText ? 'input-error-important' : ''}`} />
        </div>
        {/*cg_sec2ButtonText*/}
        {/*cg_sec2ButtonRedirect*/}
        {/* <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="cg_sec2ButtonRedirect">
            <b>URL dugmeta:</b>
          </label>
          <input id="cg_sec2ButtonRedirect" type="text" placeholder="URL dugmeta" name="cg_sec2ButtonRedirect" value={formSection2.cg.sec2ButtonRedirect} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.cg.sec2ButtonRedirect ? 'input-error-important' : ''}`} />
        </div> */}
        {/*cg_sec2ButtonRedirect*/}
        {/*cg_sec2LogoText*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="cg_sec2LogoText">
            <b>Logo tekst:</b>
          </label>
          <input id="cg_sec2LogoText" type="text" placeholder="Logo text" name="cg_sec2LogoText" value={formSection2.cg.sec2LogoText} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.cg.sec2LogoText ? 'input-error-important' : ''}`} />
        </div>
        {/*cg_sec2LogoText*/}
      </div>
      {/*Shared inputs*/}
      <div className="cms-form-flex">
        {/*Korak 1*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Korak 1:</p>
          {/*cg_sec2Step1Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec2Step1Title">
              <b>Korak 1 Naslov:</b>
            </label>
            <textarea id="cg_sec2Step1Title" type="text" placeholder="Korak 1 Naslov" name="cg_sec2Step1Title" value={formSection2.cg.sec2Step1Title} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.cg.sec2Step1Title ? 'input-error-important' : ''}`} />
          </div>
          {/*cg_sec2Step1Title*/}
          {/*cg_sec2Step1Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec2Step1Text">
              <b>Korak 1 Tekst:</b>
            </label>
            <textarea id="cg_sec2Korak1Text" type="text" placeholder="Korak 1 Tekst" name="cg_sec2Step1Text" value={formSection2.cg.sec2Step1Text} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.cg.sec2Step1Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*cg_sec2Step1Text*/}
        </div>
        {/*Korak 1*/}

        {/*Korak 2*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Korak 2:</p>
          {/*cg_sec2Step2Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec2Step2Title">
              <b>Korak 2 Naslov:</b>
            </label>
            <textarea id="cg_sec2Step2Title" type="text" placeholder="Korak 2 Naslov" name="cg_sec2Step2Title" value={formSection2.cg.sec2Step2Title} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.cg.sec2Step2Title ? 'input-error-important' : ''}`} />
          </div>
          {/*cg_sec2Step2Title*/}
          {/*cg_sec2Step2Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec2Step2Text">
              <b>Korak 2 Tekst:</b>
            </label>
            <textarea id="cg_sec2Step2Text" type="text" placeholder="Korak 2 Tekst" name="cg_sec2Step2Text" value={formSection2.cg.sec2Step2Text} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.cg.sec2Step2Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*cg_sec2Step2Text*/}
        </div>
        {/*Korak 2*/}

        {/*Korak 3*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Korak 3:</p>
          {/*cg_sec2Step3Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec2Step3Title">
              <b>Korak 3 Naslov:</b>
            </label>
            <textarea id="cg_sec2Step3Title" type="text" placeholder="Korak 3 Naslov" name="cg_sec2Step3Title" value={formSection2.cg.sec2Step3Title} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.cg.sec2Step3Title ? 'input-error-important' : ''}`} />
          </div>
          {/*cg_sec2Step3Title*/}
          {/*cg_sec2Step3Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec2Step3Text">
              <b>Korak 3 Tekst:</b>
            </label>
            <textarea id="cg_sec2Korak3Text" type="text" placeholder="Korak 3 Tekst" name="cg_sec2Step3Text" value={formSection2.cg.sec2Step3Text} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.cg.sec2Step3Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*cg_sec2Step3Text*/}
        </div>
        {/*Korak 3*/}
      </div>
    </>
  );
};
export default Section2Cg;
