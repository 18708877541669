import { PERMISSIONS } from './permissions';
import { getGroceryPermissions } from '../middleware';

const groceryMenuItems = (props) => {
  const { groceryPermissions, handleLogout, onHide } = props;

  const grocery = [
    {
      _id: '',
      label: 'Data',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.attributes}`,
      group: 'webshop',
      type: 'button',
      icon: '/assets/icons/products.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-store-dropdown',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readAttributes),
      onClick: function(value) {},
      children: [
        {
          _id: '',
          label: 'Venues',
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/venues`,
          type: 'anchor',
          icon: '',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-facilities',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readAttributes),
          onClick: () => {
            onHide(false);
          },
        },
        {
          _id: '',
          label: 'Attributes',
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.attributes}`,
          type: 'anchor',
          icon: '',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-attributes',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readAttributes),
          onClick: () => {
            onHide(false);
          },
        },
        {
          _id: '',
          label: 'Global Attributes',
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.globalAttributes}`,
          type: 'anchor',
          icon: '',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-global-attributes',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readAttributes),
          onClick: () => {
            onHide(false);
          },
        },
      ],
    },
    {
      _id: '',
      label: 'Requests',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.requests}`,
      group: 'webshop',
      type: 'button',
      icon: '/assets/icons/details.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-store-dropdown',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readRequests),

      // visible: true,
      onClick: function(value) {},
      children: [
        {
          _id: '',
          label: 'Edit Requests',
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/requests`,
          type: 'anchor',
          icon: '',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-facilities',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.updateRequests),
          onClick: () => {
            onHide(false);
          },
          visible: true,
        },
        {
          _id: '',
          label: 'Nyami Requests',
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.nyamiRequests}`,
          type: 'anchor',
          icon: '',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-facilities',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readNyamirequests),
          onClick: () => {
            onHide(false);
          },
        },
        {
          _id: '',
          label: 'Edit History',
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.editHistory}`,
          type: 'anchor',
          icon: '',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-attributes',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readAttributes),
          onClick: () => {
            onHide(false);
          },
        },
      ],
    },
    // CMS Section
    {
      _id: '',
      label: 'CMS',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.attributes}`,
      group: 'webshop',
      type: 'button',
      icon: '/assets/icons/products.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-store-dropdown',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readAttributes),
      onClick: function(value) {},
      children: [
        {
          _id: '',
          label: 'HP Sliders',
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.slideshow}`,
          type: 'anchor',
          icon: '',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-cms-homepage',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readAttributes),
          onClick: () => {
            onHide(false);
          },
        },
        {
          _id: '',
          label: 'Homepage',
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.CMSHomepage}`,
          type: 'anchor',
          icon: '',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-cms-homepage',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readAttributes),
          onClick: () => {
            onHide(false);
          },
        },
        {
          _id: '',
          label: 'About us',
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.CMSAboutUs}`,
          type: 'anchor',
          icon: '',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-cms-aboutus',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readAttributes),
          onClick: () => {
            onHide(false);
          },
        },
        {
          _id: '',
          label: 'For companies',
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.CMSForCompanies}`,
          type: 'anchor',
          icon: '',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-cms-for-companies',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readAttributes),
          onClick: () => {
            onHide(false);
          },
        },
        {
          _id: '',
          label: 'For partners',
          path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.CMSForPartners}`,
          type: 'anchor',
          icon: '',
          expanded: false,
          protected: false,
          dataCy: 'sidebar-grocery-cms-for-partners',
          visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readAttributes),
          onClick: () => {
            onHide(false);
          },
        },
      ],
    },
    // CMS Section
    {
      _id: '',
      label: 'Gallery',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.gallery}`,
      group: 'webshop',
      type: 'anchor',
      icon: '/assets/icons/gallery.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-gallery',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readGallery),
      onClick: function() {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Roles',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.roles}`,
      group: 'users',
      type: 'anchor',
      icon: '/assets/icons/roles.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-roles',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readRoles),
      onClick: function() {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Admins',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.employees}`,
      group: 'users',
      type: 'anchor',
      icon: '/assets/icons/employees.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-employees',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readEmployees),
      onClick: function() {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Members',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.customers}`,
      group: 'users',
      type: 'anchor',
      icon: '/assets/icons/employees.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-members',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readEmployees),
      onClick: function() {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Activity Log',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.activity}`,
      group: 'users',
      type: 'anchor',
      icon: '/assets/icons/activity.svg',
      expanded: false,
      protected: false,
      dataCy: 'sidebar-grocery-activity-log',
      visible: getGroceryPermissions(groceryPermissions, PERMISSIONS.readActivity),
      onClick: function() {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Profile',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.profile}`,
      group: 'account',
      type: 'anchor',
      icon: '/assets/icons/profile.svg',
      expanded: true,
      protected: false,
      dataCy: 'sidebar-grocery-profile',
      visible: true,
      onClick: function() {
        onHide(false);
      },
      children: [],
    },
    {
      _id: '',
      label: 'Logout',
      path: `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.login}`,
      group: 'account',
      type: 'button',
      icon: '/assets/icons/logout.svg',
      expanded: true,
      protected: false,
      dataCy: 'sidebar-grocery-logout',
      visible: true,
      onClick: () => {
        handleLogout();
      },
      children: [],
    },
  ];

  return grocery;
};

export { groceryMenuItems };
