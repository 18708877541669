import React from 'react';
import SidebarModal from '../../../../components/sidebarModal/SidebarModal';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';

export const CreateUnit = ({ createUnitProps, createUnitFormRef, handleOnSubmit, createUnitForm, createUnitFormError, handleOnBlur, handleOnChange, setToggleCreateUnit, createUnitBtnLoader }) => {
  const { t } = useTranslation();
  return (
    <SidebarModal {...createUnitProps} className="sidebar__modal--right" title={t('createUnit')}>
      <Form ref={createUnitFormRef} className="ant-form ant-form-horizontal" onFinish={(event) => handleOnSubmit({ event: event, form: createUnitProps, type: 'create-unit' })}>
        <div className="form-group relative">
          <label className="label mb-1 d-block" htmlFor="createUnitName">
            <b className="required">{t('name')}:</b>
          </label>

          <input type="text" placeholder={t('name')} name="createUnitName" id="createUnitName" data-cy="create-unit-name-input" value={createUnitForm.name || ''} onChange={(event) => handleOnChange(event)} onBlur={(event) => handleOnBlur(event)} className={`input ${createUnitFormError.name ? 'input-error' : ''}`} />

          <p data-cy="modal-create-unit-name-error" className={`error__onblur ${createUnitFormError.name ? 'error' : ''}`}>
            * {t('requiredField')}
          </p>
        </div>

        <div className="form-group form-group-sidebar">
          <button data-cy="modal-create-unit-cancel-btn" type="button" className="btn btn-primary-link" onClick={() => setToggleCreateUnit(false)}>
            <span className="text">{t('cancel')}</span>
          </button>

          {!createUnitBtnLoader ? (
            <button data-cy="modal-create-unit-submit-btn" type="submit" className="btn btn-primary">
              <span className="text">{t('createUnit')}</span>
            </button>
          ) : (
            <button type="button" className="btn btn-primary-outline">
              <span className="text">{t('creatingUnit')}...</span>
            </button>
          )}
        </div>
      </Form>
    </SidebarModal>
  );
};
