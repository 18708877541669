import React from 'react';
import Message from '../../components/message/Message';
import { contentMessage } from '../../helpers/contentMessage';
import Pagination from '../../components/pagination/Pagination';
import { formatTime, formatDate } from '../../helpers/helpers';

const Table = (props) => {
  // TABLE
  const { data, handleToggle } = props;
  // PAGINATION
  const { pagination } = props;

  return (
    <>
      {data && data.length > 0 ? (
        <>
          <table className="table">
            <thead className="thead">
              <tr>
                <th>Date</th>
                <th>Time</th>
                <th>Activity</th>
                <th>Data</th>
              </tr>
            </thead>

            <tbody className="tbody">
              {data && data.length > 0 ? (
                data.map((item) => {
                  return (
                    <tr key={item._id}>
                      <td>{formatDate(item)}</td>
                      <td>{formatTime(item)}</td>
                      <td>{item.description}</td>
                      <td>
                        <button type="button" className="btn btn-download" onClick={() => handleToggle({ name: 'download', data: item._id })}>
                          <span className="icon">
                            <img alt="Download" src="/assets/icons/download.svg" />
                          </span>
                          <span className="text">Download data</span>
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={20} style={{ backgroundColor: '#fff', height: '200px', border: '0' }}>
                    <h2 style={{ color: '#666' }}>Data not found!</h2>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination {...pagination} />
        </>
      ) : (
        <Message className="content-message">
          <h2 style={{ color: '#666' }}>{contentMessage.dataNotFound}</h2>
        </Message>
      )}
    </>
  );
};

export default Table;
