import React, { memo } from 'react';
import { Tabs } from 'antd';
import Section4En from '../tabs/en/Section4En';
import Section4Cg from '../tabs/cg/Section4Cg';
import CheckboxCms from '../../../../components/checkbox-cms';

const CmsHomepageSection4 = ({ onChangeTabSection4, handleInputChangeFormSection4, formSection4, setHpImages, hpImages, hpImagesPreview, formSection4Errors, hpImagesErrors }) => {
  const { TabPane } = Tabs;

  const sectionProps = {
    handleInputChangeFormSection4,
    formSection4,
    setHpImages,
    hpImages,
    hpImagesPreview,
    formSection4Errors,
    hpImagesErrors,
  };
  return (
    <>
      <Tabs defaultActiveKey="1" onChange={onChangeTabSection4}>
        <TabPane key="1" tab="EN">
          <Section4En {...sectionProps} />
        </TabPane>
        <TabPane key="2" tab="CG">
          <Section4Cg {...sectionProps} />
        </TabPane>
      </Tabs>
      <CheckboxCms inputName="section4_hide" isChecked={formSection4.hide} handleChange={handleInputChangeFormSection4} label="Hide entire section on website" tooltipText="Checking this will hide entire section on website" />
    </>
  );
};
export default memo(CmsHomepageSection4);
