import React from 'react';
import Modal from '../../../../components/modal/Modal';

export const DeleteBulkProducts = ({ deleteBlukProps, onSubmit, setToggleDeleteBulk, checkedRow }) => {
  return (
    <Modal {...deleteBlukProps} className="sidebar__modal--center" title="">
      <form name="deleteBulk" onSubmit={(event) => onSubmit({ event: event, name: 'deleteBulk', data: checkedRow })}>
        <h2 className="text-center mb-4">Are you sure you want to remove selected items ?</h2>

        <div className="form-group form-group-modal mb-4">
          <button data-cy="modal-delete-bulk-submit-btn" type="submit" className="btn btn-primary-link">
            <span className="text">Yes</span>
          </button>

          <button data-cy="modal-delete-bulk-cancel-btn" type="button" className="btn btn-primary" onClick={() => setToggleDeleteBulk(false)}>
            <span className="text">No</span>
          </button>
        </div>
      </form>
    </Modal>
  );
};
