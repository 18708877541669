import React, { useState, useCallback, useEffect } from 'react';

import axios from 'axios';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { SERVER_URL } from '../../../config/index';
import { PERMISSIONS } from '../../../utils/permissions';

import { useAuth } from '../../../context/useAuth';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

import { Main } from '../../../components/content';
import { Collapse } from 'antd';
import { notification } from 'antd';
import CheckboxCms from '../../../components/checkbox-cms';

import CmsClubSection1 from './sections/CmsClubSection1';
import CmsClubHeader from './sections/CmsClubHeader';

const CmsClub = () => {
  const { user } = useAuth();
  const [groceryPermissions, setGroceryPermissions] = useState([]);
  const [initialData, setInitialData] = useState(null);
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;

    if (user && token) {
      const decodeToken = jwtDecode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
          setGroceryPermissions(permissions.grocery);
        }
      }
    }
  }, [user, location]);

  // GENERAL SETTINGS HANDLE
  const [formGeneralSettings, setFormGeneralSettings] = React.useState({
    hide: false,
  });

  // IMAGES HANDLE
  const [clImages, setClImages] = React.useState({
    sectionHeaderImageEn: '',
    sectionHeaderImageCg: '',

    section1ImageEn: '',

    section1ImageCg: '',
  });
  const [clImagesPreview, setClImagesPreview] = React.useState({
    sectionHeaderImageEn: '',
    sectionHeaderImageCg: '',

    section1ImageEn: '',

    section1ImageCg: '',
  });
  const [clImagesErrors, setClImagesErrors] = React.useState({
    sectionHeaderImageEn: false,
    sectionHeaderImageCg: false,

    section1ImageEn: false,

    section1ImageCg: false,
  });
  // IMAGES HANDLE

  // HEADER HANDLE
  const [formSectionHeader, setFormSectionHeader] = React.useState({
    en: {
      headMainHeadingPart1: '',
      headMainHeadingPart2: '',
      headText: '',
    },
    cg: {
      headMainHeadingPart1: '',
      headMainHeadingPart2: '',
      headText: '',
    },
  });
  const [formSectionHeaderErrors, setFormSectionHeaderErrors] = React.useState({
    en: {
      headMainHeadingPart1: false,
      headMainHeadingPart2: false,
      headText: false,
    },
    cg: {
      headMainHeadingPart1: false,
      headMainHeadingPart2: false,
      headText: false,
    },
  });
  const handleInputChangeFormSectionHeader = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    setFormSectionHeader((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value,
      },
    }));
  }, []);
  const onChangeTabSectionHeader = useCallback(() => {}, []);
  // HEADER HANDLE

  // SECTION 1 HANDLE
  const [formSection1, setFormSection1] = React.useState({
    hide: false,
    en: {
      sec1MainHeading: '',
      sec1ListItem1Title: '',
      sec1ListItem1Text: '',
      sec1ListItem2Title: '',
      sec1ListItem2Text: '',
      sec1ListItem3Title: '',
      sec1ListItem3Text: '',
      sec1ListItem4Title: '',
      sec1ListItem4Text: '',
    },
    cg: {
      sec1MainHeading: '',
      sec1ListItem1Title: '',
      sec1ListItem1Text: '',
      sec1ListItem2Title: '',
      sec1ListItem2Text: '',
      sec1ListItem3Title: '',
      sec1ListItem3Text: '',
      sec1ListItem4Title: '',
      sec1ListItem4Text: '',
    },
  });
  const [formSection1Errors, setFormSection1Errors] = React.useState({
    en: {
      sec1MainHeading: false,
      sec1ListItem1Title: false,
      sec1ListItem1Text: false,
      sec1ListItem2Title: false,
      sec1ListItem2Text: false,
      sec1ListItem3Title: false,
      sec1ListItem3Text: false,
      sec1ListItem4Title: false,
      sec1ListItem4Text: false,
    },
    cg: {
      sec1MainHeading: false,
      sec1ListItem1Title: false,
      sec1ListItem1Text: false,
      sec1ListItem2Title: false,
      sec1ListItem2Text: false,
      sec1ListItem3Title: false,
      sec1ListItem3Text: false,
      sec1ListItem4Title: false,
      sec1ListItem4Text: false,
    },
  });
  const handleInputChangeFormSection1 = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    if (name === 'section1_hide') {
      setFormSection1((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
      return;
    }

    setFormSection1((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value,
      },
    }));
  }, []);
  const onChangeTabSection1 = useCallback(() => {}, []);
  // SECTION 1 HANDLE

  // HANDLE FORM SUBMIT
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const { token } = user;

    // Initialize error flag
    let hasError = false;

    // Create a copy of the error state
    let newFormSection1Errors = { ...formSection1Errors };
    let newFormSectionHeaderErrors = { ...formSectionHeaderErrors };

    // Validate Section 1 (EN)
    Object.keys(formSection1.en).forEach((key) => {
      if (!formSection1.en[key]) {
        newFormSection1Errors.en[key] = true;
        hasError = true;
      } else {
        newFormSection1Errors.en[key] = false;
      }
    });

    // Validate Section 1 (CG)
    Object.keys(formSection1.cg).forEach((key) => {
      if (!formSection1.cg[key]) {
        newFormSection1Errors.cg[key] = true;
        hasError = true;
      } else {
        newFormSection1Errors.cg[key] = false;
      }
    });

    // Validate Section Header (EN)
    Object.keys(formSectionHeader.en).forEach((key) => {
      if (!formSectionHeader.en[key]) {
        newFormSectionHeaderErrors.en[key] = true;
        hasError = true;
      } else {
        newFormSectionHeaderErrors.en[key] = false;
      }
    });

    // Validate Section Header (CG)
    Object.keys(formSectionHeader.cg).forEach((key) => {
      if (!formSectionHeader.cg[key]) {
        newFormSectionHeaderErrors.cg[key] = true;
        hasError = true;
      } else {
        newFormSectionHeaderErrors.cg[key] = false;
      }
    });

    // check if there are any fcImages that have value of '' (empty string)
    Object.keys(clImages).forEach((key) => {
      if (clImages[key] === null || clImages[key] === '') {
        setClImagesErrors((prevState) => ({
          ...prevState,
          [key]: true,
        }));
        hasError = true;
      } else {
        setClImagesErrors((prevState) => ({
          ...prevState,
          [key]: false,
        }));
      }
    });

    // Update error state
    setFormSection1Errors(newFormSection1Errors);
    setFormSectionHeaderErrors(newFormSectionHeaderErrors);

    // Prevent form submission if there are errors
    if (hasError) {
      notification.error({
        message: 'Please fill in all required fields and upload all images',
        placement: 'topRight',
      });
      return;
    }

    setSaving(true);

    const payload = {
      clHide: formGeneralSettings.hide || false,

      section1Hide: formSection1.hide || false,

      // header
      sectionHeaderMainHeadingPart1En: formSectionHeader.en.headMainHeadingPart1 || '',
      sectionHeaderMainHeadingPart2En: formSectionHeader.en.headMainHeadingPart2 || '',
      sectionHeaderTextEn: formSectionHeader.en.headText || '',

      sectionHeaderMainHeadingPart1Cg: formSectionHeader.cg.headMainHeadingPart1 || '',
      sectionHeaderMainHeadingPart2Cg: formSectionHeader.cg.headMainHeadingPart2 || '',
      sectionHeaderTextCg: formSectionHeader.cg.headText || '',

      // section 1
      section1MainHeadingEn: formSection1.en.sec1MainHeading || '',
      sec1ListItem1TitleEn: formSection1.en.sec1ListItem1Title || '',
      sec1ListItem1TextEn: formSection1.en.sec1ListItem1Text || '',
      sec1ListItem2TitleEn: formSection1.en.sec1ListItem2Title || '',
      sec1ListItem2TextEn: formSection1.en.sec1ListItem2Text || '',
      sec1ListItem3TitleEn: formSection1.en.sec1ListItem3Title || '',
      sec1ListItem3TextEn: formSection1.en.sec1ListItem3Text || '',
      sec1ListItem4TitleEn: formSection1.en.sec1ListItem4Title || '',
      sec1ListItem4TextEn: formSection1.en.sec1ListItem4Text || '',

      section1MainHeadingCg: formSection1.cg.sec1MainHeading || '',
      sec1ListItem1TitleCg: formSection1.cg.sec1ListItem1Title || '',
      sec1ListItem1TextCg: formSection1.cg.sec1ListItem1Text || '',
      sec1ListItem2TitleCg: formSection1.cg.sec1ListItem2Title || '',
      sec1ListItem2TextCg: formSection1.cg.sec1ListItem2Text || '',
      sec1ListItem3TitleCg: formSection1.cg.sec1ListItem3Title || '',
      sec1ListItem3TextCg: formSection1.cg.sec1ListItem3Text || '',
      sec1ListItem4TitleCg: formSection1.cg.sec1ListItem4Title || '',
      sec1ListItem4TextCg: formSection1.cg.sec1ListItem4Text || '',

      // images
      sectionHeaderImageEn: clImages.sectionHeaderImageEn || null,
      sectionHeaderImageCg: clImages.sectionHeaderImageCg || null,

      section1ImageEn: clImages.section1ImageEn || null,

      section1ImageCg: clImages.section1ImageCg || null,
    };

    try {
      if (!initialData || initialData.length === 0) {
        const response = await axios.post(
          `${SERVER_URL}/${AXIOS_API_CALL.createClDocument}`,
          {
            ...payload,
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      } else {
        const response = await axios.put(
          `${SERVER_URL}/${AXIOS_API_CALL.updateClDocument}/${initialData[0]?._id}`,
          {
            ...payload,
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
    notification.success({
      message: 'Data saved successfully',
    });
    setSaving(false);
    setTimeout(() => {
      navigate(0);
    }, 1000);
  };
  // HANDLE FORM SUBMIT

  // HANDLE FETCH AND SET INITIAL DATA
  const handleFetchData = async () => {
    const { token } = user;
    try {
      const response = await axios.get(`${SERVER_URL}/${AXIOS_API_CALL.getClDocument}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });
      setInitialData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!initialData) return;
    setFormGeneralSettings((prevState) => ({
      ...prevState,
      hide: initialData[0]?.clHide || false,
    }));
    setFormSectionHeader((prevState) => ({
      ...prevState,
      en: {
        headMainHeadingPart1: initialData[0]?.sectionHeaderMainHeadingPart1En || '',
        headMainHeadingPart2: initialData[0]?.sectionHeaderMainHeadingPart2En || '',
        headText: initialData[0]?.sectionHeaderTextEn || '',
      },
      cg: {
        headMainHeadingPart1: initialData[0]?.sectionHeaderMainHeadingPart1Cg || '',
        headMainHeadingPart2: initialData[0]?.sectionHeaderMainHeadingPart2Cg || '',
        headText: initialData[0]?.sectionHeaderTextCg || '',
      },
    }));
    setFormSection1((prevState) => ({
      ...prevState,
      hide: initialData[0]?.section1Hide || false,
      en: {
        sec1MainHeading: initialData[0]?.section1MainHeadingEn || '',
        sec1ListItem1Title: initialData[0]?.sec1ListItem1TitleEn || '',
        sec1ListItem1Text: initialData[0]?.sec1ListItem1TextEn || '',
        sec1ListItem2Title: initialData[0]?.sec1ListItem2TitleEn || '',
        sec1ListItem2Text: initialData[0]?.sec1ListItem2TextEn || '',
        sec1ListItem3Title: initialData[0]?.sec1ListItem3TitleEn || '',
        sec1ListItem3Text: initialData[0]?.sec1ListItem3TextEn || '',
        sec1ListItem4Title: initialData[0]?.sec1ListItem4TitleEn || '',
        sec1ListItem4Text: initialData[0]?.sec1ListItem4TextEn || '',
      },
      cg: {
        sec1MainHeading: initialData[0]?.section1MainHeadingCg || '',
        sec1ListItem1Title: initialData[0]?.sec1ListItem1TitleCg || '',
        sec1ListItem1Text: initialData[0]?.sec1ListItem1TextCg || '',
        sec1ListItem2Title: initialData[0]?.sec1ListItem2TitleCg || '',
        sec1ListItem2Text: initialData[0]?.sec1ListItem2TextCg || '',
        sec1ListItem3Title: initialData[0]?.sec1ListItem3TitleCg || '',
        sec1ListItem3Text: initialData[0]?.sec1ListItem3TextCg || '',
        sec1ListItem4Title: initialData[0]?.sec1ListItem4TitleCg || '',
        sec1ListItem4Text: initialData[0]?.sec1ListItem4TextCg || '',
      },
    }));

    setClImages((prevState) => ({
      ...prevState,
      sectionHeaderImageEn: initialData[0]?.sectionHeaderImageEn?._id || null,
      section1ImageEn: initialData[0]?.section1ImageEn?._id || null,
      sectionHeaderImageCg: initialData[0]?.sectionHeaderImageCg?._id || null,
      section1ImageCg: initialData[0]?.section1ImageCg?._id || null,
    }));
    setClImagesPreview((prevState) => ({
      ...prevState,
      sectionHeaderImageEn: initialData[0]?.sectionHeaderImageEn?.url || null,
      section1ImageEn: initialData[0]?.section1ImageEn?.url || null,
      sectionHeaderImageCg: initialData[0]?.sectionHeaderImageCg?.url || null,
      section1ImageCg: initialData[0]?.section1ImageCg?.url || null,
    }));
  }, [initialData]);

  useEffect(() => {
    handleFetchData();
  }, []);
  // HANDLE FETCH AND SET INITIAL DATA

  // PANEL AND TABS RELATED FUNCTIONS
  const { Panel } = Collapse;
  const onChange = useCallback(() => {}, []);
  // PANEL AND TABS RELATED FUNCTIONS

  const handleInputChangeGeneralSettings = (e) => {
    const { name, checked } = e.target;
    if (name === 'cl_hide') {
      setFormGeneralSettings((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
    }
  };

  console.log('formSectionHeader', formSectionHeader);
  console.log('formSection1', formSection1);
  console.log('clImages', clImages);

  return (
    <div className="cms cms-for-companies">
      <h2 className="page-title">CMS Fitpass Club</h2>
      {/* Main Content */}
      <form className="cms-form cms-form-section cms-form-section1">
        <Main className="section__content relative">
          <Collapse onChange={onChange}>
            {/* HEADER */}
            <Panel header="HEADER Club" key="1">
              <CmsClubHeader onChangeTabSectionHeader={onChangeTabSectionHeader} formSectionHeader={formSectionHeader} handleInputChangeFormSectionHeader={handleInputChangeFormSectionHeader} clImages={clImages} clImagesErrors={clImagesErrors} clImagesPreview={clImagesPreview} setClImages={setClImages} formSectionHeaderErrors={formSectionHeaderErrors} />
            </Panel>
            {/* HEADER */}

            {/* SECTION 1 */}
            <Panel header="SECTION 1" key="2">
              <CmsClubSection1 onChangeTabSection1={onChangeTabSection1} handleInputChangeFormSection1={handleInputChangeFormSection1} formSection1={formSection1} setClImages={setClImages} clImages={clImages} clImagesErrors={clImagesErrors} clImagesPreview={clImagesPreview} formSection1Errors={formSection1Errors} />
            </Panel>
            {/* SECTION 1 */}
          </Collapse>
          <CheckboxCms inputName="cl_hide" isChecked={formGeneralSettings.hide} handleChange={handleInputChangeGeneralSettings} label="Hide entire page on website" tooltipText="Checking this will hide entire page on website" />
          <button className="btn btn-primary pl-3 pr-3 mt-3 mb-5" onClick={(e) => handleFormSubmit(e)}>
            {saving ? 'Saving changes...' : 'Save changes'}
          </button>
        </Main>
      </form>
      {/* Main Content */}
    </div>
  );
};
export default CmsClub;
