import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { SERVER_URL } from '../../config';
import { Form, Input, Button, notification } from 'antd';
import { EyeFilled, EyeInvisibleFilled } from '@ant-design/icons';
import { Section } from '../../components/content';
import { AXIOS_API_CALL } from '../../utils/endpoint';

const ResetPassword = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [resetBtnLoader, setResetBtnLoader] = useState(false);

  const onFinish = async (data) => {
    setResetBtnLoader(true);
    const token = id;
    const newPassword = data['new-password'];
    const confirmPassword = data['confirm-password'];

    const dataPayload = {
      password: newPassword
    };

    if (newPassword.includes(confirmPassword) && confirmPassword.includes(newPassword)) {
      try {
        const fetchPasswordReset = await axios.post(`${SERVER_URL}/${AXIOS_API_CALL.passwordReset}/${token}`, { ...dataPayload }, { withCredentials: false });

        if (fetchPasswordReset.status === 200) {
          notification.success({
            message: 'Password was successfully reset.',
            placement: 'bottomRight'
          });

          navigate(AXIOS_API_CALL.login, { replace: true });
        }
        setTimeout(() => {
          setResetBtnLoader(false);
        }, 200);
      } catch (err) {
        notification.warn({
          message: err.response.data.message,
          placement: 'bottomRight'
        });
        setTimeout(() => {
          setResetBtnLoader(false);
        }, 200);
        navigate(AXIOS_API_CALL.login, { replace: true });
      }
    } else {
      notification.warn({
        message: 'New password or confirm password is not equal!',
        placement: 'bottomRight'
      });
      setTimeout(() => {
        setResetBtnLoader(false);
      }, 200);
    }
  };

  const onFinishFailed = (err) => {
    console.error(err);
  };

  return (
    <Section className="section section__login section__new-password">
      <div className="container-fluid">
        <div className="card-wrapper form-wrapper">
          <div className="d-flex justify-center items-center mb-4 gap-x-1">
            <img className="login-circle" src="/assets/icons/password-reset.svg" alt="Login" />
            <h1 className="mb-0">Set New Password</h1>
          </div>

          <div className="d-flex justify-center items-center">
            <p className="subtitle">
              Your new password must be different <br /> from previues one
            </p>
          </div>

          <Form name="reset-password" onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <label className="label" htmlFor="new-password">
              New Password:
            </label>
            <Form.Item name="new-password" rules={[{ required: true, message: 'Please enter your new password!' }]}>
              <Input.Password iconRender={(visible) => (visible ? <EyeFilled /> : <EyeInvisibleFilled />)} placeholder="New Password" id="new-password" />
            </Form.Item>

            <label className="label" htmlFor="confirm-password">
              Confirm Password:
            </label>
            <Form.Item name="confirm-password" rules={[{ required: true, message: 'Please enter your confirm password!' }]}>
              <Input.Password iconRender={(visible) => (visible ? <EyeFilled /> : <EyeInvisibleFilled />)} placeholder="Confirm Password" id="confirm-password" />
            </Form.Item>

            <Form.Item style={{ marginBottom: 0, paddingTop: '20px' }}>
              {!resetBtnLoader ? (
                <Button type="primary" className="btn btn-block btn-primary btn-no-focus" htmlType="submit">
                  Reset Password
                </Button>
              ) : (
                <Button type="primary" className="btn btn-block btn-primary-outline" htmlType="button">
                  Resetting...
                </Button>
              )}
            </Form.Item>

            <Form.Item style={{ paddingTop: '40px' }}>
              <Link to="/login" className="link link-primary text-center d-flex items-center gap-x-1">
                <img src="/assets/icons/arrow.svg" alt="Button Back" />
                <span>Back to Login</span>
              </Link>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Section>
  );
};

export default ResetPassword;
