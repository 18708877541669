import React from 'react';
import Modal from '../../../../components/modal/Modal';
import { useTranslation } from 'react-i18next';

export const SingleStatusDeactivate = ({ singleDeactiveProps, deactiveSingleModalFormRef, handleOnSubmit, setToggleSingleDeactive, deactivateBtnLoader }) => {
  const { t } = useTranslation();
  return (
    <Modal {...singleDeactiveProps} className='sidebar__modal--center' title=''>
      <form
        name='singleDeactive'
        ref={deactiveSingleModalFormRef}
        onSubmit={(event) =>
          handleOnSubmit({
            event: event,
            name: 'status',
            state: singleDeactiveProps,
          })
        }
        className='ant-form ant-form-horizontal'
      >
        <h2 className='text-center mb-4'>{t('areYouSureToDeactivateMembers')}</h2>
        <div className='form-group form-group-modal mb-4'>
          {!deactivateBtnLoader ? (
            <button data-cy='modal-bulk-deactive-submit-btn' type='submit' className='btn btn-primary-link'>
              <span className='text'>{t('yes')}</span>
            </button>
          ) : (
            <button type='button' className='btn btn-primary-link-outline'>
              <span className='text'> {singleDeactiveProps.getId.status === 'ACTIVE' ? `${t('deactivating')}...` : `${t('activating')}...`}</span>
            </button>
          )}

          <button data-cy='modal-bulk-deactive-cancel-btn' type='button' className='btn btn-primary' onClick={() => setToggleSingleDeactive(false)}>
            <span className='text'>{t('no')}</span>
          </button>
        </div>
      </form>
    </Modal>
  );
};
