import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../../context/useAuth';
import { SERVER_URL } from '../../../config/index';
import { Section, Main } from '../../../components/content';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { PERMISSIONS } from '../../../utils/permissions';
import SelectCustom from '../../../components/customSelect/CustomSelect';
import Loading from '../../../components/loading/Loading';
import Table from './Table';
import LoadingPlaceholder from '../../../components/loadingPlaceholder/LoadingPlaceholder';
import { getGroceryPermissions } from '../../../middleware';
import { notification } from 'antd';
import { DeleteBulkProducts } from './delete-bulk-products/DeleteBulkProducts';
import { DeleteProduct } from './delete-product/DeleteProduct';
import { ImportNetsuiteModal } from './import-netsuite-modal/ImportNetsuiteModal';

const options = [5, 10, 20, 50, 100];

const Products = () => {
  const { user } = useAuth();
  const location = useLocation();
  const [groceryPermissions, setGroceryPermissions] = useState([]);

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;
    if (user && token) {
      const decodeToken = jwtDecode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
          setGroceryPermissions(permissions.grocery);
        }
      }
    }
  }, [user, location]);

  // READ
  const [readData, setReadData] = useState([]);
  const [readDataRefetch, setReadDataRefetch] = useState(false);
  const [readDataLoading, setReadDataLoading] = useState(true);

  // SEARCH
  const [search, setSearch] = useState(null);
  const [searchForm, setSearchForm] = useState({ query: '' });
  const [filterStatus, setFilterStatus] = useState(null);

  const handleOnSubmitSearch = (e) => {
    e.preventDefault();
    setSearch(searchForm.query);
    setReadDataRefetch((prevState) => !prevState);
  };

  const handleOnChangeSearch = (value) => {
    setSearchForm({ query: value });
  };

  const handleOnClearSearch = () => {
    setSearch(null);
    setSearchForm({ query: '' });
    setReadDataRefetch((prevState) => !prevState);
  };

  // PAGE
  const [currentPage, setCurrentPage] = useState(1);
  const [limitPage, setLimitPage] = useState(10);

  // FILTER STATUS
  const handleStatusFilter = (value) => {
    setFilterStatus(value);
    setReadDataRefetch((prevState) => !prevState);
  };

  // FILTERS
  const [disabledButton, setDisabledButton] = useState(false);
  const handleDisabledButton = (status) => {
    setDisabledButton(status);
  };

  // Get selected rows id one or more
  const [checkedRow, setCheckedRow] = useState([]);
  const handleCheckedRows = (data) => {
    setCheckedRow(data);
  };

  const getReadData = useCallback(async () => {
    const { token } = user;

    const payload = {
      department: PERMISSIONS.grocery,
      searchTerm: search,
    };

    try {
      const response = await axios
        .post(`${SERVER_URL}/${AXIOS_API_CALL.getProducts}/${currentPage}/${limitPage}/null`, { ...payload }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          return res;
        })
        .catch((err) => console.error(err))
        .finally(
          setTimeout(() => {
            setReadDataLoading(false);
          }, 200)
        );

      return response;
    } catch (err) {}
  }, [user, limitPage, search, currentPage, filterStatus]);

  useEffect(() => {
    let isMounted = true;

    new Promise((resolve, reject) => {
      setReadDataLoading(true);
      setTimeout(() => {
        resolve(getReadData());
      }, 700);
    }).then((res) => {
      if (isMounted) {
        setReadData(res?.data);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [getReadData, readDataRefetch]);

  useEffect(() => {
    if (!!search) {
      setCurrentPage(1);
    }
  }, [search, setCurrentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [limitPage, setCurrentPage]);

  // FUNCTION
  function handleToggle(props) {
    const { name, state, data } = props;

    switch (name) {
      case 'create':
        state.onToggle(true);
        break;
      case 'update':
        state.onToggle(true);
        break;
      case 'deleteBulk':
        state.onToggle(true);
        break;
      case 'delete':
        state.onToggle(true);
        setDeleteFormId({ id: data });
        break;
      case 'importNetSuite':
        state.onToggle(true);
        break;
      default:
        console.warn('Default of: handleToggle function');
    }
  }

  async function onSubmit(props) {
    const { event, name, data } = props;
    event.preventDefault();
    const { token } = user;

    switch (name) {
      case 'delete':
        const deleteId = data.id;

        await axios
          .delete(`${SERVER_URL}/${AXIOS_API_CALL.deleteProduct}/${deleteId}`, { withCredentials: false, headers: { department: PERMISSIONS.grocery, Authorization: `Bearer ${token}` } })
          .then((res) => {
            if (res.status === 200) {
              setToggleDelete(false);
              setDeleteFormId({ id: '' });
              setReadDataRefetch((p) => !p);

              // notification
              notification.success({
                message: 'Product removed successfully.',
                placement: 'bottomLeft',
              });
            }
          })
          .catch((err) => {
            console.error('Error of delete single product:', err);
          })
          .finally(() => {});
        break;
      case 'deleteBulk':
        const payloadDeleteBulk = {
          products: checkedRow,
        };

        await axios
          .post(`${SERVER_URL}/${AXIOS_API_CALL.deleteProductBulk}`, { ...payloadDeleteBulk }, { withCredentials: false, headers: { department: PERMISSIONS.grocery, Authorization: `Bearer ${token}` } })
          .then((res) => {
            if (res.status === 201) {
              setToggleDeleteBulk(false);
              setReadDataRefetch((p) => !p);
              setCheckedRow([]);

              // notification
              notification.success({
                message: 'Products removed successfully.',
                placement: 'bottomLeft',
              });
            }
          })
          .catch((err) => {
            console.error('Error of delete bulk products:', err);
          })
          .finally(() => {});
        break;
      case 'importNetSuite':
        setShowImportNetSuiteLoader(true);
        await axios
          .post(`${SERVER_URL}/${AXIOS_API_CALL.importProductFromNetSuite}`, { department: PERMISSIONS.grocery }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
          .then((res) => {
            if (res.status === 200) {
              setReadDataRefetch((p) => !p);
              setToggleImportNetSuite(false);

              // notification
              notification.success({
                message: res.data.message,
                placement: 'bottomLeft',
              });
            }
            setTimeout(() => {
              setShowImportNetSuiteLoader(false);
            }, 700);
          })
          .catch((err) => {
            setShowImportNetSuiteLoader(false);
            setToggleImportNetSuite(false);

            console.error(err.message);

            // notification
            notification.warn({
              message: err.message,
              placement: 'bottomLeft',
            });
            console.error('Import from NetSuite error: ', err);
          })
          .finally(() => {});
        break;
      default:
        console.warn('Default of: onSubmit function');
    }
  }

  // CREATE
  const createModalFormRef = useRef(null);
  const [toggleCreate, setToggleCreate] = useState(false);
  const [createForm, setCreateForm] = useState({}); // data

  // UPDATE
  const updateModalFormRef = useRef(null);
  const [toggleUpdate, setToggleUpdate] = useState(false);
  const [updateFormId, setUpdateFormId] = useState({ id: '' });
  const [updateForm, setUpdateForm] = useState({}); // data

  // IMPORT FROM NETSUITE
  const importNetSuiteModalRef = useRef(null);
  const [toggleImportNetSuite, setToggleImportNetSuite] = useState(false);
  const [showImportNetSuiteLoader, setShowImportNetSuiteLoader] = useState(false);

  // DELETE BULK
  const deleteBlukModalFormRef = useRef(null);
  const [toggleDeleteBulk, setToggleDeleteBulk] = useState(false);
  const [deleteBulkFormId, setDeleteBulkFormId] = useState({ id: '' }); // data

  // DELETE SINGLE
  const deleteModalFormRef = useRef(null);
  const [toggleDelete, setToggleDelete] = useState(false);
  const [deleteFormId, setDeleteFormId] = useState({ id: '' }); // data

  // create
  const createProps = {
    active: toggleCreate,
    onToggle: setToggleCreate,
    formRef: createModalFormRef,
    data: createForm,
    onData: setCreateForm,
  };

  // update
  const updateProps = {
    active: toggleUpdate,
    onToggle: setToggleUpdate,
    formRef: updateModalFormRef,
    data: updateForm,
    onData: setUpdateForm,
  };

  // import NetSuite
  const importNetSuiteProps = {
    active: toggleImportNetSuite,
    onToggle: setToggleImportNetSuite,
    formRef: importNetSuiteModalRef,
  };

  // delete bulk
  const deleteBlukProps = {
    active: toggleDeleteBulk,
    onToggle: setToggleDeleteBulk,
    formRef: deleteBlukModalFormRef,
  };

  // delete
  const deleteProps = {
    active: toggleDelete,
    onToggle: setToggleDelete,
    formRef: deleteModalFormRef,
  };

  // PROPS
  const tableProps = {
    handleDisabledButton: handleDisabledButton,
    handleCheckedRows: handleCheckedRows,
  };

  const tableActionsProps = {
    handleToggle: handleToggle,

    updateActions: updateProps,
    deleteActions: deleteProps,

    location: location,
    groceryPermissions: groceryPermissions,
    getGroceryPermissions: getGroceryPermissions,
  };

  const tableFiltersProps = {
    handleStatusFilter: handleStatusFilter,
  };

  // PAGINATION
  const paginationProps = {
    current: currentPage,
    setCurrentPage: setCurrentPage,
    limitPage: limitPage,
    buttonLimit: 3,
    pagesCount: readData.data?.pagesCount,
    totalCount: readData.data?.totalCount,
  };

  const deleteBulkProductsProps = { deleteBlukProps, onSubmit, setToggleDeleteBulk, checkedRow };
  const deleteProductProps = { deleteProps, onSubmit, setToggleDelete, deleteFormId };
  const importNetsuiteModalProps = { importNetSuiteProps, onSubmit, showImportNetSuiteLoader, setToggleImportNetSuite };

  return (
    <>
      <h2 className="page-title">Products</h2>
      <Section className="section__wrapper section__products">
        <header className="section__header">
          {!readDataLoading ? (
            <div className="filter">
              <span style={{ color: '#333', fontWeight: '500' }}>Show:</span>
              <SelectCustom options={options} label={'Items'} limit={limitPage} setLimit={setLimitPage} setRefetch={setReadDataRefetch} loading={readDataLoading} />
            </div>
          ) : (
            <LoadingPlaceholder style={{ width: '155.5px', height: '50px' }} />
          )}

          {!readDataLoading ? (
            <div className="search">
              <form className="search-form" onSubmit={(e) => handleOnSubmitSearch(e)} data-cy="search-roles">
                <div className="form-group m-0">
                  <span className="icon icon-search">
                    <img src="/assets/icons/search.svg" alt="Search" title="Search" />
                  </span>
                  <input value={searchForm.query || ''} type="text" data-cy="search-input-field" className="input" placeholder="Search" onChange={(e) => handleOnChangeSearch(e.target.value)} />
                  <span className={`icon icon-close ${!!searchForm.query && searchForm.query.length > 0 ? 'isActive' : ''}`} onClick={() => handleOnClearSearch()}>
                    <img src="/assets/icons/times.svg" alt="Clear" title="Clear" />
                  </span>
                </div>
                <button className="form-submit" data-cy="search-submit-btn">
                  Search
                </button>
              </form>
            </div>
          ) : (
            <LoadingPlaceholder style={{ width: '100%', maxWidth: '850px', height: '50px' }} />
          )}

          <div className="actions">
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.createProducts) && (
              <>
                {!readDataLoading ? (
                  <button style={{ opacity: '.2' }} disabled type="button" onClick={() => handleToggle({ name: 'importNetSuite', state: importNetSuiteProps })} data-cy="import-netsuite-btn" className="btn btn-primary-outline pl-4 pr-4">
                    <span className="text">Import From NetSuite</span>
                  </button>
                ) : (
                  <LoadingPlaceholder style={{ width: '238px', height: '50px' }} />
                )}
              </>
            )}

            {getGroceryPermissions(groceryPermissions, PERMISSIONS.deleteProducts) && (
              <>
                {!readDataLoading ? (
                  <button type="button" onClick={() => handleToggle({ name: 'deleteBulk', state: deleteBlukProps })} disabled={disabledButton ? false : true} data-cy="delete-products-btn" className="btn btn-primary-outline pl-4 pr-4">
                    <span className="text">Delete Products</span>
                  </button>
                ) : (
                  <LoadingPlaceholder style={{ width: '189px', height: '50px' }} />
                )}
              </>
            )}

            {getGroceryPermissions(groceryPermissions, PERMISSIONS.createProducts) && (
              <>
                {!readDataLoading ? (
                  <Link to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.createProduct}`} className="btn btn-primary pl-4 pr-4 d-flex items-center">
                    <span className="text d-flex items-center align-center">Add Product</span>
                  </Link>
                ) : (
                  <LoadingPlaceholder style={{ width: '162.5px', height: '50px' }} />
                )}
              </>
            )}
          </div>
        </header>

        {/* Main content */}
        <Main className="section__content section__content relative min-h-table-content">{readDataLoading ? <Loading /> : <Table data={readData.data?.Data} {...tableProps} {...tableActionsProps} {...tableFiltersProps} pagination={paginationProps} />}</Main>

        {/* Delete Bulk Modal */}
        <DeleteBulkProducts {...deleteBulkProductsProps} />

        {/* Delete Modal */}
        <DeleteProduct {...deleteProductProps} />

        {/* Import NetSuite Modal */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.createProducts) && <ImportNetsuiteModal {...importNetsuiteModalProps} />}
      </Section>
    </>
  );
};

export default Products;
