import React, { memo } from 'react';
import { Tabs } from 'antd';
import Section3En from '../tabs/en/Section3En';
import Section3Cg from '../tabs/cg/Section3Cg';
import CheckboxCms from '../../../../components/checkbox-cms';

const CmsForCompaniesSection3 = ({ onChangeTabSection3, handleInputChangeFormSection3, formSection3, fcImages, fcImagesPreview, setFcImages, fcImagesErrors, formSection3Errors }) => {
  const { TabPane } = Tabs;

  const sectionProps = {
    handleInputChangeFormSection3,
    formSection3,
    fcImages,
    fcImagesPreview,
    setFcImages,
    onChangeTabSection3,
    fcImagesErrors,
    formSection3Errors,
  };
  return (
    <>
      <Tabs defaultActiveKey="1" onChange={onChangeTabSection3}>
        <TabPane key="1" tab="EN">
          <Section3En {...sectionProps} />
        </TabPane>
        <TabPane key="2" tab="CG">
          <Section3Cg {...sectionProps} />
        </TabPane>
      </Tabs>
      <CheckboxCms inputName="section3_hide" isChecked={formSection3.hide} handleChange={handleInputChangeFormSection3} label="Hide entire section on website" tooltipText="Checking this will hide entire section on website" />
    </>
  );
};
export default memo(CmsForCompaniesSection3);
