import React from 'react';
import SidebarModal from '../../../../components/sidebarModal/SidebarModal';
import Loading from '../../../../components/loading/Loading';
import { useTranslation } from 'react-i18next';

import { Form } from 'antd';

export const UpdateAttributesAndUnit = ({ createUnitOnUpdate, updateProps, updateLoadingForm, updateFormRef, handleOnSubmit, updateFormError, updateForm, handleOnBlur, handleOnChange, updateUnit, handleOnRemoveUnit, toggleUpdateUnit, setToggleUpdateUnit, setToggleUpdate, updateCreateUnitFormRef, createUnitBtnLoader, editBtnLoader, updateCreateUnitProps }) => {
  const { t } = useTranslation();
  return (
    <SidebarModal {...updateProps} className='sidebar__modal--right' title={t('updateDiscipline')}>
      {!updateLoadingForm ? (
        <>
          <Form ref={updateFormRef} className='ant-form ant-form-horizontal mb-2' onFinish={(event) => handleOnSubmit({ event: event, form: updateProps, type: 'update' })}>
            <div className='form-group relative'>
              <label className='label mb-1 d-block' htmlFor='updateName'>
                <b className='required'>{t('discipline')}:</b>
              </label>

              <input disabled type='text' placeholder='Name' name='updateName' id='updateName' data-cy='update-attribute-name-input' value={updateForm.name || ''} onChange={(event) => handleOnChange(event)} onBlur={(event) => handleOnBlur(event)} className={`input ${updateFormError.name ? 'input-error' : ''}`} />

              <p data-cy='modal-update-attribute-name-error' className={`error__onblur ${updateFormError.name ? 'error' : ''}`}>
                * {t('requiredField')}
              </p>
            </div>

            <div>
              <label className='label mb-1 d-block'>
                <b>{t('attributes')}:</b>
              </label>
            </div>

            <div className='form-group form-group-units relative'>
              <div className='units' data-cy='removed-update-attribute-unit-btn'>
                {updateUnit && updateUnit.length > 0 ? (
                  updateUnit.map((item) => {
                    return (
                      <div className='unit-item' key={item._id} data-cy='update-unit-item-btn' onClick={() => handleOnRemoveUnit({ name: 'update-attribute-unit', data: item })}>
                        <span className='icon'>
                          <img src='/assets/icons/times-small.svg' alt='Close' />
                        </span>
                        <span className='text'>{item.name}</span>
                      </div>
                    );
                  })
                ) : (
                  <p className='units-area'>{t('attributes')}</p>
                )}
              </div>

              {/* {toggleUpdateUnit ? (
                <button type="button" className="btn btn-default" data-cy="toggle-update-minus-attribute-unit-btn" onClick={() => setToggleUpdateUnit(false)}>
                  <span className="icon">
                    <img alt="Create new Attribute" src="/assets/icons/minus.svg" />
                  </span>
                </button>
              ) : (
                <button type="button" className="btn btn-default" data-cy="toggle-update-plus-attribute-unit-btn" onClick={() => setToggleUpdateUnit(true)}>
                  <span className="icon">
                    <img alt="Create new Attribute" src="/assets/icons/plus.svg" />
                  </span>
                </button>
              )} */}
            </div>

            {!toggleUpdateUnit && (
              <div className='form-group form-group-sidebar'>
                <button data-cy='modal-update-attribute-unit-cancel-btn' type='button' className='btn btn-primary-link' onClick={() => setToggleUpdate(false)}>
                  <span className='text'>{t('cancel')}</span>
                </button>

                {!editBtnLoader ? (
                  <button data-cy='modal-update-attribute-unit-submit-btn' type='submit' className='btn btn-primary'>
                    <span className='text'>{t('saveChanges')}</span>
                  </button>
                ) : (
                  <button type='button' className='btn btn-primary-outline'>
                    <span className='text'>{t('saving')}...</span>
                  </button>
                )}
              </div>
            )}
          </Form>

          {/* Create Unit in Attribute in Update */}
          {toggleUpdateUnit && (
            <Form ref={updateCreateUnitFormRef} className='ant-form ant-form-horizontal mb-2 p-2' onFinish={(event) => handleOnSubmit({ event: event, form: updateCreateUnitProps, type: 'update-create-unit' })} style={{ backgroundColor: '#f2f2f2', borderRadius: '6px' }}>
              <div className='form-group relative'>
                <label className='label mb-1 d-block' htmlFor='updateCreateUnitName'>
                  <b className='required'>Name:</b>
                </label>

                <input type='text' placeholder='Name' name='updateCreateUnitName' id='updateCreateUnitName' value={createUnitOnUpdate.name || ''} data-cy='update-create-attribute-unit-name-input' onChange={(event) => handleOnChange(event)} onBlur={(event) => handleOnBlur(event)} className={`input ${updateFormError.unit ? 'input-error' : ''}`} />

                <p data-cy='modal-update-create-unit-name-error' className={`error__onblur ${updateFormError.unit ? 'error' : ''}`}>
                  * This field is required!
                </p>
              </div>

              <div className='form-group form-group-sidebar'>
                <button data-cy='modal-update-create-unit-cancel-btn' type='button' className='btn btn-primary-link' onClick={() => setToggleUpdateUnit(false)}>
                  <span className='text'>Cancel</span>
                </button>

                {!createUnitBtnLoader ? (
                  <button data-cy='modal-update-create-unit-submit-btn' type='submit' className='btn btn-primary'>
                    <span className='text'>Create Attribute</span>
                  </button>
                ) : (
                  <button type='button' className='btn btn-primary-outline'>
                    <span className='text'>Creating Attribute...</span>
                  </button>
                )}
              </div>
            </Form>
          )}
        </>
      ) : (
        <Loading />
      )}
    </SidebarModal>
  );
};
