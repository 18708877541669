import React, { useEffect, useState, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { SERVER_URL } from "../../../config/index";
import { Button, notification } from "antd";
import { useAuth } from "../../../context/useAuth";
import Table from "./Table";
import SelectCustom from "../../../components/customSelect/CustomSelect";
import Loading from "../../../components/loading/Loading";
import LoadingPlaceholder from "../../../components/loadingPlaceholder/LoadingPlaceholder";
import { Section, Main } from "../../../components/content";
import { notificationMessages } from "../../../helpers/notificationMessages";
import { jwtDecode } from "jwt-decode";
import { getGroceryPermissions } from "../../../middleware";
import { AXIOS_API_CALL } from "../../../utils/endpoint";
import { PERMISSIONS } from "../../../utils/permissions";
import { CreateRole } from "./create-role/CreateRole";
import { UpdateRole } from "./update-role/UpdateRole";
import { DeleteSingleRole } from "./delete-single-role/DeleteSingleRole";
import { DeleteMultiRole } from "./delete-multi-role/DeleteMultiRole";

const options = [5, 10, 20, 50, 100];

const Roles = () => {
  const { user } = useAuth();
  const location = useLocation();
  const [groceryPermissions, setGroceryPermissions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limitPage, setLimitPage] = useState(10);
  const [list, setList] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [loading, setLoading] = useState(true);

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;
    if (user && token) {
      const decodeToken = jwtDecode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (
        location.pathname.includes(
          `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`
        )
      ) {
        if (
          Object.keys(permissions).some((permission) =>
            permission.includes(PERMISSIONS.grocery)
          )
        ) {
          setGroceryPermissions(permissions.grocery);
        }
      }
    }
  }, [user, location]);

  // LOADER PROTECTION
  const [createBtnLoader, setCreateBtnLoader] = useState(false);
  const [editBtnLoader, setEditBtnLoader] = useState(false);
  const [deleteBtnLoader, setDeleteBtnLoader] = useState(false);
  const [deleteBlkBtnLoader, setDeleteBlkBtnLoader] = useState(false);

  // SEARCH
  const [search, setSearch] = useState(null);
  const [searchForm, setSearchForm] = useState({ query: "" });

  const handleOnSubmitSearch = (e) => {
    e.preventDefault();
    setSearch(searchForm.query);
    setRefetch((prevState) => !prevState);
  };

  const handleOnChangeSearch = (value) => {
    setSearchForm({ query: value });
  };

  const handleOnClearSearch = () => {
    setSearch(null);
    setSearchForm({ query: "" });
    setRefetch((prevState) => !prevState);
  };

  const [loadingGrocery, setLoadingGrocery] = useState(false);

  const getTableData = useCallback(async () => {
    const { token } = user;

    try {
      setLoading(true);

      await axios
        .post(
          `${SERVER_URL}/${
            AXIOS_API_CALL.getRoles
          }/${currentPage}/${limitPage}/${search || null}`,
          { department: PERMISSIONS.grocery },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setList(res?.data);
        })
        .catch((err) => console.error(err))
        .finally(
          setTimeout(() => {
            setLoading(false);
          }, 700)
        );
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  }, [user, currentPage, limitPage, search]);

  // FETCH DATA FOR `TABLE`
  useEffect(() => {
    getTableData();
  }, [refetch, getTableData]);

  // RESET CURRENT PAGE TO FIRST ON CHANGE FILTERS
  useEffect(() => {
    if (!!search) {
      setCurrentPage(1);
    }
  }, [search, setCurrentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [limitPage, setCurrentPage]);

  // GROCERY CHECKBOX FOR FORM
  const [grocery, setGrocery] = useState([]);

  useEffect(() => {
    const { token } = user;

    try {
      axios
        .post(
          `${SERVER_URL}/${AXIOS_API_CALL.getPermissions}`,
          { department: PERMISSIONS.grocery },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          let data = res.data.message.sort((a, b) =>
            a._id === b._id ? 0 : a._id < b._id ? -1 : 1
          );

          setGrocery(data);
        })
        .catch((err) => console.error(err))
        .finally(setTimeout(() => {}, 700));
    } catch (err) {
      console.error(err);
    }
  }, [user]);

  // Button (deactive, delete...) in section header - change status if checked one or more to the Enable button
  const [disabledButton, setDisabledButton] = useState(false);
  const handleDisabledButton = (status) => {
    setDisabledButton(status);
  };

  // Get selected rows id one or more
  const [checkedRow, setCheckedRow] = useState([]);
  const handleCheckedRows = (data) => {
    setCheckedRow(data);
  };

  // TABLE
  const tableProps = {
    handleDisabledButton: handleDisabledButton,
    handleCheckedRows: handleCheckedRows,
  };

  // FORM
  // Refs
  const createFormRef = useRef(null);
  const updateFormRef = useRef(null);
  const deleteSingleFormRef = useRef(null);
  const deleteMultiFormRef = useRef(null);

  // State
  const [toggleCreate, setToggleCreate] = useState(false);
  const [toggleUpdate, setToggleUpdate] = useState(false);
  const [toggleSingleDelete, setToggleSingleDelete] = useState(false);
  const [toggleMultiDelete, setToggleMultiDelete] = useState(false);

  // CREATE
  const [createRole, setCreateRole] = useState({
    name: "",
    roles: [],
  });

  // UPDATE
  const [updateRoleId, setUpdateRoleId] = useState({ id: "" });
  const [updateRole, setUpdateRole] = useState({
    roleName: "",
    permissions: [],
  });

  const [updateRoleGroceryData, setUpdateRoleGroceryData] = useState([]);

  // DELETE
  const [deleteSingleRole, setDeleteSingleRole] = useState({ id: "" });

  const [isCheckboxGrocery, setIsCheckboxGrocery] = useState([]);
  const [rowCheckedGrocery, setRowCheckedGrocery] = useState([]);
  const [isCheckboxUpdateGrocery, setIsCheckboxUpdateGrocery] = useState([]);

  const handleFormValidation = (form, status = null) => {
    let findinputElements = form.current.querySelectorAll('input[type="text"]');

    if (!findinputElements) {
      return;
    }

    for (const [key] in findinputElements) {
      let checkFindInput = findinputElements[key];

      if (checkFindInput !== undefined) {
        if (findinputElements[key]) {
          let name = findinputElements[key].name;

          if (status === "length_error") {
            showErrorMessages(findinputElements[key], name);
          }

          if (status === "status_error") {
            showErrorMessages(findinputElements[key], name);
          }

          if (status === "length_success") {
            hideErrorMessages(findinputElements[key], name);
          }
        }
      }
    }
  };

  useEffect(() => {
    const roleId = updateRoleId.id;
    const roleLength = roleId.length;
    const { token } = user;

    if (roleLength > 0) {
      try {
        setLoadingGrocery(true);
        axios
          .post(
            `${SERVER_URL}/${AXIOS_API_CALL.getRole}/${roleId}`,
            { department: PERMISSIONS.grocery },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            setUpdateRoleGroceryData(res.data.message);
            setTimeout(() => {
              setLoadingGrocery(false);
            }, 700);
          })
          .catch((err) => {
            console.error(err);
            setLoadingGrocery(false);
          });
      } catch (err) {
        console.error(err);
      }
    }
  }, [user, updateRoleId]);

  useEffect(() => {
    if (typeof updateRoleGroceryData.permissions !== "undefined") {
      let getUpdateRoleGroceryData = [];

      for (const grocery in updateRoleGroceryData?.permissions) {
        const data = updateRoleGroceryData?.permissions[grocery];

        for (const d of data) {
          if (d.checked === true) {
            getUpdateRoleGroceryData.push(d._id);
          }
        }
      }

      setIsCheckboxUpdateGrocery(getUpdateRoleGroceryData);
    }
  }, [updateRoleGroceryData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { token } = user;

    switch (e.target.name) {
      case "create":
        setCreateBtnLoader(true);
        try {
          const createDate = {
            roleName: createRole.name,
            permissions: createRole.roles,
            department: PERMISSIONS.grocery,
          };

          if (createRole.name.length < 3) {
            handleFormValidation(createFormRef, "length_error");
            setCreateBtnLoader(false);
            return;
          } else {
            await axios.post(
              `${SERVER_URL}/${AXIOS_API_CALL.createRole}`,
              { ...createDate },
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${token}` },
              }
            );

            handleFormValidation(createFormRef, "length_success");
            setRefetch((prevState) => !prevState);

            setCreateRole({
              name: "",
              roles: [],
            });

            setIsCheckboxGrocery([]);
            setRowCheckedGrocery([]);

            setCurrentPage(1);
            setToggleCreate(false);

            createFormRef.current.reset();

            notification.success({
              message: notificationMessages.successCreateRole,
              placement: "bottomLeft",
            });
            setTimeout(() => {
              setCreateBtnLoader(false);
            }, 700);
          }
        } catch (err) {
          if (!err.status) {
            notification.error({
              message: notificationMessages.failedCreateRole,
              placement: "bottomLeft",
            });
          } else {
            handleFormValidation(createFormRef, "status_error");
          }
          setCreateBtnLoader(false);
        }
        break;
      case "update":
        setEditBtnLoader(true);
        try {
          const roleId = updateRoleId.id;
          const updateData = {
            id: roleId,
            roleName: updateRole.roleName,
            permissions: updateRole.roles,
            department: PERMISSIONS.grocery,
          };

          if (updateRole.roleName.length < 3) {
            handleFormValidation(updateFormRef, "length_error");
            setEditBtnLoader(false);
            return;
          } else {
            await axios.post(
              `${SERVER_URL}/${AXIOS_API_CALL.updateRole}`,
              { ...updateData },
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${token}` },
              }
            );

            handleFormValidation(updateFormRef, "length_success");
            setRefetch((prevState) => !prevState);
            setUpdateRole({
              roleName: "",
              permissions: [],
            });

            setIsCheckboxUpdateGrocery([]);

            setCurrentPage(1);
            setToggleUpdate(false);

            updateFormRef.current.reset();

            notification.success({
              message: notificationMessages.successUpdateRole,
              placement: "bottomLeft",
            });
            setTimeout(() => {
              setEditBtnLoader(false);
            }, 700);
          }
        } catch (err) {
          handleFormValidation(updateFormRef, "status_error");
          console.error(err);
          setEditBtnLoader(false);
        }
        break;
      case "delete_single":
        setDeleteBtnLoader(true);
        try {
          const roleId = deleteSingleRole.id;
          await axios.post(
            `${SERVER_URL}/${AXIOS_API_CALL.deleteRole}/${roleId}`,
            { department: AXIOS_API_CALL.grocery },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          setRefetch((prevState) => !prevState);
          setCurrentPage(1);
          setToggleSingleDelete(false);

          // reset form for delete role
          deleteSingleFormRef.current.reset();

          // notification success for delete role
          notification.success({
            message: notificationMessages.successDeleteRole,
            placement: "bottomLeft",
          });
          setTimeout(() => {
            setDeleteBtnLoader(false);
          }, 700);
        } catch (err) {
          console.error(err);

          setRefetch((prevState) => !prevState);
          setCurrentPage(1);
          setToggleSingleDelete(false);

          // reset form for delete role
          deleteSingleFormRef.current.reset();

          // notification error for delete role
          notification.error({
            message: `Don't delete Super Admin role!`,
            placement: "bottomLeft",
          });
          setDeleteBtnLoader(false);
        }
        break;
      case "delete_multi":
        setDeleteBlkBtnLoader(true);
        try {
          const rolesId = checkedRow;

          await axios.post(
            `${SERVER_URL}/${AXIOS_API_CALL.bulkDeleteRole}`,
            { roles: rolesId, department: PERMISSIONS.grocery },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          // reset form for delete multi role
          deleteMultiFormRef.current.reset();

          setRefetch((prevState) => !prevState);
          setCurrentPage(1);
          setToggleMultiDelete(false);

          // notification success for delete role
          notification.success({
            message: notificationMessages.successDeleteMultiRole,
            placement: "bottomLeft",
          });
          setTimeout(() => {
            setDeleteBlkBtnLoader(false);
          }, 700);
        } catch (err) {
          console.error(err);
          setDeleteBlkBtnLoader(false);
        }
        break;
      default:
        console.error("Error toggle switch");
    }
  };

  const handleOnChange = (e) => {
    e.target.name === "name_role" &&
      setCreateRole({ ...createRole, name: e.target.value });
    e.target.name === "name_update_role" &&
      setUpdateRole({ ...updateRole, roleName: e.target.value });
  };

  const handleValidation = (e) => {
    if (e.target.name === "name_role")
      e.target.value.length < 3
        ? showErrorMessages(e.target, e.target.name)
        : hideErrorMessages(e.target, e.target.name);
    if (e.target.name === "name_update_role")
      e.target.value.length < 3
        ? showErrorMessages(e.target, e.target.name)
        : hideErrorMessages(e.target, e.target.name);
  };

  const showErrorMessages = (elem, name) => {
    elem.style.borderColor = "#ff4d4f";
    document.querySelector(`.${name}-error__required`).style.opacity = "1";
    document.querySelector(`.${name}-error__required`).style.visibility =
      "visible";
    document.querySelector(`.${name}-error__required`).style.bottom = "-20px";
  };

  const hideErrorMessages = (elem, name) => {
    elem.style.borderColor = "";
    document.querySelector(`.${name}-error__required`).style.opacity = "0";
    document.querySelector(`.${name}-error__required`).style.visibility =
      "hidden";
    document.querySelector(`.${name}-error__required`).style.bottom = "-10px";
  };

  // CHECKBOX
  const handleSelectCheckbox = (e) => {
    const { value, name, checked } = e.target;

    if (name.includes("update_grocery_roles_")) {
      setIsCheckboxUpdateGrocery([...isCheckboxUpdateGrocery, value]);
      if (!checked) {
        setIsCheckboxUpdateGrocery(
          isCheckboxUpdateGrocery.filter((item) => item !== value)
        );
      }
    }

    if (name.includes("grocery_checkbox_")) {
      setIsCheckboxGrocery([...isCheckboxGrocery, value]);

      if (!checked) {
        setIsCheckboxGrocery(
          isCheckboxGrocery.filter((item) => item !== value)
        );
      }
    }
  };

  const handleSelectCheckboxRows = (e) => (data) => {
    const { value, name } = e.target;

    if (name.includes("grocery_")) {
      const status = data.map((item) => item._id);
      const result = status.some((check) => isCheckboxGrocery.includes(check));

      if (result) {
        let check = isCheckboxGrocery;
        check = check.filter((item) => !status.includes(item));

        let row = rowCheckedGrocery;
        row = row.filter((item) => !value.includes(item));

        setIsCheckboxGrocery(check);
        setRowCheckedGrocery(row);
      } else {
        setIsCheckboxGrocery([...isCheckboxGrocery, ...status]);
        setRowCheckedGrocery([...rowCheckedGrocery, value]);
      }
    }
  };

  const handleToggleSelectAll = (e) => {
    if (e.target.name === "all_update_grocery") {
      let allRowsGrocery = grocery.map((item) => {
        return `update_grocery_${item._id}`;
      });

      let allValuesGrocery = [];

      for (const allCheckboxes of grocery) {
        let checkbox = allCheckboxes.permissions;

        for (const [index] in checkbox) {
          allValuesGrocery.push(checkbox[index]._id);
        }
      }

      if (e.target.checked) {
        setIsCheckboxGrocery(allValuesGrocery);
        setRowCheckedGrocery(allRowsGrocery);
      } else {
        setIsCheckboxGrocery([]);
        setRowCheckedGrocery([]);
      }
    }

    if (e.target.name === "all_grocery") {
      let allRowsGrocery = grocery.map((item) => {
        return `grocery_${item._id}`;
      });

      let allValuesGrocery = [];

      for (const allCheckboxes of grocery) {
        let checkbox = allCheckboxes.permissions;

        for (const [index] in checkbox) {
          allValuesGrocery.push(checkbox[index]._id);
        }
      }

      if (e.target.checked) {
        setIsCheckboxGrocery(allValuesGrocery);
        setRowCheckedGrocery(allRowsGrocery);
      } else {
        setIsCheckboxGrocery([]);
        setRowCheckedGrocery([]);
      }
    }
  };

  // REMOVE VALUE FROM CHECKBOX
  useEffect(() => {
    if (!!toggleUpdate) {
      setIsCheckboxGrocery([]);
    }
  }, [toggleUpdate]);

  useEffect(() => {
    if (!!toggleCreate) {
      setIsCheckboxGrocery([]);
    }
  }, [toggleCreate]);

  const handleToggle = (props) => {
    const { name, data } = props; // status in options

    switch (name) {
      case "create":
        setToggleCreate((prevState) => !prevState);
        break;
      case "update":
        setToggleUpdate((prevState) => !prevState);
        setUpdateRoleId({ id: data });
        break;
      case "delete":
        setToggleSingleDelete((prevState) => !prevState);
        setDeleteSingleRole({ id: data });
        break;
      case "deleteMulti":
        setToggleMultiDelete((prevState) => !prevState);
        break;
      default:
        console.error("handleToggle ---- error");
    }
  };

  // CREATE GROCERY
  const [createRoleGroceryNew, setCreateRoleGroceryNew] = useState([]);
  useEffect(() => {
    if (toggleCreate && isCheckboxGrocery && isCheckboxGrocery.length > 0) {
      setCreateRoleGroceryNew(isCheckboxGrocery);
    }
  }, [
    toggleCreate,
    createRoleGroceryNew,
    setCreateRoleGroceryNew,
    isCheckboxGrocery,
  ]);

  useEffect(() => {
    if (!!toggleCreate) {
      if (createRoleGroceryNew && createRoleGroceryNew.length > 0) {
        setCreateRole((c) => {
          return { ...c, roles: createRoleGroceryNew };
        });
      }
    }
  }, [toggleCreate, createRoleGroceryNew]);

  // UPDATE GROCERY
  const [updateRoleGorceryNew, setUpdateRoleGroceryNew] = useState([]);
  useEffect(() => {
    if (toggleUpdate) {
      setUpdateRoleGroceryNew(isCheckboxUpdateGrocery);
    }
  }, [
    toggleUpdate,
    isCheckboxUpdateGrocery,
    setUpdateRoleGroceryNew,
    updateRoleGorceryNew,
  ]);

  useEffect(() => {
    if (!!toggleUpdate) {
      if (updateRoleGorceryNew && updateRoleGorceryNew.length > 0) {
        setUpdateRole((u) => {
          return { ...u, roles: updateRoleGorceryNew };
        });
      }
    }
  }, [toggleUpdate, updateRoleGorceryNew]);

  useEffect(() => {
    if (toggleUpdate) {
      const roleName = updateRoleGroceryData?.roleName;

      setUpdateRole((r) => {
        return { ...r, roleName: roleName };
      });
    }
  }, [toggleUpdate, updateRoleGroceryData]);

  // PROPS
  const createProps = {
    active: toggleCreate,
    onToggle: setToggleCreate,
    formRef: createFormRef,
  };

  const updateProps = {
    active: toggleUpdate,
    onToggle: setToggleUpdate,
    formRef: updateFormRef,
    loading: loadingGrocery,
  };

  const deleteSingleProps = {
    active: toggleSingleDelete,
    onToggle: setToggleSingleDelete,
    formRef: deleteSingleFormRef,
  };

  const deleteMultiProps = {
    active: toggleMultiDelete,
    onToggle: setToggleMultiDelete,
    formRef: deleteMultiFormRef,
  };

  const tableActionsProps = {
    user: user,
    // custom for this table - always changes for next implementation
    handleToggle: handleToggle,
    location: location,
    groceryPermissions: groceryPermissions,
    getGroceryPermissions: getGroceryPermissions,
  };

  const paginationProps = {
    current: currentPage,
    setCurrentPage: setCurrentPage,
    limitPage: limitPage,
    buttonLimit: 3,
    pageCount: list.data?.pagesCount,
    totalCount: list.data?.totalCount,
  };

  const createRoleProps = {
    createProps,
    handleSubmit,
    createFormRef,
    handleOnChange,
    handleValidation,
    handleToggleSelectAll,
    grocery,
    rowCheckedGrocery,
    handleSelectCheckboxRows,
    handleSelectCheckbox,
    isCheckboxGrocery,
    createBtnLoader,
    setToggleCreate,
    createRole,
  };

  const updateRoleProps = {
    updateProps,
    loadingGrocery,
    updateFormRef,
    handleSubmit,
    updateRoleGroceryData,
    handleOnChange,
    handleValidation,
    handleToggleSelectAll,
    handleSelectCheckboxRows,
    setToggleUpdate,
    editBtnLoader,
    handleSelectCheckbox,
  };

  const deleteSingleRoleProps = {
    deleteSingleProps,
    deleteSingleFormRef,
    handleSubmit,
    deleteBtnLoader,
    setToggleSingleDelete,
  };

  const deleteMultiRoleProps = {
    deleteMultiProps,
    deleteMultiFormRef,
    handleSubmit,
    setToggleMultiDelete,
    deleteBlkBtnLoader,
  };

  return (
    <>
      <h2 className="page-title">Roles</h2>

      <Section className="section section__wrapper section__roles">
        {/* Main Header */}
        <header className={`section__header`}>
          {!loading ? (
            <div className="filter">
              <span style={{ color: "#333", fontWeight: "500" }}>Show:</span>
              <SelectCustom
                options={options}
                label={"Items"}
                limit={limitPage}
                setLimit={setLimitPage}
                setRefetch={setRefetch}
                loading={loading}
              />
            </div>
          ) : (
            <LoadingPlaceholder style={{ width: "155.5px", height: "50px" }} />
          )}

          {!loading ? (
            <div className="search">
              <form
                className="search-form"
                onSubmit={(e) => handleOnSubmitSearch(e)}
                data-cy="search-roles"
              >
                <div className="form-group m-0">
                  <span className="icon icon-search">
                    <img
                      src="/assets/icons/search.svg"
                      alt="Search"
                      title="Search"
                      data-cy="search-input-field"
                    />
                  </span>
                  <input
                    value={searchForm.query || ""}
                    type="text"
                    className="input"
                    placeholder="Search"
                    onChange={(e) => handleOnChangeSearch(e.target.value)}
                  />
                  <span
                    className={`icon icon-close ${
                      !!searchForm && searchForm.query.length > 0
                        ? "isActive"
                        : ""
                    }`}
                    onClick={() => handleOnClearSearch()}
                  >
                    <img
                      src="/assets/icons/times.svg"
                      alt="Clear"
                      title="Clear"
                    />
                  </span>
                </div>
                <button className="form-submit" data-cy="search-submit-btn">
                  Search
                </button>
              </form>
            </div>
          ) : (
            <LoadingPlaceholder
              style={{ width: "100%", maxWidth: "850px", height: "50px" }}
            />
          )}

          <div className="actions">
            {location &&
              location.pathname.includes(
                `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`
              ) && (
                <>
                  {getGroceryPermissions(
                    groceryPermissions,
                    PERMISSIONS.createRoles
                  ) && (
                    <>
                      {!loading ? (
                        <Button
                          type="button"
                          htmlType="button"
                          onClick={() => handleToggle({ name: "create" })}
                          className="btn btn-primary pl-4 pr-4"
                          data-cy="create-roles-btn"
                        >
                          <span className="text">Create Role</span>
                        </Button>
                      ) : (
                        <LoadingPlaceholder
                          style={{ width: "144.5px", height: "50px" }}
                        />
                      )}
                    </>
                  )}

                  {getGroceryPermissions(
                    groceryPermissions,
                    PERMISSIONS.deleteRoles
                  ) && (
                    <>
                      {!loading ? (
                        <Button
                          type="button"
                          htmlType="button"
                          onClick={() => handleToggle({ name: "deleteMulti" })}
                          className="btn btn-primary-outline pl-4 pr-4"
                          disabled={disabledButton ? false : true}
                          data-cy="bulk-delete-roles-btn"
                        >
                          <span className="text">Delete Roles</span>
                        </Button>
                      ) : (
                        <LoadingPlaceholder
                          style={{ width: "144.5px", height: "50px" }}
                        />
                      )}
                    </>
                  )}
                </>
              )}
          </div>
        </header>

        {/* Main Content */}
        <Main className="section__content relative min-h-table-content">
          {loading ? (
            <Loading />
          ) : (
            <Table
              data={list.data?.Data}
              {...tableProps}
              {...tableActionsProps}
              pagination={paginationProps}
            />
          )}
        </Main>

        {/* Create Role */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.createRoles) && (
          <CreateRole {...createRoleProps} />
        )}

        {/* Update Role */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.updateRoles) && (
          <UpdateRole {...updateRoleProps} />
        )}

        {/* Delete Single Role */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.deleteRoles) && (
          <DeleteSingleRole {...deleteSingleRoleProps} />
        )}

        {/* Delete Multi Role */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.deleteRoles) && (
          <DeleteMultiRole {...deleteMultiRoleProps} />
        )}
      </Section>
    </>
  );
};

export default Roles;
