import React, { useState, useEffect } from 'react';
import Loading from '../../../../../components/loading/Loading';
import SidebarModal from '../../../../../components/sidebarModal/SidebarModal';
import { Select, Input, Button, Form, Row, Col, Checkbox } from 'antd';

const { TextArea } = Input;

export const UpdateFormAllSettings = ({ updateProps, loading, updateModalFormRef, updateFormFields, editBtnLoader, handleOnFinish, setToggleUpdate, allDisciplines, setSelectedDisciplinesIds, selectedDisciplinesIds, setSelectedVenueAttributes, allAttributes, venueDisciplines, setVenueDisciplines, selectedVenueAttributes }) => {
  const handleChange = (value) => {
    const removedIds = venueDisciplines.filter((id) => !value.includes(id));
    const removedDiscipline = allDisciplines.find((discipline) => removedIds[removedIds.length - 1] === discipline._id)?.id;
    const allAttributesToRemove = allAttributes
      .filter((item) => item.isGlobal !== true)
      .filter((attribute) => attribute.disciplineId === removedDiscipline)
      ?.map((attribute) => attribute._id);
    setSelectedVenueAttributes((prev) => prev.filter((id) => !allAttributesToRemove.includes(id)));
    setVenueDisciplines(value);
  };

  const [isStudent, setIsStudent] = useState(false);
  const onChange = (value) => {
    setIsStudent(value.target.checked);
  };

  const [isFavoriteVenue, setIsFavoriteVenue] = useState(false);
  const onChangeFav = (value) => {
    setIsFavoriteVenue(value.target.checked);
  };

  return (
    <SidebarModal {...updateProps} className="sidebar__modal--right" title="Edit Venue">
      {!loading ? (
        <Form
          name="update"
          ref={updateModalFormRef}
          wrapperCol={{ span: 24 }}
          // initialValues={{
          //   disciplines: selectedDisciplinesIds, // Set initial value for disciplines
          // }}
          onFinish={(data) => handleOnFinish({ name: 'update', data: data })}
          fields={updateFormFields}
        >
          <Form.Item
            name="name"
            label="Venue Name:"
            rules={[
              {
                required: true,
                message: 'Please input venue name!',
              },
            ]}
          >
            <TextArea placeholder="Venue Name:" style={{ resize: 'none' }} rows={4} />
          </Form.Item>

          <Form.Item
            name="lat"
            label="Latitude:"
            rules={[
              {
                required: true,
                message: 'Please input latitude!',
              },
            ]}
          >
            <Input placeholder="Latitude:" type="number" />
          </Form.Item>

          <Form.Item
            name="lng"
            label="Longitude:"
            rules={[
              {
                required: true,
                message: 'Please input longitude!',
              },
            ]}
          >
            <Input placeholder="Longitude:" type="number" />
          </Form.Item>

          <Form.Item name="phones" label="Phones">
            <Select mode="tags"></Select>
          </Form.Item>

          <Form.Item label="Work Hours">
            <Form.Item name={['workHours', 'monday']} label="Monday" rules={[{ required: true, message: 'Please enter Monday hours' }]}>
              <Input placeholder="09:00-23:00" />
            </Form.Item>
            <Form.Item name={['workHours', 'tuesday']} label="Tuesday" rules={[{ required: true, message: 'Please enter Tuesday hours' }]}>
              <Input placeholder="09:00-23:00" />
            </Form.Item>
            <Form.Item name={['workHours', 'wednesday']} label="Wednesday" rules={[{ required: true, message: 'Please enter Wednesday hours' }]}>
              <Input placeholder="09:00-23:00" />
            </Form.Item>
            <Form.Item name={['workHours', 'thursday']} label="Thursday" rules={[{ required: true, message: 'Please enter Thursday hours' }]}>
              <Input placeholder="09:00-23:00" />
            </Form.Item>
            <Form.Item name={['workHours', 'friday']} label="Friday" rules={[{ required: true, message: 'Please enter Friday hours' }]}>
              <Input placeholder="09:00-23:00" />
            </Form.Item>
            <Form.Item name={['workHours', 'saturday']} label="Saturday" rules={[{ required: true, message: 'Please enter Saturday hours' }]}>
              <Input placeholder="11:00-21:00" />
            </Form.Item>
            <Form.Item name={['workHours', 'sunday']} label="Sunday" rules={[{ required: true, message: 'Please enter Sunday hours' }]}>
              <Input placeholder="11:00-19:00" />
            </Form.Item>
          </Form.Item>

          <Form.Item name="disciplines" label="Disciplines">
            <Select
              mode="multiple"
              allowClear
              placeholder="Disciplines"
              style={{ width: '100%' }}
              defaultValue={updateProps?.data?.disciplines?.map((discipline) => discipline._id) || []}
              optionFilterProp="children"
              showSearch
              filterOption={(input, option) => {
                {
                  const isMatch = (option?.children ?? '').toLowerCase().includes(input.toLowerCase());
                  return isMatch;
                }
              }}
              onChange={handleChange}
            >
              {allDisciplines.map((item, index) => (
                <Option key={index} value={item._id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="attributes" label="Attributes">
            <Select
              mode="multiple"
              allowClear
              placeholder="Attributes"
              style={{ width: '100%' }}
              onChange={(value) => setSelectedVenueAttributes(value)}
              optionFilterProp="children"
              showSearch
              filterOption={(input, option) => {
                {
                  const isMatch = (option?.children ?? '').toLowerCase().includes(input.toLowerCase());
                  return isMatch;
                }
              }}
            >
              {allAttributes &&
                allAttributes.map((item, index) => (
                  <Option key={index} value={item._id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item name={['seo', 'title']} label="SEO Title">
            <TextArea style={{ resize: 'none' }} rows={4} />
          </Form.Item>

          <Form.Item name={['seo', 'description']} label="SEO Description">
            <TextArea rows={8} style={{ resize: 'none' }} />
          </Form.Item>

          <Form.Item name="is_student" valuePropName="checked" className="initial-height">
            <Checkbox checked={isStudent} defaultChecked={updateProps?.data?.is_student} onChange={onChange}>
              Is student
            </Checkbox>
          </Form.Item>

          <Form.Item name="isFavourite" valuePropName="checked" className="initial-height">
            <Checkbox checked={isFavoriteVenue} defaultChecked={updateProps?.data?.isFavourite} onChange={onChange}>
              Is favorite
            </Checkbox>
          </Form.Item>

          <Form.Item className="ant-col-btn action-btns" wrapperCol={{ offset: 8 }}>
            <Row gutter={16}>
              <Col span={12}>
                <Button type="primary" htmlType="button" className="btn btn-primary-link btn-block" onClick={() => setToggleUpdate(false)}>
                  <span>Cancel</span>
                </Button>
              </Col>
              <Col span={12}>
                {!editBtnLoader ? (
                  <Button type="submit" htmlType="submit" className="btn btn-primary btn-block">
                    <span>Edit Venue</span>
                  </Button>
                ) : (
                  <Button type="button" className="btn btn-primary-outline btn-block">
                    <span>Editing...</span>
                  </Button>
                )}
              </Col>
            </Row>
          </Form.Item>
        </Form>
      ) : (
        <Loading />
      )}
    </SidebarModal>
  );
};
