import React, { useCallback, useEffect, useState } from 'react';
import Modal from '../../../components/modal/Modal';
import { TabsPanel } from './tabs-panel/TabsPanel';
import { useProducts } from '../../../context/products';
import GalleryUpload from './GalleryUpload';
import GalleryContent from './GalleryContent';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import axios from 'axios';
import { SERVER_URL } from '../../../config';
import { PERMISSIONS } from '../../../utils/permissions';
import { jwtDecode } from 'jwt-decode';
import { useAuth } from '../../../context/useAuth';
import { useLocation } from 'react-router-dom';

const GalleryModal = ({ formFields, formIndex }) => {
  const { toggleGallery, setToggleGallery, tabsGallery, setTabsGallery, select, selected, refetchGallery } = useProducts();
  const { user } = useAuth();
  const location = useLocation();
  const [groceryPermissions, setGroceryPermissions] = useState([]);
  const [search, setSearch] = useState(null);
  const [checked, setChecked] = useState({ isChecked: '' });
  const [currentForm, setCurrentForm] = useState(null);
  const [readData, setReadData] = useState([]);
  const [readDataLoading, setReadDataLoading] = useState(true);

  useEffect(() => {
    if (formFields && formFields[formIndex]) {
      setCurrentForm(formFields[formIndex]);
    }
  }, [formFields, formIndex, setCurrentForm]);

  useEffect(() => {
    if (currentForm && currentForm.variantImage && currentForm.variantImage?._id) {
      setChecked({
        isChecked: currentForm.variantImage?._id,
      });
    } else {
      setChecked({
        isChecked: '',
      });
    }
  }, [currentForm, setChecked]);

  const [readDataRefetch, setReadDataRefetch] = useState(false);

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;
    if (user && token) {
      const decodeToken = jwtDecode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
          setGroceryPermissions(permissions.grocery);
        }
      }
    }
  }, [user, location]);

  const getReadData = useCallback(async () => {
    const { token } = user;

    try {
      const url = search === null ? `${AXIOS_API_CALL.galleryImages}` : `${AXIOS_API_CALL.galleryImagesSearch}` + search;
      const response = await axios
        .get(`${SERVER_URL}/${url}`, {
          withCredentials: false,
          headers: {
            department: PERMISSIONS.grocery,
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setTimeout(() => {
            setReadDataLoading(false);
          }, 500);

          return res;
        })
        .catch((err) => {
          setReadDataLoading(false);
          console.error(err);
        });

      return response;
    } catch (err) {}
  }, [user]);

  useEffect(() => {
    let isMounted = true;

    new Promise((resolve, reject) => {
      setReadDataLoading(true);
      setTimeout(() => {
        resolve(getReadData());
      }, 700);
    }).then((res) => {
      if (isMounted) {
        setReadData(res?.data);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [refetchGallery, toggleGallery, getReadData, setReadData, readDataRefetch]);

  const galleryContentProps = {
    select,
    selected,
    formFields,
    formIndex,
    checked,
    setChecked,
    currentForm,
    uncheckRadio: true,
    readDataLoading,
    readData,
    setSearch,
    setReadDataRefetch,
  };

  const tabsContent = [
    {
      id: 'fdsfsdfdfdfjhjukutrtr',
      name: 'Upload Files',
      element: toggleGallery ? <GalleryUpload /> : null,
    },
    {
      id: 'dsopewierropiuoituedi',
      name: 'Media Library',
      element: <GalleryContent {...galleryContentProps} />,
    },
  ];

  const handleSetGallery = () => {
    setToggleGallery((prevState) => !prevState);
  };

  useEffect(() => {
    if (!toggleGallery) {
      setTimeout(() => {
        setTabsGallery(0);
      }, 300);
    }
  }, [toggleGallery, setTabsGallery]);

  return (
    <Modal active={toggleGallery} onToggle={handleSetGallery} setChecked={setChecked} currentForm={currentForm} title="Product image" className="gallery-view modal-full">
      <TabsPanel tabsContent={tabsContent} tabsGallery={tabsGallery} setTabsGallery={setTabsGallery} />
    </Modal>
  );
};

export default GalleryModal;
