import React, { useEffect, useCallback } from 'react';
import LoadingPlaceholder from '../loadingPlaceholder/LoadingPlaceholder';

const SidebarModal = (props) => {
  const { className, children, active, onToggle, title, loading } = props;

  const claseToggleSidebarEscape = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        onToggle(false);
      }
    },
    [onToggle]
  );

  useEffect(() => {
    document.addEventListener('keydown', claseToggleSidebarEscape, false);
    return () => {
      document.removeEventListener('keydown', claseToggleSidebarEscape, false);
    };
  }, [claseToggleSidebarEscape]);

  useEffect(() => {
    active ? document.documentElement.classList.add('overflow-hidden') : document.documentElement.classList.remove('overflow-hidden');
  }, [active]);

  return (
    <>
      <aside className={`sidebar__modal ${className !== undefined ? className : ''} ${active ? 'isActive' : ''}`}>
        <header className="header">
          {!loading ? <h3 className="title">{title}</h3> : <LoadingPlaceholder style={{ width: '100%', maxWidth: '200px', height: '50px' }} />}

          {!loading ? (
            <button type="button" className="btn close-button" onClick={() => onToggle(false)} data-cy="modal-close-btn">
              <span className="icon">
                <img src="/assets/icons/times.svg" alt="Close" />
              </span>
            </button>
          ) : (
            <LoadingPlaceholder style={{ width: '50px', height: '50px' }} />
          )}
        </header>

        <main className="content">{children}</main>
      </aside>

      <div className={`modal-overlay ${active ? 'isActive' : ''}`} onClick={() => onToggle(false)} data-cy="modal-close-overlay" />
    </>
  );
};

export default SidebarModal;
