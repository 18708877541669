export const constructInitialFormSection1State = (data, prevState) => {
  return {
    ...prevState,
    hide: data?.section1Hide || false,
    en: {
      ...prevState.en,
      title1: data?.section1Card1TitleEn || '',
      title2: data?.section1Card2TitleEn || '',
      subTitle1Part1: data?.section1Card1SubtitlePart1En || '',
      subTitle1Part2: data?.section1Card1SubtitlePart2En || '',
      subTitle2Part1: data?.section1Card2SubtitlePart1En || '',
      subTitle2Part2: data?.section1Card2SubtitlePart2En || '',
      button1Text: data?.section1Card1ButtonTextEn || '',
      button2Text: data?.section1Card2ButtonTextEn || '',
      button1Url: data?.section1Card1ButtonUrlEn || '',
      button2Url: data?.section1Card2ButtonUrlEn || '',
    },
    cg: {
      ...prevState.cg,
      title1: data?.section1Card1TitleCg || '',
      title2: data?.section1Card2TitleCg || '',
      subTitle1Part1: data?.section1Card1SubtitlePart1Cg || '',
      subTitle1Part2: data?.section1Card1SubtitlePart2Cg || '',
      subTitle2Part1: data?.section1Card2SubtitlePart1Cg || '',
      subTitle2Part2: data?.section1Card2SubtitlePart2Cg || '',
      button1Text: data?.section1Card1ButtonTextCg || '',
      button2Text: data?.section1Card2ButtonTextCg || '',
      button1Url: data?.section1Card1ButtonUrlCg || '',
      button2Url: data?.section1Card2ButtonUrlCg || '',
    },
  };
};

export const constructInitialFormSection2State = (data, prevState) => {
  return {
    ...prevState,
    hide: data?.section2Hide || false,
    en: {
      ...prevState.en,
      mainHeading: data?.section2MainHeadingEn || '',
      sec2button1Text: data?.section2Button1TextEn || '',
      sec2button2Text: data?.section2Button2TextEn || '',
      list1item1: data?.section2List1Item1En || '',
      list1item2: data?.section2List1Item2En || '',
      list1item3: data?.section2List1Item3En || '',
      list1item4: data?.section2List1Item4En || '',
      list1item5: data?.section2List1Item5En || '',
      list2item1: data?.section2List2Item1En || '',
      list2item2: data?.section2List2Item2En || '',
      list2item3: data?.section2List2Item3En || '',
      list2item4: data?.section2List2Item4En || '',
    },
    cg: {
      ...prevState.cg,
      mainHeading: data?.section2MainHeadingCg || '',
      sec2button1Text: data?.section2Button1TextCg || '',
      sec2button2Text: data?.section2Button2TextCg || '',
      list1item1: data?.section2List1Item1Cg || '',
      list1item2: data?.section2List1Item2Cg || '',
      list1item3: data?.section2List1Item3Cg || '',
      list1item4: data?.section2List1Item4Cg || '',
      list1item5: data?.section2List1Item5Cg || '',
      list2item1: data?.section2List2Item1Cg || '',
      list2item2: data?.section2List2Item2Cg || '',
      list2item3: data?.section2List2Item3Cg || '',
      list2item4: data?.section2List2Item4Cg || '',
    },
  };
};

export const constructInitialFormSection3State = (data, prevState) => {
  return {
    ...prevState,
    hide: data?.section3Hide || false,
    en: {
      ...prevState.en,
      sec3MainHeading: data?.section3MainHeadingEn || '',
      sec3Card1Title: data?.section3Card1TitleEn || '',
      sec3Card1SubTitle: data?.section3Card1SubtitleEn || '',
      sec3Card2Title: data?.section3Card2TitleEn || '',
      sec3Card2SubTitle: data?.section3Card2SubtitleEn || '',
      sec3Card3Title: data?.section3Card3TitleEn || '',
      sec3Card3SubTitle: data?.section3Card3SubtitleEn || '',
      sec3Card4Title: data?.section3Card4TitleEn || '',
      sec3Card4SubTitle: data?.section3Card4SubtitleEn || '',
      sec3Card5Title: data?.section3Card5TitleEn || '',
      sec3Card5SubTitle: data?.section3Card5SubtitleEn || '',
      sec3Card6Title: data?.section3Card6TitleEn || '',
      sec3Card6SubTitle: data?.section3Card6SubtitleEn || '',
    },
    cg: {
      ...prevState.cg,
      sec3MainHeading: data?.section3MainHeadingCg || '',
      sec3Card1Title: data?.section3Card1TitleCg || '',
      sec3Card1SubTitle: data?.section3Card1SubtitleCg || '',
      sec3Card2Title: data?.section3Card2TitleCg || '',
      sec3Card2SubTitle: data?.section3Card2SubtitleCg || '',
      sec3Card3Title: data?.section3Card3TitleCg || '',
      sec3Card3SubTitle: data?.section3Card3SubtitleCg || '',
      sec3Card4Title: data?.section3Card4TitleCg || '',
      sec3Card4SubTitle: data?.section3Card4SubtitleCg || '',
      sec3Card5Title: data?.section3Card5TitleCg || '',
      sec3Card5SubTitle: data?.section3Card5SubtitleCg || '',
      sec3Card6Title: data?.section3Card6TitleCg || '',
      sec3Card6SubTitle: data?.section3Card6SubtitleCg || '',
    },
  };
};

export const constructInitialFormSection4State = (data, prevState) => {
  return {
    ...prevState,
    hide: data?.section4Hide || false,
    en: {
      ...prevState.en,
      sec4VideoUrl: data?.section4VideoUrlEn || '',
      sec4StepsTitlePart1: data?.section4StepsTitleEnPart1 || '',
      sec4StepsTitlePart2: data?.section4StepsTitleEnPart2 || '',
      sec4Step1Title: data?.section4Step1TitleEn || '',
      sec4Step1Text: data?.section4Step1TextEn || '',
      sec4Step2Title: data?.section4Step2TitleEn || '',
      sec4Step2Text: data?.section4Step2TextEn || '',
      sec4Step3Title: data?.section4Step3TitleEn || '',
      sec4Step3Text: data?.section4Step3TextEn || '',
      sec4StepsButtonText: data?.section4StepsButtonTextEn || '',
      sec4StepsButtonUrl: data?.section4StepsButtonUrlEn || '',
    },
    cg: {
      ...prevState.cg,
      sec4VideoUrl: data?.section4VideoUrlCg || '',
      sec4StepsTitlePart1: data?.section4StepsTitleCgPart1 || '',
      sec4StepsTitlePart2: data?.section4StepsTitleCgPart2 || '',
      sec4Step1Title: data?.section4Step1TitleCg || '',
      sec4Step1Text: data?.section4Step1TextCg || '',
      sec4Step2Title: data?.section4Step2TitleCg || '',
      sec4Step2Text: data?.section4Step2TextCg || '',
      sec4Step3Title: data?.section4Step3TitleCg || '',
      sec4Step3Text: data?.section4Step3TextCg || '',
      sec4StepsButtonText: data?.section4StepsButtonTextCg || '',
      sec4StepsButtonUrl: data?.section4StepsButtonUrlCg || '',
    },
  };
};

export const constructInitialHpImagesState = (data, prevState) => {
  return {
    ...prevState,
    section1Icon1En: data?.section1Card1IconEn?._id || null,
    section1Icon2En: data?.section1Card2IconEn?._id || null,
    section2Image1En: data?.section2Image1En?._id || null,
    section2Image2En: data?.section2Image2En?._id || null,
    section3Icon1En: data?.section3Icon1En?._id || null,
    section3Icon2En: data?.section3Icon2En?._id || null,
    section3Icon3En: data?.section3Icon3En?._id || null,
    section3Icon4En: data?.section3Icon4En?._id || null,
    section3Icon5En: data?.section3Icon5En?._id || null,
    section3Icon6En: data?.section3Icon6En?._id || null,
    section4Image1En: data?.section4Image1En?._id || null,

    section1Icon1Cg: data?.section1Card1IconCg?._id || null,
    section1Icon2Cg: data?.section1Card2IconCg?._id || null,
    section2Image1Cg: data?.section2Image1Cg?._id || null,
    section2Image2Cg: data?.section2Image2Cg?._id || null,
    section3Icon1Cg: data?.section3Icon1Cg?._id || null,
    section3Icon2Cg: data?.section3Icon2Cg?._id || null,
    section3Icon3Cg: data?.section3Icon3Cg?._id || null,
    section3Icon4Cg: data?.section3Icon4Cg?._id || null,
    section3Icon5Cg: data?.section3Icon5Cg?._id || null,
    section3Icon6Cg: data?.section3Icon6Cg?._id || null,
    section4Image1Cg: data?.section4Image1Cg?._id || null,
  };
};

export const constructInitialHpImagesPreviewState = (data, prevState) => {
  return {
    ...prevState,
    section1Icon1En: data?.section1Card1IconEn?.url || null,
    section1Icon2En: data?.section1Card2IconEn?.url || null,
    section2Image1En: data?.section2Image1En?.url || null,
    section2Image2En: data?.section2Image2En?.url || null,
    section3Icon1En: data?.section3Icon1En?.url || null,
    section3Icon2En: data?.section3Icon2En?.url || null,
    section3Icon3En: data?.section3Icon3En?.url || null,
    section3Icon4En: data?.section3Icon4En?.url || null,
    section3Icon5En: data?.section3Icon5En?.url || null,
    section3Icon6En: data?.section3Icon6En?.url || null,
    section4Image1En: data?.section4Image1En?.url || null,

    section1Icon1Cg: data?.section1Card1IconCg?.url || null,
    section1Icon2Cg: data?.section1Card2IconCg?.url || null,
    section2Image1Cg: data?.section2Image1Cg?.url || null,
    section2Image2Cg: data?.section2Image2Cg?.url || null,
    section3Icon1Cg: data?.section3Icon1Cg?.url || null,
    section3Icon2Cg: data?.section3Icon2Cg?.url || null,
    section3Icon3Cg: data?.section3Icon3Cg?.url || null,
    section3Icon4Cg: data?.section3Icon4Cg?.url || null,
    section3Icon5Cg: data?.section3Icon5Cg?.url || null,
    section3Icon6Cg: data?.section3Icon6Cg?.url || null,
    section4Image1Cg: data?.section4Image1Cg?.url || null,
  };
};
