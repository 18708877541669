import React from 'react';
import Message from '../../../components/message/Message';
import { contentMessage } from '../../../helpers/contentMessage';

const Table = (props) => {
  const { data } = props;
  let subtotal = 0;

  const isProductImage = (item) => {
    const isCheckFeaturedImage = item && item.hasOwnProperty('featureImage');

    if (isCheckFeaturedImage) {
      const featureName = item && item.featureImage.originalname;
      const featureImage = item && item.featureImage && item.featureImage.url;

      return <img src={featureImage} alt={featureName} />;
    }
    return <div>1</div>;
  };

  return (
    <>
      <table className="table">
        <thead className="thead">
          <tr>
            <th>Items</th>
            <th>Quantity</th>
            <th>Address Delivery</th>
            <th>Shipping</th>
            <th>Payment</th>
            <th>Status</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody className="tbody">
          {data && data.order.length > 0 ? (
            data?.order.map((item, idx) => {
              subtotal = subtotal + parseFloat(item.totalPrice);
              const address = data.address[0].address + ', ' + data.address[0].city + ', ' + data.address[0].state;
              return (
                <tr key={item._id}>
                  <td>
                    <div className="product-item">
                      {/* <img src="/assets/images/fake-image.png" alt={item.title} /> */}
                      {isProductImage(item)}
                      <span>{item.title}</span>
                    </div>
                  </td>
                  <td>{item.quantity}</td>
                  <td>{data.address.length > 0 ? (data.address[0] === 'Split Shipping' ? item.deliveryAddress : address) : ''}</td>
                  <td>{item.status}</td>
                  <td>{data.paymentType}</td>
                  <td>{data.status}</td>
                  <td>${parseFloat(item.totalPrice).toFixed(2)}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={20} style={{ backgroundColor: '#fff', height: '200px', border: '0' }}>
                <Message className="content-message">
                  <h2 style={{ color: '#666' }}>{contentMessage.dataNotFound}</h2>
                </Message>
              </td>
            </tr>
          )}
          {data && data.order.length > 0 ? (
            <tr className="subtotal-row">
              <td style={{ textAlign: 'right', paddingRight: '30px' }} colSpan={7}>
                Subtotal: ${subtotal.toFixed(2)}
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </>
  );
};

export default Table;
