import ImageUpload from '../../components/image-upload';
import TooltipDash from '../../../../../helpers/TooltipDash';

const Section1Cg = ({ handleInputChangeFormSection1, formSection1, clImages, clImagesErrors, clImagesPreview, setClImages, formSection1Errors }) => {
  return (
    <>
      <div className="cms-form-flex media" style={{ paddingBottom: '20px', marginBottom: '40px' }}>
        <div className="cms-form-flex-col">
          <div className="images-card-header">
            <span>Slika</span>
            <span className="ml-6">
              <TooltipDash text="Proporcije slike 1/1 (480x480)px" acceptFormatText={true} />
            </span>
          </div>
          <div className="upload-wrapper">
            <ImageUpload clImages={clImages} clImagesErrors={clImagesErrors} clImagesPreview={clImagesPreview} setClImages={setClImages} toStoreImg="section1ImageCg" />
          </div>
        </div>
      </div>
      {/*Shared inputs*/}
      <div className="shared">
        {/*en_sec1MainHeading*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="cg_sec1MainHeading">
            <b>Glavni naslov:</b>
          </label>
          <input id="cg_sec1MainHeading" type="text" placeholder="Main heading" name="cg_sec1MainHeading" value={formSection1.cg.sec1MainHeading} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.cg.sec1MainHeading ? 'input-error-important' : ''}`} />
        </div>
        {/*cg_sec1MainHeading*/}
      </div>
      {/*Shared inputs*/}
      <div className="cms-form-flex">
        {/*Advantage 1*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Stavka 1:</p>
          {/*cg_sec1ListItem1Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec1ListItem1Title">
              <b>Stavka 1 Naslov:</b>
            </label>
            <textarea id="cg_sec1ListItem1Title" type="text" placeholder="Stavka 1 Naslov" name="cg_sec1ListItem1Title" value={formSection1.cg.sec1ListItem1Title} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.cg.sec1ListItem1Title ? 'input-error-important' : ''}`} />
          </div>
          {/*cg_sec1ListItem1Title*/}
          {/*cg_sec1ListItem1Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec1ListItem1Text">
              <b>Stavka 1 Tekst:</b>
            </label>
            <textarea id="cg_sec1ListItem1Text" type="text" placeholder="Stavka 1 Tekst" name="cg_sec1ListItem1Text" value={formSection1.cg.sec1ListItem1Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.cg.sec1ListItem1Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*cg_sec1ListItem1Text*/}
        </div>
        {/*Advantage 1*/}

        {/*Advantage 2*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Stavka 2:</p>
          {/*cg_sec1ListItem2Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec1ListItem2Title">
              <b>Stavka 2 Naslov:</b>
            </label>
            <textarea id="cg_sec1ListItem2Title" type="text" placeholder="Stavka 2 Naslov" name="cg_sec1ListItem2Title" value={formSection1.cg.sec1ListItem2Title} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.cg.sec1ListItem2Title ? 'input-error-important' : ''}`} />
          </div>
          {/*cg_sec1ListItem2Title*/}
          {/*cg_sec1ListItem2Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec1ListItem2Text">
              <b>Stavka 2 Tekst:</b>
            </label>
            <textarea id="cg_sec1ListItem2Text" type="text" placeholder="Stavka 2 Tekst" name="cg_sec1ListItem2Text" value={formSection1.cg.sec1ListItem2Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.cg.sec1ListItem2Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*cg_sec1ListItem2Text*/}
        </div>
        {/*Advantage 2*/}

        {/*Advantage 3*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Stavka 3:</p>
          {/*cg_sec1ListItem3Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec1ListItem3Title">
              <b>Stavka 3 Naslov:</b>
            </label>
            <textarea id="cg_sec1ListItem3Title" type="text" placeholder="Stavka 3 Naslov" name="cg_sec1ListItem3Title" value={formSection1.cg.sec1ListItem3Title} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.en.sec1ListItem3Title ? 'input-error-important' : ''}`} />
          </div>
          {/*cg_sec1ListItem3Title*/}
          {/*cg_sec1ListItem3Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec1ListItem3Text">
              <b>Stavka 3 Tekst:</b>
            </label>
            <textarea id="cg_sec1ListItem3Text" type="text" placeholder="Stavka 3 Tekst" name="cg_sec1ListItem3Text" value={formSection1.cg.sec1ListItem3Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.cg.sec1ListItem3Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*cg_sec1ListItem3Text*/}
        </div>
        {/*Advantage 3*/}

        {/*Advantage 4*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Stavka 4:</p>
          {/*cg_sec1ListItem4Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec1ListItem4Title">
              <b>Stavka 4 Naslov:</b>
            </label>
            <textarea id="cg_sec1ListItem4Title" type="text" placeholder="Stavka 4 Naslov" name="cg_sec1ListItem4Title" value={formSection1.cg.sec1ListItem4Title} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.cg.sec1ListItem4Title ? 'input-error-important' : ''}`} />
          </div>
          {/*cg_sec1ListItem4Title*/}
          {/*cg_sec1ListItem4Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec1ListItem4Text">
              <b>Stavka 4 Tekst:</b>
            </label>
            <textarea id="cg_sec1ListItem4Text" type="text" placeholder="Stavka 4 Tekst" name="cg_sec1ListItem4Text" value={formSection1.cg.sec1ListItem4Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.cg.sec1ListItem4Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*cg_sec1ListItem4Text*/}
        </div>
        {/*Advantage 4*/}
      </div>
    </>
  );
};
export default Section1Cg;
