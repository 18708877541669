const tooltips = {
  addresses: 'Addresses tooltip',
  attributes: 'Attributes tooltip',
  customers: 'Customers tooltip',
  venues: 'Venues tooltip',
  employees: 'Employees tooltip',
  images: 'Images tooltip',
  logs: 'Logs tooltip',
  nyami: 'Nyami tooltip',
  roles: 'Roles tooltip',
};

export { tooltips };