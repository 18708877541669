import ImageUpload from '../../components/image-upload';
import TooltipDash from '../../../../../helpers/TooltipDash';

const Section1En = ({ handleInputChangeFormSection1, formSection1, fpImages, fpImagesErrors, fpImagesPreview, setFpImages, formSection1Errors }) => {
  return (
    <>
      <div className="cms-form-flex media" style={{ paddingBottom: '20px', marginBottom: '40px' }}>
        <div className="cms-form-flex-col">
          <div className="images-card-header">
            <span>Image</span>
            <span className="ml-6">
              <TooltipDash text="Image aspect ratio 1/1.82 (638x1165)px" acceptFormatText={true} />
            </span>
          </div>
          <div className="upload-wrapper">
            <ImageUpload fpImages={fpImages} fpImagesErrors={fpImagesErrors} fpImagesPreview={fpImagesPreview} setFpImages={setFpImages} toStoreImg="section1ImageEn" />
          </div>
        </div>
      </div>
      {/*Shared inputs*/}
      <div className="shared">
        {/*en_sec1MainHeadingPart1*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="en_sec1MainHeadingPart1">
            <b>Main heading line 1:</b>
          </label>
          <input id="en_sec1MainHeadingPart1" type="text" placeholder="Main heading line 1" name="en_sec1MainHeadingPart1" value={formSection1.en.sec1MainHeadingPart1} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.en.sec1MainHeadingPart1 ? 'input-error-important' : ''}`} />
        </div>
        {/*en_sec1MainHeadingPart1*/}
        {/*en_sec1MainHeadingPart2*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="en_sec1MainHeadingPart2">
            <b>Main heading line 2:</b>
          </label>
          <input id="en_sec1MainHeadingPart2" type="text" placeholder="Main heading line 2" name="en_sec1MainHeadingPart2" value={formSection1.en.sec1MainHeadingPart2} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.en.sec1MainHeadingPart2 ? 'input-error-important' : ''}`} />
        </div>
        {/*en_sec1MainHeadingPart2*/}
        {/*en_sec1MainHeadingPart3*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="en_sec1MainHeadingPart3">
            <b>Main heading line 3:</b>
          </label>
          <input id="en_sec1MainHeadingPart3" type="text" placeholder="Main heading line 3" name="en_sec1MainHeadingPart3" value={formSection1.en.sec1MainHeadingPart3} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.en.sec1MainHeadingPart3 ? 'input-error-important' : ''}`} />
        </div>
        {/*en_sec1MainHeadingPart3*/}
      </div>
      {/*Shared inputs*/}
      <div className="cms-form-flex">
        {/*Advantage 1*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Advantage 1:</p>
          {/*en_sec1Advantage1Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="en_sec1Advantage1Title">
              <b>Advantage 1 Title:</b>
            </label>
            <textarea id="en_sec1Advantage1Title" type="text" placeholder="Advantage 1 Title" name="en_sec1Advantage1Title" value={formSection1.en.sec1Advantage1Title} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.en.sec1Advantage1Title ? 'input-error-important' : ''}`} />
          </div>
          {/*en_sec1Advantage1Title*/}
          {/*en_sec1Advantage1Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="en_sec1Advantage1Text">
              <b>Advantage 1 Text:</b>
            </label>
            <textarea id="en_sec1Advantage1Text" type="text" placeholder="Advantage 1 Text" name="en_sec1Advantage1Text" value={formSection1.en.sec1Advantage1Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.en.sec1Advantage1Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*en_sec1Advantage1Text*/}
        </div>
        {/*Advantage 1*/}

        {/*Advantage 2*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Advantage 2:</p>
          {/*en_sec1Advantage2Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="en_sec1Advantage2Title">
              <b>Advantage 2 Title:</b>
            </label>
            <textarea id="en_sec1Advantage2Title" type="text" placeholder="Advantage 2 Title" name="en_sec1Advantage2Title" value={formSection1.en.sec1Advantage2Title} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.en.sec1Advantage2Title ? 'input-error-important' : ''}`} />
          </div>
          {/*en_sec1Advantage2Title*/}
          {/*en_sec1Advantage2Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="en_sec1Advantage2Text">
              <b>Advantage 2 Text:</b>
            </label>
            <textarea id="en_sec1Advantage2Text" type="text" placeholder="Advantage 2 Text" name="en_sec1Advantage2Text" value={formSection1.en.sec1Advantage2Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.en.sec1Advantage2Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*en_sec1Advantage2Text*/}
        </div>
        {/*Advantage 2*/}

        {/*Advantage 3*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Advantage 3:</p>
          {/*en_sec1Advantage3Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="en_sec1Advantage3Title">
              <b>Advantage 3 Title:</b>
            </label>
            <textarea id="en_sec1Advantage3Title" type="text" placeholder="Advantage 3 Title" name="en_sec1Advantage3Title" value={formSection1.en.sec1Advantage3Title} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.en.sec1Advantage3Title ? 'input-error-important' : ''}`} />
          </div>
          {/*en_sec1Advantage3Title*/}
          {/*en_sec1Advantage3Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="en_sec1Advantage3Text">
              <b>Advantage 3 Text:</b>
            </label>
            <textarea id="en_sec1Advantage3Text" type="text" placeholder="Advantage 3 Text" name="en_sec1Advantage3Text" value={formSection1.en.sec1Advantage3Text} onChange={handleInputChangeFormSection1} className={`${formSection1Errors.en.sec1Advantage3Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*en_sec1Advantage3Text*/}
        </div>
        {/*Advantage 3*/}
      </div>
    </>
  );
};
export default Section1En;
