import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../context/useAuth';
import { USER, PERMISSIONS } from '../utils/permissions';

const ProtectedRoute = (props) => {
  const { user } = useAuth();
  const { children, redirectPath } = props;
  const location = useLocation();

  // CHECK USER SESSION STORAGE
  useEffect(() => {
    const checkUser = window.sessionStorage.getItem(USER);
    if (!checkUser) {
      window.sessionStorage.setItem(USER, JSON.stringify(null));
      window.location.href = `/${PERMISSIONS.login}`;
      window.location.reload(); // testirati sa navigate react
    }
  }, [location]);

  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
