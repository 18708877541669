import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../../context/useAuth';
import { SERVER_URL } from '../../../config/index';
import { Select, notification } from 'antd';
import Table from './Table';
import SelectCustom from '../../../components/customSelect/CustomSelect';
import Loading from '../../../components/loading/Loading';
import LoadingPlaceholder from '../../../components/loadingPlaceholder/LoadingPlaceholder';
import { Main, Section } from '../../../components/content';
import { notificationMessages } from '../../../helpers/notificationMessages';
import { jwtDecode } from 'jwt-decode';
import { getGroceryPermissions } from '../../../middleware';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { PERMISSIONS } from '../../../utils/permissions';
import { UpdateFormAllSettings } from './update-form/update-form-all-settings/UpdateFormAllSettings';

const options = [5, 10, 20, 50, 100];
const { Option } = Select;

const EditRequest = () => {
  const { user } = useAuth();
  const location = useLocation();
  const [groceryPermissions, setGroceryPermissions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limitPage, setLimitPage] = useState(10);
  const [list, setList] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [loading, setLoading] = useState(true);

  const [venueId, setVenueId] = useState('');
  const [venueData, setVenueData] = useState({});

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;

    if (user && token) {
      const decodeToken = jwtDecode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
          setGroceryPermissions(permissions.grocery);
        }
      }
    }
  }, [user, location]);

  // LOADER PROTECTION
  const [editBtnLoader, setEditBtnLoader] = useState(false);

  // SEARCH
  const [search, setSearch] = useState(null);
  const [searchForm, setSearchForm] = useState({ query: '' });
  const [filterStatus, setFilterStatus] = useState(null);

  const handleOnSubmitSearch = (e) => {
    e.preventDefault();
    setSearch(searchForm.query);
    setRefetch((prevState) => !prevState);
  };

  const handleOnChangeSearch = (value) => {
    setSearchForm({ query: value });
  };

  const handleOnClearSearch = () => {
    setSearch(null);
    setSearchForm({ query: '' });
    setRefetch((prevState) => !prevState);
  };

  const getTableData = useCallback(async () => {
    setLoading(true);
    try {
      await axios
        .get(`${SERVER_URL}/${AXIOS_API_CALL.requests}/${currentPage}/${limitPage}/${search || null}/${filterStatus || null}`, {})
        .then((res) => {
          setList(res?.data);
          setTimeout(() => {
            setLoading(false);
          }, 700);
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  }, [user, currentPage, limitPage, search, filterStatus]);

  // FETCH DATA FOR `TABLE`
  useEffect(() => {
    getTableData();
  }, [getTableData, refetch]);

  useEffect(() => {
    if (!!search) {
      setCurrentPage(1);
    }
  }, [search]);

  useEffect(() => {
    setCurrentPage(1);
  }, [limitPage]);

  // FILTER STATUS
  const handleStatusFilter = (value) => {
    setFilterStatus(value);
    setRefetch((prevState) => !prevState);
  };

  // Get selected rows id one or more
  const [checkedRow, setCheckedRow] = useState([]);
  const handleCheckedRows = (data) => {
    setCheckedRow(data);
  };

  // const [employeeUpdateId, setEmployeeUpdateId] = useState('');
  const [editRequestId, setEditRequestId] = useState({
    id: '',
  });

  const handleToggle = (props) => {
    const { name, state, data } = props;

    switch (name) {
      case 'update':
        setVenueId(data);
        state.onToggle((prevState) => !prevState);
        break;
      default:
        console.error('handleToggle ---- error');
    }
  };

  // Refs
  const updateModalFormRef = useRef(null);

  // State
  const [toggleUpdate, setToggleUpdate] = useState(false);

  // FETCH ROLES
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    const { token } = user;

    try {
      axios
        .post(
          `${SERVER_URL}/${AXIOS_API_CALL.getRoles}/null/null/null`,
          { department: PERMISSIONS.grocery },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setRoles(res.data?.data.Data);
        })
        .catch((err) => console.error(err))
        .finally(setTimeout(() => {}, 700));
    } catch (err) {
      console.error(err);
    }
  }, [user]);

  // READ - EDIT VENUE REQUEST
  useEffect(() => {
    const { token } = user;

    if (!!venueId) {
      try {
        axios
          .get(`${SERVER_URL}/${AXIOS_API_CALL.requests}/${venueId}`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            setVenueData(res.data);
          })
          .catch((err) => console.error(err))
          .finally(() => {});
      } catch (err) {
        console.error(err);
      }
    }
  }, [venueId, setVenueId]);

  const [loadingEditRequest, setLoadingEditRequest] = useState(false);

  const handleOnFinish = async (props) => {
    const { name, data } = props;

    const { token } = user;

    switch (name) {
      case 'update':
        setEditBtnLoader(true);
        try {
          const postData = {
            status: data,
            requestBy: user.id,
          };

          const editRequestResponse = await axios.put(
            `${SERVER_URL}/venue-requests/${AXIOS_API_CALL.editRequest}/${venueId}`,
            { ...postData },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          if (editRequestResponse.request.status === 200) {
            setTimeout(() => {
              setEditBtnLoader(false);
            }, 700);
          }
          setRefetch((prevState) => !prevState);
          setCurrentPage(1);
          setToggleUpdate(false);
          setEditRequestId({ id: '' });

          setTimeout(() => {
            updateModalFormRef.current.resetFields();
          }, 750);
        } catch (err) {
          console.error(err);
          setEditBtnLoader(false);
        }
        break;

      default:
        console.error('handleOnFinish ---- error');
    }
  };

  // Update Edit Request
  const updateProps = {
    active: toggleUpdate,
    onToggle: setToggleUpdate,
    formRef: updateModalFormRef,
    loading: loadingEditRequest,
  };

  // TABLE
  const tableProps = {
    handleCheckedRows: handleCheckedRows,
  };

  const tableActionsProps = {
    // custom for this table - always changes for next implementation
    handleToggle: handleToggle,
    updateActions: updateProps,
    location: location,
    groceryPermissions: groceryPermissions,
    getGroceryPermissions: getGroceryPermissions,
  };

  const tableFiltersProps = {
    handleStatusFilter: handleStatusFilter,
  };

  // Table Pagination
  const paginationProps = {
    current: currentPage,
    setCurrentPage: setCurrentPage,
    limitPage: limitPage,
    buttonLimit: 3,
    pageCount: list?.pagesCount,
    totalCount: list?.totalCount,
  };

  let changes = {
    _id: '6690f56cacdc9443ec4c1a27',
    venue: '668e43ccdf5ccfe5e41f607e',
    changes: {
      name: {
        oldValue: 'Osinski LLC GYM',
        newValue: '111Osinski LLC GYM',
      },
    },
    status: 'PENDING',
    requestedBy: '668e43960dfc555dd3564b6d',
    reviewedBy: null,
    reviewedAt: null,
    createdAt: '2024-07-12T09:20:44.845Z',
    updatedAt: '2024-07-12T09:20:44.845Z',
    __v: 0,
  };

  const updateFormFields = [
    {
      name: 'name',
      value: venueData?.newValues?.name,
      className: venueData?.changes?.name?.oldValue ? 'edit-request-change-new' : '',
    },
    {
      name: 'lat',
      value: venueData?.newValues?.lat,
      className: venueData?.changes?.lat?.oldValue ? 'edit-request-change-new' : '',
    },
    {
      name: 'lng',
      value: venueData?.newValues?.lng,
      className: venueData?.changes?.lng?.oldValue ? 'edit-request-change-new' : '',
    },
    {
      name: 'phones',
      value: venueData?.newValues?.phones ? venueData?.newValues.phones : [],
      className: venueData?.changes?.phones?.oldValue ? 'edit-request-change-new' : '',
    },
    {
      name: 'disciplines',
      value: venueData?.newValues?.disciplines?.length > 0 ? venueData?.newValues.disciplines?.map((item) => item?.name) : [],
      className: venueData?.changes?.disciplines?.newValue?.length > 0 ? 'edit-request-change-new' : '',
    },
    {
      name: 'attributes',
      value: venueData?.newValues?.attributes?.length > 0 ? venueData?.newValues.attributes?.map((item) => item?.name) : [],
      className: venueData?.changes?.attributes?.newValue?.length > 0 ? 'edit-request-change-new' : '',
    },
    {
      name: 'workHours',
      value: {
        monday: venueData?.newValues?.workHours?.monday,
        tuesday: venueData?.newValues?.workHours?.tuesday,
        wednesday: venueData?.newValues?.workHours?.wednesday,
        thursday: venueData?.newValues?.workHours?.thursday,
        friday: venueData?.newValues?.workHours?.friday,
        saturday: venueData?.newValues?.workHours?.saturday,
        sunday: venueData?.newValues?.workHours?.sunday,
      },
      className: venueData?.changes?.workHours?.oldValue ? 'edit-request-change-new' : '',
    },
    {
      name: 'seo',
      value: {
        title: venueData?.newValues?.seo?.title,
        description: venueData?.newValues?.seo?.description,
      },
      className: venueData?.changes?.seo?.oldValue?.description ? 'edit-request-change-new' : '',
    },
    {
      name: 'is_student',
      value: venueData?.newValues?.is_student,
      className: venueData?.changes?.is_student ? 'edit-request-change-new' : '',
    },
    {
      name: 'isFavourite',
      value: venueData?.newValues?.isFavourite,
      className: venueData?.changes?.isFavourite ? 'edit-request-change-new' : '',
    },
  ];

  const updateFormFieldsWithChanges = [
    {
      name: 'name',
      value: venueData?.changes?.name?.oldValue ? venueData?.changes?.name?.oldValue : venueData?.newValues?.name,
      className: venueData?.changes?.name?.oldValue ? 'edit-request-change-old' : '',
    },
    {
      name: 'lat',
      value: venueData?.changes?.lat?.oldValue ? venueData?.changes?.lat?.oldValue : venueData?.newValues?.lat,
      className: venueData?.changes?.lat?.oldValue ? 'edit-request-change-old' : '',
    },
    {
      name: 'lng',
      value: venueData?.changes?.lng?.oldValue ? venueData?.changes?.lng?.oldValue : venueData?.newValues?.lng,
      className: venueData?.changes?.lng?.oldValue ? 'edit-request-change-old' : '',
    },
    {
      name: 'phones',
      value: venueData?.changes?.phones?.oldValue ? venueData?.changes?.phones?.oldValue : venueData?.newValues?.phones,
      className: venueData?.changes?.phones?.oldValue ? 'edit-request-change-old' : '',
    },
    {
      name: 'disciplines',
      value: venueData?.changes?.disciplines?.oldValue ? venueData?.changes?.disciplines?.oldValue?.map((item) => item?.name) : venueData?.newValues?.disciplines?.map((item) => item?.name),
      className: venueData?.changes?.disciplines?.oldValue?.length > 0 ? 'edit-request-change-old' : '',
    },
    {
      name: 'attributes',
      value: venueData?.changes?.attributes?.oldValue ? venueData?.changes?.attributes?.oldValue?.map((item) => item?.name) : venueData?.newValues?.attributes?.map((item) => item?.name),
      className: venueData?.changes?.attributes?.oldValue ? 'edit-request-change-old' : '',
    },
    {
      name: 'workHours',
      value: {
        monday: venueData?.changes?.workHours?.oldValue?.monday ? venueData?.changes?.workHours?.oldValue?.monday : venueData?.newValues?.workHours.monday,
        tuesday: venueData?.changes?.workHours?.oldValue?.tuesday ? venueData?.changes?.workHours?.oldValue?.tuesday : venueData?.newValues?.workHours.tuesday,
        wednesday: venueData?.changes?.workHours?.oldValue?.wednesday ? venueData?.changes?.workHours?.oldValue?.wednesday : venueData?.newValues?.workHours.wednesday,
        thursday: venueData?.changes?.workHours?.oldValue?.thursday ? venueData?.changes?.workHours?.oldValue?.thursday : venueData?.newValues?.workHours.thursday,
        friday: venueData?.changes?.workHours?.oldValue?.friday ? venueData?.changes?.workHours?.oldValue?.friday : venueData?.newValues?.workHours.friday,
        saturday: venueData?.changes?.workHours?.oldValue?.saturday ? venueData?.changes?.workHours?.oldValue?.saturday : venueData?.newValues?.workHours.saturday,
        sunday: venueData?.changes?.workHours?.oldValue?.sunday ? venueData?.changes?.workHours?.oldValue?.sunday : venueData?.newValues?.workHours.sunday,
      },
      className: venueData?.changes?.workHours?.oldValue ? 'edit-request-change-old' : '',
    },
    {
      name: 'seo',
      value: {
        title: venueData?.changes?.seo?.oldValue.title ? venueData?.changes?.seo?.oldValue.title : venueData?.newValues?.seo.title,
        description: venueData?.changes?.seo?.oldValue?.description ? venueData?.changes?.seo?.oldValue.description : venueData?.newValues?.seo.description,
      },
      className: venueData?.changes?.seo?.oldValue?.description ? 'edit-request-change-old' : '',
    },
    {
      name: 'is_student',
      value: venueData?.changes?.is_student ? venueData?.changes?.is_student?.oldValue : venueData?.newValues?.is_student,
      className: venueData?.changes?.is_student ? 'edit-request-change-old' : '',
    },
    {
      name: 'isFavourite',
      value: venueData?.changes?.isFavourite ? venueData?.changes?.isFavourite?.oldValue : venueData?.newValues?.isFavourite,
      className: venueData?.changes?.isFavourite ? 'edit-request-change-old' : '',
    },
    // {
    //   name: ['seo', 'description'],
    //   value: venueData?.changes?.seo?.description?.oldValue ? venueData?.changes?.seo?.description?.oldValue : venueData?.newValues?.seo.description,
    //   className: venueData?.changes?.seo?.description?.oldValue ? 'edit-request-change-old' : '',
    // },
  ];

  const updateFormAllSettingsProps = {
    updateProps,
    loadingEditRequest,
    updateModalFormRef,
    updateFormFields,
    updateFormFieldsWithChanges,
    roles,
    editBtnLoader,
    handleOnFinish,
    setToggleUpdate,
  };

  return (
    <>
      <h2 className="page-title">Edit Requests</h2>

      <Section className="section section__wrapper section__employees">
        {/* Main Header */}
        <header className="section__header">
          {!loading ? (
            <div className="filter">
              <span style={{ color: '#333', fontWeight: '500' }}>Show:</span>
              <SelectCustom options={options} label={'Items'} limit={limitPage} setLimit={setLimitPage} setRefetch={setRefetch} loading={loading} />
            </div>
          ) : (
            <LoadingPlaceholder style={{ width: '155.5px', height: '50px' }} />
          )}

          {!loading ? (
            <div className="search">
              <form className="search-form" onSubmit={(e) => handleOnSubmitSearch(e)} data-cy="search-roles">
                <div className="form-group m-0">
                  <span className="icon icon-search">
                    <img src="/assets/icons/search.svg" alt="Search" title="Search" data-cy="search-input-field" />
                  </span>
                  <input value={searchForm.query || ''} type="text" className="input" placeholder="Search" onChange={(e) => handleOnChangeSearch(e.target.value)} />
                  <span className={`icon icon-close ${!!searchForm.query && searchForm.query.length > 0 ? 'isActive' : ''}`} onClick={() => handleOnClearSearch()}>
                    <img src="/assets/icons/times.svg" alt="Clear" title="Clear" />
                  </span>
                </div>
                <button className="form-submit" data-cy="search-submit-btn">
                  Search
                </button>
              </form>
            </div>
          ) : (
            <LoadingPlaceholder style={{ width: '100%', maxWidth: '850px', height: '50px' }} />
          )}
        </header>
        {/* Main Content */}
        <Main className="section__content relative min-h-table-content">{loading ? <Loading /> : <Table data={list.data} {...tableProps} {...tableActionsProps} {...tableFiltersProps} pagination={paginationProps} />}</Main>
        {/* Update Edit Request - all settings */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.updateRequests) && <UpdateFormAllSettings {...updateFormAllSettingsProps} />}
      </Section>
    </>
  );
};

export default EditRequest;
