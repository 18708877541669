import { useEffect, useRef, useState } from 'react';
import { useProducts } from '../../../../../../context/products';

// Data
const productEligible = [
  { id: 'fdfdskfdfkdjkfkaa', name: 'FSA', value: 'FSA' },
  { id: 'fjkdsdjfdjfhsddfh', name: 'WIC', value: 'WIC' },
  { id: 'fjsdfsdjfdsfjdjjd', name: 'EBT', value: 'EBT' }
];

export const Eligible = (props) => {
  const { label, className } = props;
  const { form, setForm, productEdit } = useProducts();

  const elementRef = useRef(null);
  const [toggle, setToggle] = useState(false);
  const [elements, setElements] = useState(productEligible);
  const [selected, setSelected] = useState(form.Eligible || []);

  const onSelect = (props) => {
    const { action, payload } = props;

    switch (action) {
      case 'add':
        const newArrRemove = elements.filter((item) => item.value !== payload);
        setElements(newArrRemove);
        const newUpdateResult = elements.filter((item) => item.value === payload);
        setSelected([...selected, ...newUpdateResult]);
        break;
      case 'remove':
        const newArrAdd = selected.filter((item) => item.value !== payload);
        setSelected(newArrAdd);
        const newRemoveResult = selected.filter((item) => item.value === payload);
        setElements([...elements, ...newRemoveResult]);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setElements(productEligible);
  }, [productEligible]);

  const onClickOutside = (event) => {
    if (elementRef.current && !elementRef.current.contains(event.target)) {
      setToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', onClickOutside, false);
    return () => {
      document.removeEventListener('mousedown', onClickOutside, false);
    };
  }, [elementRef]);

  useEffect(() => {
    if (selected && selected.length > 0) {
      const selectedValue = selected.map((item) => {
        return item.value;
      });

      setForm((prevState) => {
        return { ...prevState, productEligible: selectedValue };
      });
    } else {
      setForm((prevState) => {
        return { ...prevState, productEligible: [] };
      });
    }
  }, [selected, setForm]);

  useEffect(() => {
    if (productEdit) {
      if (form.productEligible && form.productEligible.length > 0) {
        const newArrRemove = elements.filter((item, idx) => item.value !== form.productEligible[idx]);
        setElements(newArrRemove);
        const newUpdateResult = elements.filter((item, idx) => item.value === form.productEligible[idx]);
        setSelected([...selected, ...newUpdateResult]);
      }
    }
  }, []);

  return (
    <div ref={elementRef} className={`custom-select ${className !== undefined ? className : ''}`}>
      <span className="label">
        <b>{label}:</b>
      </span>

      <div className="select select-result" onClick={() => setToggle((t) => !t)}>
        {selected && selected.length > 0 ? (
          <div className="result">
            {selected.map((item, idx) => {
              return (
                <span
                  onClick={() => {
                    onSelect({ action: 'remove', payload: item.value });
                    setToggle((t) => !t);
                  }}
                  className={`item item-${item?.value?.toLowerCase()}`}
                  key={idx}
                >
                  <span className="text">{item?.name}</span>
                  <span className="icon">
                    <img src={`/assets/icons/img_${item?.value?.toLowerCase()}.png`} alt={item?.name} title={item?.name} />
                  </span>
                </span>
              );
            })}
          </div>
        ) : (
          <span className="no-data">No selected</span>
        )}

        <button type="button" className="btn">
          <span className="icon">
            <img alt="Arrow down" src="/assets/icons/arrow-down-table-filters.svg" />
          </span>
        </button>
      </div>

      <div className={`select select-area ${toggle ? 'isActive' : ''}`}>
        <div className="result">
          {elements && elements.length > 0 ? (
            <>
              {elements.map((item, idx) => {
                return (
                  <span
                    onClick={() => {
                      onSelect({ action: 'add', payload: item?.value });
                      setToggle(true);
                    }}
                    className={`item item-${item?.value.toLowerCase()}`}
                    key={idx}
                  >
                    <span className="text">{item?.name}</span>
                  </span>
                );
              })}
            </>
          ) : (
            <span className="no-data">No selected</span>
          )}
        </div>
      </div>
    </div>
  );
};
