import ImageUpload from '../../components/image-upload';
import TooltipDash from '../../../../../helpers/TooltipDash';

const Section2Cg = ({ handleInputChangeFormSection2, formSection2, fcImages, fcImagesErrors, fcImagesPreview, setFcImages, formSection2Errors }) => {
  return (
    <>
      <div className="cms-form-flex media" style={{ paddingBottom: '20px', marginBottom: '40px' }}>
        <div className="cms-form-flex-col">
          <div className="images-card-header">
            <span>Slika</span>
            <span className="ml-6">
              <TooltipDash text="Proporcije slike 1/2 (584x1188)px" acceptFormatText={true} />
            </span>
          </div>
          <div className="upload-wrapper">
            <ImageUpload fcImages={fcImages} fcImagesErrors={fcImagesErrors} fcImagesPreview={fcImagesPreview} setFcImages={setFcImages} toStoreImg="section2ImageCg" />
          </div>
        </div>
      </div>
      {/*Shared inputs*/}
      <div className="shared">
        {/*cg_sec2MainHeadingPart1*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="cg_sec2MainHeadingPart1">
            <b>Glavni naslov deo 1:</b>
          </label>
          <input id="cg_sec2MainHeadingPart1" type="text" placeholder="Glavni naslov deo 1" name="cg_sec2MainHeadingPart1" value={formSection2.cg.sec2MainHeadingPart1} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.cg.sec2MainHeadingPart1 ? 'input-error-important' : ''}`} />
        </div>
        {/*cg_sec2MainHeadingPart1*/}
        {/*cg_sec2MainHeadingPart2*/}
        <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
          <label className="label d-block" htmlFor="cg_sec2MainHeadingPart2">
            <b>Glavni naslov deo 2:</b>
          </label>
          <input id="cg_sec2MainHeadingPart2" type="text" placeholder="Glavni naslov deo 2" name="cg_sec2MainHeadingPart2" value={formSection2.cg.sec2MainHeadingPart2} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.cg.sec2MainHeadingPart2 ? 'input-error-important' : ''}`} />
        </div>
        {/*cg_sec2MainHeadingPart2*/}
      </div>
      {/*Shared inputs*/}
      <div className="cms-form-flex">
        {/*Advantage 1*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Prednost 1:</p>
          {/*cg_sec2Advantage1Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec2Advantage1Title">
              <b>Prednost 1 Naslov:</b>
            </label>
            <textarea id="cg_sec2Advantage1Title" type="text" placeholder="Prednost 1 Naslov" name="cg_sec2Advantage1Title" value={formSection2.cg.sec2Advantage1Title} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.cg.sec2Advantage1Title ? 'input-error-important' : ''}`} />
          </div>
          {/*cg_sec2Advantage1Title*/}
          {/*cg_sec2Advantage1Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec2Advantage1Text">
              <b>Prednost 1 Tekst:</b>
            </label>
            <textarea id="cg_sec2Advantage1Text" type="text" placeholder="Prednost 1 Tekst" name="cg_sec2Advantage1Text" value={formSection2.cg.sec2Advantage1Text} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.cg.sec2Advantage1Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*cg_sec2Advantage1Text*/}
        </div>
        {/*Advantage 1*/}

        {/*Advantage 2*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Prednost 2:</p>
          {/*cg_sec2Advantage2Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec2Advantage2Title">
              <b>Prednost 2 Naslov:</b>
            </label>
            <textarea id="cg_sec2Advantage2Title" type="text" placeholder="Prednost 2 Naslov" name="cg_sec2Advantage2Title" value={formSection2.cg.sec2Advantage2Title} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.cg.sec2Advantage2Title ? 'input-error-important' : ''}`} />
          </div>
          {/*cg_sec2Advantage2Title*/}
          {/*cg_sec2Advantage2Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec2Advantage2Text">
              <b>Prednost 2 Tekst:</b>
            </label>
            <textarea id="cg_sec2Advantage2Text" type="text" placeholder="Prednost 2 Tekst" name="cg_sec2Advantage2Text" value={formSection2.cg.sec2Advantage2Text} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.cg.sec2Advantage2Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*cg_sec2Advantage2Text*/}
        </div>
        {/*Advantage 2*/}

        {/*Advantage 3*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Prednost 3:</p>
          {/*cg_sec2Advantage3Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec2Advantage3Title">
              <b>Prednost 3 Naslov:</b>
            </label>
            <textarea id="cg_sec2Advantage3Title" type="text" placeholder="Prednost 3 Naslov" name="cg_sec2Advantage3Title" value={formSection2.cg.sec2Advantage3Title} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.cg.sec2Advantage3Title ? 'input-error-important' : ''}`} />
          </div>
          {/*cg_sec2Advantage3Title*/}
          {/*cg_sec2Advantage3Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec2Advantage3Text">
              <b>Prednost 3 Tekst:</b>
            </label>
            <textarea id="cg_sec2Advantage3Text" type="text" placeholder="Prednost 3 Tekst" name="cg_sec2Advantage3Text" value={formSection2.cg.sec2Advantage3Text} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.cg.sec2Advantage3Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*cg_sec2Advantage3Text*/}
        </div>
        {/*Advantage 3*/}

        {/*Advantage 4*/}
        <div className="cms-form-flex-col">
          <p className="card-top">Prednost 4:</p>
          {/*cg_sec2Advantage4Title*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec2Advantage4Title">
              <b>Prednost 4 Naslov:</b>
            </label>
            <textarea id="cg_sec2Advantage4Title" type="text" placeholder="Prednost 4 Naslov" name="cg_sec2Advantage4Title" value={formSection2.cg.sec2Advantage4Title} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.cg.sec2Advantage4Title ? 'input-error-important' : ''}`} />
          </div>
          {/*cg_sec2Advantage4Title*/}
          {/*cg_sec2Advantage4Text*/}
          <div className="ant-form-item-control-input-content mb-2" style={{ position: 'relative' }}>
            <label className="label d-block" htmlFor="cg_sec2Advantage4Text">
              <b>Prednost 4 Tekst:</b>
            </label>
            <textarea id="cg_sec2Advantage4Text" type="text" placeholder="Prednost 4 Tekst" name="cg_sec2Advantage4Text" value={formSection2.cg.sec2Advantage4Text} onChange={handleInputChangeFormSection2} className={`${formSection2Errors.cg.sec2Advantage4Text ? 'input-error-important' : ''} large`} />
          </div>
          {/*cg_sec2Advantage4Text*/}
        </div>
        {/*Advantage 4*/}
      </div>
    </>
  );
};
export default Section2Cg;
