import React from 'react';
import { stateUS } from '../../../../../helpers/state';
import Loading from '../../../../../components/loading/Loading';
import SidebarModal from '../../../../../components/sidebarModal/SidebarModal';
import { Select, Input, Button, Form, Row, Col, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

export const UpdateFormAllSettings = ({ updateProps, loadingEmployee, updateModalFormRef, updateFormFields, updateFormFieldsWithChanges, onChange, toggleNewPassword, roles, editBtnLoader, handleOnFinish, setToggleUpdate }) => {
  const { t } = useTranslation();
  const getFieldLabel = (name) => {
    const labels = {
      name: t('venueName'),
      lat: t('lat'),
      lng: t('long'),
      phones: t('phones'),
      workHours: t('workHours'),
      seo: 'SEO',
      disciplines: t('disciplines'),
      attributes: t('attributes'),
      is_student: t('student'),
      isFavourite: t('favorite'),
    };
    return labels[name] || name;
  };

  const getFieldRules = (name) => {
    const rules = {
      name: [{ required: false, message: t('pleaseInputVenueName') }],
      lat: [{ required: false, message: t('pleaseInputVenueLatitude') }],
      lng: [{ required: false, message: t('pleaseInputVenueLongitude') }],
      phones: [],
      workHours: [{ required: false, message: t('pleaseInputWorkHours') }],
      seo: [{ required: false, message: t('pleaseInputSeoData') }],
      disciplines: [{ required: false, message: t('pleaseSelectDisciplines') }],
      attributes: [{ required: false, message: t('pleaseSelectAttributes') }],
    };
    return rules[name] || [];
  };

  const getFieldInput = (name) => {
    const inputs = {
      name: <TextArea disabled placeholder={t('venueName')} style={{ resize: 'none' }} rows={4} />,
      lat: <Input disabled placeholder={t('lat')} type='number' />,
      lng: <Input disabled placeholder={t('long')} type='number' />,
      phones: <Select disabled mode='tags' />,
      disciplines: <Select disabled mode='tags' />,
      attributes: <Select disabled mode='tags' />,
      is_student: <Input disabled />,
      isFavourite: <Input disabled />,
      workHours: (
        <>
          {[t('monday'), t('tuesday'), t('wednesday'), t('thursday'), t('friday'), t('saturday'), t('sunday')].map((day) => (
            <Form.Item key={day} name={['workHours', day]} label={capitalize(day)} rules={[{ required: false, message: `Please enter ${capitalize(day)} hours` }]}>
              <Input disabled placeholder='09:00-23:00' />
            </Form.Item>
          ))}
        </>
      ),
      seo: (
        <>
          {[t('title'), t('description')].map((type) => (
            <Form.Item key={type} name={['seo', type]} label={capitalize(type)} rules={[{ required: false, message: `Please enter ${capitalize(type)} hours` }]}>
              {type === 'title' ? <TextArea disabled rows={4} style={{ resize: 'none' }} /> : <TextArea disabled rows={8} style={{ resize: 'none' }} />}
            </Form.Item>
          ))}
        </>
      ),
    };
    return inputs[name] || <Input />;
  };

  const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);

  return (
    <SidebarModal {...updateProps} className='sidebar__modal--right wide-sidebar' title={t('editRequest')}>
      {!loadingEmployee ? (
        <div className='wide-sidebar-wrapper'>
          <Form name='update' ref={updateModalFormRef} wrapperCol={{ span: 24 }} initialValues={{ remember: false }} onFinish={(data) => handleOnFinish({ name: 'update', data: data })} fields={updateFormFields}>
            <label style={{ fontWeight: 'bold' }}>{t('newValues')}</label>

            {updateFormFields.map((field) => (
              <Form.Item key={Array.isArray(field.name) ? field.name.join('-') : field.name} name={field.name} className={`${field.className} ${field.name}`} label={getFieldLabel(field.name)}>
                {getFieldInput(field.name)}
              </Form.Item>
            ))}

            <Form.Item className='ant-col-btn action-btns'>
              <Row gutter={16}>
                <Col span={12}>
                  <Button
                    type='primary'
                    htmlType='button'
                    className='btn btn-primary-link btn-block'
                    onClick={() => {
                      setToggleUpdate(false);
                      handleOnFinish({ name: 'update', data: 'REJECTED' });
                    }}
                  >
                    <span>{t('rejectChanges')}</span>
                  </Button>
                </Col>
                <Col span={12}>
                  {!editBtnLoader ? (
                    <Button
                      type='submit'
                      htmlType='submit'
                      className='btn btn-primary btn-block'
                      onClick={() => {
                        handleOnFinish({ name: 'update', data: 'APPROVED' });
                      }}
                    >
                      <span>{t('acceptChanges')}</span>
                    </Button>
                  ) : (
                    <Button type='button' className='btn btn-primary-outline btn-block'>
                      <span>{t('editing')}...</span>
                    </Button>
                  )}
                </Col>
              </Row>
            </Form.Item>
          </Form>

          <Form name='update' ref={updateModalFormRef} wrapperCol={{ span: 24 }} initialValues={{ remember: false }} onFinish={(data) => handleOnFinish({ name: 'update', data: data })} fields={updateFormFieldsWithChanges}>
            <label style={{ fontWeight: 'bold' }}>{t('oldValues')}</label>

            {updateFormFieldsWithChanges.map((field) => (
              <Form.Item key={Array.isArray(field.name) ? field.name.join('-') : field.name} name={field.name} className={`${field.className} ${field.name}`} label={getFieldLabel(field.name)}>
                {getFieldInput(field.name)}
              </Form.Item>
            ))}
          </Form>
        </div>
      ) : (
        <Loading />
      )}
    </SidebarModal>
  );
};
