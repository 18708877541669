const slugify = (str) => {
  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');
};

const slugifyReplaceAll = (str) => {
  return str
    .toLowerCase()
    .replaceAll('-', ' ')
    .replace(/\s+/g, '-');
};

const formatTime = (item) => {
  const time = new Date(item.createdAt).toTimeString().slice(0, 8);
  return `${time}`;
};

const formatDate = (item) => {
  const date = new Date(item.createdAt);

  let month = date.getMonth() + 1;
  let day = date.getDate();
  let year = date.getFullYear();

  return `${month}/${day}/${year}`;
};

const emailIsValid = (str) => {
  const reg = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  return reg.test(str.toLowerCase());
};

const numberIsValid = (num) => {
  const reg = new RegExp('[0-9]');
  return reg.test(num);
};

const upperCaseIsValid = (str) => {
  const reg = new RegExp(/.*[A-Z].*/);
  return reg.test(str);
};

const specialCharIsValid = (str) => {
  const reg = new RegExp(/[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/);
  return reg.test(str);
};

const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16));
};

/**
 * Group list data from array object
 * @param {*} data
 * @returns
 */
const getGroupList = (data) => {
  if (!data) {
    return false;
  }

  try {
    const results = data.reduce(function(acc, cur) {
      acc[cur.group] = acc[cur.group] || [];
      acc[cur.group].push(cur);

      return acc;
    }, Object.create(null));

    return results;
  } catch (err) {
    throw Error('Error');
  }
};

const getImageUrl = (URL) => {
  return URL;
};

/**
 *  Capitalize first letter of char
 * @param {*} string
 * @returns
 */
const capitalizeFirstLetter = (string) => {
  const dangerouslySetInnerHTML = {}; // disabled this line

  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Generated OTP id
 * @returns
 */
const generateId = () => {
  return Math.floor(1000 + Math.random() * 9000);
};

/**
 * Flatten function for array
 * @param {*} arr
 * @returns
 */
function flatten(arr) {
  return arr.reduce(function(flat, toFlatten) {
    return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
  }, []);
}

export { slugify, slugifyReplaceAll, formatTime, formatDate, emailIsValid, numberIsValid, upperCaseIsValid, specialCharIsValid, uuidv4, getGroupList, getImageUrl, generateId };
