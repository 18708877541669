import React, { useState, useCallback, useEffect } from 'react';

import axios from 'axios';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { SERVER_URL } from '../../../config/index';
import { PERMISSIONS } from '../../../utils/permissions';

import { useAuth } from '../../../context/useAuth';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

import { Main } from '../../../components/content';
import { Collapse } from 'antd';
import { notification } from 'antd';

import CmsHomepageSection1 from './sections/CmsHomepageSection1';
import CmsHomepageSection2 from './sections/CmsHomepageSection2';
import CmsHomepageSection3 from './sections/CmsHomepageSection3';
import CmsHomepageSection4 from './sections/CmsHomepageSection4';

import { constructInitialFormSection1State, constructInitialFormSection2State, constructInitialFormSection3State, constructInitialFormSection4State, constructInitialHpImagesState, constructInitialHpImagesPreviewState } from './helpers/helpers';
import { useTranslation } from 'react-i18next';

const CmsHomepage = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [groceryPermissions, setGroceryPermissions] = useState([]);
  const [initialData, setInitialData] = useState(null);
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;

    if (user && token) {
      const decodeToken = jwtDecode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
          setGroceryPermissions(permissions.grocery);
        }
      }
    }
  }, [user, location]);

  // SECTION 1 HANDLE
  const [hpImages, setHpImages] = React.useState({
    section1Icon1En: '',
    section1Icon2En: '',
    section2Image1En: '',
    section2Image2En: '',
    section3Icon1En: '',
    section3Icon2En: '',
    section3Icon3En: '',
    section3Icon4En: '',
    section3Icon5En: '',
    section3Icon6En: '',
    section4Image1En: '',

    section1Icon1Cg: '',
    section1Icon2Cg: '',
    section2Image1Cg: '',
    section2Image2Cg: '',
    section3Icon1Cg: '',
    section3Icon2Cg: '',
    section3Icon3Cg: '',
    section3Icon4Cg: '',
    section3Icon5Cg: '',
    section3Icon6Cg: '',
    section4Image1Cg: '',
  });
  const [hpImagesErrors, setHpImagesErrors] = React.useState({
    section1Icon1En: false,
    section1Icon2En: false,
    section2Image1En: false,
    section2Image2En: false,
    section3Icon1En: false,
    section3Icon2En: false,
    section3Icon3En: false,
    section3Icon4En: false,
    section3Icon5En: false,
    section3Icon6En: false,
    section4Image1En: false,

    section1Icon1Cg: false,
    section1Icon2Cg: false,
    section2Image1Cg: false,
    section2Image2Cg: false,
    section3Icon1Cg: false,
    section3Icon2Cg: false,
    section3Icon3Cg: false,
    section3Icon4Cg: false,
    section3Icon5Cg: false,
    section3Icon6Cg: false,
    section4Image1Cg: false,
  });
  const [hpImagesPreview, setHpImagesPreview] = React.useState({
    section1Icon1En: '',
    section1Icon2En: '',
    section2Image1En: '',
    section2Image2En: '',
    section3Icon1En: '',
    section3Icon2En: '',
    section3Icon3En: '',
    section3Icon4En: '',
    section3Icon5En: '',
    section3Icon6En: '',
    section4Image1En: '',

    section1Icon1Cg: '',
    section1Icon2Cg: '',
    section2Image1Cg: '',
    section2Image2Cg: '',
    section3Icon1Cg: '',
    section3Icon2Cg: '',
    section3Icon3Cg: '',
    section3Icon4Cg: '',
    section3Icon5Cg: '',
    section3Icon6Cg: '',
    section4Image1Cg: '',
  });
  const [formSection1, setFormSection1] = React.useState({
    hide: false,
    en: {
      title1: '',
      title2: '',
      subTitle1Part1: '',
      subTitle1Part2: '',
      subTitle2Part1: '',
      subTitle2Part2: '',
      button1Text: '',
      button2Text: '',
      button1Url: '',
      button2Url: '',
    },
    cg: {
      title1: '',
      title2: '',
      subTitle1Part1: '',
      subTitle1Part2: '',
      subTitle2Part1: '',
      subTitle2Part2: '',
      button1Text: '',
      button2Text: '',
      button1Url: '',
      button2Url: '',
    },
  });
  const [formSection1Errors, setFormSection1Errors] = React.useState({
    en: {
      title1: false,
      title2: false,
      subTitle1Part1: false,
      subTitle1Part2: false,
      subTitle2Part1: false,
      subTitle2Part2: false,
      button1Text: false,
      button2Text: false,
      button1Url: false,
      button2Url: false,
    },
    cg: {
      title1: false,
      title2: false,
      subTitle1Part1: false,
      subTitle1Part2: false,
      subTitle2Part1: false,
      subTitle2Part2: false,
      button1Text: false,
      button2Text: false,
      button1Url: false,
      button2Url: false,
    },
  });
  const handleInputChangeFormSection1 = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    if (name === 'section1_hide') {
      setFormSection1((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
      return;
    }

    setFormSection1((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value,
      },
    }));
  }, []);
  const onChangeTabSection1 = useCallback(() => {}, []);
  // SECTION 1 HANDLE

  // SECTION 2 HANDLE
  const [formSection2, setFormSection2] = React.useState({
    hide: false,
    en: {
      mainHeading: '',
      sec2button1Text: '',
      sec2button2Text: '',
      list1item1: '',
      list1item2: '',
      list1item3: '',
      list1item4: '',
      list1item5: '',
      list2item1: '',
      list2item2: '',
      list2item3: '',
      list2item4: '',
      // list2item5: '',
    },
    cg: {
      mainHeading: '',
      sec2button1Text: '',
      sec2button2Text: '',
      list1item1: '',
      list1item2: '',
      list1item3: '',
      list1item4: '',
      list1item5: '',
      list2item1: '',
      list2item2: '',
      list2item3: '',
      list2item4: '',
      // list2item5: '',
    },
  });
  const [formSection2Errors, setFormSection2Errors] = React.useState({
    en: {
      mainHeading: false,
      sec2button1Text: false,
      sec2button2Text: false,
      list1item1: false,
      list1item2: false,
      list1item3: false,
      list1item4: false,
      list1item5: false,
      list2item1: false,
      list2item2: false,
      list2item3: false,
      list2item4: false,
      // list2item5: false,
    },
    cg: {
      mainHeading: false,
      sec2button1Text: false,
      sec2button2Text: false,
      list1item1: false,
      list1item2: false,
      list1item3: false,
      list1item4: false,
      list1item5: false,
      list2item1: false,
      list2item2: false,
      list2item3: false,
      list2item4: false,
      // list2item5: false,
    },
  });
  const handleInputChangeFormSection2 = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    if (name === 'section2_hide') {
      setFormSection2((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
      return;
    }

    setFormSection2((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value,
      },
    }));
  }, []);
  const onChangeTabSection2 = useCallback(() => {}, []);
  // SECTION 2 HANDLE

  // SECTION 3 HANDLE
  const [formSection3, setFormSection3] = React.useState({
    hide: false,
    en: {
      sec3MainHeading: '',
      sec3Card1Title: '',
      sec3Card1SubTitle: '',
      sec3Card2Title: '',
      sec3Card2SubTitle: '',
      sec3Card3Title: '',
      sec3Card3SubTitle: '',
      sec3Card4Title: '',
      sec3Card4SubTitle: '',
      sec3Card5Title: '',
      sec3Card5SubTitle: '',
      sec3Card6Title: '',
      sec3Card6SubTitle: '',
    },
    cg: {
      sec3MainHeading: '',
      sec3Card1Title: '',
      sec3Card1SubTitle: '',
      sec3Card2Title: '',
      sec3Card2SubTitle: '',
      sec3Card3Title: '',
      sec3Card3SubTitle: '',
      sec3Card4Title: '',
      sec3Card4SubTitle: '',
      sec3Card5Title: '',
      sec3Card5SubTitle: '',
      sec3Card6Title: '',
      sec3Card6SubTitle: '',
    },
  });
  const [formSection3Errors, setFormSection3Errors] = React.useState({
    en: {
      sec3MainHeading: false,
      sec3Card1Title: false,
      sec3Card1SubTitle: false,
      sec3Card2Title: false,
      sec3Card2SubTitle: false,
      sec3Card3Title: false,
      sec3Card3SubTitle: false,
      sec3Card4Title: false,
      sec3Card4SubTitle: false,
      sec3Card5Title: false,
      sec3Card5SubTitle: false,
      sec3Card6Title: false,
      sec3Card6SubTitle: false,
    },
    cg: {
      sec3MainHeading: false,
      sec3Card1Title: false,
      sec3Card1SubTitle: false,
      sec3Card2Title: false,
      sec3Card2SubTitle: false,
      sec3Card3Title: false,
      sec3Card3SubTitle: false,
      sec3Card4Title: false,
      sec3Card4SubTitle: false,
      sec3Card5Title: false,
      sec3Card5SubTitle: false,
      sec3Card6Title: false,
      sec3Card6SubTitle: false,
    },
  });
  const handleInputChangeFormSection3 = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    if (name === 'section3_hide') {
      setFormSection3((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
      return;
    }

    setFormSection3((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value,
      },
    }));
  }, []);
  const onChangeTabSection3 = useCallback(() => {}, []);
  // SECTION 3 HANDLE

  // SECTION 4 HANDLE
  const [formSection4, setFormSection4] = React.useState({
    hide: false,
    en: {
      sec4VideoUrl: '',
      sec4StepsTitlePart1: '',
      sec4StepsTitlePart2: '',
      sec4Step1Title: '',
      sec4Step1Text: '',
      sec4Step2Title: '',
      sec4Step2Text: '',
      sec4Step3Title: '',
      sec4Step3Text: '',
      sec4StepsButtonText: '',
      sec4StepsButtonUrl: '',
    },
    cg: {
      sec4VideoUrl: '',
      sec4StepsTitlePart1: '',
      sec4StepsTitlePart2: '',
      sec4Step1Title: '',
      sec4Step1Text: '',
      sec4Step2Title: '',
      sec4Step2Text: '',
      sec4Step3Title: '',
      sec4Step3Text: '',
      sec4StepsButtonText: '',
      sec4StepsButtonUrl: '',
    },
  });
  const [formSection4Errors, setFormSection4Errors] = React.useState({
    en: {
      sec4VideoUrl: false,
      sec4StepsTitlePart1: false,
      sec4StepsTitlePart2: false,
      sec4Step1Title: false,
      sec4Step1Text: false,
      sec4Step2Title: false,
      sec4Step2Text: false,
      sec4Step3Title: false,
      sec4Step3Text: false,
      sec4StepsButtonText: false,
      sec4StepsButtonUrl: false,
    },
    cg: {
      sec4VideoUrl: false,
      sec4StepsTitlePart1: false,
      sec4StepsTitlePart2: false,
      sec4Step1Title: false,
      sec4Step1Text: false,
      sec4Step2Title: false,
      sec4Step2Text: false,
      sec4Step3Title: false,
      sec4Step3Text: false,
      sec4StepsButtonText: false,
      sec4StepsButtonUrl: false,
    },
  });
  const handleInputChangeFormSection4 = useCallback((e) => {
    const { name, value } = e.target;
    const [lang, field] = name.split('_');

    if (name === 'section4_hide') {
      setFormSection4((prevState) => ({
        ...prevState,
        hide: !prevState.hide,
      }));
      return;
    }

    setFormSection4((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [field]: value,
      },
    }));
  }, []);
  const onChangeTabSection4 = useCallback(() => {}, []);
  // SECTION 4 HANDLE

  // HANDLE FORM SUBMIT
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const { token } = user;

    // Initialize error flag
    let hasError = false;

    // Create a copy of the error state
    let newFormSection1Errors = { ...formSection1Errors };
    let newFormSection2Errors = { ...formSection2Errors };
    let newFormSection3Errors = { ...formSection3Errors };
    let newFormSection4Errors = { ...formSection4Errors };

    // Validate Section 1 (EN)
    Object.keys(formSection1.en).forEach((key) => {
      if (!formSection1.en[key]) {
        newFormSection1Errors.en[key] = true;
        hasError = true;
      } else {
        newFormSection1Errors.en[key] = false;
      }
    });

    // Validate Section 1 (CG)
    Object.keys(formSection1.cg).forEach((key) => {
      if (!formSection1.cg[key]) {
        newFormSection1Errors.cg[key] = true;
        hasError = true;
      } else {
        newFormSection1Errors.cg[key] = false;
      }
    });

    // Validate Section 2 (EN)
    Object.keys(formSection2.en).forEach((key) => {
      if (!formSection2.en[key]) {
        newFormSection2Errors.en[key] = true;
        hasError = true;
      } else {
        newFormSection2Errors.en[key] = false;
      }
    });

    // Validate Section 2 (CG)
    Object.keys(formSection2.cg).forEach((key) => {
      if (!formSection2.cg[key]) {
        newFormSection2Errors.cg[key] = true;
        hasError = true;
      } else {
        newFormSection2Errors.cg[key] = false;
      }
    });

    // Validate Section 3 (EN)
    Object.keys(formSection3.en).forEach((key) => {
      if (!formSection3.en[key]) {
        newFormSection3Errors.en[key] = true;
        hasError = true;
      } else {
        newFormSection3Errors.en[key] = false;
      }
    });

    // Validate Section 3 (CG)
    Object.keys(formSection3.cg).forEach((key) => {
      if (!formSection3.cg[key]) {
        newFormSection3Errors.cg[key] = true;
        hasError = true;
      } else {
        newFormSection3Errors.cg[key] = false;
      }
    });

    // Validate Section 4 (EN)
    Object.keys(formSection4.en).forEach((key) => {
      if (!formSection4.en[key]) {
        newFormSection4Errors.en[key] = true;
        hasError = true;
      } else {
        newFormSection4Errors.en[key] = false;
      }
    });

    // Validate Section 4 (CG)
    Object.keys(formSection4.cg).forEach((key) => {
      if (!formSection4.cg[key]) {
        newFormSection4Errors.cg[key] = true;
        hasError = true;
      } else {
        newFormSection4Errors.cg[key] = false;
      }
    });

    // check if there are any fcImages that have value of '' (empty string)
    Object.keys(hpImages).forEach((key) => {
      if (hpImages[key] === null) {
        setHpImagesErrors((prevState) => ({
          ...prevState,
          [key]: true,
        }));
        hasError = true;
      } else {
        setHpImagesErrors((prevState) => ({
          ...prevState,
          [key]: false,
        }));
      }
    });

    // Update error state
    setFormSection1Errors(newFormSection1Errors);
    setFormSection2Errors(newFormSection2Errors);
    setFormSection3Errors(newFormSection3Errors);

    // Prevent form submission if there are errors
    if (hasError) {
      notification.error({
        message: t('pleaseFillAllTheFieldsAndUploadImages'),
        placement: 'topRight',
      });
      return;
    }

    setSaving(true);

    const payload = {
      section1Hide: formSection1.hide || false,
      section2Hide: formSection2.hide || false,
      section3Hide: formSection3.hide || false,
      section4Hide: formSection4.hide || false,

      section1Card1TitleEn: formSection1.en.title1 || '',
      section1Card1SubtitlePart1En: formSection1.en.subTitle1Part1 || '',
      section1Card1SubtitlePart2En: formSection1.en.subTitle1Part2 || '',
      section1Card2SubtitlePart1En: formSection1.en.subTitle2Part1 || '',
      section1Card2SubtitlePart2En: formSection1.en.subTitle2Part2 || '',
      section1Card1ButtonTextEn: formSection1.en.button1Text || '',
      section1Card1ButtonUrlEn: formSection1.en.button1Url || '',
      section1Card2TitleEn: formSection1.en.title2 || '',
      section1Card2ButtonTextEn: formSection1.en.button2Text || '',
      section1Card2ButtonUrlEn: formSection1.en.button2Url || '',

      section1Card1TitleCg: formSection1.cg.title1 || '',
      section1Card1SubtitlePart1Cg: formSection1.cg.subTitle1Part1 || '',
      section1Card1SubtitlePart2Cg: formSection1.cg.subTitle1Part2 || '',
      section1Card2SubtitlePart1Cg: formSection1.cg.subTitle2Part1 || '',
      section1Card2SubtitlePart2Cg: formSection1.cg.subTitle2Part2 || '',
      section1Card1ButtonTextCg: formSection1.cg.button1Text || '',
      section1Card1ButtonUrlCg: formSection1.cg.button1Url || '',
      section1Card2TitleCg: formSection1.cg.title2 || '',
      section1Card2ButtonTextCg: formSection1.cg.button2Text || '',
      section1Card2ButtonUrlCg: formSection1.cg.button2Url || '',

      section2MainHeadingEn: formSection2.en.mainHeading || '',
      section2Button1TextEn: formSection2.en.sec2button1Text || '',
      section2Button2TextEn: formSection2.en.sec2button2Text || '',
      section2List1Item1En: formSection2.en.list1item1 || '',
      section2List1Item2En: formSection2.en.list1item2 || '',
      section2List1Item3En: formSection2.en.list1item3 || '',
      section2List1Item4En: formSection2.en.list1item4 || '',
      section2List1Item5En: formSection2.en.list1item5 || '',
      section2List2Item1En: formSection2.en.list2item1 || '',
      section2List2Item2En: formSection2.en.list2item2 || '',
      section2List2Item3En: formSection2.en.list2item3 || '',
      section2List2Item4En: formSection2.en.list2item4 || '',
      // section2List2Item5En: formSection2.en.list2item5 || '',

      section2MainHeadingCg: formSection2.cg.mainHeading || '',
      section2Button1TextCg: formSection2.cg.sec2button1Text || '',
      section2Button2TextCg: formSection2.cg.sec2button2Text || '',
      section2List1Item1Cg: formSection2.cg.list1item1 || '',
      section2List1Item2Cg: formSection2.cg.list1item2 || '',
      section2List1Item3Cg: formSection2.cg.list1item3 || '',
      section2List1Item4Cg: formSection2.cg.list1item4 || '',
      section2List1Item5Cg: formSection2.cg.list1item5 || '',
      section2List2Item1Cg: formSection2.cg.list2item1 || '',
      section2List2Item2Cg: formSection2.cg.list2item2 || '',
      section2List2Item3Cg: formSection2.cg.list2item3 || '',
      section2List2Item4Cg: formSection2.cg.list2item4 || '',
      // section2List2Item5Cg: formSection2.cg.list2item5 || '',

      section3MainHeadingEn: formSection3.en.sec3MainHeading || '',
      section3Card1TitleEn: formSection3.en.sec3Card1Title || '',
      section3Card1SubtitleEn: formSection3.en.sec3Card1SubTitle || '',
      section3Card2TitleEn: formSection3.en.sec3Card2Title || '',
      section3Card2SubtitleEn: formSection3.en.sec3Card2SubTitle || '',
      section3Card3TitleEn: formSection3.en.sec3Card3Title || '',
      section3Card3SubtitleEn: formSection3.en.sec3Card3SubTitle || '',
      section3Card4TitleEn: formSection3.en.sec3Card4Title || '',
      section3Card4SubtitleEn: formSection3.en.sec3Card4SubTitle || '',
      section3Card5TitleEn: formSection3.en.sec3Card5Title || '',
      section3Card5SubtitleEn: formSection3.en.sec3Card5SubTitle || '',
      section3Card6TitleEn: formSection3.en.sec3Card6Title || '',
      section3Card6SubtitleEn: formSection3.en.sec3Card6SubTitle || '',

      section3MainHeadingCg: formSection3.cg.sec3MainHeading || '',
      section3Card1TitleCg: formSection3.cg.sec3Card1Title || '',
      section3Card1SubtitleCg: formSection3.cg.sec3Card1SubTitle || '',
      section3Card2TitleCg: formSection3.cg.sec3Card2Title || '',
      section3Card2SubtitleCg: formSection3.cg.sec3Card2SubTitle || '',
      section3Card3TitleCg: formSection3.cg.sec3Card3Title || '',
      section3Card3SubtitleCg: formSection3.cg.sec3Card3SubTitle || '',
      section3Card4TitleCg: formSection3.cg.sec3Card4Title || '',
      section3Card4SubtitleCg: formSection3.cg.sec3Card4SubTitle || '',
      section3Card5TitleCg: formSection3.cg.sec3Card5Title || '',
      section3Card5SubtitleCg: formSection3.cg.sec3Card5SubTitle || '',
      section3Card6TitleCg: formSection3.cg.sec3Card6Title || '',
      section3Card6SubtitleCg: formSection3.cg.sec3Card6SubTitle || '',

      section4VideoUrlEn: formSection4.en.sec4VideoUrl || '',
      section4StepsTitleEnPart1: formSection4.en.sec4StepsTitlePart1 || '',
      section4StepsTitleEnPart2: formSection4.en.sec4StepsTitlePart2 || '',
      section4Step1TitleEn: formSection4.en.sec4Step1Title || '',
      section4Step1TextEn: formSection4.en.sec4Step1Text || '',
      section4Step2TitleEn: formSection4.en.sec4Step2Title || '',
      section4Step2TextEn: formSection4.en.sec4Step2Text || '',
      section4Step3TitleEn: formSection4.en.sec4Step3Title || '',
      section4Step3TextEn: formSection4.en.sec4Step3Text || '',
      section4StepsButtonTextEn: formSection4.en.sec4StepsButtonText || '',
      section4StepsButtonUrlEn: formSection4.en.sec4StepsButtonUrl || '',

      section4VideoUrlCg: formSection4.cg.sec4VideoUrl || '',
      section4StepsTitleCgPart1: formSection4.cg.sec4StepsTitlePart1 || '',
      section4StepsTitleCgPart2: formSection4.cg.sec4StepsTitlePart2 || '',
      section4Step1TitleCg: formSection4.cg.sec4Step1Title || '',
      section4Step1TextCg: formSection4.cg.sec4Step1Text || '',
      section4Step2TitleCg: formSection4.cg.sec4Step2Title || '',
      section4Step2TextCg: formSection4.cg.sec4Step2Text || '',
      section4Step3TitleCg: formSection4.cg.sec4Step3Title || '',
      section4Step3TextCg: formSection4.cg.sec4Step3Text || '',
      section4StepsButtonTextCg: formSection4.cg.sec4StepsButtonText || '',
      section4StepsButtonUrlCg: formSection4.cg.sec4StepsButtonUrl || '',

      // IMAGES
      section1Card1IconEn: hpImages.section1Icon1En || null,
      section1Card2IconEn: hpImages.section1Icon2En || null,
      section2Image1En: hpImages.section2Image1En || null,
      section2Image2En: hpImages.section2Image2En || null,
      section3Icon1En: hpImages.section3Icon1En || null,
      section3Icon2En: hpImages.section3Icon2En || null,
      section3Icon3En: hpImages.section3Icon3En || null,
      section3Icon4En: hpImages.section3Icon4En || null,
      section3Icon5En: hpImages.section3Icon5En || null,
      section3Icon6En: hpImages.section3Icon6En || null,
      section4Image1En: hpImages.section4Image1En || null,

      section1Card1IconCg: hpImages.section1Icon1Cg || null,
      section1Card2IconCg: hpImages.section1Icon2Cg || null,
      section2Image1Cg: hpImages.section2Image1Cg || null,
      section2Image2Cg: hpImages.section2Image2Cg || null,
      section3Icon1Cg: hpImages.section3Icon1Cg || null,
      section3Icon2Cg: hpImages.section3Icon2Cg || null,
      section3Icon3Cg: hpImages.section3Icon3Cg || null,
      section3Icon4Cg: hpImages.section3Icon4Cg || null,
      section3Icon5Cg: hpImages.section3Icon5Cg || null,
      section3Icon6Cg: hpImages.section3Icon6Cg || null,
      section4Image1Cg: hpImages.section4Image1Cg || null,
    };
    try {
      if (!initialData || initialData.length === 0) {
        const response = await axios.post(
          `${SERVER_URL}/${AXIOS_API_CALL.createHpDocument}`,
          {
            ...payload,
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      } else {
        const response = await axios.put(
          `${SERVER_URL}/${AXIOS_API_CALL.updateHpDocument}/${initialData[0]?._id}`,
          {
            ...payload,
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
    notification.success({
      message: t('dataSavedSuccess'),
    });
    setSaving(false);
    setTimeout(() => {
      navigate(0);
    }, 1000);
  };
  // HANDLE FORM SUBMIT

  // HANDLE FETCH AND SET INITIAL DATA
  const handleFetchData = async () => {
    const { token } = user;
    try {
      const response = await axios.get(`${SERVER_URL}/${AXIOS_API_CALL.getHpDocument}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });
      setInitialData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  useEffect(() => {
    if (!initialData) return;
    const data = initialData[0];
    setFormSection1((prevState) => constructInitialFormSection1State(data, prevState));
    setFormSection2((prevState) => constructInitialFormSection2State(data, prevState));
    setFormSection3((prevState) => constructInitialFormSection3State(data, prevState));
    setFormSection4((prevState) => constructInitialFormSection4State(data, prevState));
    setHpImages((prevState) => constructInitialHpImagesState(data, prevState));
    setHpImagesPreview((prevState) => constructInitialHpImagesPreviewState(data, prevState));
  }, [initialData]);
  // HANDLE FETCH AND SET INITIAL DATA

  // PANEL AND TABS RELATED FUNCTIONS
  const { Panel } = Collapse;
  const onChange = useCallback(() => {}, []);
  // PANEL AND TABS RELATED FUNCTIONS

  return (
    <div className="cms cms-homepage">
      <h2 className="page-title">{t('cmsHomepage')}</h2>
      {/* Main Content */}
      <form className="cms-form cms-form-section cms-form-section1">
        <Main className="section__content relative">
          <Collapse onChange={onChange}>
            {/* SECTION 1 (Companies - Partners) */}
            <Panel header={t('section1CompaniesPartners')} key="1">
              <CmsHomepageSection1 onChangeTabSection1={onChangeTabSection1} handleInputChangeFormSection1={handleInputChangeFormSection1} formSection1={formSection1} setHpImages={setHpImages} hpImages={hpImages} hpImagesErrors={hpImagesErrors} hpImagesPreview={hpImagesPreview} formSection1Errors={formSection1Errors} />
            </Panel>
            {/* SECTION 1 (Companies - Partners) */}
            {/* SECTION 2 (Everyone wins with Fitpass!) */}
            <Panel header={t('section2EveryoneWins')} key="2">
              <CmsHomepageSection2 onChangeTabSection2={onChangeTabSection2} formSection2={formSection2} handleInputChangeFormSection2={handleInputChangeFormSection2} setHpImages={setHpImages} hpImages={hpImages} hpImagesErrors={hpImagesErrors} hpImagesPreview={hpImagesPreview} formSection2Errors={formSection2Errors} />
            </Panel>
            {/* SECTION 2 (Everyone wins with Fitpass!) */}
            {/* SECTION 3 (Discover all Fitpass benefits!) */}
            <Panel header={t('section3DiscoverFitpassBenefits')} key="3">
              <CmsHomepageSection3 onChangeTabSection3={onChangeTabSection3} formSection3={formSection3} handleInputChangeFormSection3={handleInputChangeFormSection3} setHpImages={setHpImages} hpImages={hpImages} hpImagesErrors={hpImagesErrors} hpImagesPreview={hpImagesPreview} formSection3Errors={formSection3Errors} />
            </Panel>
            {/* SECTION 3 (Discover all Fitpass benefits!) */}
            {/* SECTION 4 (Get Fitpass in 3 simple steps) */}
            <Panel header={t('section4GetFitpass3Steps')} key="4">
              <CmsHomepageSection4 onChangeTabSection4={onChangeTabSection4} formSection4={formSection4} handleInputChangeFormSection4={handleInputChangeFormSection4} setHpImages={setHpImages} hpImages={hpImages} hpImagesErrors={hpImagesErrors} hpImagesPreview={hpImagesPreview} formSection4Errors={formSection4Errors} />
            </Panel>
            {/* SECTION 4 (Get Fitpass in 3 simple steps) */}
          </Collapse>
          <button className="btn btn-primary pl-3 pr-3 mt-3 mb-5" onClick={(e) => handleFormSubmit(e)}>
            {saving ? t('savingChanges') : t('saveChanges')}
          </button>
        </Main>
      </form>
      {/* Main Content */}
    </div>
  );
};

export default CmsHomepage;
