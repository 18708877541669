import React, { memo } from 'react';
import { Tabs } from 'antd';
import SectionHeaderEn from '../tabs/en/SectionHeaderEn';
import SectionHeaderCg from '../tabs/cg/SectionHeaderCg';

const CmsContactHeader = ({ onChangeTabSectionHeader, handleInputChangeFormSectionHeader, formSectionHeader, ctImages, ctImagesPreview, setCtImages, ctImagesErrors, formSectionHeaderErrors }) => {
  const { TabPane } = Tabs;

  const sectionProps = {
    handleInputChangeFormSectionHeader,
    formSectionHeader,
    ctImages,
    ctImagesPreview,
    setCtImages,
    onChangeTabSectionHeader,
    ctImagesErrors,
    formSectionHeaderErrors,
  };
  return (
    <>
      <Tabs defaultActiveKey="1" onChange={onChangeTabSectionHeader}>
        <TabPane key="1" tab="EN">
          <SectionHeaderEn {...sectionProps} />
        </TabPane>
        <TabPane key="2" tab="CG">
          <SectionHeaderCg {...sectionProps} />
        </TabPane>
      </Tabs>
    </>
  );
};
export default memo(CmsContactHeader);
