import React from 'react';

import { Input, Button, Form, Row } from 'antd';
import Modal from '../../../../../components/modal/Modal';
import { useTranslation } from 'react-i18next';

export const UpdateEmployeeStatusMultiChanges = ({ statusMultiProps, statusModalMultiFormRef, handleOnFinish, statusMultiFormFields, deactivateBlkBtnLoader, setToggleStatusMulti }) => {
  const { t } = useTranslation();
  return (
    <Modal {...statusMultiProps} className='sidebar__modal--center' title=''>
      <Form name='updateStatusMulti' ref={statusModalMultiFormRef} wrapperCol={{ span: 24 }} initialValues={{ remember: false }} onFinish={(data) => handleOnFinish({ name: 'updateStatusMulti', data: data })} fields={statusMultiFormFields}>
        <h2 className='text-center mb-4'>
          <span className='d-block'>{t('areYouSureYouWantToChange')}</span>
          <span className='d-block'>{t('statusOfSelectedAdmins')}?</span>
        </h2>

        <Form.Item name='statusMultiUser' style={{ display: 'none' }}>
          <Input type='hidden' />
        </Form.Item>

        <Form.Item>
          <Row gutter={16} style={{ justifyContent: 'center', columnGap: '20px' }} className='row-direction'>
            {!deactivateBlkBtnLoader ? (
              <Button type='submit' htmlType='submit' className='btn btn-primary-link btn-block'>
                <span>{t('yes')}</span>
              </Button>
            ) : (
              <Button type='button' className='btn btn-primary-link btn-block'>
                <span>{t('deactivating')}...</span>
              </Button>
            )}

            <Button type='primary' htmlType='button' className='btn btn-primary btn-block' onClick={() => setToggleStatusMulti(false)}>
              <span>{t('no')}</span>
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};
