const Tooltip = ({ text, acceptFormatText }) => {
  return (
    <span className="tooltips">
      <img src="/assets/icons/tooltip-icon.svg" alt="tooltip icon" width="20px" height="20px" />
      <span className="tooltips-desc">
        {text && <span>{text}</span>}
        {acceptFormatText && <span>Format: (JPG, PNG, WEBP)</span>}
      </span>
    </span>
  );
};
export default Tooltip;
