import React from 'react';

const Header = (props) => {
  const { onToggle, className } = props;

  return (
    <header className={`gl__header ${className !== undefined ? className : ''}`}>
      <button type="button" className={`btn menu-btn`} onClick={() => onToggle()} data-cy="toggle-menu">
        <span className="icon">
          <img src="/assets/icons/bars.svg" alt="Open Menu" />
        </span>
        <span className="text">Menu</span>
      </button>
    </header>
  );
};

export default Header;
