import React from 'react';
import { useTranslation } from 'react-i18next';

const Loading = (props) => {
  const { t } = useTranslation();
  const { className } = props;

  return (
    <div className={`loading ${className !== undefined ? className : ''}`}>
      <div className="loading-container">
        <span className="loading-animation" />
        <span className="loading-text">{t('loading')}...</span>
      </div>
    </div>
  );
};

export default Loading;
