import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useAuth } from '../../../context/useAuth';
import { useLocation } from 'react-router-dom';
import { PERMISSIONS } from '../../../utils/permissions';
import { getGroceryPermissions } from '../../../middleware';
import { Main, Section } from '../../../components/content';
import { jwtDecode } from 'jwt-decode';
import LoadingPlaceholder from '../../../components/loadingPlaceholder/LoadingPlaceholder';
import axios from 'axios';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { Select, Button, notification } from 'antd';
import { SERVER_URL } from '../../../config/index';
import Loading from '../../../components/loading/Loading';
import Table from './Table';
import { CreateForm } from './create-form/CreateForm';
import { notificationMessages } from '../../../helpers/notificationMessages';
import { UpdateFormAllSettings } from './update-form/update-form-all-settings/UpdateFormAllSettings';
import { useTranslation } from 'react-i18next';

const CountryCodes = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const location = useLocation();
  const [groceryPermissions, setGroceryPermissions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limitPage, setLimitPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [refetch, setRefetch] = useState(false);
  const [list, setList] = useState([]);
  // SEARCH
  const [search, setSearch] = useState(null);
  const [filterStatus, setFilterStatus] = useState(null);
  const [toggleCreate, setToggleCreate] = useState(false);
  const [toggleUpdate, setToggleUpdate] = useState(false);
  const [toggleDelete, setToggleDelete] = useState(false);
  const createModalFormRef = useRef(null);
  const updateModalFormRef = useRef(null);
  const deleteModalFormRef = useRef(null);
  const statusModalSingleFormRef = useRef(null);
  const [createCountryCode, setCreateCountryCode] = useState([]);
  const [updateCountryCode, setUpdateCountryCode] = useState([]);
  const [deleteCountryCode, setDeleteCountryCode] = useState([]);
  const [loadingCountryCode, setLoadingCountryCode] = useState(false);
  const [toggleStatusSingle, setToggleStatusSingle] = useState(false);
  const [statusSingleCountryCode, setStatusSingleCountryCode] = useState([]);
  const [createBtnLoader, setCreateBtnLoader] = useState(false);
  const [editBtnLoader, setEditBtnLoader] = useState(false);
  const [countryCodeTestUpdateId, setCountryCodeTestUpdateId] = useState({
    id: '',
  });

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;

    if (user && token) {
      const decodeToken = jwtDecode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
          setGroceryPermissions(permissions.grocery);
        }
      }
    }
  }, [user, location]);

  // FETCH ROLES
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    const { token } = user;

    try {
      axios
        .post(
          `${SERVER_URL}/${AXIOS_API_CALL.getRoles}/null/null/null`,
          { department: PERMISSIONS.grocery },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setRoles(res.data?.data.Data);
        })
        .catch((err) => console.error(err))
        .finally(setTimeout(() => {}, 700));
    } catch (err) {
      console.error(err);
    }
  }, [user]);

  const getTableData = useCallback(async () => {
    const { token } = user;

    setLoading(true);
    try {
      await axios
        .get(`${SERVER_URL}/${AXIOS_API_CALL.getAllCountryCodes}`, {
          params: {
            limit: '10',
            pageNumber: '1',
            searchTerm: '',
          },
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          setList(res?.data);
          setTimeout(() => {
            setLoading(false);
          }, 700);
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  }, [user, currentPage, limitPage, search, filterStatus]);

  // FETCH DATA FOR `TABLE`
  useEffect(() => {
    getTableData();
  }, [getTableData, refetch]);

  const handleOnFinish = async (props) => {
    const { name, formData } = props;

    const { token } = user;

    switch (name) {
      case 'create':
        setCreateBtnLoader(true);
        try {
          const data = {
            code: parseInt(formData?.countryCode) || '',
            countryName: formData?.countryName || '',
            timeZone: formData?.countryTimeZone || '',
          };

          let fetchData = await axios.post(
            `${SERVER_URL}/${AXIOS_API_CALL.createCountryCode}`,
            { ...data },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          if (fetchData.status === 201) {
            setRefetch((prevState) => !prevState);
            setCurrentPage(1);
            setToggleCreate(false);
            setCreateCountryCode([]);

            createModalFormRef.current.resetFields();

            // notification
            notification.success({
              message: t('successCreateCountryCode'),
              placement: 'bottomLeft',
            });
          }
          setTimeout(() => {
            setCreateBtnLoader(false);
          }, 700);
        } catch (err) {
          console.error(err);
          setCreateBtnLoader(false);

          notification.error({
            message: t('errorCreateCountryCode'),
            placement: 'bottomLeft',
          });
        }
        break;
      case 'update':
        // setEditBtnLoader(true);
        try {
          const updateData = {
            id: formData?.updateCountryCodeId,
            code: parseInt(formData?.countryCode) || '',
            countryName: formData?.countryName || '',
            timeZone: formData?.countryTimezone || '',
          };
          const countryCodeUpdateResponse = await axios.put(
            `${SERVER_URL}/${AXIOS_API_CALL.updateCountryCodeById}/${updateData.id}`,
            { ...updateData },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          if (countryCodeUpdateResponse.request.status === 200) {
            setTimeout(() => {
              setEditBtnLoader(false);
            }, 700);
          }
          setRefetch((prevState) => !prevState);
          setCurrentPage(1);
          setToggleUpdate(false);
          setUpdateCountryCode([]);
          setCountryCodeTestUpdateId({ id: '' });

          setTimeout(() => {
            updateModalFormRef.current.resetFields();
          }, 750);
          notification.success({
            message: t('successUpdateCountryCode'),
            placement: 'bottomLeft',
          });
        } catch (err) {}
        break;
      default:
        console.error('handleOnFinish ---- error');
    }
  };

  // FETCH UPDATE DATA
  useEffect(() => {
    const countryCodeUpdateId = countryCodeTestUpdateId.id;
    const { token } = user;

    if (!!countryCodeUpdateId) {
      try {
        setLoadingCountryCode(true);
        axios
          .get(`${SERVER_URL}/${AXIOS_API_CALL.getCountryCodeById}`, {
            params: {
              id: countryCodeUpdateId,
            },
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            setUpdateCountryCode(res.data);
            setTimeout(() => {
              setLoadingCountryCode(false);
            }, 700);
          })
          .catch((err) => {
            setLoadingCountryCode(false);
            console.error(err);
          });
      } catch (err) {
        console.error(err);
      }
    }
  }, [user, countryCodeTestUpdateId, refetch]);

  const handleToggle = (props) => {
    const { name, state, data } = props;

    switch (name) {
      case 'create':
        state.onToggle((prevState) => !prevState);
        break;
      case 'update':
        // setEmployeeUpdateId(data);
        setCountryCodeTestUpdateId({ id: data });
        state.onToggle((prevState) => !prevState);
        break;
      case 'status':
        setEmployeeStatusSingleId({ id: data.id, status: data.status });
        state.onToggle((prevState) => !prevState);
        break;
      case 'updateStatusMulti':
        setEmployeeStatusMultiId(data);
        state.onToggle((prevState) => !prevState);
        break;
      case 'delete':
        setEmployeeDeleteId(data);
        state.onToggle((prevState) => !prevState);
        break;
      default:
        console.error('handleToggle ---- error');
    }
  };

  const createFormFields = [
    {
      name: ['role'],
      value: '',
    },
    {
      name: ['state'],
      value: '',
    },
    {
      name: 'createUserId',
      value: user.id,
    },
    {
      name: 'createUserType',
      value: 'dashboard',
    },
  ];

  const updateFormFields = [
    {
      name: 'updateCountryCodeId',
      value: updateCountryCode._id,
    },
    {
      name: 'countryCode',
      value: updateCountryCode.code,
    },
    {
      name: 'countryName',
      value: updateCountryCode.countryName,
    },
    {
      name: 'countryTimezone',
      value: updateCountryCode.timeZone,
    },
  ];

  const createProps = {
    active: toggleCreate,
    onToggle: setToggleCreate,
    formRef: createModalFormRef,
    data: createCountryCode,
    onData: setCreateCountryCode,
  };

  const createFormProps = {
    createProps,
    createModalFormRef,
    handleOnFinish,
    createFormFields,
    roles,
    createBtnLoader,
    setToggleCreate,
  };

  const updateProps = {
    active: toggleUpdate,
    onToggle: setToggleUpdate,
    formRef: updateModalFormRef,
    data: updateCountryCode,
    onData: setUpdateCountryCode,
    loading: loadingCountryCode,
  };

  const updateFormAllSettingsProps = {
    updateProps,
    loadingCountryCode,
    updateModalFormRef,
    updateFormFields,
    roles,
    editBtnLoader,
    handleOnFinish,
    setToggleUpdate,
  };

  const deleteProps = {
    active: toggleDelete,
    onToggle: setToggleDelete,
    formRef: deleteModalFormRef,
    data: deleteCountryCode,
    onData: setDeleteCountryCode,
  };

  const statusSingleProps = {
    active: toggleStatusSingle,
    onToggle: setToggleStatusSingle,
    formRef: statusModalSingleFormRef,
    data: statusSingleCountryCode,
    onData: setStatusSingleCountryCode,
  };

  const tableActionsProps = {
    // custom for this table - always changes for next implementation
    handleToggle: handleToggle,

    updateActions: updateProps,
    deleteActions: deleteProps,
    statusActions: statusSingleProps,

    location: location,
    groceryPermissions: groceryPermissions,
    getGroceryPermissions: getGroceryPermissions,
  };

  const handleStatusFilter = (value) => {
    setFilterStatus(value);
    setRefetch((prevState) => !prevState);
  };

  const tableFiltersProps = {
    handleStatusFilter: handleStatusFilter,
  };

  const paginationProps = {
    current: currentPage,
    setCurrentPage: setCurrentPage,
    limitPage: limitPage,
    buttonLimit: 3,
    pageCount: list?.pagesCount,
    totalCount: list?.totalCount,
  };

  return (
    <>
      <h2 className="page-title">{t('countryCodes')}</h2>
      <Section className="section section__wrapper">
        <header className="section__header">
          <div className="actions">
            {getGroceryPermissions(groceryPermissions, PERMISSIONS.createEmployees) && (
              <>
                {!loading ? (
                  <Button type="button" htmlType="button" onClick={() => handleToggle({ name: 'create', state: createProps })} data-cy="create-employee-btn" className="btn btn-primary pl-4 pr-4">
                    <span className="text">{t('createCountryCode')}</span>
                  </Button>
                ) : (
                  <LoadingPlaceholder style={{ width: '180.5px', height: '50px' }} />
                )}
              </>
            )}
          </div>
        </header>

        <Main className="section__content relative min-h-table-content">{loading ? <Loading /> : <Table data={list?.paginatedData} {...tableActionsProps} {...tableFiltersProps} pagination={paginationProps} />}</Main>

        {getGroceryPermissions(groceryPermissions, PERMISSIONS.createCountryCodes) && <CreateForm {...createFormProps} />}

        {getGroceryPermissions(groceryPermissions, PERMISSIONS.updateCountryCodes) && <UpdateFormAllSettings {...updateFormAllSettingsProps} />}
      </Section>
    </>
  );
};
export default CountryCodes;
