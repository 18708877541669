import React from 'react';
import Modal from '../../../../components/modal/Modal';
import { useTranslation } from 'react-i18next';

import { Form } from 'antd';

export const DeleteAttribute = ({ deleteProps, handleOnSubmit, deleteFormRef, deleteBtnLoader, setToggleDelete }) => {
  const { t } = useTranslation();
  return (
    <Modal {...deleteProps} className="sidebar__modal--center" title="">
      <Form ref={deleteFormRef} className="ant-form ant-form-horizontal" onFinish={(event) => handleOnSubmit({ event: event, form: deleteProps, type: 'delete-global-attribute' })}>
        <h2 className="text-center mb-4">{t('areYouSureToDelete')} ?</h2>

        <div className="form-group form-group-modal mb-4">
          {!deleteBtnLoader ? (
            <button data-cy="modal-delete-submit-btn" type="submit" className="btn btn-primary-link">
              <span className="text">{t('yes')}</span>
            </button>
          ) : (
            <button type="button" className="btn btn-primary-outline">
              <span className="text">{t('deleting')}...</span>
            </button>
          )}

          <button data-cy="modal-delete-cancel-btn" type="button" className="btn btn-primary" onClick={() => setToggleDelete(false)}>
            <span className="text">{t('no')}</span>
          </button>
        </div>
      </Form>
    </Modal>
  );
};
