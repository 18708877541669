import React, { useEffect, useState } from 'react';
import { Popover } from 'antd';
import Checkbox from '../../../components/customCheckbox/CustomCheckbox';
import { PERMISSIONS } from '../../../utils/permissions';
import Message from '../../../components/message/Message';
import { contentMessage } from '../../../helpers/contentMessage';
import Pagination from '../../../components/pagination/Pagination';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const Table = (props) => {
  const { t } = useTranslation();
  // TABLE
  const { data } = props;
  // PAGINATION
  const { pagination } = props;

  // CUSTOM FOR ACTIONS
  const { updateActions, statusActions, deleteActions, location, groceryPermissions, getGroceryPermissions } = props;
  // CUSTOM FOR FILTERS
  const { handleStatusFilter } = props;

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  // HANDLE CHECKED ALL
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(data.map((li) => li._id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  // HANDLE CHECKED CURRENT
  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheckAll(true);
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  // UNTOGGLE CHECKED TOP
  useEffect(() => {
    if (isCheck.length < 1) {
      setIsCheckAll(false);
    }
  }, [isCheck, setIsCheckAll]);

  return (
    <>
      <table className='table'>
        <thead className='thead'>
          <tr>
            <th>{t('venueName')}</th>
            <th>{t('requestedBy')}</th>
            <th>{t('requestedAt')}</th>
          </tr>
        </thead>
        <tbody className='tbody'>
          {data && data.length > 0 ? (
            data?.map((item, idx) => {
              const formatedDate = moment(item.requestedAt).format('DD/MM/YYYY HH:mm');
              return (
                <tr key={item._id}>
                  <td>{item.newValues.name}</td>
                  <td>
                    {item.requestedBy.firstName} {item.requestedBy.lastName}
                  </td>
                  <td>{formatedDate}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={20}>
                <Message className='content-message'>
                  <h2 style={{ color: '#666' }}>{t('dataNotFound')}</h2>
                </Message>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {data && data.length > 0 && <Pagination {...pagination} />}
    </>
  );
};

export default Table;
