import React from 'react';
import SidebarModal from '../../../../components/sidebarModal/SidebarModal';
import { stateUS } from '../../../../helpers/state';
import Loading from '../../../../components/loading/Loading';
import { useTranslation } from 'react-i18next';

export const UpdateForm = ({ updateProps, updateLoading, updateModalFormRef, handleOnSubmit, handleOnChange, handleOnBlur, sleep, updateFormError, updateAddress, handleOnRemoveUpdate, toggleUpdateAddress, sleepAddress, toggleNewPassword, setToggleUpdateAddress, handleOnSaveUpdate, updatePasswordProps, editBtnLoader, setToggleUpdate, setToggleNewPassword, TogglePasswordChecker }) => {
  const { t } = useTranslation();
  return (
    <SidebarModal {...updateProps} className='sidebar__modal--right' title={t('editMember')}>
      {!updateLoading ? (
        <form
          name='update'
          ref={updateModalFormRef}
          onSubmit={(event) =>
            handleOnSubmit({
              event: event,
              name: 'update',
              state: updateProps,
            })
          }
          className='ant-form ant-form-horizontal'
        >
          <div className='form-group relative'>
            <label className='label mb-1 d-block' htmlFor='updateFirstName'>
              <b className='required'>{t('firstName')}:</b>
            </label>

            <input name='updateFirstName' id='updateFirstName' value={sleep.updateFirstName || ''} onChange={handleOnChange} onBlur={handleOnBlur} type='text' placeholder={t('firstName')} data-cy='' className={`input ${updateFormError.updateFirstNameError ? 'input-error' : ''}`} />
            <p data-cy='' className={`error__onblur ${updateFormError.updateFirstNameError ? 'error' : ''}`}>
              {t('requiredField')}
            </p>
          </div>

          <div className='form-group relative'>
            <label className='label mb-1 d-block' htmlFor='updateLastName'>
              <b className='required'>{t('lastName')}:</b>
            </label>

            <input name='updateLastName' id='updateLastName' value={sleep.updateLastName || ''} onChange={handleOnChange} onBlur={handleOnBlur} type='text' placeholder={t('lastName')} data-cy='' className={`input ${updateFormError.updateLastNameError ? 'input-error' : ''}`} />
            <p data-cy='' className={`error__onblur ${updateFormError.updateLastNameError ? 'error' : ''}`}>
              {t('requiredField')}
            </p>
          </div>

          <div className='form-group relative'>
            <label className='label mb-1 d-block' htmlFor='updateEmail'>
              <b className='required'>{t('email')}:</b>
            </label>

            <input name='updateEmail' id='updateEmail' value={sleep.updateEmail || ''} onChange={handleOnChange} onBlur={handleOnBlur} type='email' placeholder={t('email')} data-cy='' className={`input ${updateFormError.updateEmailError ? 'input-error' : ''}`} />
            <p data-cy='' className={`error__onblur ${updateFormError.updateEmailError ? 'error' : ''}`}>
              {t('requiredField')}
            </p>
          </div>

          <span className='fake-label'>
            <b className='required'>{t('address')}:</b>
          </span>

          <div className='form-group form-group-address relative'>
            <div className='addresses' data-cy=''>
              {updateAddress &&
                updateAddress.length > 0 &&
                updateAddress.map((item, idx) => {
                  const { address, zip_code, city, state } = item;
                  return (
                    <div key={`address_update_${idx}`} className='item' data-cy=''>
                      <p>
                        {address}, {zip_code}, {city}, {state}
                      </p>
                      <div className='d-flex gap-x-1'>
                        <button
                          className='btn'
                          type='button'
                          onClick={() =>
                            handleOnRemoveUpdate({
                              _id: item._id,
                              index: idx,
                            })
                          }
                        >
                          <span className='icon'>
                            <img alt='Delete' src='/assets/icons/delete.svg' />
                          </span>
                        </button>
                      </div>
                    </div>
                  );
                })}
            </div>

            <button className='btn btn-default' type='button' onClick={() => setToggleUpdateAddress((prevState) => !prevState)}>
              <span className='icon'>
                <img alt='Create new Address' src='/assets/icons/plus.svg' />
              </span>
              <span className='text'></span>
            </button>
          </div>

          {toggleUpdateAddress && (
            <div className='wrapper-address'>
              <div className='form-group relative'>
                <label className='label mb-1 d-block' htmlFor='updateAddress'>
                  <b className='required'>{t('address')}:</b>
                </label>

                <input name='updateAddress' id='updateAddress' value={sleepAddress.updateAddress || ''} onChange={handleOnChange} onBlur={handleOnBlur} type='text' placeholder={t('address')} data-cy='' className={`input ${updateFormError.updateAddressError ? 'input-error' : ''}`} />
                <p data-cy='' className={`error__onblur ${updateFormError.updateAddressError ? 'error' : ''}`}>
                  {t('requiredField')}
                </p>
              </div>

              <div className='form-group relative'>
                <label className='label mb-1 d-block' htmlFor='updateZipCode'>
                  <b className='required'>{t('zipCode')}:</b>
                </label>

                <input name='updateZipCode' id='updateZipCode' value={sleepAddress.updateZipCode || ''} onChange={handleOnChange} onBlur={handleOnBlur} type='number' placeholder={t('zipCode')} data-cy='' className={`input ${updateFormError.updateZipCodeError ? 'input-error' : ''}`} />
                <p data-cy='' className={`error__onblur ${updateFormError.updateZipCodeError ? 'error' : ''}`}>
                  {t('requiredField')}
                </p>
              </div>

              <div className='form-group relative'>
                <label className='label mb-1 d-block' htmlFor='updateCity'>
                  <b className='required'>{t('city')}:</b>
                </label>

                <input name='updateCity' id='updateCity' value={sleepAddress.updateCity || ''} onChange={handleOnChange} onBlur={handleOnBlur} type='text' placeholder={t('city')} data-cy='' className={`input ${updateFormError.updateCityError ? 'input-error' : ''}`} />
                <p data-cy='' className={`error__onblur ${updateFormError.updateCityError ? 'error' : ''}`}>
                  {t('requiredField')}
                </p>
              </div>

              <div className='form-group relative'>
                <label className='label mb-1 d-block' htmlFor='updateState'>
                  <b className='required'>{t('state')}:</b>
                </label>

                <select name='updateState' id='updateState' value={sleepAddress.updateState || 'none'} onChange={handleOnChange} onBlur={handleOnBlur} data-cy='' className={`select select-full ${updateFormError.updateStateError ? 'input-error' : ''}`}>
                  <option value='none'>{t('selectState')}</option>
                  {stateUS &&
                    stateUS.length > 0 &&
                    stateUS.map((item) => {
                      return (
                        <option key={`update_${item.name.toLowerCase()}`} value={item.abbreviation}>
                          {item.name}
                        </option>
                      );
                    })}
                </select>

                <p data-cy='' className={`error__onblur ${updateFormError.updateStateError ? 'error' : ''}`}>
                  {t('requiredField')}
                </p>
              </div>

              <div className='form-group form-group-sidebar mt-6'>
                <button data-cy='' type='button' className='btn btn-primary-link' onClick={() => setToggleUpdateAddress(false)}>
                  <span className='text'>{t('cancel')}</span>
                </button>

                <button data-cy='' type='button' className='btn btn-primary' onClick={handleOnSaveUpdate}>
                  <span className='text'>{t('createAddress')}</span>
                </button>
              </div>
            </div>
          )}

          <div className='form-group relative'>
            <label className='label mb-1 d-block' htmlFor='updatePhone'>
              <b className='required'>{t('phone')}:</b>
            </label>

            <input name='updatePhone' id='updatePhone' value={sleep.updatePhone || ''} onChange={handleOnChange} onBlur={handleOnBlur} type='number' placeholder={t('phone')} data-cy='' className={`input ${updateFormError.updatePhoneError ? 'input-error' : ''}`} />
            <p data-cy='' className={`error__onblur ${updateFormError.updatePhoneError ? 'error' : ''}`}>
              {t('requiredField')}
            </p>
          </div>

          <div className='form-group relative'>
            <div className='toggle-new-password'>
              <input type='checkbox' id='toggleNewPassword' className='input-checkbox' onClick={() => setToggleNewPassword((prevState) => !prevState)} />
              <label htmlFor='toggleNewPassword'>{t('createNewPassword')}</label>
            </div>
          </div>

          {toggleNewPassword === true && <TogglePasswordChecker {...updatePasswordProps} />}

          <div className='form-group relative'>
            <label className='label mb-1 d-block' htmlFor='updateStatus'>
              <b className='required'>{t('status')}:</b>
            </label>

            <select name='updateStatus' id='updateStatus' value={sleep.updateStatus} onChange={handleOnChange} onBlur={handleOnBlur} data-cy='' className={`select select-full ${updateFormError.updateStatusError ? 'input-error' : ''}`}>
              <option value='none'>{t('select')}</option>
              <option value='active'>{t('active')}</option>
              <option value='inactive'>{t('inactive')}</option>
            </select>

            <p data-cy='' className={`error__onblur ${updateFormError.updateStatusError ? 'error' : ''}`}>
              {t('requiredField')}
            </p>
          </div>

          <div className='form-group form-group-sidebar'>
            <button data-cy='modal-update-cancel-btn' type='button' className='btn btn-primary-link' onClick={() => setToggleUpdate(false)}>
              <span className='text'>{t('cancel')}</span>
            </button>

            {!editBtnLoader ? (
              <button data-cy='modal-update-submit-btn' type='submit' className='btn btn-primary'>
                <span className='text'>{t('saveChanges')}</span>
              </button>
            ) : (
              <button type='button' className='btn btn-primary-outline'>
                <span className='text'>{t('saving')}...</span>
              </button>
            )}
          </div>
        </form>
      ) : (
        <Loading />
      )}
    </SidebarModal>
  );
};
