import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { SERVER_URL } from '../../../config/index';
import Modal from '../../../components/modal/Modal';
import { Col, Row, Wrapper } from '../../../components/content';
import { useAuth } from '../../../context/useAuth';
import { PERMISSIONS } from '../../../utils/permissions';
import { notification } from 'antd';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import Loading from '../../../components/loading/Loading';
import { SortableList } from './SortableList';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import CheckboxCms from '../../../components/checkbox-cms';

const extansionRemoved = (str) => {
  if (str) {
    return str.replace(/.png|.jpg|.jpeg|.gif|.svg/g, '');
  } else {
    console.warn('Problem with string of undefined');
    return;
  }
};

const SlideshowImages = (props) => {
  const { t } = useTranslation();
  const { data, setRefetch } = props;
  const { user } = useAuth();
  const { TabPane } = Tabs;

  const [toggleImage, setToggleImage] = useState(false);
  const [toggleImageLoader, setToggleImageLoader] = useState(false);
  const [imageId, setImageId] = useState({ id: '' });
  const [imageData, setImageData] = useState({});
  const [imageDataLoading, setImageDataLoading] = useState(false);
  const [featuredImageError, setFeaturedImageError] = useState(false);

  const handleOnToggle = (id) => {
    setImageId({ id: id });
    setToggleImage(true);
  };

  const getImageById = useCallback(
    async (image) => {
      const id = image.id;

      if (id && id.length > 0) {
        setImageDataLoading(true);
        await axios
          .get(`${SERVER_URL}/${AXIOS_API_CALL.slideshowgetImagesById}/${id}`)
          .then((res) => {
            if (res.status === 200) {
              setImageData(res?.data);
            }
          })
          .catch((err) => {
            console.error(err);
            setImageDataLoading(false);
          })
          .finally(() => {
            setTimeout(() => {
              setImageDataLoading(false);
            }, 700);
          });
      }
    },
    [user]
  );

  useEffect(() => {
    getImageById(imageId);
  }, [imageId]);

  const getDateFormatMDY = (date) => {
    const d = new Date(date);
    const monthAndDay = d.toLocaleString('default', { month: 'long', day: 'numeric' });
    const year = d.getFullYear();

    return `${monthAndDay}, ${year}`;
  };

  const [form, setForm] = useState({
    id: '',
    imageName: '',
    imageAlt: '',
    featured: false,
    sliderTitleLine1En: '',
    sliderTitleLine2En: '',
    sliderTitleLine3En: '',
    sliderSubTitleLine1En: '',
    sliderSubTitleLine2En: '',
    sliderSubTitleLine3En: '',
    sliderBtnText: '',
    sliderUrlRedirect: '',
    googleDescription: '',
    sliderTitleLine1Cg: '',
    sliderTitleLine2Cg: '',
    sliderTitleLine3Cg: '',
    sliderSubTitleLine1Cg: '',
    sliderSubTitleLine2Cg: '',
    sliderSubTitleLine3Cg: '',
    sliderBtnTextCg: '',
    sliderUrlRedirectCg: '',
    hideImage: false,
  });

  useEffect(() => {
    if (imageId.id !== '' && Object.keys(imageData).length > 0) {
      const form = {
        id: imageId.id,
        imageName: extansionRemoved(imageData.originalname),
        imageAlt: imageData.altTitle,
        featured: imageData.featured,
        sliderTitleLine1En: imageData.sliderTitleLine1En,
        sliderTitleLine2En: imageData.sliderTitleLine2En,
        sliderTitleLine3En: imageData.sliderTitleLine3En,
        sliderSubTitleLine1En: imageData.sliderSubTitleLine1En,
        sliderSubTitleLine2En: imageData.sliderSubTitleLine2En,
        sliderSubTitleLine3En: imageData.sliderSubTitleLine3En,
        sliderBtnText: imageData.sliderBtnText,
        sliderUrlRedirect: imageData.sliderUrlRedirect,
        googleDescription: imageData.googleDescription,
        sliderTitleLine1Cg: imageData.sliderTitleLine1Cg,
        sliderTitleLine2Cg: imageData.sliderTitleLine2Cg,
        sliderTitleLine3Cg: imageData.sliderTitleLine3Cg,
        sliderSubTitleLine1Cg: imageData.sliderSubTitleLine1Cg,
        sliderSubTitleLine2Cg: imageData.sliderSubTitleLine2Cg,
        sliderSubTitleLine3Cg: imageData.sliderSubTitleLine3Cg,
        sliderBtnTextCg: imageData.sliderBtnTextCg,
        sliderUrlRedirectCg: imageData.sliderUrlRedirectCg,
        hideImage: imageData.hideImage,
      };

      setForm(form);
    }
  }, [imageId, imageData, setForm]);

  const onChange = (event) => {
    const { name, value } = event.target;

    if (name === 'featured') {
      setForm((prevState) => {
        return { ...prevState, [name]: !prevState.featured };
      });
      return;
    }

    if (name === 'img_hide') {
      setForm((prevState) => ({
        ...prevState,
        hideImage: !prevState.hideImage,
      }));
      return;
    }

    setForm((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    const id = form.id;
    const token = user.token;

    let payload = {
      department: PERMISSIONS.grocery,
      title: form.imageName,
      featured: form.featured,
      sliderTitleLine1En: form.sliderTitleLine1En,
      sliderTitleLine2En: form.sliderTitleLine2En,
      sliderTitleLine3En: form.sliderTitleLine3En,
      sliderSubTitleLine1En: form.sliderSubTitleLine1En,
      sliderSubTitleLine2En: form.sliderSubTitleLine2En,
      sliderSubTitleLine3En: form.sliderSubTitleLine3En,
      sliderBtnText: form.sliderBtnText,
      sliderUrlRedirect: form.sliderUrlRedirect,
      googleDescription: form.googleDescription,
      sliderTitleLine1Cg: form.sliderTitleLine1Cg,
      sliderTitleLine2Cg: form.sliderTitleLine2Cg,
      sliderTitleLine3Cg: form.sliderTitleLine3Cg,
      sliderSubTitleLine1Cg: form.sliderSubTitleLine1Cg,
      sliderSubTitleLine2Cg: form.sliderSubTitleLine2Cg,
      sliderSubTitleLine3Cg: form.sliderSubTitleLine3Cg,
      sliderBtnTextCg: form.sliderBtnTextCg,
      sliderUrlRedirectCg: form.sliderUrlRedirectCg,
      hideImage: form.hideImage,
    };

    try {
      setToggleImageLoader(true);
      await axios
        .put(`${SERVER_URL}/${AXIOS_API_CALL.slideshowupdateGalleryImage}/${id}`, { ...payload }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          if (res.status === 200) {
            setRefetch((p) => !p);
            setToggleImage(false);
            setImageId({ id: '' });
            setImageData({});
            setToggleImageLoader(false);

            // notification
            notification.success({
              message: t('sliderDataSucessEdit'),
              placement: 'bottomLeft',
            });
          }
        })
        .catch((err) => {
          setFeaturedImageError(true);
          setToggleImageLoader(false);
        })
        .finally(() => {
          setToggleImageLoader(false);
        });
    } catch (err) {
      console.log('err', err.message);
    }
  };

  const [deleteBtnLoader, setDeleteBtnLoader] = useState(false);
  const onDelete = async () => {
    const id = form.id;
    const token = user.token;

    try {
      setDeleteBtnLoader(true);
      await axios
        .delete(`${SERVER_URL}/${AXIOS_API_CALL.slideshowdeleteGalleryImage}/${id}`, { withCredentials: false, headers: { department: PERMISSIONS.grocery, Authorization: `Bearer ${token}` } })
        .then((res) => {
          if (res.status === 200) {
            setImageId({ id: '' });
            setToggleImage(false);
            setImageData({});
            setRefetch((p) => !p);
            setDeleteBtnLoader(false);

            // notifications
            notification.success({
              message: t('imageDeletedSuccesfully'),
              placement: 'bottomLeft',
            });
          }
        })
        .catch((err) => {
          setDeleteBtnLoader(false);
          console.error(err);
        })
        .finally(() => {
          setDeleteBtnLoader(false);
        });
    } catch (err) {
      console.log('err', err.message);
    }
  };

  // order data by featured items first
  const sortedData = data?.sort((a, b) => {
    return a.positionIndex - b.positionIndex;
  });

  const onChangeTabSection1 = (key) => {
    console.log('key', key);
  };

  const [formGeneralSettings, setFormGeneralSettings] = React.useState({
    hide: false,
  });

  // const handleInputChangeGeneralSettings = (e) => {
  //   const { name, checked } = e.target;
  //   if (name === 'img_hide') {
  //     setForm((prevState) => ({
  //       ...prevState,
  //       hideImage: !prevState.hideImage,
  //     }));
  //   }
  // };
  console.log('form img slideshow', form);

  return (
    <>
      <Wrapper className="gallery-images">
        <Row>
          <SortableList>
            {sortedData &&
              sortedData.length > 0 &&
              sortedData.map((item) => {
                return (
                  <Col key={item._id}>
                    <div className="card" style={{ cursor: 'pointer' }} onClick={() => handleOnToggle(item._id)}>
                      {/* {item.featured && <div className="featured-flag">Prva slika</div>} */}
                      <img className="img" src={item.url} alt={item.originalname} />
                    </div>
                  </Col>
                );
              })}
          </SortableList>
        </Row>
      </Wrapper>

      <Modal active={toggleImage} onToggle={setToggleImage} setFeaturedImageError={setFeaturedImageError} title={t('imageSliderData')} className="gallery-view modal-full cms">
        <div className="view-layout relative">
          {!imageDataLoading ? (
            <>
              <div className="content-image relative">{imageData ? <img src={imageData?.url} /> : null}</div>

              <div className="content-info relative">
                {/* <div className="information">
                  <p>
                    <b>Uploaded:</b>
                    <span> {imageData && imageData?.updatedAt ? getDateFormatMDY(imageData?.updatedAt) : null}</span>
                  </p>
                </div> */}

                <form
                  onSubmit={(event) => {
                    onSubmit(event);
                  }}
                  name="update-image"
                >
                  <Tabs defaultActiveKey="1" onChange={onChangeTabSection1}>
                    <TabPane key="1" tab="EN">
                      <section className="form form-update-images slideshow-form-groups">
                        <div className="form-group">
                          <label className="label" htmlFor="sliderTitleLine1En">
                            <b>Slider title line 1:</b>
                          </label>

                          <input value={form.sliderTitleLine1En || ''} onChange={onChange} name="sliderTitleLine1En" id="sliderTitleLine1En" className="input input-full" type="text" placeholder="Slider Title Line 1" />
                        </div>

                        <div className="form-group">
                          <label className="label" htmlFor="sliderTitleLine2En">
                            <b>Slider title line 2:</b>
                          </label>

                          <input value={form.sliderTitleLine2En || ''} onChange={onChange} name="sliderTitleLine2En" id="sliderTitleLine2En" className="input input-full" type="text" placeholder="Slider Title Line 2" />
                        </div>

                        <div className="form-group">
                          <label className="label" htmlFor="sliderTitleLine3En">
                            <b>Slider title line 3:</b>
                          </label>

                          <input value={form.sliderTitleLine3En || ''} onChange={onChange} name="sliderTitleLine3En" id="sliderTitleLine3En" className="input input-full" type="text" placeholder="Slider Title Line 3" />
                        </div>

                        <div className="form-group">
                          <label className="label" htmlFor="sliderSubTitleLine1En">
                            <b>Slider text line 1:</b>
                          </label>

                          <input value={form.sliderSubTitleLine1En || ''} onChange={onChange} name="sliderSubTitleLine1En" id="sliderSubTitleLine1En" className="input input-full" type="text" placeholder="Slider text line 1" />
                        </div>

                        <div className="form-group">
                          <label className="label" htmlFor="sliderSubTitleLine2En">
                            <b>Slider text line 2:</b>
                          </label>

                          <input value={form.sliderSubTitleLine2En || ''} onChange={onChange} name="sliderSubTitleLine2En" id="sliderSubTitleLine2En" className="input input-full" type="text" placeholder="Slider text line 2" />
                        </div>

                        <div className="form-group">
                          <label className="label" htmlFor="sliderSubTitleLine3En">
                            <b>Slider text line 3:</b>
                          </label>

                          <input value={form.sliderSubTitleLine3En || ''} onChange={onChange} name="sliderSubTitleLine3En" id="sliderSubTitleLine3En" className="input input-full" type="text" placeholder="Slider text line 3" />
                        </div>

                        <div className="form-group">
                          <label className="label" htmlFor="sliderBtnText">
                            <b>Button text:</b>
                          </label>

                          <input value={form.sliderBtnText || ''} onChange={onChange} name="sliderBtnText" id="sliderBtnText" className="input input-full" type="text" placeholder="Button text" />
                        </div>

                        <div className="form-group">
                          <label className="label" htmlFor="sliderUrlRedirect">
                            <b>Button URL:</b>
                          </label>

                          <input value={form.sliderUrlRedirect || ''} onChange={onChange} name="sliderUrlRedirect" id="sliderUrlRedirect" className="input input-full" type="text" placeholder="Button URL" />
                        </div>
                      </section>
                    </TabPane>
                    <TabPane key="2" tab="CG">
                      <section className="form form-update-images slideshow-form-groups">
                        <div className="form-group">
                          <label className="label" htmlFor="sliderTitleLine1Cg">
                            <b>Naslov slidera linija 1:</b>
                          </label>

                          <input value={form.sliderTitleLine1Cg || ''} onChange={onChange} name="sliderTitleLine1Cg" id="sliderTitleLine1Cg" className="input input-full" type="text" placeholder="Naslov slidera linija 1" />
                        </div>

                        <div className="form-group">
                          <label className="label" htmlFor="sliderTitleLine2Cg">
                            <b>Naslov slidera linija 2:</b>
                          </label>

                          <input value={form.sliderTitleLine2Cg || ''} onChange={onChange} name="sliderTitleLine2Cg" id="sliderTitleLine2Cg" className="input input-full" type="text" placeholder="Naslov slidera linija 2" />
                        </div>

                        <div className="form-group">
                          <label className="label" htmlFor="sliderTitleLine3Cg">
                            <b>Naslov slidera linija 3:</b>
                          </label>

                          <input value={form.sliderTitleLine3Cg || ''} onChange={onChange} name="sliderTitleLine3Cg" id="sliderTitleLine3Cg" className="input input-full" type="text" placeholder="Naslov slidera linija 3" />
                        </div>

                        <div className="form-group">
                          <label className="label" htmlFor="sliderSubTitleLine1Cg">
                            <b>Tekst slidera linija 1:</b>
                          </label>

                          <input value={form.sliderSubTitleLine1Cg || ''} onChange={onChange} name="sliderSubTitleLine1Cg" id="sliderSubTitleLine1Cg" className="input input-full" type="text" placeholder="Tekst slidera linija 1" />
                        </div>

                        <div className="form-group">
                          <label className="label" htmlFor="sliderSubTitleLine2Cg">
                            <b>Tekst slidera linija 2:</b>
                          </label>

                          <input value={form.sliderSubTitleLine2Cg || ''} onChange={onChange} name="sliderSubTitleLine2Cg" id="sliderSubTitleLine2Cg" className="input input-full" type="text" placeholder="Tekst slidera linija 2" />
                        </div>

                        <div className="form-group">
                          <label className="label" htmlFor="sliderSubTitleLine3Cg">
                            <b>Tekst slidera linija 3:</b>
                          </label>

                          <input value={form.sliderSubTitleLine3Cg || ''} onChange={onChange} name="sliderSubTitleLine3Cg" id="sliderSubTitleLine3Cg" className="input input-full" type="text" placeholder="Tekst slidera linija 3" />
                        </div>

                        <div className="form-group">
                          <label className="label" htmlFor="sliderBtnText">
                            <b>Tekst dugmeta:</b>
                          </label>

                          <input value={form.sliderBtnTextCg || ''} onChange={onChange} name="sliderBtnTextCg" id="sliderBtnText" className="input input-full" type="text" placeholder="Tekst dugmeta" />
                        </div>

                        <div className="form-group">
                          <label className="label" htmlFor="sliderUrlRedirect">
                            <b>URL dugmeta:</b>
                          </label>

                          <input value={form.sliderUrlRedirectCg || ''} onChange={onChange} name="sliderUrlRedirectCg" id="sliderUrlRedirect" className="input input-full" type="text" placeholder="URL dugmeta" />
                        </div>
                      </section>
                    </TabPane>
                  </Tabs>
                  <CheckboxCms inputName="img_hide" isChecked={form.hideImage} handleChange={onChange} label={t('hideImageOnSlider')} tooltipText={t('hideImageOnSliderTooltipText')} />

                  <div className="form-group" style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
                    <div className="actions">
                      {toggleImageLoader || deleteBtnLoader ? (
                        <button className="btn btn-primary pl-2 pr-2" type="button" style={{ maxWidth: 'unset' }}>
                          <span className="text">{t('saving')}...</span>
                        </button>
                      ) : (
                        <button className="btn btn-primary pl-2 pr-2" type="submit" style={{ maxWidth: 'unset' }}>
                          <span className="text">{t('saveChanges')}</span>
                        </button>
                      )}
                    </div>
                  </div>
                </form>

                <div className="information">
                  <div className="actions">
                    {toggleImageLoader || deleteBtnLoader ? (
                      <button type="button" className="btn btn-danger">
                        <span className="text">{t('deleting')}...</span>
                      </button>
                    ) : (
                      <button onClick={() => onDelete()} type="button" className="btn btn-danger">
                        <span className="text">{t('delete')}</span>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Loading />
          )}
        </div>
      </Modal>
    </>
  );
};

export default SlideshowImages;
